;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('AdminFutureCameraFormController', AdminFutureCameraFormController)

  AdminFutureCameraFormController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'ApiCameraModel',
    'ApiAdminCameraModel',
    'CameraMapService',
    'FlashMessage',
    'BOOL_VALUES',
    'APP_CONFIG',
  ]

  function AdminFutureCameraFormController(
    $state,
    $stateParams,
    $uibModal,
    ApiCameraModel,
    ApiAdminCameraModel,
    CameraMapService,
    FlashMessage,
    BOOL_VALUES,
    APP_CONFIG,
  ) {
    const vm = this

    vm.ok = ok

    vm.data = {
      mapLayers: [],
      legend: [],
    }
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.isEdit = !!$stateParams.id

    vm.boolSelect = BOOL_VALUES
    vm.openUploadDocumentModal = openUploadDocumentModal
    vm.openDeleteDocumentModal = openDeleteDocumentModal

    // TODO remove filled form data
    // vm.camera = {
    //     "name": "TK 007 2",
    //     "alias": "TK 007 2",
    //     "isPublic": false,
    //     "requestedBy": "Nekdo",
    //     "requestedOn": null,
    //     "requestNote": "",
    //     "cameraMobilityType": "FIXED",
    //     "description": "Pekna kamerka",
    //     "location": {
    //         "latitude": 0,
    //         "longitude": 0
    //     },
    //     "locationDescription": "Nekde",
    //     "locationOwner": "Nekdo",
    //     "locationAddress": "Nekde",
    //     "constructionReason": "NewCamera",
    //     "state": "Draft",
    //     "newFibreOpticConnectionOn": null,
    //     "existingFibreOpticConnectionSbName": "Unknown",
    //     "fibreOptionConnectionNote": "",
    //     "ownerApprovalRequestOn": null,
    //     "ownerApprovedOn": null,
    //     "ownerApprovalNote": "Tak teda jo no",
    //     "conservationApprovalRequestedOn": null,
    //     "conservationApprovedOn": null,
    //     "conservationApprovalNote": "Tak my taky jo",
    //     "buildingPermitRequestedOn": null,
    //     "buildingPermitGrantedOn": null,
    //     "buildingPermitNote": "Jasny, v pohode",
    //     "connectionToNnOn": null,
    // };

    activate()

    function activate() {
      getCamera()
      getCameraTypes()
      getCameraStates()
      getCameraConstructionReasons()
    }

    function getCamera() {
      vm.loadingData = true

      if ($stateParams.id) {
        ApiAdminCameraModel.getFutureCameraRequest({ id2: $stateParams.id }, function (data) {
          vm.camera = data
          initMap(vm.camera)
          vm.loadingData = false
        })
      } else {
        vm.camera = {
          cameraMobilityType: 'FIXED',
          constructionReason: 'NewCamera',
          state: 'Draft',
        }
        initMap(vm.camera)
        vm.loadingData = false
      }
    }

    function getCameraTypes() {
      return (vm.cameraTypes = ApiCameraModel.getTypes())
    }

    function getCameraStates() {
      return (vm.cameraStates = ApiCameraModel.getCameraStates())
    }

    function getCameraConstructionReasons() {
      return (vm.cameraConstructionReasons = ApiCameraModel.getCameraConstructionReasons())
    }

    function initMap(futureCamera) {
      CameraMapService.initCameraMapForFutureCameraSelect(vm.data, futureCamera)
    }

    function createFutureCamera(camera) {
      var attachments = angular.copy(camera.attachments)
      delete camera['attachments'] // TODO hack protoze BE haze chybu, kdyz mu poslu atribut attachments, melo by se poresit na BE

      ApiAdminCameraModel.createFutureCamera({ camera: camera }, function (data) {
        FlashMessage.add('Kamera byla úspěšně vytvořena.', 'success', 1)

        angular.forEach(attachments, function (file) {
          ApiAdminCameraModel.assignFutureCameraAttachment({
            id1: data.id,
            id2: file.id,
          })
        })
        $state.go('admin.future-camera')
      })
    }

    function updateFutureCamera(camera) {
      ApiAdminCameraModel.updateFutureCamera(
        { id2: camera.id },
        { camera: camera },
        function (data) {
          FlashMessage.add('Kamera byla úspěšně upravena.', 'success', 1)
          $state.go('admin.future-camera')
        },
      )
    }

    function ok() {
      if (vm.isEdit) {
        updateFutureCamera(vm.camera)
      } else {
        createFutureCamera(vm.camera)
      }
    }

    function openUploadDocumentModal() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadDocumentModal.html',
        controller: 'UploadDocumentModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function (files) {
          if (!vm.camera.hasOwnProperty('attachments')) {
            vm.camera.attachments = []
          }

          angular.forEach(files, function (file) {
            if (vm.isEdit) {
              ApiAdminCameraModel.assignFutureCameraAttachment({
                id1: vm.camera.id,
                id2: file.fileInfo.id,
              })
            }
            vm.camera.attachments.push(file.fileInfo)
          })
        },
        function () {},
      )
    }

    function openDeleteDocumentModal(file) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/deleteCameraAttachmentModal.html',
        controller: 'DeleteCameraAttachmentModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return vm.camera
          },
          attachment: function () {
            return file
          },
        },
      })
      modalInstance.result.then(
        function (file) {
          if (vm.isEdit) {
            ApiAdminCameraModel.unassignFutureCameraAttachment({
              id1: vm.camera.id,
              id2: file.id,
            })
          }
          for (var index in vm.camera.attachments) {
            if (vm.camera.attachments[index].id === file.id) {
              vm.camera.attachments.splice(index, 1)
              break
            }
          }
        },
        function () {},
      )
    }
  }
})()
