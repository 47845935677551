;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('ServerLayer', ServerLayer)

  ServerLayer.$inject = ['Layer']

  function ServerLayer(Layer) {
    function _createEsriLayer(options) {
      var innerOptions = {
        url: options.url,
        minZoom: 0,
        maxZoom: 18,
        continuousWorld: true,
        opacity: options.opacity,
      }

      if (options.where) {
        innerOptions.where = options.where
      }

      var esriLayer = null
      if (options.isWms) {
        innerOptions.transparent = true
        innerOptions.format = 'image/png'
        innerOptions.layers = options.wmsLayers
        delete innerOptions.url
        esriLayer = new L.TileLayer.WMS(options.url, innerOptions)
      } else if (options.isDynamic) {
        var dynamicLayerOptions = angular.extend(innerOptions, {
          layers: options.layers,
        })
        esriLayer = new L.AgsDynamicLayer(options.url, dynamicLayerOptions)
      } else {
        esriLayer = new L.esri.TiledMapLayer(innerOptions)
      }
      if (options.tileSize) {
        esriLayer.options.tileSize = options.tileSize
      }
      return esriLayer
    }

    return function (options) {
      var child = Object.create(Layer)
      child.super(options)

      child.init = function () {
        this.options = options
        this.layer = _createEsriLayer(options)

        var me = this
        if (this.options.clickFn && this.options.clickContext && this.options.map) {
          this.options.map.on('click', function (event) {
            if (me.options.map.hasLayer(me.layer)) {
              child.handleServerQuery(event)
            }
          })
        }
        return child
      }

      return child.init()
    }
  }
})()
