;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('datetimeFilter', DatetimeFilter)

  DatetimeFilter.$inject = []

  function DatetimeFilter() {
    var dateFormat = 'YYYY-MM-DD HH:mm:ss Z'

    function compareLt(item, datetime) {
      return item.isBefore(datetime)
    }

    function compareLte(item, datetime) {
      return compareLt(item, datetime) || item.isSame(datetime)
    }

    function compareGt(item, datetime) {
      return item.isAfter(datetime)
    }

    function compareGte(item, datetime) {
      return compareGt(item, datetime) || item.isSame(datetime)
    }

    return function (items, query, itemDatePath) {
      if (angular.isDefined(items) && angular.isDefined(query)) {
        var filtered = items
        var datePath = angular.isDefined(itemDatePath) ? itemDatePath : Object.keys(query)[0]
        if (!angular.isDefined(itemDatePath)) {
          query = query[datePath]
        }
        if (datePath) {
          var stepFiltered = []
          for (var i = 0; i < filtered.length; i++) {
            var item = filtered[i]
            var itemDate = _.get(item, datePath)
            if (itemDate) {
              var itemDatetime = moment(itemDate, dateFormat)
              var isGreater = true
              var isLess = true
              if (angular.isDefined(query.from) && query.from != null) {
                var from = moment(query.from, dateFormat)
                isGreater = compareGte(itemDatetime, from)
              }
              if (angular.isDefined(query.to) && query.to != null) {
                var to = moment(query.to, dateFormat)
                isLess = compareLte(itemDatetime, to)
              }
              if (isGreater && isLess) {
                stepFiltered.push(item)
              }
            }
          }
          filtered = stepFiltered
        }
        return filtered
      }
      return items
    }
  }
})()
