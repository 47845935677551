;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminBollardsModel', ApiAdminBollardsModel)

  ApiAdminBollardsModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminBollardsModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/:path/:path1',
      { path: '@path', path1: '@path1' },
      {
        getTrafficBollardsPoints: {
          method: 'GET',
          params: {
            path: 'trafficBollards',
          },

        },
      },
    )
  }
})()
