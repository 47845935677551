L.Intens = function () {}

L.Intens.CollapsibleControl = L.Control.Layers.extend({
  options: {
    position: 'topleft',
    title: {
      false: 'Zobrazit legendu',
      true: 'Skrýt legendu',
    },
  },

  setLayerGroups: function (obj) {
    this._layerGroups = obj
    this._update()
  },

  update: function () {
    this._update()
  },

  addBaseLayer: function (layer, name) {
    this._addLayer(layer, name)
    return this
  },

  addOverlay: function (layer, name, options) {
    this._addLayer(layer, name, true, options)
    return this
  },

  onRemove: function () {
    this._map.off('zoomend', this._checkDisabledLayers, this)

    for (var id in this._layers) {
      if (this._layers.hasOwnProperty(id)) {
        this._layers[id].layer.off('add remove', this._onLayerChange, this)
      }
    }
  },

  _addLayer: function (layer, name, overlay, options) {
    layer.on('add remove', this._onLayerChange, this)

    var id = L.Util.stamp(layer)

    this._layers[id] = {
      layer: layer,
      name: name,
      overlay: overlay,
      options: options,
    }

    if (this.options.autoZIndex && layer.setZIndex) {
      this._lastZIndex++
      layer.setZIndex(this._lastZIndex)
    }
    this._layersArr = this._layersArr || []
    this._layersArr.push(this._layers[id])
  },

  _initLayout: function () {
    var className = 'leaflet-control-layers',
      container = (this._container = L.DomUtil.create('div', className))

    //Makes this work on IE10 Touch devices by stopping it from firing a mouseout event when the touch is released
    container.setAttribute('aria-haspopup', 'true')

    if (!L.Browser.touch) {
      L.DomEvent.disableClickPropagation(container).disableScrollPropagation(container)
    } else {
      L.DomEvent.on(container, 'click', L.DomEvent.stopPropagation)
      L.DomEvent.on(container, 'dblclick', L.DomEvent.stopPropagation)
    }

    var form = (this._form = L.DomUtil.create('form', className + '-list'))

    if (this.options.collapsed) {
      if (!L.Browser.android) {
        L.DomEvent.on(container, 'mouseover', this._expand, this).on(
          container,
          'mouseout',
          this._collapse,
          this,
        )
      }
      var link = L.DomUtil.create('a', className + '-toggle', container)
      link.href = '#'
      link.title = 'Layers'

      if (L.Browser.touch) {
        L.DomEvent.on(link, 'click', L.DomEvent.stop).on(link, 'click', this._expand, this)
      } else {
        L.DomEvent.on(link, 'focus', this._expand, this)
      }
      //Work around for Firefox android issue https://github.com/Leaflet/Leaflet/issues/2033
      L.DomEvent.on(
        form,
        'click',
        function () {
          setTimeout(L.bind(this._onInputClick, this), 0)
        },
        this,
      )

      this._map.on('click', this._collapse, this)
      // TODO keyboard accessibility
    } else {
      this._expand()
    }

    this._baseLayersList = L.DomUtil.create('div', className + '-base', form)
    this._separator = L.DomUtil.create('div', className + '-separator', form)
    this._overlaysList = L.DomUtil.create('div', className + '-overlays', form)

    container.appendChild(form)
  },

  removeLayer: function (layer) {
    layer.off('add remove', this._onLayerChange, this)
    var id = L.Util.stamp(layer)
    if (this._layersArr) {
      var index = this._layersArr.indexOf(layer)
      if (index > -1) {
        this._layersArr.splice(index, 1)
      }
    }
    delete this._layers[id]
    return this._update()
  },

  // _addItem: function (obj, parentContainer) {
  //     var label = document.createElement('label'),
  //         input,
  //         checked = this._map.hasLayer(obj.layer);
  //
  //     if (obj.overlay) {
  //         input = document.createElement('input');
  //         input.type = 'checkbox';
  //         input.className = 'leaflet-control-layers-selector';
  //         input.defaultChecked = checked;
  //     } else {
  //         input = this._createRadioElement('leaflet-base-layers', checked);
  //     }
  //
  //     input.layerId = L.Util.stamp(obj.layer);
  //
  //     L.DomEvent.on(input, 'click', this._onInputClick, this);
  //
  //     var name = document.createElement('span');
  //     name.innerHTML = ' ' + obj.name;
  //
  //     if (!obj.options.legendOptions.disable) {
  //         label.appendChild(input);
  //     }
  //     label.appendChild(name);
  //
  //     var container = parentContainer || (obj.overlay ? this._overlaysList : this._baseLayersList);
  //     container.appendChild(label);
  //
  //     return label;
  // },

  _onInputClick: function () {
    var inputs = this._form.getElementsByTagName('input'),
      input,
      layer,
      hasLayer
    var addedLayers = [],
      removedLayers = []

    this._handlingClick = true

    for (var i = inputs.length - 1; i >= 0; i--) {
      input = inputs[i]
      layer = this._getLayer(input.layerId).layer
      hasLayer = this._map.hasLayer(layer)

      if (input.checked && !hasLayer) {
        addedLayers.push(layer)
      } else if (!input.checked && hasLayer) {
        removedLayers.push(layer)
      }
    }

    // Bugfix issue 2318: Should remove all old layers before readding new ones
    for (i = 0; i < removedLayers.length; i++) {
      this._map.removeLayer(removedLayers[i])
    }
    for (i = 0; i < addedLayers.length; i++) {
      this._map.addLayer(addedLayers[i])
    }

    this._handlingClick = false

    this._refocusOnMap()
  },

  _addItem: function (obj, parentContainer) {
    var id = obj.options.key
    var div = document.createElement('label'),
      input,
      checked = this._map.hasLayer(obj.layer)

    if (obj.overlay) {
      input = document.createElement('input')
      input.id = id
      input.type = 'checkbox'
      input.className = 'leaflet-control-layers-selector'
      input.defaultChecked = checked
    } else {
      input = this._createRadioElement('leaflet-base-layers', checked)
    }

    input.layerId = L.Util.stamp(obj.layer)

    L.DomEvent.on(input, 'click', this._onInputClick, this)

    var label = document.createElement('label')
    label.htmlFor = id
    var span = document.createElement('span')
    span.innerHTML = ' ' + obj.name

    div.className = 'chkbox ' + (obj.options.group ? 'hidden' : '')
    if (obj.options.legendOptions && !obj.options.legendOptions.disable) {
      div.appendChild(input)
    } else {
      var emptySpan = document.createElement('span')
      emptySpan.className = 'fake-input'
      div.appendChild(emptySpan)
    }
    label.appendChild(span)
    div.appendChild(label)

    var container = parentContainer || (obj.overlay ? this._overlaysList : this._baseLayersList)
    if (obj.options.legendOptions && obj.options.legendOptions.display === true) {
      container.appendChild(div)
    }

    return div
  },

  _sortLayers: function (layers) {
    return (
      _.sortBy(layers, function (obj) {
        if (obj.hasOwnProperty('options') && obj.options.hasOwnProperty('legendOptions')) {
          return obj.options.legendOptions.order || 999
        }
        return 999
      }) || []
    )
  },

  _update: function () {
    if (!this._container) {
      return
    }

    this._baseLayersList.innerHTML = ''
    this._overlaysList.innerHTML = ''

    var baseLayersPresent = false,
      overlaysPresent = false,
      j,
      me = this

    if (this._layerGroups) {
      var accordionParent = document.createElement('div')
      accordionParent.className = 'panel-group'
      var randomId = Math.round(Math.random() * 10000000)
      accordionParent.id = 'layercontrolaccordion' + randomId
      this._overlaysList.appendChild(accordionParent)

      for (j = 0; j < this._layerGroups.length; j++) {
        var layerGroup = this._layerGroups[j]
        var panelParent = document.createElement('div')
        panelParent.className = 'panel panel-default'
        accordionParent.appendChild(panelParent)

        var contentId = 'collapse' + randomId + '' + (j + 1)

        var collapseHeader = document.createElement('div')
        collapseHeader.className = 'panel-heading'
        var collapseHeaderHtml =
          '<h4 class="panel-title"><a data-toggle="collapse" data-parent="#' +
          accordionParent.id +
          '" '
        collapseHeaderHtml +=
          'data-target="#' + contentId + '" href="javascript:;">' + layerGroup.name + '</a></h4>'
        collapseHeader.innerHTML = collapseHeaderHtml
        panelParent.appendChild(collapseHeader)

        var contentDiv = document.createElement('div')
        contentDiv.className = 'panel-collapse collapse' + (false ? ' in' : '')
        contentDiv.id = contentId
        panelParent.appendChild(contentDiv)

        var layersDiv = document.createElement('div')
        layersDiv.className = 'panel-body'
        contentDiv.appendChild(layersDiv)

        var layers = this._sortLayers(layerGroup.layers)
        layers.forEach(function (layer) {
          me._addItem(layer, layersDiv)
        })
      }
    }

    this._layersArr = this._sortLayers(this._layersArr) || []
    this._layersArr.forEach(function (obj) {
      if (obj.overlay && me._layerGroups) {
        overlaysPresent = overlaysPresent || obj.overlay
        return
      }
      me._addItem(obj)
      overlaysPresent = overlaysPresent || obj.overlay
      baseLayersPresent = baseLayersPresent || !obj.overlay
    })

    if (this._layersArr.length === 0) {
      this._container.style.display = 'none'
    } else {
      this._container.style.display = 'block'
    }
    this._separator.style.display = overlaysPresent && baseLayersPresent ? '' : 'none'
  },

  _expand: function () {
    this._changeTouchEvents(false)
    L.DomUtil.addClass(this._container, 'leaflet-control-layers-expanded')
    this._form.style.height = null
    var acceptableHeight = this._map._size.y - (this._container.offsetTop + 50)
    if (acceptableHeight >= this._form.clientHeight + 20) {
      L.DomUtil.addClass(this._overlaysList, 'leaflet-control-layers-noscrollbar')
      //this._form.style.height = acceptableHeight + 'px';
    } else {
      L.DomUtil.removeClass(this._overlaysList, 'leaflet-control-layers-noscrollbar')
    }
    this._checkDisabledLayers()
  },

  _collapse: function (e) {
    // hacks clicking on bootstrap accordion needs to be propagated up in touch browsers, but this also closes the control
    // This one prevents the control from closing
    var event = e.originalEvent || e
    var target = event.target
    if (
      target.offsetParent &&
      L.DomUtil.hasClass(target.offsetParent, 'leaflet-control-layers-list')
    ) {
      return
    }
    this._changeTouchEvents(true)
    L.DomUtil.removeClass(this._container, 'leaflet-control-layers-expanded')
  },

  _changeTouchEvents: function (toBeEnabled) {
    if (toBeEnabled) {
      this._map.dragging.enable()
      this._map.touchZoom.enable()
      if (this._map.tap) {
        this._map.tap.enable()
      }
    } else {
      this._map.dragging.disable()
      this._map.touchZoom.disable()
      if (this._map.tap) {
        this._map.tap.disable()
      }
    }
  },

  _checkDisabledLayers: function () {
    var inputs = this._form.getElementsByTagName('input'),
      input,
      layer,
      zoom = this._map.getZoom()

    for (var i = inputs.length - 1; i >= 0; i--) {
      input = inputs[i]
      var foundLayer = this._layers[input.layerId] || {}
      layer = foundLayer.layer
      if (!layer || !layer.options) {
        continue
      }
      input.disabled =
        (layer.options.minZoom !== undefined && zoom < layer.options.minZoom) ||
        (layer.options.maxZoom !== undefined && zoom > layer.options.maxZoom)
    }
  },
})

L.Intens.BaseLayerSwitcher = L.Control.extend({
  options: {
    position: 'bottomright',
  },

  initialize: function (layersToShow, layersToHide, cls, title) {
    this._layersToShow = layersToShow
    this._layersToHide = layersToHide
    this._cls = cls
    this._title = title
  },

  onAdd: function (map) {
    var container = L.DomUtil.create(
      'div',
      'leaflet-control-baselayerswitcher leaflet-bar leaflet-control ' + this._cls,
    )

    this.link = L.DomUtil.create('a', 'leaflet-bar-part', container)
    this.link.href = '#'
    if (this._title) {
      this.link.title = this._title
    }

    this._map = map

    L.DomEvent.on(this.link, 'click', this._click, this)

    return container
  },

  _click: function (e) {
    L.DomEvent.stopPropagation(e)
    L.DomEvent.preventDefault(e)
    this.activate()
  },

  activate: function () {
    var children = this.link.parentNode.parentNode.children
    var childrenLength = children.length
    var i

    for (i = 0; i < childrenLength; i++) {
      var child = children[i]
      L.DomUtil.removeClass(child.children[0], 'active')
    }
    L.DomUtil.addClass(this.link, 'active')
    for (i = 0; i < this._layersToHide.length; i++) {
      this._map.removeLayer(this._layersToHide[i])
    }
    for (i = 0; i < this._layersToShow.length; i++) {
      this._map.addLayer(this._layersToShow[i])
    }
  },
})
