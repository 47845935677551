;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('ddrImage', DdrImageFilter)

  DdrImageFilter.$inject = []

  function DdrImageFilter() {
    return function (ddrMessage) {
      var imageName = 'dopravni_znacka_genericInfo.png'

      if (ddrMessage.hasOwnProperty('categories') && ddrMessage.categories.length > 0) {
        switch (ddrMessage.categories[0]) {
          case 'Accident':
            imageName = 'dopravni_znacka_nehoda.png'
            break
          case 'Warning':
            imageName = 'dopravni_znacka_nebezpeci.png'
            break
          case 'Closures':
            imageName = 'dopravni_znacka_uzavirka.png'
            break
          case 'Roadworks':
            imageName = 'dopravni_znacka_prace.png'
            break
          case 'WinterInfo':
            imageName = 'dopravni_znacka_namraza.png'
            break
          case 'WeatherConditions':
            imageName = 'dopravni_znacka_vitr.png'
            break
        }
      }

      return 'styles/img/' + imageName
    }
  }
})()
