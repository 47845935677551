;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.services')
    .factory('DynamicDataSourceLayerProvider', DynamicDataSourceLayerProvider)

  DynamicDataSourceLayerProvider.$inject = [
    '$translate',
    'ItemCollectionLayerProviderBase',
    'ApiMapModel',
    'MapHelperService',
    'GeometryHelper',
  ]

  function DynamicDataSourceLayerProvider(
    $translate,
    ItemCollectionLayerProviderBase,
    ApiMapModel,
    MapHelperService,
    GeometryHelper,
  ) {
    return function (map, endpointKey, layerConfig, dateFrom, dateTo) {
      var child = Object.create(ItemCollectionLayerProviderBase)
      child.super(map)

      child.init = function () {
        this.endpointKey = endpointKey
        this.layerConfig = layerConfig
        this.dateFrom = dateFrom || moment().startOf('year').subtract(100, 'years').format()
        this.dateTo = dateTo || moment().format()
      }

      child.update = function update() {
        return ApiMapModel.getDataForMap(
          {
            id: this.endpointKey,
            id1: this.layerConfig.key,
            since: this.dateFrom,
            until: this.dateTo,
            cultureCode: $translate.use(),
          },
          function (data) {
            child.processItems(data)
          },
        )
      }

      child.createLayer = function createLayer(item) {
        var markerOptions = {}

        if (this.layerConfig.legendOptions.hasOwnProperty('colors')) {
          markerOptions = {
            icon: L.divIcon({
              html: `<div style="background-color: ${this.layerConfig.legendOptions.colors[0]}; height:inherit"></div>`,
              iconSize: L.point(20, 20),
              className: 'dynamic-div-icon',
            }),
          }
        } else if (this.layerConfig.legendOptions.hasOwnProperty('images')) {
          markerOptions = {
            icon: MapHelperService.createIcon(
              'styles/img/' + this.layerConfig.legendOptions.images[0],
            ),
          }
        } else {
          log.error('Dynamic legend layer color or image has to be set.')
        }

        return new L.marker([item.latitude, item.longitude], markerOptions)
      }

      child.getItemKey = function getItemKey(item) {
        return item.id.toString()
      }

      child.itemsEqual = function itemsEqual(item1, item2) {
        return GeometryHelper.coordinatesEqual(item1, item2) && item1.id === item2.id
      }

      child.getDateFrom = function () {
        return this.dateFrom
      }

      child.getDateTo = function () {
        return this.dateTo
      }

      child.init()
      return child
    }
  }
})()
