;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').controller('FrontLayoutController', FrontLayoutController)

  FrontLayoutController.$inject = []

  function FrontLayoutController() {}
})()
