;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.event')
    .controller('AdminEventDetailController', AdminEventDetailController)

  AdminEventDetailController.$inject = [
    '$http',
    '$state',
    '$stateParams',
    '$uibModal',
    'ApiUserModel',
    'ApiAdminUserModel',
    'ApiEventModel',
    'ApiAdminEventModel',
    'PersistenceModel',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function AdminEventDetailController(
    $http,
    $state,
    $stateParams,
    $uibModal,
    ApiUserModel,
    ApiAdminUserModel,
    ApiEventModel,
    ApiAdminEventModel,
    PersistenceModel,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.hasHeadManagerRole = PersistenceModel.hasHeadManagerRole()
    vm.hasEventPublisherRole = PersistenceModel.hasEventPublisherRole()
    vm.hasTranslatorRole = PersistenceModel.hasTranslatorRole()

    vm.publishEvent = publishEvent
    vm.unpublishEvent = unpublishEvent
    vm.openUploadDocumentModal = openUploadDocumentModal
    vm.isWebPublicationType = isWebPublicationType
    vm.isEmailPublicationType = isEmailPublicationType
    vm.eventPublicationTypeChanged = eventPublicationTypeChanged

    activate()

    function activate() {
      prepareTableHeaders()
      getEvent()
      // getEventSeverities();
      getEventPriorities()
      getEventTypes()
      getEventPublications()

      getPublicationTargetTypes()
      getEventDistricts()
      getUserRoles()
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/event/meta/event-detail.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getEvent() {
      vm.loadingData = true
      ApiAdminEventModel.getSingle(
        {
          id: $stateParams.id,
          cultureCode: APP_CONFIG.translation.fallbackLanguage,
        },
        function (event) {
          vm.event = angular.extend(event, event.eventCultures[0])
          if (
            vm.event.publication &&
            vm.event.publication.emailToDistricts &&
            vm.event.publication.emailToDistricts.length === 0
          ) {
            delete vm.event.publication.emailToDistricts
          }
          vm.event.publication = angular.extend({}, setDefaultPublication(), vm.event.publication)
          vm.event.isPublished = vm.event.publication.publicationType === 'PUBLISHED'
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function setDefaultPublication() {
      return {
        publishTarget: vm.hasEventPublisherRole || vm.hasTranslatorRole ? 'WEB_AND_EMAIL' : 'EMAIL',
        private: true,
        publishForRolesByEmail:
          vm.hasEventPublisherRole || vm.hasTranslatorRole
            ? ['REGISTERED_USER', 'CRISIS_MANAGER', 'HEAD_MANAGER']
            : ['CRISIS_MANAGER', 'HEAD_MANAGER'],
        emailToDistricts: ['PRAHA_1'],
      }
    }

    // function getEventSeverities() {
    //     vm.eventSeverities = ApiEventModel.getSeverities();
    // }

    function getEventPriorities() {
      vm.eventPriorities = ApiEventModel.getPriorities()
    }

    function getEventTypes() {
      vm.eventTypes = ApiEventModel.getEventTypes({
        cultureCode: APP_CONFIG.translation.fallbackLanguage,
      })
    }

    function getEventPublications() {
      vm.eventPublications = ApiEventModel.getEventPublications()
    }

    function getEventDistricts() {
      vm.districts = ApiUserModel.getAllDistricts()
    }

    function getPublicationTargetTypes() {
      vm.eventTargetPublications = ApiEventModel.getPublicationTargetTypes()
    }

    function publishEvent() {
      if (vm.hasEventPublisherRole || vm.hasTranslatorRole) {
        ApiAdminEventModel.publishEvent(
          { id: vm.event.id, cultureCode: vm.event.cultureCode },
          vm.event.publication,
          function () {
            FlashMessage.add(
              'Aktualita ' + vm.event.title + ' byla úspěšně publikována.',
              'success',
              1,
            )
            $state.go('admin.event', {}, { reload: true })
          },
        )
      } else if (vm.hasHeadManagerRole) {
        vm.event.id = null
        ApiAdminEventModel.createEventEmail(vm.event, function (event) {
          FlashMessage.add(
            'Aktualita ' + vm.event.title + ' byla úspěšně publikována na email.',
            'success',
            1,
          )
          $state.go('admin.event', {}, { reload: true })
        })
      } else {
        FlashMessage.add('Nemáte oprávnění vytvořit aktualitu.')
      }
    }

    function unpublishEvent() {
      ApiAdminEventModel.unpublishEvent(
        { id: vm.event.id, cultureCode: vm.event.cultureCode },
        function () {
          FlashMessage.add(
            'Aktualita ' + vm.event.title + ' byla úspěšně odpublikována.',
            'success',
            1,
          )
          $state.go('admin.event', {}, { reload: true })
        },
      )
    }

    function openUploadDocumentModal() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadDocumentModal.html',
        controller: 'UploadDocumentModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function (files) {
          angular.forEach(files, function (file) {
            vm.event.attachment = file.fileInfo
          })
        },
        function () {},
      )
    }

    function getUserRoles() {
      ApiAdminUserModel.getAllRoles(function (roles) {
        vm.roles = _.filter(roles, function (obj) {
          if (vm.hasEventPublisherRole || vm.hasTranslatorRole) {
            return (
              obj.id === 'REGISTERED_USER' ||
              obj.id === 'CRISIS_MANAGER' ||
              obj.id === 'HEAD_MANAGER'
            )
          }
          return obj.id === 'CRISIS_MANAGER' || obj.id === 'HEAD_MANAGER'
        })
      })
    }

    function isWebPublicationType() {
      return vm.event
        ? vm.event.publication
          ? vm.event.publication.publishTarget === 'WEB'
          : false
        : false
    }

    function isEmailPublicationType() {
      return vm.event
        ? vm.event.publication
          ? vm.event.publication.publishTarget === 'EMAIL'
          : false
        : false
    }

    function eventPublicationTypeChanged() {
      if (vm.event.publication) {
        vm.event.publication.private = true
      }
    }
  }
})()
