;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.layout')
    .controller('LinkArticleModalController', LinkArticleModalController)

  LinkArticleModalController.$inject = ['$uibModalInstance', 'ApiAdminArticleModel']

  function LinkArticleModalController($uibModalInstance, ApiAdminArticleModel) {
    const vm = this
    vm.article = {}

    vm.ok = ok
    vm.cancel = cancel

    activate()

    function activate() {
      ApiAdminArticleModel.getArticleTree(function (data) {
        vm.articlesStructure = data
      })
    }

    function ok() {
      $uibModalInstance.close(vm.article)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
