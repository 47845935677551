;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.layout')
    .controller('RegistrationModalController', RegistrationModalController)

  RegistrationModalController.$inject = [
    '$uibModalInstance',
    '$translate',
    'ApiUserModel',
    'FlashMessage',
  ]

  function RegistrationModalController(
    $uibModalInstance,
    $translate,
    ApiUserModel,
    FlashMessage,
  ) {
    const vm = this
    vm.register = register
    vm.cancel = cancel

    activate()

    function activate() {
      vm.districts = ApiUserModel.getAllDistricts()
    }

    function register(data) {
      data.cultureCode = $translate.use()
      ApiUserModel.register({ user: data }, () => {
        FlashMessage.add(
          {
            id: 'FRONT.REGISTRATION.FLASH_MESSAGE.REGISTRATION.EMAIL_CONFIRMATION',
            values: { email: data.email },
          },
          'success',
          1,
        )
        $uibModalInstance.close()
      })
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
