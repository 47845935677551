;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app', [
      'ngResource',
      'ngMessages',
      'gridster',
      'LocalStorageModule',
      'angular-bootstrap-select',
      'leaflet-directive',
      'ui.router',
      'ui.bootstrap',
      'app.common',
      'app.front',
      'app.admin',
      'ngCookies',
      'pascalprecht.translate',
    ])
    .config([
      '$provide',
      '$breadcrumbProvider',
      '$compileProvider',
      '$httpProvider',
      '$locationProvider',
      '$logProvider',
      '$translateProvider',
      '$translatePartialLoaderProvider',
      'localStorageServiceProvider',
      function (
        $provide,
        $breadcrumbProvider,
        $compileProvider,
        $httpProvider,
        $locationProvider,
        $logProvider,
        $translateProvider,
        $translatePartialLoaderProvider,
        localStorageServiceProvider,
      ) {
        var localStoragePrefix = 'crisisPortalPragueOne'

        if (!window.localStorage) {
          console.error(
            'Aplikace ke svému běhu potřebuje zapnutý localStorage. V IE lze nastavit v Internet Options => Advanced => sekce Security => Enable DOM Storage',
          )
          alert('Prohlížeč nemá povolen localStorage.')
        }

        $breadcrumbProvider.setOptions({
          prefixStateName: 'front.homepage',
        })

        // TODO commentes because ArcGIS CORS -> ApiArcGisModel
        // $httpProvider.defaults.transformRequest = function (data) {
        //     angular.element('.spinner').show();
        //
        //     var apiKey = angular.fromJson(localStorage.getItem(localStoragePrefix + ".accessToken"));
        //     if (apiKey) {
        //         $httpProvider.defaults.headers.common['X-Api-Key'] = apiKey;
        //     } else {
        //         delete $httpProvider.defaults.headers.common['X-Api-Key'];
        //     }
        //     return angular.toJson(data);
        // };

        // $httpProvider.defaults.headers.common['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        // $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
        // $httpProvider.defaults.headers.common['Pragma'] = 'no-cache';
        $httpProvider.interceptors.push('HttpInterceptor')

        $locationProvider.html5Mode(true)

        localStorageServiceProvider.setPrefix(localStoragePrefix)
        $compileProvider.aHrefSanitizationTrustedUrlList(
          /^\s*(https?|ftp|mailto|chrome-extension|blob|tel):|data:image\//,
        )

        $logProvider.debugEnabled(false)

        var translationConfig = window.appConfig.translation

        $translateProvider.useLoader('$translatePartialLoader', {
          urlTemplate: 'app/modules/_common/locales/{lang}.json',
        })
        $translatePartialLoaderProvider.addPart('main')

        $translateProvider
          .useSanitizeValueStrategy('sanitize')
          .registerAvailableLanguageKeys(
            translationConfig.supportedLanguages,
            translationConfig.availableLanguageKeys,
          )
          .fallbackLanguage(translationConfig.fallbackLanguage)
          .preferredLanguage(translationConfig.preferredLanguage)
          .determinePreferredLanguage()
          .useLocalStorage()

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('service-worker.js')
            .then((registration) => {
              console.log('ServiceWorker registration successful with scope: ', registration.scope)
            })
            .catch((err) => {
              console.log('ServiceWorker registration failed: ', err)
            })
        } else {
          console.log('This browser does NOT support ServiceWorker.')
        }

        $provide.decorator('taOptions', [
          'taRegisterTool',
          'taToolFunctions',
          '$delegate',
          '$uibModal',
          '$state',
          function (taRegisterTool, taToolFunctions, taOptions, $uibModal, $state) {
            taRegisterTool('uploadImage', {
              iconclass: 'fa fa-image',
              tooltiptext: 'Vložit obrázky',
              action: function (deferred, restoreSelection) {
                var textAngular = this

                $uibModal
                  .open({
                    templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadImageModal.html',
                    controller: 'UploadImageModalController',
                    controllerAs: 'modalCtrl',
                  })
                  .result.then(
                    (images) => {
                      angular.forEach(images, (image) => {
                        textAngular.$editor().wrapSelection('insertImage', image.fileInfo.url, true)
                      })
                      restoreSelection()
                      deferred.resolve()
                    },
                    () => {
                      restoreSelection()
                      deferred.resolve()
                    },
                  )
                return false
              },
              onElementSelect: {
                element: 'img',
                action: taToolFunctions.imgOnSelectAction,
              },
            })

            taRegisterTool('uploadDocument', {
              iconclass: 'fa fa-file-sound-o',
              tooltiptext: 'Vložit soubory',
              action: function (deferred, restoreSelection) {
                var textAngular = this

                $uibModal
                  .open({
                    templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadDocumentModal.html',
                    controller: 'UploadDocumentModalController',
                    controllerAs: 'modalCtrl',
                  })
                  .result.then(
                    (files) => {
                      angular.forEach(files, (file) => {
                        // textAngular.$editor().wrapSelection('insertHTML', "<a href='" + file.fileInfo.url + "' title='" + file.fileInfo.alias + "' target='_self'>" + file.fileInfo.alias + "</a>", true);
                        textAngular.$editor().wrapSelection('createLink', file.fileInfo.url, true)
                      })
                      restoreSelection()
                      deferred.resolve()
                    },
                    () => {
                      restoreSelection()
                      deferred.resolve()
                    },
                  )
                return false
              },
            })

            taRegisterTool('insertMap', {
              iconclass: 'fa fa-map-marker',
              tooltiptext: 'Vložit mapu',
              action: function (deferred, restoreSelection) {
                var textAngular = this

                $uibModal
                  .open({
                    templateUrl: 'app/modules/admin/layout/modal/textAngular/insertMapModal.html',
                    controller: 'InsertMapModalController',
                    controllerAs: 'modalCtrl',
                  })
                  .result.then(
                    (mapProviderService) => {
                      restoreSelection()
                      textAngular
                        .$editor()
                        .wrapSelection(
                          'insertHTML',
                          `<div data-cpp-map-select data-provider-service="${mapProviderService}">->TADY BUDE MAPA<-</div>`,
                        )
                      deferred.resolve()
                    },
                    () => {
                      restoreSelection()
                      deferred.resolve()
                    },
                  )
                return false
              },
            })

            taRegisterTool('linkArticle', {
              iconclass: 'fa fa-file-text',
              tooltiptext: 'Vložit odkaz na článek',
              action: function (deferred, restoreSelection) {
                var textAngular = this

                $uibModal
                  .open({
                    templateUrl: 'app/modules/admin/layout/modal/textAngular/linkArticleModal.html',
                    controller: 'LinkArticleModalController',
                    controllerAs: 'modalCtrl',
                  })
                  .result.then(
                    (article) => {
                      restoreSelection()
                      var url = $state.href(
                        'front.article-detail',
                        { relativeUrl: article.parent.relativeUrl },
                        { absolute: false },
                      )
                      textAngular.$editor().wrapSelection('createLink', url, true)
                      deferred.resolve()
                    },
                    () => {
                      restoreSelection()
                      deferred.resolve()
                    },
                  )
                return false
              },
            })

            taRegisterTool('insertTable', {
              iconclass: 'fa fa-table',
              tooltiptext: 'Vložit tabulku',
              action: function (deferred, restoreSelection) {
                var textAngular = this

                $uibModal
                  .open({
                    templateUrl: 'app/modules/admin/layout/modal/textAngular/insertTableModal.html',
                    controller: 'InsertTableModalController',
                    controllerAs: 'modalCtrl',
                  })
                  .result.then(
                    (result) => {
                      restoreSelection()
                      textAngular.$editor().wrapSelection('insertHTML', result)
                      deferred.resolve()
                    },
                    () => {
                      restoreSelection()
                      deferred.resolve()
                    },
                  )
                return false
              },
              onElementSelect: {
                element: 'table',
                action: function (event, $element, editorScope) {
                  var finishEdit = () => {
                    editorScope.updateTaBindtaTextElement()
                    editorScope.hidePopover()
                  }

                  event.preventDefault()
                  editorScope.displayElements.popover.css('width', '275px')
                  editorScope.displayElements.popover.css('text-align', 'center')
                  var container = editorScope.displayElements.popoverContainer
                  container.empty()

                  var tableEditGroup = angular.element('<div class="btn-group tablePopupBtnGroup">')
                  var addRowButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa-2x cpp-table_row_add"></i></button>',
                  )
                  addRowButton.on('click', (event) => {
                    event.preventDefault()
                    var tbody = $element
                    var colnums = tbody.find('tr:nth-child(1)').children().length
                    var col = '<td>&nbsp;</td>'
                    var allCols = ''
                    for (var x = 0; x < colnums; x++) {
                      allCols = allCols + col
                    }
                    var newElem = '<tr>' + allCols + '</tr>'
                    tbody.append(newElem)
                    finishEdit()
                  })
                  var addColButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa-2x cpp-table_col_add"></i></button>',
                  )
                  addColButton.on('click', (event) => {
                    event.preventDefault()

                    var tbody = $element
                    var col = '<td>&nbsp;</td>'
                    var head = '<th>&nbsp;</th>'
                    tbody.find('tr').each((k) => {
                      if (k === 0) {
                        $(this).append(head)
                      } else {
                        $(this).append(col)
                      }
                    })

                    var colnums = tbody.find('tr:nth-child(1)').children().length
                    tbody
                      .find('tr:first')
                      .find('td')
                      .each(() => {
                        $(this).width(100 / colnums)
                      })
                    tbody
                      .find('tr:first')
                      .find('th')
                      .each(() => {
                        $(this).width(100 / colnums)
                      })
                    finishEdit()
                  })

                  var remRowButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa-2x cpp-table_row_rem"></i></button>',
                  )
                  remRowButton.on('click', (event) => {
                    event.preventDefault()
                    var lastrow = $element.find('tr:last')
                    lastrow.remove()
                    finishEdit()
                  })

                  var remColButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa-2x cpp-table_col_rem"></i></button>',
                  )
                  remColButton.on('click', (event) => {
                    event.preventDefault()
                    $element.find('tr').each(() => {
                      $(this).find('td:last').remove()
                      $(this).find('th:last').remove()
                    })
                    finishEdit()
                  })

                  var tableResizeGroup = angular.element(
                    '<div class="btn-group tablePopupBtnGroup">',
                  )

                  var fullButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1">100%</button>',
                  )
                  fullButton.on('click', (event) => {
                    event.preventDefault()
                    $element.css({
                      width: '100%',
                      height: '',
                    })
                    finishEdit()
                  })
                  var halfButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1">50%</button>',
                  )
                  halfButton.on('click', (event) => {
                    event.preventDefault()
                    $element.css({
                      width: '50%',
                      height: '',
                    })
                    finishEdit()
                  })
                  var quartButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1">25%</button>',
                  )
                  quartButton.on('click', (event) => {
                    event.preventDefault()
                    $element.css({
                      width: '25%',
                      height: '',
                    })
                    finishEdit()
                  })

                  var remove = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa fa-trash-o"></i></button>',
                  )
                  remove.on('click', (event) => {
                    event.preventDefault()
                    $element.remove()
                    finishEdit()
                  })

                  var tablePositionGroup = angular.element(
                    '<div class="btn-group tablePopupBtnGroup">',
                  )

                  var leftButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa fa-align-left"></i></button>',
                  )
                  leftButton.on('click', (event) => {
                    event.preventDefault()
                    $element.attr('align', 'left')
                    // $element.css({'float': 'left'});
                    finishEdit()
                  })
                  var centerButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa fa-align-center"></i></button>',
                  )
                  centerButton.on('click', (event) => {
                    event.preventDefault()
                    $element.attr('align', 'center')
                    // $element.css({'float': 'both'});
                    finishEdit()
                  })
                  var rightButton = angular.element(
                    '<button type="button" class="btn btn-default btn-sm btn-small" tabindex="-1"><i class="fa fa-align-right"></i></button>',
                  )
                  rightButton.on('click', (event) => {
                    event.preventDefault()
                    $element.attr('align', 'right')
                    // $element.css({'float': 'right'});
                    finishEdit()
                  })

                  tableEditGroup.append(addRowButton)
                  tableEditGroup.append(addColButton)

                  tableEditGroup.append(remRowButton)
                  tableEditGroup.append(remColButton)

                  tableResizeGroup.append(quartButton)
                  tableResizeGroup.append(halfButton)
                  tableResizeGroup.append(fullButton)
                  tableResizeGroup.append(remove)

                  tablePositionGroup.append(leftButton)
                  tablePositionGroup.append(centerButton)
                  tablePositionGroup.append(rightButton)

                  container.append(tableEditGroup)
                  container.append(tableResizeGroup)
                  container.append(tablePositionGroup)

                  editorScope.showPopover($element)
                  editorScope.showResizeOverlay($element)
                },
              },
            })

            taOptions.disableSanitizer = true
            taOptions.defaultTagAttributes.a.target = '_self'
            taOptions.taSelectableElements = ['table']
            taOptions.toolbar = [
              ['h1', 'h2', 'h3', 'p', 'pre', 'quote'],
              [
                'bold',
                'italics',
                'underline',
                'strikeThrough',
                'ul',
                'ol',
                'undo',
                'redo',
                'clear',
              ],
              ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
              ['insertTable'],
              [
                'html',
                'uploadImage',
                'insertLink',
                'insertMap',
                'uploadDocument',
                'linkArticle',
                'wordcount',
                'charcount',
              ],
            ]
            return taOptions
          },
        ])

        $provide.decorator('taSelectableElements', [
          '$delegate',
          () => {
            return ['a', 'img', 'table']
          },
        ])
      },
    ])
    .constant('APP_CONFIG', window.appConfig)
    .constant('uiDatetimePickerConfig', {
      dateFormat: 'dd. MM. yyyy HH:mm',
      defaultTime: '00:00:00',
      html5Types: {
        date: 'yyyy-MM-dd',
        'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
        month: 'yyyy-MM',
      },
      initialPicker: 'date',
      reOpenDefault: false,
      enableDate: true,
      enableTime: true,
      buttonBar: {
        show: true,
        now: {
          show: true,
          text: 'Nyní',
        },
        today: {
          show: true,
          text: 'Dnes',
        },
        clear: {
          show: true,
          text: 'Smazat',
        },
        date: {
          show: true,
          text: 'Datum',
        },
        time: {
          show: true,
          text: 'Čas',
        },
        close: {
          show: true,
          text: 'Zavřít',
        },
        cancel: {
          show: false,
          text: 'Zrušit',
        },
      },
      closeOnDateSelection: true,
      closeOnTimeNow: true,
      appendToBody: false,
      altInputFormats: [],
      ngModelOptions: {},
      saveAs: (datetime) => {
        if (datetime !== null && angular.isDefined(datetime)) {
          return moment(datetime).format()
        } else {
          return datetime
        }
      },
      readAs: false,
    })
    .run([
      '$rootScope',
      '$state',
      '$transitions',
      '$translate',
      'FlashMessage',
      'PersistenceModel',
      function ($rootScope, $state, $transitions, $translate, FlashMessage, PersistenceModel) {
        let actualState

        function updateTitles() {
          $.fn.selectpicker.defaults = {
            noneSelectedText: $translate.instant('_COMMON.BOOTSTRAP_SELECT.noneSelectedText'),
            multipleSeparator: $translate.instant('_COMMON.BOOTSTRAP_SELECT.multipleSeparator'),
            selectAllText: $translate.instant('_COMMON.BOOTSTRAP_SELECT.selectAllText'),
            deselectAllText: $translate.instant('_COMMON.BOOTSTRAP_SELECT.deselectAllText'),
          }
          const globalTitle = $translate.instant('_COMMON.TITLE')

          if (actualState.name.includes('front.')) {
            $rootScope.pageTitleTranslation =
              $translate.instant(`FRONT.MODULE_JS.${actualState.name}`) + ` | ${globalTitle}`
          } else {
            if (actualState.hasOwnProperty('data')) {
              $rootScope.pageTitleTranslation = `${actualState.data.title} | ${globalTitle}`
            } else {
              $rootScope.pageTitleTranslation = globalTitle
            }
          }
        }

        moment.locale($translate.use())

        $transitions.onStart({ to: '**' }, (trans) => {
          if (trans.params().hasOwnProperty('roles')) {
            var allowed = false
            angular.forEach(PersistenceModel.getUserRoles(), (role) => {
              allowed = _.includes(trans.params().roles, role) || allowed
            })

            if (!allowed) {
              var toUrl = $state.href(trans.to().name, $state.params, {
                absolute: true,
              })
              FlashMessage.add(
                {
                  id: 'APP_JS_RUN.NOT_ALLOWED_PAGE',
                  values: { pageUrl: toUrl },
                },
                'warning',
                1,
              )
            }
          }
        })

        $transitions.onSuccess({}, (transition) => {
          actualState = transition.to()
          document.body.scrollTop = document.documentElement.scrollTop = 0
          updateTitles()
        })

        $rootScope.$on('noRights', () => {
          FlashMessage.add('APP_JS_RUN.NOT_ALLOWED_ACTION', 'warning', 1)
        })

        $rootScope.$on('autoLogout', () => {
          PersistenceModel.clear()
          FlashMessage.add('APP_JS_RUN.AUTO_LOGOUT', 'warning', 1)
          $state.go(PersistenceModel.getDefaultPage(), {}, { reload: true })
        })
      },
    ])
})()
