;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppFooter', {
    templateUrl: 'app/modules/front/layout/components/footer/index.html',
    controller: FooterController,
  })

  FooterController.$inject = []

  function FooterController() {
    const vm = this

    vm.currentDate = new Date()
  }
})()
