;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.user')
    .controller('ResetUserPasswordModalController', ResetUserPasswordModalController)

  ResetUserPasswordModalController.$inject = [
    '$uibModalInstance',
    'ApiUserModel',
    'FlashMessage',
    'user',
  ]

  function ResetUserPasswordModalController($uibModalInstance, ApiUserModel, FlashMessage, user) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.user = user
    

    function ok() {
      var promise = ApiUserModel.sendPassword({}, { username: vm.user.email }, function () {
        FlashMessage.add(
          'Heslo uživatele ' + vm.user.email + ' bylo úspěšně resetováno.',
          'success',
        )
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
