;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.traffic').controller('TrafficController', TrafficController)

  TrafficController.$inject = ['ApiTrafficModel']

  function TrafficController(ApiTrafficModel) {
    const vm = this
    vm.pageSize = 5
    vm.currentPage = 1

    vm.getDdrMessages = getDdrMessages

    activate()

    function activate() {
      getDdrMessages()
    }

    function getDdrMessages() {
      vm.loadingData = true
      ApiTrafficModel.getDdrMessages(
        {
          limit: vm.pageSize,
          offset: (vm.currentPage - 1) * vm.pageSize,
        },
        (data) => {
          vm.ddrData = data.messages
          vm.totalCount = data.totalCount
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }
  }
})()
