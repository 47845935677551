;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('CameraDetailMapController', CameraDetailMapController)

  CameraDetailMapController.$inject = [
    '$scope',
    '$translate',
    '$stateParams',
    '$interval',
    '$timeout',
    'ApiCameraModel',
    'ApiUserModel',
    'PersistenceModel',
  ]

  function CameraDetailMapController(
    $scope,
    $translate,
    $stateParams,
    $interval,
    $timeout,
    ApiCameraModel,
    ApiUserModel,
    PersistenceModel,
  ) {
    const vm = this
    vm.changeAutoRefresh = changeAutoRefresh
    vm.changeRefreshRate = changeRefreshRate
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()

    var reloadTimer = null
    var countdownTimer = null
    var reloadEnabled = true

    activate()

    function activate() {
      getCamera()
    }

    function getCamera() {
      vm.camera = ApiCameraModel.getCamera({ id1: $stateParams.id }, function () {
        getUserRefreshRate()
      })
    }

    function getUserRefreshRate() {
      ApiUserModel.getCurrentUser(function (user) {
        vm.data = {
          autoRefreshEnabled: false,
          refreshRate: 30000 || user.usersAccessRights.cameraRefreshRate * 1000,
          refreshRates: [
            {
              value: 1000,
              name: $translate.instant('FRONT.MAP.CAMERA.PIC', { value: 1 }),
            },
            {
              value: 5000,
              name: $translate.instant('FRONT.MAP.CAMERA.PIC', { value: 5 }),
            },
            {
              value: 10000,
              name: $translate.instant('FRONT.MAP.CAMERA.PIC', { value: 10 }),
            },
            {
              value: 20000,
              name: $translate.instant('FRONT.MAP.CAMERA.PIC', { value: 20 }),
            },
            {
              value: 30000,
              name: $translate.instant('FRONT.MAP.CAMERA.PIC', { value: 30 }),
            },
          ],
        }
        reloadImage()
      })
    }

    function reloadImage() {
      vm.data.imageLoadedTime = new Date().getTime()
      ApiCameraModel.getImage({ id1: vm.camera.id }, function (data) {
        vm.camera.dataUrl = data.contentBase64
        vm.camera.imageContentType = data.contentType
      })
      reloadEnabled = false
      setupReloadTimer()
    }

    function setupCountdownTimer() {
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
      }

      vm.data.timeToReload = vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      countdownTimer = $interval(function () {
        vm.data.timeToReload =
          vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      }, 500)
    }

    function setupReloadTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      reloadTimer = $timeout(function () {
        reloadEnabled = true
        if (vm.data.autoRefreshEnabled) {
          reloadImage()
        }
      }, vm.data.refreshRate)
    }

    function changeRefreshRate() {
      if (vm.data.autoRefreshEnabled) {
        reloadImage()
      }
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
      if (vm.data.autoRefreshEnabled) {
        setupCountdownTimer()
      }
    }

    function changeAutoRefresh() {
      if (reloadEnabled && vm.data.autoRefreshEnabled) {
        reloadImage()
      }
      if (vm.data.autoRefreshEnabled) {
        setupCountdownTimer()
      } else if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    function destroyTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    $scope.$on('$destroy', destroyTimer)
    $scope.$on('logout', destroyTimer)
  }
})()
