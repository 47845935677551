;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('MppDynamicsMapModalController', MppDynamicsMapModalController)

  MppDynamicsMapModalController.$inject = ['ApiSourceModel', 'MapDialogService']

  function MppDynamicsMapModalController(ApiSourceModel, MapDialogService) {
    const vm = this

    activate()

    function activate() {
      getEnums()
      vm.data = MapDialogService.getPopupData()
    }

    function getEnums() {
      vm.policeGroups = ApiSourceModel.getPoliceGroups()
      // vm.policeTypes = ApiSourceModel.getPoliceTypes();
      // vm.policePriorityTypes = ApiSourceModel.getPolicePriorityTypes();
      // vm.policeStateTypes = ApiSourceModel.getPoliceStateTypes();
    }
  }
})()
