;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('MapController', MapController)

  MapController.$inject = ['$state']

  function MapController($state) {
    const vm = this
    vm.providerService = $state.current.data.providerService
  }
})()
