;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiMockModel', ApiMockModel)

  ApiMockModel.$inject = ['$http', 'UtilService', 'APP_CONFIG']

  function ApiMockModel($http, UtilService, APP_CONFIG) {
    function processMockData(processFunction, url, attribute) {
      return $http.get(url).then((response) => {
        processFunction(UtilService.simpleResponseTransform(attribute)(response.data))
      })
    }

    return {
      getCameras: (process) => {
        return processMockData(process, 'app/modules/mock/camera.json', 'cameras')
      },
      getSyposDetail: (params, process) => {
        return processMockData(process, 'app/modules/mock/sypos-detail.json', 'institutes')
      },
    }
  }
})()
