;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.cms')
    .controller('AdminEditArticleController', AdminEditArticleController)

  AdminEditArticleController.$inject = [
    '$stateParams',
    '$state',
    '$uibModal',
    'ApiAdminArticleModel',
    'ApiAdminDocumentModel',
    'FlashMessage',
    'UtilService',
    'APP_CONFIG',
  ]

  function AdminEditArticleController(
    $stateParams,
    $state,
    $uibModal,
    ApiAdminArticleModel,
    ApiAdminDocumentModel,
    FlashMessage,
    UtilService,
    APP_CONFIG,
  ) {
    const vm = this
    vm.modelCultureData = [
      'cultureCode',
      'title',
      'description',
      'keywords',
      'perex',
      'relativeUrl',
      'publicContent',
      'privateContent',
      'breadcrumbs',
      'active',
      'fileFolderId',
    ]

    vm.setEntityLoadedFn = function (emitEntityLoadedFn) {
      vm.emitEntityLoaded = emitEntityLoadedFn
    }

    vm.editArticle = editArticle
    vm.openEditStructure = openEditStructure
    vm.openDeactivateArticle = openDeactivateArticle
    vm.openDeleteArticle = openDeleteArticle

    activate()

    function activate() {
      vm.loadingData = true

      ApiAdminArticleModel.getArticle({ id: $stateParams.id }, function (article) {
        vm.article = article
        vm.emitEntityLoaded()
      })
    }

    function editArticle(publish) {
      if (vm.article.fileFolderId) {
        vm.article.fileFolderId = vm.article.fileFolderId.folderId
          ? vm.article.fileFolderId.folderId
          : null
      }

      ApiAdminArticleModel.editArticle({ id: vm.article.id }, vm.article, function (article) {
        if (publish) {
          ApiAdminArticleModel.publishArticle(
            { cultureCode: vm.article.cultureCode, id: article.id },
            function (warningResponse) {
              if (warningResponse && warningResponse.hasOwnProperty('errorMessage')) {
                FlashMessage.add(warningResponse.errorMessage, 'warning', 1)
              } else {
                FlashMessage.add(
                  'Článek ' + vm.article.title + ' byl úspěšně editován a publikován.',
                  'success',
                  1,
                )
              }
              vm.article.draft = { id: vm.article.draftParentId }
              $state.go('admin.cms', getRedirectPathParams(vm.article.parent, vm.article), {
                reload: true,
              })
            },
          )
        } else {
          FlashMessage.add('Článek ' + vm.article.title + ' byl úspěšně editován.', 'success', 1)
          $state.go('admin.cms', getRedirectPathParams(vm.article.parent, vm.article), {
            reload: true,
          })
        }
      })
    }

    function getRedirectPathParams(parentArticle, article) {
      if (angular.isUndefined(parentArticle.parentId) || parentArticle.parentId === null) {
        return { id: article.draft ? article.draft.id : article.id }
      } else {
        var parent = _.find(vm.articlesStructure[0].childArticles, ['id', parentArticle.parentId])
        if (parent && parent.parentId !== null) {
          return {
            id: parent.draft ? parent.draft.id : parent.id,
            childId: parentArticle.draft ? parentArticle.draft.id : parentArticle.id,
          }
        } else {
          return {
            id: parentArticle.draft ? parentArticle.draft.id : parentArticle.id,
            childId: article.draft ? article.draft.id : article.id,
          }
        }
      }
    }

    function openEditStructure() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/cms/modal/structure/editArticleStructureModal.html',
        controller: 'EditArticleStructureModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }

    function openDeactivateArticle(article) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/cms/modal/article/deactivateArticleModal.html',
        controller: 'DeactivateArticleModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          article: function () {
            return article
          },
          cultureData: function () {
            return {
              cultureCode: vm.actualCultureCode,
              isDefault: vm.isDefualtLanguage,
            }
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            var params = getRedirectPathParams(article.parent, article)
            $state.go('admin.cms', params, { reload: true })
          })
        },
        function () {},
      )
    }

    function openDeleteArticle(article) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/cms/modal/article/deleteArticleModal.html',
        controller: 'DeleteArticleModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          article: function () {
            return article
          },
          cultureData: function () {
            return {
              cultureCode: vm.actualCultureCode,
              isDefault: vm.isDefualtLanguage,
            }
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            var params = getRedirectPathParams(article.parent, article)
            $state.go('admin.cms', params, { reload: true })
          })
        },
        function () {},
      )
    }

    function getDocuments(cultureCode, firstLoad) {
      ApiAdminDocumentModel.getStructure(
        { cultureCode: cultureCode, folderCultureRequired: true },
        function (structure) {
          vm.documentsStructure = structure

          if (firstLoad) {
            ApiAdminArticleModel.getArticleTree(
              { cultureCode: cultureCode },
              function (data) {
                vm.articlesStructure = data

                var result = []
                UtilService.findObjectKeyRecursively(
                  data[0],
                  'childArticles',
                  'id',
                  vm.article.parentId,
                  result,
                )
                vm.article.parent = result.length > 0 ? result[0] : null

                result = []
                UtilService.findObjectKeyRecursively(
                  structure[0],
                  'subFolders',
                  'folderId',
                  vm.article.fileFolderId,
                  result,
                )
                vm.article.fileFolderId = result.length > 0 ? result[0] : null

                vm.loadingData = false
              },
              function () {
                vm.loadingData = false
              },
            )
          } else {
            var result = []
            UtilService.findObjectKeyRecursively(
              structure[0],
              'subFolders',
              'folderId',
              vm.article.fileFolderId,
              result,
            )
            vm.article.fileFolderId = result.length > 0 ? result[0] : null
          }
        },
      )
    }

    function isDefualtLanguageUpdate(cultureCode) {
      vm.isDefualtLanguage = cultureCode === APP_CONFIG.translation.fallbackLanguage
    }

    vm.onTranslationInit = function (cultureCode) {
      vm.actualCultureCode = cultureCode
      isDefualtLanguageUpdate(cultureCode)
      getDocuments(cultureCode, true)
    }

    vm.onTranslationAdd = function (cultureCode) {
      vm.actualCultureCode = cultureCode
      isDefualtLanguageUpdate(cultureCode)
      getDocuments(cultureCode)
      vm.article.active = false
    }

    vm.onTranslationChange = function (cultureCode) {
      vm.actualCultureCode = cultureCode
      isDefualtLanguageUpdate(cultureCode)
      getDocuments(cultureCode)
    }
  }
})()
