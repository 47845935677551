;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiCameraModel', ApiCameraModel)

  ApiCameraModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiCameraModel($resource, UtilService, APP_CONFIG) {
    function extendCameraObject(camera) {
      return angular.extend(camera, {
        imageUrl: APP_CONFIG.apiRootUrl + 'cameras/' + camera.id + '/image',
        sizeX: 1,
        sizeY: 1,
      })
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'cameras/:id1/:path/:id2',
      { id1: '@id1', path: '@path', id2: '@id2' },
      {
        getAll: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cameras')) {
              angular.forEach(data.cameras, function (camera, index) {
                data.cameras[index] = extendCameraObject(camera)
              })
              return data.cameras
            }
            return data
          },
        },
        getCamera: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('camera')) {
              return data.camera
            }
            return data
          },
        },
        getImage: {
          method: 'GET',
          params: {
            path: 'image',
          },
        },
        getCameraGrids: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'grids',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cameraGrids')) {
              var map = {
                coordinateX: 'col',
                coordinateY: 'row',
              }
              angular.forEach(data.cameraGrids, function (grid) {
                angular.forEach(grid.cameraPositions, function (camera, index) {
                  grid.cameraPositions[index] = extendCameraObject(
                    UtilService.remapObjectAttributes(camera, map),
                  )
                })
              })
              return data.cameraGrids
            }
            return data
          },
        },
        getFutureCameras: {
          method: 'GET',
          params: {
            path: 'future',
          },
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('cameras'),
        },
        getCameraStates: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'camera-states',
          },
          transformResponse: UtilService.simpleResponseTransform('values'),
        },
        getCameraConstructionReasons: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'contruction-reasons',
          },
          transformResponse: UtilService.simpleResponseTransform('values'),
        },
        getTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'types',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getCameraMobilityTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'mobility-types',
          },
          transformResponse: UtilService.simpleResponseTransform('values'),
        },
      },
    )
  }
})()
