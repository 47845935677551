;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAuthenticationModel', ApiAuthenticationModel)

  ApiAuthenticationModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiAuthenticationModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'authentication',
      {},
      {
        login: {
          method: 'POST',
        },
        refreshToken: {
          method: 'PUT',
        },
        logout: {
          method: 'DELETE',
        },
      },
    )
  }
})()
