;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('GeometryHelper', GeometryHelper)

  GeometryHelper.$inject = []

  function GeometryHelper() {
    return {
      coordinatesEqual: function (item1, item2) {
        var coordinateX1 = item1.coordinateX.toString()
        var coordinateX2 = item2.coordinateX.toString()
        var coordinateY1 = item1.coordinateY.toString()
        var coordinateY2 = item2.coordinateY.toString()
        return coordinateX1 === coordinateX2 && coordinateY1 === coordinateY2
      },

      geoJsonEquals: function (position1, position2) {
        var coordinates1 = position1.coordinates.toString()
        var coordinates2 = position2.coordinates.toString()
        return coordinates1 === coordinates2
      },

      convertToBoundingBox: function (bounds) {
        return {
          northEast: {
            lat: bounds.getNorthEast().lat,
            lng: bounds.getNorthEast().lng,
          },
          southWest: {
            lat: bounds.getSouthWest().lat,
            lng: bounds.getSouthWest().lng,
          },
        }
      },
    }
  }
})()
