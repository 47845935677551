;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpTreeSelect', TreeSelectDirective)

  TreeSelectDirective.$inject = ['$compile', '$filter']

  function TreeSelectDirective($compile, $filter) {
    var template =
      "<div class='select form-control' data-ng-click='openTree()'><p>{{activeItemName}}</p></div>"
    template += "<div class='list' data-ng-show='isOpen'></div>"

    treeDropdownController.$inject = ['$scope']

    return {
      restrict: 'A',
      scope: {
        model: '=',
        selected: '=',
        children: '@',
        attributeName: '@',
        optional: '@',
      },
      template: template,
      controller: treeDropdownController,
      link: function (scope, element, attrs, ngModel) {
        var list = angular.element(element[0].querySelector('.list'))
        var defaultOptionAdded = false

        scope.$watchGroup(['model', 'selected'], function (newValues, oldValues, scope) {
          if (scope.optional) {
            var obj = { id: null, order: 0 }
            _.set(
              obj,
              scope.attributeName,
              $filter('translate')('_COMMON.DIRECTIVE.TREE_SELECT.NOTHING'),
            )
            if (!defaultOptionAdded && scope.model) {
              scope.model.push(obj)
              defaultOptionAdded = true
            }
          }

          list.html('')

          if (!scope.selected) {
            setSelected(scope, undefined)
          }

          var options = getOptions(scope, scope.model, 0)
          list.append($compile(options)(scope))
        })

        // Close on click outside the dropdown...
        angular.element(document).bind('click', function (event) {
          if (element !== event.target && !element[0].contains(event.target)) {
            scope.$apply(function () {
              scope.isOpen = false
            })
          }
        })
      },
    }

    function treeDropdownController($scope) {
      var ctrl = $scope
      ctrl.isOpen = false

      ctrl.openTree = function () {
        ctrl.isOpen = !ctrl.isOpen
      }

      ctrl.childClick = function (obj) {
        setSelected(ctrl, obj)
        ctrl.isOpen = false
        ctrl.$apply()
      }
    }

    function getOptions(scope, data, level) {
      data = $filter('orderBy')(data, 'order')

      var optionUL = angular.element('<ul></ul>')
      angular.forEach(data, function (obj) {
        var optionLI = angular.element('<li></li>')
        var optionA = angular.element(
          '<p data-ng-class=\'{selected:selected.id=="' +
            obj.id +
            '", nonactive:' +
            (obj.hasOwnProperty('active') ? !obj.active : false) +
            "}' class='level-" +
            level +
            "'>" +
            _.get(obj, scope.attributeName) +
            '</p>',
        )
        optionLI.append(optionA)

        // Set selected option if selected id or object exist..
        if (scope.selected == obj) {
          setSelected(scope, obj)
        }

        optionA.bind('click', function () {
          scope.childClick(obj)
        })

        if (obj[scope.children]) {
          optionLI.append(getOptions(scope, obj[scope.children], level + 1))
        }
        optionUL.append(optionLI)
      })

      return optionUL
    }

    function setSelected(scope, obj) {
      if (obj) {
        scope.selected = obj
        scope.activeItemName = _.get(obj, scope.attributeName)
      } else {
        if (scope.optional) {
          scope.selected = scope.model ? scope.model[scope.model.length - 1] : undefined
        } else {
          scope.selected = undefined
        }
      }
    }
  }
})()
