;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('InitiativeLayerProvider', InitiativeLayerProvider)

  InitiativeLayerProvider.$inject = [
    '$translate',
    'ItemCollectionLayerProviderBase',
    'ApiInitiativeModel',
    'MapHelperService',
    'GeometryHelper',
  ]

  function InitiativeLayerProvider(
    $translate,
    ItemCollectionLayerProviderBase,
    ApiInitiativeModel,
    MapHelperService,
    GeometryHelper,
  ) {
    function createLayer(item) {
      var markerOptions = {
        icon: MapHelperService.createIcon(this.image),
      }

      const marker = L.marker([item.latitude, item.longitude], markerOptions)
      marker.initiativeId = item.id
      return marker
    }

    function getItemKey(item) {
      return item.id.toString()
    }

    function itemsEqual(item1, item2) {
      return GeometryHelper.coordinatesEqual(item1, item2) && item1.id === item2.id
    }

    return {
      fromCategory: function (map, categoryId, image) {
        var child = Object.create(ItemCollectionLayerProviderBase)
        child.super(map)

        child.init = function () {
          this.categoryId = categoryId
          this.image = image
        }

        child.update = function update() {
          return ApiInitiativeModel.getInitiatives(
            { categoryId: this.categoryId, cultureCode: $translate.use() },
            function (initiatives) {
              child.processItems(initiatives)
            },
          )
        }

        child.createLayer = createLayer

        child.getItemKey = getItemKey

        child.itemsEqual = itemsEqual

        child.init()
        return child
      },
      fromSingleInitiative: function (map, initiative, image) {
        var child = Object.create(ItemCollectionLayerProviderBase)
        child.super(map)

        child.init = function () {
          this.initiative = initiative
          this.image = image
        }

        child.update = function update() {
          child.processItems([this.initiative])
        }

        child.createLayer = createLayer

        child.getItemKey = getItemKey

        child.itemsEqual = itemsEqual

        child.init()
        return child
      },
    }
  }
})()
