;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.source')
    .controller('AdminPassabilityController', AdminPassabilityController)

  AdminPassabilityController.$inject = [
    '$http',
    '$state',
    '$uibModal',
    'QueryFilterService',
    'ApiAdminPassabilityModel',
    'ApiSourceModel',
    'ApiUserModel',
    'EventService',
    'FlashMessage',
  ]

  function AdminPassabilityController(
    $http,
    $state,
    $uibModal,
    QueryFilterService,
    ApiAdminPassabilityModel,
    ApiSourceModel,
    ApiUserModel,
    EventService,
    FlashMessage,
  ) {
    const vm = this
    var districts = []

    vm.getPassabilityEntries = getPassabilityEntries
    vm.publish = publish
    vm.openUploadPassabilityCsv = openUploadPassabilityCsv
    vm.openDelete = openDelete

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getPassabilityEntries()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = '-placeName'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/sources/meta/passability-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getPassabilityEntries() {
      vm.loadingData = true
      ApiAdminPassabilityModel.getPassabilityPoints(
        {},
        function (entries) {
          vm.entries = entries.passabilityPoints
          vm.totalCount = entries.passabilityPoints.length
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function openUploadPassabilityCsv() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/importCsvModal.html',
        controller: 'ImportCsvModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          title: () => 'Nahrát data průjezdnosti',
          importType: () => 'passabilityPoints',
          successMessage: () => 'Data průjezdnosti byla úspěšně nahrána.',
        },
      })
      modalInstance.result.then(
        function () {
          getPassabilityEntries()
        },
        function () {},
      )
    }

    function publish(firemanEntry) {
      EventService.saveFiremanSourceData(firemanEntry, districts)
      $state.go('admin.event-create', {}, { reload: true })
    }

    function openDelete(passabilityPoint) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/deleteModal.html',
        controller: 'DeleteModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          data: () => {
            return {
              title: 'Smazání bodu průjezdnosti ' + passabilityPoint.placeName,
              message: 'Opravdu chcete smazat bod průjezdnosti ' + passabilityPoint.placeName + '?',
            }
          },
        },
      })
      modalInstance.result.then(
        () => {
          const promise = ApiAdminPassabilityModel.delete({ id: passabilityPoint.id }, () => {
            FlashMessage.add(`Bod průjezdnosti ${passabilityPoint.placeName} byl úspěšně smazán.`)
          })
          promise.$promise.then(() => {
            getPassabilityEntries()
          })
        },
        () => {},
      )
    }
  }
})()
