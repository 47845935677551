;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('capLevelFilter', CapLevelFilter)

  CapLevelFilter.$inject = []

  function CapLevelFilter() {
    return function (input) {
      if (angular.isDefined(input)) {
        switch (input) {
          case 'NoDanger':
            return 'Situace bez nebezpečných jevů'
          case 'Level1':
            return '1. stupeň nebezpečí'
          case 'Level2':
            return '2. stupeň nebezpečí'
          case 'Level3':
            return '3. stupeň nebezpečí'
          default:
            return 'Neplatný'
        }
      }
      return 'Neplatný'
    }
  }
})()
