;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('arrayFilter', ArrayFilter)

  ArrayFilter.$inject = []

  function ArrayFilter() {
    function isValueFound(attribute, item, query) {
      var queryValue = query[attribute]
      if (Array.isArray(queryValue)) {
        var localFound = false
        if (
          (queryValue.length === 0 &&
            item.hasOwnProperty(attribute) &&
            item[attribute].length === 0) ||
          queryValue.length === 0
        ) {
          localFound = true
        }

        for (var j = 0; j < queryValue.length; j++) {
          localFound |= _.includes(_.toLower(item[attribute]), _.toLower(queryValue[j]))
        }
        return localFound
      } else {
        if (!_.toLower(item[attribute]) && !_.toLower(queryValue)) {
          return false
        } else {
          return _.includes(_.toLower(item[attribute]), _.toLower(queryValue))
        }
      }
    }

    return function (items, query) {
      if (items && Array.isArray(items)) {
        var filtered = []
        for (var i = 0; i < items.length; i++) {
          var item = items[i]
          var found = true
          for (var attribute in query) {
            if (query.hasOwnProperty(attribute) && attribute in item) {
              if (_.isPlainObject(query[attribute]) && !_.isEmpty(query[attribute])) {
                // pokud jsem o jednu uroven filtrace hloubeji, napr.: query.attribute.childAttribute
                found &= isValueFound(_.keys(query[attribute]), item[attribute], query[attribute])
              } else {
                // pokud jsem na prvni urovni filtrace, napr.: query.attribute
                found &= isValueFound(attribute, item, query)
              }
            }
          }
          if (found) {
            filtered.push(item)
          }
        }
        items = filtered
      }
      return items
    }
  }
})()
