;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('AirQualityLayerProvider', AirQualityLayerProvider)

  AirQualityLayerProvider.$inject = [
    '$q',
    '$rootScope',
    '$stateParams',
    '$templateRequest',
    '$timeout',
    '$compile',
    'ApiMapModel',
    'MapHelperService',
    'CustomLayer',
  ]

  function AirQualityLayerProvider(
    $q,
    $rootScope,
    $stateParams,
    $templateRequest,
    $timeout,
    $compile,
    ApiMapModel,
    MapHelperService,
    CustomLayer,
  ) {
    function _getOverallQualityColor(ix) {
      switch (ix) {
        case '-1':
        case '0':
          return 'black'
        case '1A':
        case '1B':
          return 'green'
        case '2A':
        case '2B':
          return 'yellow'
        case '3A':
        case '3B':
          return 'red'
        default:
          return 'black'
      }
    }

    function initAirQualityLayer(configuration, layerCollection) {
      ApiMapModel.getAirQuality(function (data) {
        var promise = _getAirQualityStationsLayerGroup(data)
        promise.then(function (stationGroup) {
          var customLayer = new CustomLayer(
            angular.extend(configuration, {
              layer: stationGroup,
            }),
          )
          MapHelperService.addLayer(customLayer, layerCollection)
          _handlePopupOpen(stationGroup)
        })
      })
    }

    function _getAirQualityStationsLayerGroup(data) {
      var deferred = $q.defer(),
        markers = [],
        promises = []

      angular.forEach(data.stations, function (station) {
        var marker = new L.Marker(new L.LatLng(station.lat, station.lon), {
          draggable: false,
          icon: new L.Icon({
            iconUrl: 'styles/img/air-' + _getOverallQualityColor(station.ix) + '.png',
            iconSize: new L.Point(24, 24),
            iconAnchor: new L.Point(24, 24),
            shadowAnchor: new L.Point(24, 28),
            popupAnchor: new L.Point(0, -35),
          }),
        })

        var def = $q.defer()
        promises.push(def.promise)

        var popupScope = $rootScope.$new(true)
        angular.extend(popupScope, {
          data: data,
          station: _preparePopupData(data, station),
          overallAirConditionLegend: _.find(data.legends, function (legend) {
            return station.ix === legend.ix
          }),
        })

        $templateRequest('app/modules/_common/service/maps/html/airQualityPopup.html').then(
          function (response) {
            var template = $compile(response)(popupScope)
            $timeout(function () {
              marker.bindPopup(
                template[0].outerHTML,
                MapHelperService.extendLeafletPopupDefaultOptions({}),
              )
              marker.stationName = station.code
              markers.push(marker)
              def.resolve(marker)
            })
          },
        )
      })

      $q.all(promises).then(function (markers) {
        var stationGroup = new L.LayerGroup(markers)
        deferred.resolve(stationGroup)
      })

      return deferred.promise
    }

    function _handlePopupOpen(stationGroup) {
      const stationName = $stateParams.stationName
      stationGroup.eachLayer((marker) => {
        if (stationName && marker.stationName === stationName.toString()) {
          marker.openPopup()
        }
      })
    }

    function _preparePopupData(data, station) {
      var components = []
      angular.forEach(station.components, function (component) {
        station.backgroundColor = _.result(_.find(data.legends, { ix: station.ix }), 'color')
        station.color = _.result(_.find(data.legends, { ix: station.ix }), 'colorText')

        component.compLegend = _.find(data.componentLegends, function (compLegend) {
          return component.code === compLegend.code
        })
        component.legend = _.find(data.legends, function (legend) {
          return component.ix === legend.ix
        })
        components.push(component)
      })
      station.components = components
      return station
    }

    return {
      initAirQualityLayer: initAirQualityLayer,
    }
  }
})()
