;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiSourceModel', ApiSourceModel)

  ApiSourceModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiSourceModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + ':path/:path1/:key',
      { path: '@path', path1: '@path1', key: '@key' },
      {
        getSivsDangerLevels: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'sivs',
            path1: 'dangerLevelType',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('dangerLevelTypes')) {
              return data.dangerLevelTypes
            }
            return data
          },
        },
        getCapDangerLevels: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'sivs',
            path1: 'dangerLevelType',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('dangerLevelTypes')) {
              return data.dangerLevelTypes
            }
            return data
          },
        },
        getDdrCategoryTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'ddr',
            path1: 'categoryType',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('categoryTypes')) {
              return data.categoryTypes
            }
            return data
          },
        },
        getRiversSpaTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'rivers',
            path1: 'spaType',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('spaTypes')) {
              return data.spaTypes
            }
            return data
          },
        },
        getSvodMap: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'map',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
        getPoliceGroups: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'groupTypes',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getPoliceTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'types',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getPoliceMapTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'mapTypes',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getPolicePriorityTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'priorityTypes',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getPoliceStateTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
            path1: 'stateTypes',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getFiremanTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'fireDept',
            path1: 'types',
          },
          transformResponse: UtilService.simpleResponseTransform('test_types'),
        },
        getFiremanSubTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'fireDept',
            path1: 'subtypes',
          },
          transformResponse: UtilService.simpleResponseTransform('subtypes'),
        },
        getNoiseStates: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'noise',
            path1: 'states',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getNoiseGraphData: {
          method: 'GET',
          params: {
            path: 'noise',
            key: 'data',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)

            if (data && data.hasOwnProperty('dailyGraphData')) {
              var minDayGraphData = [],
                maxDayGraphData = [],
                averageDayGraphData = [],
                allGraphData = []

              angular.forEach(_.orderBy(data.dailyGraphData, ['day'], ['asc']), (mainObj) => {
                // var minValue = Number.MAX_SAFE_INTEGER, maxValue = Number.MIN_SAFE_INTEGER, sumValue =  0;

                if (mainObj.entries.length > 0) {
                  angular.forEach(_.orderBy(mainObj.entries, ['incomeDate'], ['asc']), (obj) => {
                    // if (minValue > obj.noiseValue) {
                    //     minValue = obj.noiseValue;
                    // }
                    // if (maxValue < obj.noiseValue) {
                    //     maxValue = obj.noiseValue;
                    // }
                    // sumValue += obj.noiseValue;
                    allGraphData.push(obj.noiseValue)
                  })

                  // averageDayGraphData.push((sumValue/mainObj.entries.length).toFixed(2));
                } else {
                  // minValue = 0;
                  // maxValue = 0;
                  // averageDayGraphData.push(0);
                }

                // minDayGraphData.push(minValue);
                // maxDayGraphData.push(maxValue);
              })
              return {
                // minDayGraphData: minDayGraphData,
                // maxDayGraphData: maxDayGraphData,
                // averageDayGraphData: averageDayGraphData,
                allGraphData: allGraphData,
              }
            }
            return data
          },
        },
        downloadNoiseData: {
          method: 'POST',
          params: {
            path: 'noise',
            key: 'export',
          },
          transformResponse: (data, headers) => {
            var attachment = null
            if (data) {
              attachment = new Blob([data], {
                type: 'text/csv',
              })
            }
            var fileName =
              UtilService.getFileNameFromHeader(headers('content-disposition')) || 'DataHluku.csv'
            return {
              blob: attachment,
              fileName: fileName,
            }
          },
        },
      },
    )
  }
})()
