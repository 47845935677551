;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('AdminInitiativeCategoryController', AdminInitiativeCategoryController)

  AdminInitiativeCategoryController.$inject = [
    '$uibModal',
    'ApiAdminInitiativeModel',
    'ApiAdminUserModel',
    'APP_CONFIG',
  ]

  function AdminInitiativeCategoryController(
    $uibModal,
    ApiAdminInitiativeModel,
    ApiAdminUserModel,
    APP_CONFIG,
  ) {
    const vm = this

    vm.openCreateCategory = openCreateCategory
    vm.openEditCategory = openEditCategory
    vm.openIsActiveToggleCategory = openIsActiveToggleCategory

    activate()

    function activate() {
      getInitiativeCategories()
      getInitiativeAdminUsers()
    }

    function getInitiativeCategories() {
      vm.loadingData = true
      ApiAdminInitiativeModel.getInitiativeCategories(
        function (data) {
          vm.initiativeCategories = data.map(function (category) {
            category.name = category.cultures.filter(function (culture) {
              return culture.cultureCode === APP_CONFIG.translation.fallbackLanguage
            })[0].name
            return category
          })
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function getInitiativeAdminUsers() {
      ApiAdminUserModel.getInitiativeAdminUsers(function (data) {
        vm.initiativeAdminUsers = data
      })
    }

    function openCreateCategory() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/initiative/modal/category/createCategoryModal.html',
        controller: 'CreateCategoryModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          users: function () {
            return vm.initiativeAdminUsers
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getInitiativeCategories()
          })
        },
        function () {},
      )
    }

    function openEditCategory(category) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/initiative/modal/category/editCategoryModal.html',
        controller: 'EditCategoryModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          users: function () {
            return vm.initiativeAdminUsers
          },
          category: function () {
            return category
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getInitiativeCategories()
          })
        },
        function () {},
      )
    }

    function openIsActiveToggleCategory(category) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/initiative/modal/category/isActiveCategoryModal.html',
        controller: 'IsActiveCategoryModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          category: function () {
            return category
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getInitiativeCategories()
          })
        },
        function () {},
      )
    }
  }
})()
