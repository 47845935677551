;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.source').controller('AdminPubController', AdminPubController)

  AdminPubController.$inject = ['$http', '$uibModal', 'ApiAdminSourceModel', 'ApiMapModel']

  function AdminPubController($http, $uibModal, ApiAdminSourceModel, ApiMapModel) {
    const vm = this
    vm.openPubEdit = openPubEdit
    vm.openPubDelete = openPubDelete

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getPubEntries()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = false
      vm.orderByField = 'name'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/sources/meta/pub-list.json').then((response) => {
        vm.headers = response.data
      })
    }

    function getPubEntries() {
      vm.loadingData = true
      vm.pubEntries = ApiMapModel.getRestaurants(
        () => {
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }

    function openPubEdit(pub) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/editPubModal.html',
        controller: 'EditPubModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          pub: () => {
            return pub
          },
        },
      })
      modalInstance.result.then(
        (promise) => {
          promise.$promise.then(() => {
            getPubEntries()
          })
        },
        () => {},
      )
    }

    function openPubDelete(pub) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/deleteModal.html',
        controller: 'DeleteModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          data: () => {
            return {
              title: 'Smazání hospody ' + pub.name,
              message: 'Opravdu chcete smazat hospodu ' + pub.name + '?',
            }
          },
        },
      })
      modalInstance.result.then(
        () => {
          const promise = ApiAdminSourceModel.deletePub({ id: vm.pub.id }, () => {
            FlashMessage.add(`Hospoda ${vm.pub.name} byla úspěšně smazána.`)
          })
          promise.$promise.then(() => {
            getPubEntries()
          })
        },
        () => {},
      )
    }
  }
})()
