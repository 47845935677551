;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.camera').controller('AdminCameraController', AdminCameraController)

  AdminCameraController.$inject = [
    '$http',
    '$uibModal',
    'ApiCameraModel',
    'ApiAdminCameraModel',
    'ApiFilterService',
    'BOOL_VALUES',
  ]

  function AdminCameraController(
    $http,
    $uibModal,
    ApiCameraModel,
    ApiAdminCameraModel,
    ApiFilterService,
    BOOL_VALUES,
  ) {
    const vm = this
    vm.boolValues = BOOL_VALUES

    vm.getCameras = getCameras
    vm.downloadCameras = downloadCameras
    vm.openEditCamera = openEditCamera

    activate()

    function activate() {
      getCameras()
      getCameraTypes()
      prepareTableHeaders()
    }

    function getCameras() {
      vm.loadingData = true
      vm.cameras = ApiAdminCameraModel.getAll(
        function () {
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function downloadCameras(format) {
      var search = ApiFilterService.transformSearchQueryAttribute(
        vm.query,
        ApiFilterService.KEYS.camera,
      )
      ApiAdminCameraModel.downloadCameras({
        orderBy: vm.orderByField,
        desc: vm.reverseSort,
        search: search,
        format: format,
      })
    }

    function getCameraTypes() {
      vm.cameraTypes = ApiCameraModel.getTypes()
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/camera/meta/camera-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function openEditCamera(camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/editCameraModal.html',
        controller: 'EditCameraModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return camera
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getCameras()
          })
        },
        function () {},
      )
    }
  }
})()
