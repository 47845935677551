;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppContacts', {
    templateUrl: 'app/modules/front/layout/components/contacts/index.html',
    controller: ContactsController,
  })

  ContactsController.$inject = []

  function ContactsController() {}
})()
