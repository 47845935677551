;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpScrollbar', ScrollbarDirective)

  ScrollbarDirective.$inject = []

  function ScrollbarDirective() {
    return {
      restrict: 'A',
      link: function (scope, element) {
          new PerfectScrollbar(element[0], {minScrollbarLength: 20})
      },
      controller: ['$scope', '$element', function ($scope, $element) {
          $scope.reset = function () {
              $element.scrollTop(0)
              $element.perfectScrollbar('update')
          }
      }],
    }
  }
})()
