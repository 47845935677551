;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminPassabilityModel', ApiAdminPassabilityModel)

  ApiAdminPassabilityModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminPassabilityModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/passabilityPoints/:id',
      { id: '@id' },
      {
        getPassabilityPoints: {
          method: 'GET',
        },
        create: {
          method: 'POST',
        },
        getSingle: {
          method: 'GET',
        },
        edit: {
          method: 'PUT',
        },
        delete: {
          method: 'DELETE',
        },
      },
    )
  }
})()
