;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppEvents', {
    templateUrl: 'app/modules/front/homepage/components/events/index.html',
    controller: EventsListController,
  })

  EventsListController.$inject = ['$translate', 'ApiEventModel']

  function EventsListController($translate, ApiEventModel) {
    const vm = this
    

    activate()

    function activate() {
      getEvents()
    }

    function getEvents() {
      vm.loadingEventsData = true
      vm.actualities = ApiEventModel.getEvents(
        { cultureCode: $translate.use(), limit: 3, offset: 0 },
        () => {
          vm.loadingEventsData = false
        },
        () => {
          vm.loadingEventsData = false
        },
      )
    }
  }
})()
