;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpMapSearch', MapSearchDirective)

  MapSearchDirective.$inject = []

  function MapSearchDirective() {
    return {
      restrict: 'A',
      scope: false,
      replace: true,
      templateUrl: 'app/modules/_common/directives/mapSearch.html',
    }
  }
})()
