;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpFbShare', FBShareDirective)

  FBShareDirective.$inject = []

  function FBShareDirective() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          FB.ui({
            method: 'feed',
            name: attrs.title,
            link: attrs.link,
            picture: window.location.href + '/styles/img/logo-wide.png',
            caption: attrs.caption,
            description: attrs.description,
            message: attrs.quote,
          })
        })
      },
    }
  }
})()
