;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('DeleteCameraAttachmentModalController', DeleteCameraAttachmentModalController)

  DeleteCameraAttachmentModalController.$inject = ['$uibModalInstance', 'camera', 'attachment']

  function DeleteCameraAttachmentModalController($uibModalInstance, camera, attachment) {
    const vm = this
    vm.camera = camera
    vm.attachment = attachment

    vm.ok = ok
    vm.cancel = cancel
    

    function ok() {
      $uibModalInstance.close(vm.attachment)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
