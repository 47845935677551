;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('Layer', Layer)

  Layer.$inject = [
    '$q',
    '$templateRequest',
    '$compile',
    '$rootScope',
    '$timeout',
    '$state',
    'PersistenceModel',
    'APP_CONFIG',
  ]

  function Layer(
    $q,
    $templateRequest,
    $compile,
    $rootScope,
    $timeout,
    $state,
    PersistenceModel,
    APP_CONFIG,
  ) {
    function _setRememberedLegendVisibility(generalOptions) {
      var layerVisible = PersistenceModel.getMapLayerVisibility(generalOptions.key)
      if (layerVisible !== null) {
        generalOptions.visible = layerVisible
      }
    }

    function _setRememberedLegendActive(generalOptions) {
      var selectedMaps = PersistenceModel.getSelectedMaps()
      if (
        selectedMaps !== null &&
        generalOptions.legendOptions &&
        !generalOptions.legendOptions.disable
      ) {
        generalOptions.legendOptions.display = false

        angular.forEach(selectedMaps, function (selectedMap) {
          angular.forEach(APP_CONFIG.mapSettings[selectedMap], function (entity) {
            if (_.isArray(entity)) {
              angular.forEach(entity, function (obj) {
                if (obj.hasOwnProperty('key') && generalOptions.key === obj.key) {
                  generalOptions.legendOptions.display = true
                }
              })
            }
            if (
              _.isObject(entity) &&
              entity.hasOwnProperty('key') &&
              generalOptions.key === entity.key
            ) {
              generalOptions.legendOptions.display = true
            }
          })
        })
      }
    }

    return {
      super: function (generalOptions) {
        this.layer = {}
        this.legendLabel = null
        this.generalOptions = generalOptions

        if ($state.$current.name === 'front.map.all') {
          _setRememberedLegendVisibility(this.generalOptions)
          _setRememberedLegendActive(this.generalOptions)
        }
      },

      handleServerQuery: function (e) {
        var me = this
        return L.esri
          .identifyFeatures({
            url: this.generalOptions.queryUrl || this.generalOptions.url,
          })
          .layers(
            'all' + (this.generalOptions.queryLayers ? ':' + this.generalOptions.queryLayers : ''),
          )
          .on(this.generalOptions.map)
          .at(e.latlng)
          .tolerance(30)
          .run(function (err, featureCollection) {
            if (
              featureCollection &&
              featureCollection.features &&
              featureCollection.features.length
            ) {
              var propertiesArr = _.map(featureCollection.features, 'properties')
              var propertiesArrUpperCase = _.map(propertiesArr, function (propertiesObj) {
                return _.mapKeys(propertiesObj, function (propertyValue, propertyKey) {
                  return propertyKey.toUpperCase()
                })
              })
              me.generalOptions.clickFn.apply(me.generalOptions.clickContext, [
                me.generalOptions.queryAllowMultiple
                  ? propertiesArrUpperCase
                  : propertiesArrUpperCase[0],
                me.generalOptions.map,
                e.latlng,
                me.generalOptions.popupScope,
              ])
            }
          })
      },

      generateLegendLabel: function () {
        var deferred = $q.defer()

        if (!this.generalOptions.legendOptions || !this.generalOptions.legendOptions.visible) {
          this.legendLabel = null
          deferred.resolve(this.legendLabel)
        } else {
          var me = this
          var legendScope = $rootScope.$new(true)
          angular.extend(legendScope, {
            data: this.generalOptions.legendOptions,
          })
          $templateRequest('app/modules/_common/service/maps/html/legend.html').then(function (
            response,
          ) {
            var template = $compile(response)(legendScope)
            $timeout(function () {
              me.legendLabel = template[0].outerHTML
              deferred.resolve(me.legendLabel)
            })
          })
        }
        return deferred.promise
      },

      setLegendVisible: function (legendVisible) {
        this.generalOptions.legendOptions.display = legendVisible
      },

      getOptions: function () {
        return this.generalOptions
      },

      getLegendLabel: function () {
        return this.legendLabel
      },

      getLayer: function () {
        return this.layer
      },

      getKey: function () {
        return this.generalOptions.key
      },

      setLayer: function (layer) {
        this.layer = layer
      },
    }
  }
})()
