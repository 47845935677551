;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.components').component('cppFlashMessage', {
    templateUrl: 'app/modules/_common/components/flash-message/index.html',
    controller: FlashMessageController,
  })

  FlashMessageController.$inject = ['$state', 'FlashMessage']

  function FlashMessageController($state, FlashMessage) {
    const vm = this
    vm.removeAll = removeAll
    vm.isFrontSection = isFrontSection
    vm.isAdminSection = isAdminSection

    activate()

    function activate() {
      getFlashMessages()
    }

    function getFlashMessages() {
      vm.alerts = FlashMessage.all()
    }

    function removeAll() {
      FlashMessage.removeAll()
    }

    function isFrontSection() {
      return /^front\..+$/.test($state.current.name)
    }

    function isAdminSection() {
      return /^admin\..+$/.test($state.current.name)
    }
  }
})()
