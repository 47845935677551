;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('CadastreMapService', CadastreMapService)

  CadastreMapService.$inject = [
    '$rootScope',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'ServerLayer',
    'LineLayer',
    'APP_CONFIG',
  ]

  function CadastreMapService(
    $rootScope,
    leafletData,
    MapHelperService,
    MapDialogService,
    ServerLayer,
    LineLayer,
    APP_CONFIG,
  ) {
    var defaultOpacity = 0.8
    var defaultWeight = 3

    function initCadastreLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.cadastreLayers.lineLayers,
        function (lineLayerConfiguration) {
          var lineLayer = new LineLayer(
            angular.extend(lineLayerConfiguration, {
              fillOpacity: 0,
              weight: defaultWeight,
              opacity: defaultOpacity,
              simplifyFactor: 0,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(lineLayer, layerCollection)
        },
      )

      angular.forEach(
        APP_CONFIG.mapSettings.cadastreLayers.serverLayers,
        function (serverLayerConfiguration) {
          var serverLayer = new ServerLayer(
            angular.extend(serverLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: serverLayerConfiguration.dialogFn
                ? MapDialogService[serverLayerConfiguration.dialogFn]
                : null,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(serverLayer, layerCollection)
        },
      )
    }

    function initCadastreMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initCadastreLayers(map, layerCollection)
        map.setZoom(14)

        // /* Show/hide layer at zoom level. But legend checkbox is the problem - solved in the IzsService - PublicLighting. */
        // map.on('zoomend', function () {
        //     var buildingsLayer = layerCollection.getLayerByKey("buildings");
        //     if (buildingsLayer) {
        //         if (map.getZoom() < 16 && map.hasLayer(buildingsLayer)) {
        //             map.removeLayer(buildingsLayer);
        //         }
        //         if (map.getZoom() >= 16 && !map.hasLayer(buildingsLayer)) {
        //             map.addLayer(buildingsLayer);
        //         }
        //     }
        // });
      })
    }

    return {
      initCadastreMap: initCadastreMap,
      initCadastreLayers: initCadastreLayers,
    }
  }
})()
