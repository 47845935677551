;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('DeleteFolderModalController', DeleteFolderModalController)

  DeleteFolderModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminDocumentModel',
    'FlashMessage',
    'folder',
  ]

  function DeleteFolderModalController(
    $uibModalInstance,
    ApiAdminDocumentModel,
    FlashMessage,
    folder,
  ) {
    const vm = this
    vm.folder = folder
    vm.ok = ok
    vm.cancel = cancel
    

    function ok() {
      var promise = ApiAdminDocumentModel.deleteFolder({ id: vm.folder.folderId }, function () {
        FlashMessage.add(
          'Složka ' + vm.folder.folderCultures[0].name + ' byla úspěšně smazána.',
          'success',
        )
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
