;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('boolToText', BoolToTextFilter)

  BoolToTextFilter.$inject = []

  function BoolToTextFilter() {
    return function (input) {
      if (angular.isDefined(input)) {
        return input === true ? 'Ano' : 'Ne'
      }
      return input
    }
  }
})()
