;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.notFound').controller('PageNotFoundController', PageNotFoundController)

  PageNotFoundController.$inject = []

  function PageNotFoundController() {}
})()
