;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppAir', {
    templateUrl: 'app/modules/front/homepage/components/weather/widgets/air.html',
    controller: AirController,
  })

  AirController.$inject = ['ApiWeatherModel']

  function AirController(ApiWeatherModel) {
    const vm = this

    activate()

    function activate() {
      getIskoData()
    }

    function getIskoData() {
      vm.loadingIskoData = true
      vm.iskoData = ApiWeatherModel.getIskoData(
        () => {
          vm.loadingIskoData = false
        },
        () => {
          vm.loadingIskoData = false
        },
      )
    }
  }
})()
