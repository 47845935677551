;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('CameraLayerProvider', CameraLayerProvider)

  CameraLayerProvider.$inject = [
    'ItemCollectionLayerProviderBase',
    'ApiCameraModel',
    'MapHelperService',
    'GeometryHelper',
  ]

  function CameraLayerProvider(
    ItemCollectionLayerProviderBase,
    ApiCameraModel,
    MapHelperService,
    GeometryHelper,
  ) {
    return function (map, filter) {
      var child = Object.create(ItemCollectionLayerProviderBase)
      child.super(map)

      child.init = function () {
        this.filter = filter
        this.apiGetFn = filter.futureCameras
          ? ApiCameraModel.getFutureCameras
          : ApiCameraModel.getAll
      }

      child.update = function update() {
        return this.apiGetFn(this.filter, function (cameras) {
          child.processItems(cameras)
        })
      }

      child.createLayer = function createLayer(item) {
        item.imagePath = 'styles/img/'
        if (filter.futureCameras) {
          item.imagePath +=
            'camera_suggested' + (item.state ? '_' + _.snakeCase(item.state) : '') + '.png'
        } else {
          item.imagePath += item.public ? 'camera_public.png' : 'camera_private.png'
        }

        var markerOptions = {
          icon: MapHelperService.createIcon(item.imagePath),
          cameraState: item.state,
        }

        const marker = new L.marker([item.latitude, item.longitude], markerOptions)
        marker.cameraId = item.id
        return marker
      }

      child.getItemKey = function getItemKey(item) {
        return item.id.toString()
      }

      child.itemsEqual = function itemsEqual(item1, item2) {
        return (
          GeometryHelper.geoJsonEquals(item1.location, item2.location) && item1.name === item2.name
        )
      }

      child.init()
      return child
    }
  }
})()
