;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').controller('LoginModalController', LoginModalController)

  LoginModalController.$inject = [
    '$state',
    '$uibModal',
    '$uibModalInstance',
    '$translate',
    'ApiAuthenticationModel',
    'PersistenceModel',
  ]

  function LoginModalController(
    $state,
    $uibModal,
    $uibModalInstance,
    $translate,
    ApiAuthenticationModel,
    PersistenceModel,
  ) {
    const vm = this
    vm.login = login
    vm.openForgottenPasswordModal = openForgottenPasswordModal
    vm.cancel = cancel

    function login(credentials) {
      ApiAuthenticationModel.login(credentials, (data) => {
        PersistenceModel.setupUser(data)
        if (data.preferredLanguage) {
          $translate.use(data.preferredLanguage)
        }
        $uibModalInstance.close()
        $state.go('front.homepage', {}, { reload: true })
      })
    }

    function openForgottenPasswordModal() {
      $uibModalInstance.close()
      const modalInstance = $uibModal.open({
        templateUrl: 'app/modules/front/layout/modal/forgotten-password/forgottenPasswordModal.html',
        controller: 'ForgottenPasswordModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        () => {},
        () => {},
      )
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
