;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('AdminCameraGridController', AdminCameraGridController)

  AdminCameraGridController.$inject = [
    '$scope',
    '$uibModal',
    '$interval',
    '$timeout',
    'ApiAdminCameraModel',
    'ApiCameraModel',
    'ApiUserModel',
    'FlashMessage',
    'CameraMapService',
  ]

  function AdminCameraGridController(
    $scope,
    $uibModal,
    $interval,
    $timeout,
    ApiAdminCameraModel,
    ApiCameraModel,
    ApiUserModel,
    FlashMessage,
    CameraMapService,
  ) {
    const vm = this

    vm.data = {}
    vm.activeIndex = 0
    vm.initMap = initMap

    vm.createOrUpdateGrid = createOrUpdateGrid
    vm.deleteGrid = deleteGrid

    vm.addGrid = addGrid
    vm.clearGrid = clearGrid

    vm.clearCamera = clearCamera
    vm.clearCameras = clearCameras
    vm.openCameraSettings = openCameraSettings

    vm.changeAutoRefresh = changeAutoRefresh

    activate()

    function activate() {
      getUserRefreshRate().then(function (user) {
        vm.userCameraRefreshRate = user.usersAccessRights.cameraRefreshRate * 1000
        vm.userPrivateCameraAccess = user.usersAccessRights.privateAccess

        getCameraGrids()
      })
    }

    function getUserRefreshRate() {
      return ApiUserModel.getCurrentUser().$promise
    }

    function getCameraGrids() {
      vm.cameraGrids = []
      ApiAdminCameraModel.getCameraGrids(function (grids) {
        angular.forEach(grids, function (grid) {
          addGrid(grid)
          reloadImage(grid)
        })
      })
    }

    function initMap(index, grid) {
      if (angular.isDefined(index)) {
        CameraMapService.initCameraMap(
          index,
          grid,
          vm.data,
          openCameraDetail,
          vm.userPrivateCameraAccess,
        )
      }
    }

    function createOrUpdateGrid(cameraGrid) {
      if (cameraGrid.id) {
        editGrid(cameraGrid)
      } else {
        createNewGrid(cameraGrid)
      }
    }

    function createNewGrid(cameraGrid) {
      ApiAdminCameraModel.createCameraGrid({}, cameraGrid, function (data) {
        cameraGrid.id = data.id
        FlashMessage.add('Grid ' + cameraGrid.name + ' byl úspěšně vytvořen.', 'success')
      })
    }

    function editGrid(cameraGrid) {
      ApiAdminCameraModel.editCameraGrid({ id2: cameraGrid.id }, cameraGrid, function () {
        FlashMessage.add('Grid ' + cameraGrid.name + ' byl úspěšně editován.', 'success')
      })
    }

    function deleteGrid(cameraGrid) {
      ApiAdminCameraModel.deleteCameraGrid({ id2: cameraGrid.id }, function () {
        FlashMessage.add('Grid ' + cameraGrid.name + ' byl úspěšně smazán.', 'success')
        clearGrid(cameraGrid)
      })
    }

    function addGrid(grid) {
      vm.cameraGrids.push({
        id: grid ? grid.id : null,
        name: grid ? grid.name : 'Grid ' + (vm.cameraGrids.length + 1),
        reloadTimer: null,
        countdownTimer: null,
        reloadEnabled: true,
        autoRefreshEnabled: false,
        refreshRate: vm.userCameraRefreshRate,
        cameraPositions: grid ? grid.cameraPositions : [],
        gridsterOptions: {
          margins: [20, 20],
          columns: grid && grid.columnNumber > 0 ? grid.columnNumber : 2,
          draggable: {
            handle: 'div.draggable-' + vm.cameraGrids.length,
          },
        },
      })
      vm.activeIndex = vm.cameraGrids.length - 1
    }

    function clearGrid(grid) {
      vm.cameraGrids.splice(vm.cameraGrids.indexOf(grid), 1)
      vm.activeIndex = vm.cameraGrids.length - 1
    }

    function openCameraDetail(grid, camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/cameraDetailModal.html',
        controller: 'CameraDetailModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return camera
          },
          refreshRate: function () {
            return vm.userCameraRefreshRate
          },
        },
      })
      modalInstance.result.then(
        function (camera) {
          addCamera(grid, camera)
        },
        function () {},
      )
    }

    function addCamera(grid, camera) {
      var cam = angular.extend(camera, {
        sizeX: 1,
        sizeY: 1,
      })
      if (!_.find(grid.cameraPositions, { id: camera.id })) {
        grid.cameraPositions.push(cam)
      }
    }

    function clearCamera(grid, camera) {
      grid.cameraPositions.splice(grid.cameraPositions.indexOf(camera), 1)
    }

    function clearCameras(grid) {
      grid.cameraPositions = []
    }

    function openCameraSettings(camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/cameraGridSettingsModal.html',
        controller: 'CameraGridSettingsModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return camera
          },
        },
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }

    function reloadImage(grid) {
      grid.imageLoadedTime = new Date().getTime()
      angular.forEach(grid.cameraPositions, function (camera) {
        ApiCameraModel.getImage({ id1: camera.id }, function (data) {
          camera.dataUrl = data.contentBase64
          camera.imageContentType = data.contentType
        })
      })
      grid.reloadEnabled = false
      setupReloadTimer(grid)
    }

    function setupCountdownTimer(grid) {
      if (grid.countdownTimer) {
        $interval.cancel(grid.countdownTimer)
      }

      grid.timeToReload = grid.refreshRate - (new Date().getTime() - grid.imageLoadedTime)
      grid.countdownTimer = $interval(function () {
        grid.timeToReload = grid.refreshRate - (new Date().getTime() - grid.imageLoadedTime)
      }, 500)
    }

    function setupReloadTimer(grid) {
      if (grid.reloadTimer) {
        $timeout.cancel(grid.reloadTimer)
      }
      grid.reloadTimer = $timeout(function () {
        grid.reloadEnabled = true
        if (grid.autoRefreshEnabled) {
          reloadImage(grid)
        }
      }, grid.refreshRate)
    }

    function changeAutoRefresh(grid) {
      if (grid.reloadEnabled && grid.autoRefreshEnabled) {
        reloadImage(grid)
      }
      if (grid.autoRefreshEnabled) {
        setupCountdownTimer(grid)
      } else if (grid.countdownTimer) {
        $interval.cancel(grid.countdownTimer)
        grid.countdownTimer = null
      }
    }

    function destroyTimers() {
      angular.forEach(vm.cameraGrids, function (grid) {
        if (grid.countdownTimer) {
          $interval.cancel(grid.countdownTimer)
          grid.countdownTimer = null
        }
        if (grid.reloadTimer) {
          $timeout.cancel(grid.reloadTimer)
        }
      })
    }

    $scope.$on('$destroy', destroyTimers)
    $scope.$on('logout', destroyTimers)
  }
})()
