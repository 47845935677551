;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppLang', {
    templateUrl: 'app/modules/front/layout/components/lang/index.html',
    controller: LanguageController,
  })

  LanguageController.$inject = ['$translate', 'ApiLanguageModel']

  function LanguageController($translate, ApiLanguageModel) {
    const vm = this

    
    vm.changeLanguage = changeLanguage
    vm.getAllLanguages = getLanguages

    activate()

    function activate() {
      vm.actualCultureCode = $translate.use()
      getLanguages()
    }

    function changeLanguage(langKey) {
      $translate.use(langKey)
      location.reload()
    }

    function getLanguages() {
      vm.languageSelect = ApiLanguageModel.getAllLanguages()
    }
  }
})()
