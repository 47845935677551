;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('LayerCollection', LayerCollection)

  LayerCollection.$inject = []

  function LayerCollection() {
    return function (controllerData) {
      var self = this
      this.layerMap = {}
      this.controlLayerGroups = []

      this.map = controllerData.map
      this.layersControl = new L.Intens.CollapsibleControl({}, {})
      this.layersControl.addTo(this.map)

      this.addControl = function () {
        this.layersControl.addTo(this.map)
      }

      this.getMap = function () {
        return this.map
      }

      this.getLayerControl = function () {
        return this.layersControl
      }

      this.setLayerControl = function (control) {
        this.layersControl = control
      }

      this.addLayers = function (layers) {
        layers.forEach(function (layer) {
          _addLayer(layer)
        })
        this.layersControl.update()
      }

      this.addLayer = function (layer) {
        _addLayer(layer)
        this.layersControl.update()
      }

      this.hasLayer = function (layer) {
        var key = layer.getKey()
        return this.layerMap.hasOwnProperty(key)
      }

      this.getLayers = function () {
        var layers = []
        _.forEach(this.layerMap, function (layer) {
          layers.push(layer)
        })
        return layers
      }

      this.removeLayer = function (layer) {
        var key = layer.getKey()
        if (!this.layerMap[key]) {
          return
        }
        var mapLayer = layer.getLayer()
        this.map.removeLayer(mapLayer)
        this.layersControl.removeLayer(mapLayer)
        delete this.layerMap[key]
      }

      this.getLayerByKey = function (key) {
        return this.layerMap[key].getLayer()
      }

      function _addLayer(layer) {
        var key = layer.getKey()
        if (self.layerMap[key]) {
          return
        }
        var mapLayer = layer.getLayer()
        var options = layer.getOptions()
        self.layerMap[key] = layer
        var legendLabelPromise = layer.generateLegendLabel()

        legendLabelPromise.then(function (legendLabel) {
          if (legendLabel && !options.isBase) {
            self.layersControl.addOverlay(mapLayer, legendLabel, options)
            self.layersControl.update()
          }
        })

        if (options.boundLayers) {
          var me = this
          self.map.on('overlayadd', function (addedLayerObj) {
            if (mapLayer === addedLayerObj.layer) {
              options.boundLayers.forEach(function (boundLayer) {
                me.map.addLayer(boundLayer.getLayer())
              })
            }
          })
          self.map.on('overlayremove', function (removedLayerObj) {
            if (mapLayer === removedLayerObj.layer) {
              options.boundLayers.forEach(function (boundLayer) {
                me.map.removeLayer(boundLayer.getLayer())
              })
            }
          })
        }
        if (options.visible) {
          /* Podminka, ktera pro eventsmapu vypina vsechny vsrtvy, ktere maji order (aby zustaly zapnute podkladove vrstvy) a ty s disablovanou legendou, ktere se maji objevovat porad */
          if (
            (options.legendOptions &&
              (!options.legendOptions.order || options.legendOptions.disable)) ||
            controllerData.key !== 'eventsmap'
          ) {
            self.map.addLayer(mapLayer)
          }
        }
      }

      this.setLayerGroups = function (layerGroups) {
        var transformedLayerGroups = []
        layerGroups.forEach(function (obj) {
          var transformedObj = {
            name: obj.name,
            layers: [],
          }
          obj.layers.forEach(function (layer) {
            transformedObj.layers.push({
              layer: layer.getLayer(),
              overlay: !layer.getOptions().isBase,
            })
          })
          transformedLayerGroups.push(transformedObj)
        })
        this.controlLayerGroups = transformedLayerGroups
        this.layersControl.setLayerGroups(this.controlLayerGroups)
      }

      this.addLayerGroup = function (layerGroup) {
        var transformedObj = {
          name: layerGroup.name,
          layers: [],
        }
        layerGroup.layers.forEach(function (layer) {
          transformedObj.layers.push({
            layer: layer.getLayer(),
            overlay: !layer.getOptions().isBase,
          })
        })
        this.controlLayerGroups.push(transformedObj)
        this.layersControl.setLayerGroups(this.controlLayerGroups)
      }
    }
  }
})()
