;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.article').controller('DetailArticleController', DetailArticleController)

  DetailArticleController.$inject = [
    '$stateParams',
    '$location',
    '$translate',
    'ArticleTreeService',
    'PersistenceModel',
    'ApiArticleModel',
    'ApiDocumentModel',
    'ApiPdfModel',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function DetailArticleController(
    $stateParams,
    $location,
    $translate,
    ArticleTreeService,
    PersistenceModel,
    ApiArticleModel,
    ApiDocumentModel,
    ApiPdfModel,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.apiKey = PersistenceModel.getAccessToken()
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.facebookAppId = APP_CONFIG.facebookAppId

    
    vm.isTreeSectionActive = isTreeSectionActive
    vm.printContent = printContent
    vm.downloadPdf = downloadPdf

    activate()

    function activate() {
      getArticle()
      vm.articleStructure = ArticleTreeService.getArticleTree()
    }

    function getArticle() {
      vm.loadingData = true
      ApiArticleModel.getArticle(
        { id1: $stateParams.relativeUrl, cultureCode: $translate.use() },
        (article) => {
          vm.article = article
          vm.article.url = _.join([$location.absUrl(), $translate.use()], '/')
          getDocuments(article.fileFolderId)
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }

    function getDocuments(folderId) {
      ApiDocumentModel.getFolder(
        { id: folderId, cultureCode: $translate.use() },
        (documents) => {
          vm.documents = [documents]
        },
        () => {
          FlashMessage.removeAll()
        },
      )
    }

    function isTreeSectionActive(relativeUrl) {
      var isActive = false
      if (vm.article) {
        angular.forEach(vm.article.breadcrumbs, (breadcrumb) => {
          if (breadcrumb.relativeUrl === relativeUrl) {
            isActive = true
          }
        })
      }
      return isActive
    }

    function printContent() {
      window.print()
    }

    function downloadPdf() {
      vm.loadingPdf = true
      ApiPdfModel.downloadPdf(
        {
          filename: $stateParams.relativeUrl,
          relativeUrl: window.location.pathname,
        },
        () => {
          vm.loadingPdf = false
        },
        () => {
          vm.loadingPdf = false
        },
      )
    }
  }
})()
