;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.article').component('cppStaticSections', {
    templateUrl: 'app/modules/front/article/components/static-sections/index.html',
    controller: StaticSectionsController,
  })

  StaticSectionsController.$inject = ['$translate']

  function StaticSectionsController($translate) {
    const vm = this

    activate()

    function activate() {
      getStaticSections()
    }

    function getStaticSections() {
      vm.staticSections = [
        {
          iconLink: '/styles/img/map/all.svg',
          routerState: 'front.map.floods',
          title: $translate.instant('FRONT.LAYOUT.CONTROLLER.FRONT_LAYOUT.SECTIONS.MAPS'),
        },
        {
          iconLink: '/styles/img/map/camera.svg',
          routerState: 'front.map.camera',
          title: $translate.instant('FRONT.LAYOUT.CONTROLLER.FRONT_LAYOUT.SECTIONS.CAMERAS'),
        },
      ]
    }
  }
})()
