;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.forgottenPassword')
    .controller('ForgottenPasswordConfirmationController', ForgottenPasswordConfirmationController)

  ForgottenPasswordConfirmationController.$inject = [
    '$state',
    '$stateParams',
    'ApiUserModel',
    'FlashMessage',
  ]

  function ForgottenPasswordConfirmationController(
    $state,
    $stateParams,
    ApiUserModel,
    FlashMessage,
  ) {
    const vm = this
    vm.resetPassword = resetPassword    

    function resetPassword(password) {
      ApiUserModel.resetPassword(
        { path1: $stateParams.token },
        { password: password },
        function () {
          FlashMessage.add(
            'FRONT.FORGOTTEN_PASSWORD.FLASH_MESSAGE.FORGOTTEN_PASSWORD_CONFIRMATION.PASS_CHANGED',
            'success',
            1,
          )
          $state.go('front.homepage', {}, { reload: true })
        },
      )
    }
  }
})()
