;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiLanguageModel', ApiLanguageModel)

  ApiLanguageModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiLanguageModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'cultures',
      {},
      {
        getAllLanguages: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cultures')) {
              return data.cultures
            }
            return data
          },
        },
      },
    )
  }
})()
