;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpDirtyForm', DirtyFormDirective)

  DirtyFormDirective.$inject = ['$uibModal', '$transitions']

  function DirtyFormDirective($uibModal, $transitions) {
    return {
      restrict: 'A',
      require: 'form',
      link: function (scope, formElement, attributes, formController) {
        $transitions.onStart({ to: '**' }, function () {
          if (!formController.$submitted && formController.$dirty) {
            var modalInstance = $uibModal.open({
              templateUrl: 'app/modules/_common/modal/leaveConfirmationModal.html',
              controller: 'LeaveConfirmationModalController',
              controllerAs: 'modalCtrl',
            })
            modalInstance.result.then(
              function () {
                formController.$dirty = false
                return true
              },
              function () {},
            )

            return modalInstance.result
          }
        })

        window.addEventListener('beforeunload', function (e) {
          if (!formController.$submitted && formController.$dirty) {
            var confirmationMessage = ''

            ;(e || window.event).returnValue = confirmationMessage
            return confirmationMessage
          }
        })
      },
    }
  }
})()
