;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.school').controller('SyposDetailController', SyposDetailController)

  SyposDetailController.$inject = ['$translate', '$stateParams', 'ApiMapModel', 'APP_CONFIG']

  function SyposDetailController($translate, $stateParams, ApiMapModel, APP_CONFIG) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl

    activate()

    function activate() {
      getSyposDetail()
    }

    function getSyposDetail() {
      vm.loadingData = true
      ApiMapModel.getSchool(
        { id1: $stateParams.id, cultureCode: $translate.use() },
        (data) => {
          vm.institution = data.institute
          vm.loadingData = false
        },
        (errData) => {
          if (errData.status === 404) {
            $state.go('front.pageNotFound')
          }
          vm.loadingData = false
        },
      )
    }
  }
})()
