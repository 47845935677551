;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapSearchService', MapSearchService)

  MapSearchService.$inject = ['$q', 'HelperService', 'APP_CONFIG']

  function MapSearchService($q, HelperService, APP_CONFIG) {
    function _doQuery(url, query, func) {
      var deferred = $q.defer()

      var query = L.esri
        .query({
          url: url,
        })
        .where(query)
        .fields('*')
        .run(function (err, featureCollection) {
          var data = _processSearchResults(err, featureCollection, deferred, func)
          deferred.resolve(data)
        })

      return deferred.promise
    }

    function _processSearchResults(error, featureCollection, deferred, func) {
      if (!HelperService.isNullOrUndefined(error) && error.code > 0) {
        deferred.reject()
        return
      }

      var data = []
      for (var index in featureCollection.features) {
        if (featureCollection.features.hasOwnProperty(index)) {
          var feature = featureCollection.features[index]
          if (HelperService.isNullOrUndefined(feature.geometry)) {
            continue
          }

          var obj = func(feature, data)
          if (obj) {
            data.push(obj)
          }
        }
      }
      return data
    }

    function _processAddress(feature) {
      return {
        adresa: feature.properties.ADRESA,
        cisloDomovni: feature.properties.CISLODOMOVNI,
        cisloOrientacni: feature.properties.CISLOORIENTACNI,
        pismenoCo: feature.properties.PISMENOCO,
        idAdresa: Number(feature.properties.IDADRESA),
        psc: feature.properties.PSC,
        geometry: feature.geometry,
        isStreetModel: false,
        nazevCastObce: feature.properties.NAZEVCASTOBCE,
        nazevMestCast: feature.properties.NAZEVMESTCAST,
        nazevUlice: feature.properties.NAZEVULICE,
      }
    }

    function _processStreet(feature, streets) {
      if (_.some(streets, { ck: feature.properties.CK })) {
        for (var streetIndex in streets) {
          if (streets.hasOwnProperty(streetIndex)) {
            var street = streets[streetIndex]
            if (street.ck === feature.properties.CK) {
              street.geometries.push(feature.geometry)
            }
          }
        }
      } else {
        return {
          mkn: feature.properties.MKN,
          ck: feature.properties.CK,
          poskytovatel: feature.properties.POSKYT,
          geometries: new Array(feature.geometry),
          isStreetModel: true,
        }
      }
    }

    function findStreetsAddresses(value) {
      var addressPromise = this.findAddresses(value)
      var streetsPromise = this.findStreets(value)
      return $q.all([addressPromise, streetsPromise])
    }

    function findAddresses(value) {
      return _doQuery(
        APP_CONFIG.mapSettings.search.MAP_SEARCH_ADDRESSES_URL,
        "LOWER(ADRESA) like '%" + value.toLowerCase() + "%'",
        _processAddress,
      )
    }

    function findStreets(value) {
      return _doQuery(
        APP_CONFIG.mapSettings.search.MAP_SEARCH_STREETS_URL,
        "LOWER(MKN) like '%" + value.toLowerCase() + "%'",
        _processStreet,
      )
    }

    return {
      findStreetsAddresses: findStreetsAddresses,
      findAddresses: findAddresses,
      findStreets: findStreets,
    }
  }
})()
