;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin', [
      'ui.tree',
      'textAngular',
      'ngFileUpload',
      'ui.bootstrap.datetimepicker',
      'app.admin.layout',
      'app.admin.camera',
      'app.admin.cms',
      'app.admin.document',
      'app.admin.event',
      'app.admin.initiative',
      'app.admin.user',
      'app.admin.source',
    ])
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('admin', {
            abstract: true,
            resolve: {
              load: [
                '$q',
                '$http',
                'InjectCss',
                function ($q, $http, InjectCss) {
                  var deferred = $q.defer()
                  $http.get('rev-manifest.json').then(function (response) {
                    var id = 'style',
                      link = angular.element('link#' + id)
                    InjectCss.set(id, 'build/' + response.data['admin-style.css']).then(
                      function () {
                        if (link.length) {
                          link.remove()
                        }
                        deferred.resolve()
                      },
                    )
                  })
                  return deferred.promise
                },
              ],
            },
            views: {
              root: {
                templateUrl: 'app/modules/admin/layout/layout.tpl.html',
                controller: 'AdminLayoutController',
                controllerAs: 'adminLayoutCtrl',
                ncyBreadcrumb: {
                  label: 'Domů',
                },
              },
            },
          })
          .state('admin.camera', {
            url: '/admin/kamery',
            data: {
              title: 'Kamery',
              roles: ['ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/camera/views/main.html',
                controller: 'AdminCameraController',
                controllerAs: 'cameraCtrl',
              },
            },
          })
          .state('admin.future-camera', {
            url: '/admin/zasobnik-kamer',
            data: {
              title: 'Zásobník kamer',
              roles: ['ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/camera/views/futureCamera.html',
                controller: 'AdminFutureCameraController',
                controllerAs: 'futureCameraCtrl',
              },
            },
          })
          .state('admin.future-camera-detail', {
            url: '/admin/zasobnik-kamer/:id',
            params: {
              id: { squash: true, value: null },
            },
            data: {
              title: 'Zásobník kamer',
              roles: ['ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/camera/views/futureCameraForm.html',
                controller: 'AdminFutureCameraFormController',
                controllerAs: 'futureCameraFormCtrl',
              },
            },
          })
          .state('admin.camera-availability', {
            url: '/admin/pristupnost-kamer',
            data: {
              title: 'Přístupnost kamer',
              roles: ['ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/camera/views/availability.html',
                controller: 'AdminCameraAvailabilityController',
                controllerAs: 'cameraAvailabilityCtrl',
              },
            },
          })
          .state('admin.camera-grid', {
            url: '/admin/prehled-kamer',
            data: {
              title: 'Přehled kamer',
              roles: [
                'REGISTERED_USER',
                'CONTENT_PUBLISHER',
                'EVENT_PUBLISHER',
                'CRISIS_MANAGER',
                'HEAD_MANAGER',
                'ADMIN',
              ],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/camera/views/grid.html',
                controller: 'AdminCameraGridController',
                controllerAs: 'cameraGridCtrl',
              },
            },
          })
          .state('admin.cms', {
            url: '/admin/clanky/:id/:childId',
            params: {
              id: { squash: true, value: null },
              childId: { squash: true, value: null },
            },
            data: {
              title: 'Články',
              roles: ['CONTENT_PUBLISHER', 'TRANSLATOR'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/cms/views/main.html',
                controller: 'AdminCmsController',
                controllerAs: 'cmsCtrl',
              },
            },
          })
          .state('admin.cms-create', {
            url: '/admin/vytvoreni-clanku',
            data: {
              title: 'Vytvoření článku',
              roles: ['CONTENT_PUBLISHER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/cms/views/createArticle.html',
                controller: 'AdminCreateArticleController',
                controllerAs: 'createArticleCtrl',
              },
            },
          })
          .state('admin.cms-edit', {
            url: '/admin/editace-clanku/:id',
            data: {
              title: 'Editace článku',
              roles: ['CONTENT_PUBLISHER', 'TRANSLATOR'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/cms/views/editArticle.html',
                controller: 'AdminEditArticleController',
                controllerAs: 'editArticleCtrl',
              },
            },
          })
          .state('admin.document', {
            url: '/admin/dokumenty',
            data: {
              title: 'Dokumenty',
              roles: ['CONTENT_PUBLISHER', 'TRANSLATOR'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/document/views/main.html',
                controller: 'AdminDocumentController',
                controllerAs: 'documentCtrl',
              },
            },
          })
          .state('admin.event', {
            url: '/admin/aktuality',
            data: {
              title: 'Aktuality',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER', 'TRANSLATOR'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/event/views/main.html',
                controller: 'AdminEventController',
                controllerAs: 'adminEventCtrl',
              },
            },
          })
          .state('admin.event-detail', {
            url: '/admin/aktuality/:id',
            data: {
              title: 'Detail aktuality',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER', 'TRANSLATOR'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/event/views/detail.html',
                controller: 'AdminEventDetailController',
                controllerAs: 'adminEventDetailCtrl',
              },
            },
          })
          .state('admin.event-create', {
            url: '/admin/vytvoreni-aktuality/:id',
            data: {
              title: 'Vytvoření aktuality',
              roles: ['EVENT_PUBLISHER', 'HEAD_MANAGER'],
            },
            params: {
              id: { squash: true, value: null },
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/event/views/createEvent.html',
                controller: 'AdminCreateEventController',
                controllerAs: 'createEventCtrl',
              },
            },
          })
          .state('admin.event-edit', {
            url: '/admin/editace-aktuality/:id',
            data: {
              title: 'Editace aktuality',
              roles: ['EVENT_PUBLISHER', 'HEAD_MANAGER', 'TRANSLATOR'],
            },
            params: {
              isEventEdit: true,
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/event/views/createEvent.html',
                controller: 'AdminCreateEventController',
                controllerAs: 'createEventCtrl',
              },
            },
          })
          .state('admin.pub', {
            url: '/admin/hospody',
            data: {
              title: 'Hospody',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/pub.html',
                controller: 'AdminPubController',
                controllerAs: 'pubCtrl',
              },
            },
          })
          .state('admin.pub-detail', {
            url: '/admin/hospody/:id',
            params: {
              id: { squash: true, value: null },
            },
            data: {
              title: 'Hospoda',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/pubForm.html',
                controller: 'AdminPubFormController',
                controllerAs: 'pubFormCtrl',
              },
            },
          })
          .state('admin.police', {
            url: '/admin/kriminalita',
            data: {
              title: 'Kriminalita',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/police.html',
                controller: 'AdminPoliceController',
                controllerAs: 'policeCtrl',
              },
            },
          })
          .state('admin.fireman', {
            url: '/admin/hasicsky-zachranny-sbor',
            data: {
              title: 'Hasičský záchranný sbor',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/fireman.html',
                controller: 'AdminFiremanController',
                controllerAs: 'firemanCtrl',
              },
            },
          })
          .state('admin.passability', {
            url: '/admin/prujezdnost',
            data: {
              title: 'HZS - Průjezdnost',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/passability.html',
                controller: 'AdminPassabilityController',
                controllerAs: 'passabilityCtrl',
              },
            },
          })
          .state('admin.passability-detail', {
            url: '/admin/prujezdnost/:id',
            params: {
              id: { squash: true, value: null },
            },
            data: {
              title: 'Průjezdnost',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/passabilityPointForm.html',
                controller: 'AdminPassabilityPointFormController',
                controllerAs: 'passabilityFormCtrl',
              },
            },
          })
          .state('admin.bollards', {
            url: '/admin/sloupky',
            data: {
              title: 'HZS - Sloupky',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/bollard.html',
                controller: 'AdminTrafficBollardsController',
                controllerAs: 'bollardsCtrl',
              },
            },
          })
          .state('admin.bollards-detail', {
            url: '/admin/sloupky/:id',
            params: {
              id: { squash: true, value: null },
            },
            data: {
              title: 'Sloupky',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/bollardForm.html',
                controller: 'AdminTrafficBollardFormController',
                controllerAs: 'bollardFormCtrl',
              },
            },
          })
          .state('admin.noise', {
            url: '/admin/hluk',
            data: {
              title: 'Hluk',
              roles: ['EVENT_PUBLISHER', 'CRISIS_MANAGER', 'HEAD_MANAGER'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/sources/views/noise.html',
                controller: 'AdminNoiseController',
                controllerAs: 'noiseCtrl',
              },
            },
          })
          .state('admin.initiative', {
            url: '/admin/podnety-obcanu',
            data: {
              title: 'Oznámení občanů',
              roles: ['INITIATIVES_ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/initiative/views/main.html',
                controller: 'AdminInitiativeController',
                controllerAs: 'initiativeCtrl',
              },
            },
          })
          .state('admin.initiative-detail', {
            url: '/admin/podnety-obcanu/:id',
            data: {
              title: 'Úprava oznámení',
              roles: ['INITIATIVES_ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/initiative/views/detail.html',
                controller: 'AdminInitiativeDetailController',
                controllerAs: 'initiativeDetailCtrl',
              },
            },
          })
          .state('admin.initiative-category', {
            url: '/admin/podnety-obcanu-kategorie',
            data: {
              title: 'Kategorie oznámení',
              roles: ['INITIATIVES_ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/initiative/views/category/main.html',
                controller: 'AdminInitiativeCategoryController',
                controllerAs: 'initiativeCategoryCtrl',
              },
            },
          })
          .state('admin.user', {
            url: '/admin/uzivatele',
            data: {
              title: 'Správa uživatelů',
              roles: ['ADMIN'],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/user/views/main.html',
                controller: 'AdminUserController',
                controllerAs: 'userCtrl',
              },
            },
          })
          .state('admin.user-profile', {
            url: '/admin/profil',
            data: {
              title: 'Profil uživatele',
              roles: [
                'REGISTERED_USER',
                'CONTENT_PUBLISHER',
                'EVENT_PUBLISHER',
                'CRISIS_MANAGER',
                'HEAD_MANAGER',
                'ADMIN',
              ],
            },
            views: {
              content: {
                templateUrl: 'app/modules/admin/user/views/profile.html',
                controller: 'AdminUserProfileController',
                controllerAs: 'userProfileCtrl',
              },
            },
          })
      },
    ])
})()
