;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('RestaurantMapService', RestaurantMapService)

  RestaurantMapService.$inject = [
    '$rootScope',
    'leafletData',
    'GeometryHelper',
    'MapHelperService',
    'LayerProviderFactory',
    'CustomLayer',
    'APP_CONFIG',
  ]

  function RestaurantMapService(
    $rootScope,
    leafletData,
    GeometryHelper,
    MapHelperService,
    LayerProviderFactory,
    CustomLayer,
    APP_CONFIG,
  ) {
    function initRestaurantMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initRestaurantLayers(map, layerCollection)
      })
    }

    function initRestaurantLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.restaurantLayers.customLayers,
        function (customLayerConfig) {
          const provider = LayerProviderFactory.provide(map, customLayerConfig.layerProvider)
          provider.update().then(() => {
            var customLayer = new CustomLayer(
              angular.extend(customLayerConfig, {
                layer: provider.getLayer(),
              }),
            )
            MapHelperService.addLayer(customLayer, layerCollection)
          })
        },
      )
    }

    return {
      initRestaurantMap: initRestaurantMap,
      initRestaurantLayers: initRestaurantLayers,
    }
  }
})()
