;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('CameraGridSettingsModalController', CameraGridSettingsModalController)

  CameraGridSettingsModalController.$inject = ['$uibModalInstance', 'camera']

  function CameraGridSettingsModalController($uibModalInstance, camera) {
    const vm = this
    vm.camera = camera

    vm.ok = ok
    vm.cancel = cancel   

    function ok() {
      $uibModalInstance.close(vm.camera)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
