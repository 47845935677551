;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').component('cppSafety', {
    templateUrl: 'app/modules/front/map/components/safety/index.html',
    controller: SafetyController,
    bindings: {
      polygons: '=',
    },
  })

  SafetyController.$inject = ['$rootScope', 'ApiAdminSafetyModel', 'FlashMessage']

  function SafetyController($rootScope, ApiAdminSafetyModel, FlashMessage) {
    const vm = this
    vm.polygons = $rootScope.polygons

    vm.savePolygons = savePolygons
    vm.polygonDataChanged = polygonDataChanged

    function savePolygons() {
      ApiAdminSafetyModel.savePolygons(
        { data: vm.polygons },
        () => {
          FlashMessage.add('Polygony pocitu bezpečí byly úspěšně uloženy.', 'success')
        },
        () => {
          FlashMessage.add('Polygony pocitu bezpečí se bohužel nepodařilo uložit.')
        },
      )
    }

    function polygonDataChanged(polygon) {
      $rootScope.$emit('polygon:formEdited', polygon)
    }

    $rootScope.$on('polygon:created', setFormDirty)
    $rootScope.$on('polygon:edited', setFormDirty)
    $rootScope.$on('polygon:deleted', setFormDirty)

    function setFormDirty(event, data) {
      vm.polygonsForm && vm.polygonsForm.$setDirty()
    }
  }
})()
