;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiCdnModel', ApiCdnModel)

  ApiCdnModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiCdnModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'cdn/:path/:id/:path1',
      { path: '@path', id: '@id', path1: '@path1' },
      {
        getSingle: {
          method: 'GET',
          params: {
            path: 'files',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('file')) {
              return data.file
            }
            return data
          },
        },
        getAllFileInfo: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'files',
            path1: 'info',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('fileInfos')) {
              return data.fileInfos
            }
            return data
          },
        },
      },
    )
  }
})()
