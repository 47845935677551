;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('SchoolMapService', SchoolMapService)

  SchoolMapService.$inject = [
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'LayerProviderFactory',
    'PointLayer',
    'ServerLayer',
    'CustomLayer',
    'APP_CONFIG',
    '$rootScope',
    '$stateParams',
  ]

  function SchoolMapService(
    leafletData,
    MapHelperService,
    MapDialogService,
    LayerProviderFactory,
    PointLayer,
    ServerLayer,
    CustomLayer,
    APP_CONFIG,
    $rootScope,
    $stateParams,
  ) {
    function initSchoolLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.schoolLayers.serverLayers,
        function (serverLayerConfiguration) {
          var serverLayer = new ServerLayer(
            angular.extend(serverLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: serverLayerConfiguration.dialogFn
                ? MapDialogService[serverLayerConfiguration.dialogFn]
                : null,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(serverLayer, layerCollection)
        },
      )

      angular.forEach(
        APP_CONFIG.mapSettings.schoolLayers.pointLayers,
        function (pointLayerConfiguration) {
          var pointLayer = new PointLayer(
            angular.extend(pointLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: MapDialogService[pointLayerConfiguration.dialogFn],
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(pointLayer, layerCollection)
        },
      )

      angular.forEach(
        APP_CONFIG.mapSettings.schoolLayers.customLayers,
        function (customLayerConfig) {
          const provider = LayerProviderFactory.provide(map, customLayerConfig.layerProvider)
          provider.update().then(() => {
            var customLayer = new CustomLayer(
              angular.extend(customLayerConfig, {
                layer: provider.getLayer(),
              }),
            )
            MapHelperService.addLayer(customLayer, layerCollection)
            _handlePopupOpen(provider.getLayer())
          })
        },
      )
    }

    function _handlePopupOpen(layer) {
      const syposId = $stateParams.syposId
      layer.eachLayer((marker) => {
        if (syposId && marker.syposId.toString() === syposId.toString()) {
          marker.fire('click')
        }
      })
    }

    function initSchoolMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initSchoolLayers(map, layerCollection)
      })
    }

    return {
      initSchoolMap: initSchoolMap,
      initSchoolLayers: initSchoolLayers,
    }
  }
})()
