;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiSearchModel', ApiSearchModel)

  ApiSearchModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiSearchModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'search/:path',
      { path: '@path' },
      {
        search: {
          method: 'GET',
          params: {
            path: 'fulltext',
          },
        },
      },
    )
  }
})()
