;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('DdrMessageLayerProvider', DdrMessageLayerProvider)

  DdrMessageLayerProvider.$inject = [
    '$rootScope',
    '$filter',
    'ApiMapModel',
    'MapHelperService',
    'MapDialogService',
    'CustomLayer',
  ]

  function DdrMessageLayerProvider(
    $rootScope,
    $filter,
    ApiMapModel,
    MapHelperService,
    MapDialogService,
    CustomLayer,
  ) {
    function initDdrMessagesLayer(map, configuration, layerCollection) {
      var markersGroup = new L.MarkerClusterGroup({
        iconCreateFunction: MapHelperService.getCreateIconFunction(),
        disableClusteringAtZoom: 10,
      })

      var trafficLayer = new CustomLayer(
        angular.extend(configuration, {
          layer: markersGroup,
        }),
      )
      MapHelperService.addLayer(trafficLayer, layerCollection)

      ApiMapModel.getDdrMessages(function (mapDdrMessages) {
        ddrMessagesToMarkers(map, mapDdrMessages, markersGroup)
      })
    }

    function ddrMessagesToMarkers(map, ddrMessages, markersGroup) {
      angular.forEach(ddrMessages, function (ddrMessage) {
        var imagePath = $filter('ddrImage')(ddrMessage)
        ddrMessage.imagePath = imagePath
        var position = ddrMessage.position

        var markerOptions = {
          icon: new L.Icon({
            iconUrl: imagePath,
            iconSize: new L.point(36, 36),
            iconAnchor: new L.point(24, 24),
          }),
        }
        var marker = new L.marker([position.coordinates[1], position.coordinates[0]], markerOptions)
        marker.on('click', function (event, args) {
          MapDialogService.openDialogDdrMessages(
            ddrMessage,
            map,
            marker.getLatLng(),
            $rootScope.$new(),
          )
        })
        markersGroup.addLayer(marker)
        return marker
      })
    }

    return {
      initDdrMessagesLayer: initDdrMessagesLayer,
    }
  }
})()
