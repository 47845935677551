;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiTrafficModel', ApiTrafficModel)

  ApiTrafficModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiTrafficModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + ':path/:id/',
      {
        path: '@path',
        id: '@id',
      },
      {
        getDdrMessages: {
          method: 'GET',
          params: {
            path: 'ddr',
          },
        },
        getDdrMessage: {
          method: 'GET',
          params: {
            path: 'ddr',
          },
          transformResponse: UtilService.simpleResponseTransform('message'),
        },
      },
    )
  }
})()
