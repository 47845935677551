;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiArticleModel', ApiArticleModel)

  ApiArticleModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiArticleModel($resource, UtilService, APP_CONFIG) {
    var cultureFields = [
      'cultureCode',
      'title',
      'description',
      'keywords',
      'parentRelativeUrl',
      'perex',
      'relativeUrl',
      'publicContent',
      'privateContent',
      'breadcrumbs',
    ]

    function transformArticleResponse(articleObj) {
      return _.assign(articleObj, articleObj.articleCulture[0])
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'articles/:id/:path/:id1',
      { id: '@id', path: '@path', id1: '@id1' },
      {
        getArticle: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('article')) {
              var article = transformArticleResponse(data.article)
              // article = UtilService.decodeToBase64(article, "publicContent");
              article.publicContent = UtilService.replaceContentVariableToValue(
                article.publicContent,
              )
              // article = UtilService.decodeToBase64(article, "privateContent");
              article.privateContent = UtilService.replaceContentVariableToValue(
                article.privateContent,
              )

              return article
            }
            return data
          },
        },
        getArticleTree: {
          method: 'GET',
          params: {
            path: 'tree',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('articles')) {
              return data.articles
            }
            return data
          },
        },
        downloadArticle: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
      },
    )
  }
})()
