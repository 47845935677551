;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cppMapSelect', MapSelect)

  MapSelect.$inject = ['MapProviderService']

  function MapSelect(MapProviderService) {
    return {
      restrict: 'A',
      scope: {
        providerService: '@',
        showLegend: '<',
      },
      replace: true,
      templateUrl: 'app/modules/_common/directives/mapSelect.html',
      link: function (scope) {
        scope.data = {
          mapLayers: [],
          legend: [],
        }
        MapProviderService.provide(scope.providerService, scope.data)
      },
    }
  }
})()
