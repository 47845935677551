;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cppScrollTo', ScrollToDirective)

  ScrollToDirective.$inject = []

  function ScrollToDirective() {
    return {
      restrict: 'A',
      scope: {
        verticalScroll: '=',
      },
      link: function (scope) {
        scope.verticalScroll = (param) => {
          angular.element('html, body').animate({ scrollTop: +param }, 400)
        }
      },
    }
  }
})()
