;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('HzsDynamicsMapModalController', HzsDynamicsMapModalController)

  HzsDynamicsMapModalController.$inject = ['ApiSourceModel', 'MapDialogService']

  function HzsDynamicsMapModalController(ApiSourceModel, MapDialogService) {
    const vm = this

    activate()

    function activate() {
      getEnums()
      vm.data = MapDialogService.getPopupData()
    }

    function getEnums() {
      vm.firemanTypes = ApiSourceModel.getFiremanTypes()
      vm.firemanSubTypes = ApiSourceModel.getFiremanSubTypes()
    }
  }
})()
