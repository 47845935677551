;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').controller('HomepageController', HomepageController)

  HomepageController.$inject = []

  function HomepageController() {}
})()
