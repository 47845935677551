;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.registration')
    .controller('RegistrationConfirmationController', RegistrationConfirmationController)

  RegistrationConfirmationController.$inject = [
    '$state',
    '$stateParams',
    'ApiUserModel',
    'FlashMessage',
  ]

  function RegistrationConfirmationController($state, $stateParams, ApiUserModel, FlashMessage) {
    confirmRegistration()

    function confirmRegistration() {
      ApiUserModel.verifyAccount(
        {},
        { token: $stateParams.token },
        () => {
          FlashMessage.add(
            'FRONT.REGISTRATION.FLASH_MESSAGE.REGISTRATION_CONFIRMATION.CONFIRMED',
            'success',
            1,
          )
          $state.go('front.homepage', {}, { reload: true })
        },
        () => {
          $state.go('front.homepage', {}, { reload: true })
        },
      )
    }
  }
})()
