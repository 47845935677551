;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('userName', UserNameFilter)

  UserNameFilter.$inject = []

  function UserNameFilter() {
    return function (input) {
      if (angular.isDefined(input)) {
        var name = '',
          firstName = input.firstName,
          lastName = input.lastName
        if (firstName) {
          name += firstName
        }
        if (lastName) {
          name += ' ' + lastName
        }
        return _.trim(name)
      }
      return input
    }
  }
})()
