;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiWeatherModel', ApiWeatherModel)

  ApiWeatherModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiWeatherModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + ':path',
      { path: '@path' },
      {
        getForecast: {
          method: 'GET',
          params: {
            path: 'weather',
          },
        },
        getSivs: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'sivs',
          },
          transformResponse: UtilService.simpleResponseTransform('entries'),
        },
        getCap: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'sivs',
          },
          transformResponse: UtilService.simpleResponseTransform('entries'),
        },
        getRiverStations: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'rivers',
          },
          transformResponse: UtilService.simpleResponseTransform('stations'),
        },
        getIskoData: {
          method: 'GET',
          params: {
            path: 'isko',
          },
          transformResponse: (data) => {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('data')) {
              data.data.stations = _.map(data.data.stations, (station) => {
                return angular.extend(station, {
                  backgroundColor: _.result(_.find(data.data.legends, { ix: station.ix }), 'color'),
                  color: _.result(_.find(data.data.legends, { ix: station.ix }), 'colorText'),
                  description: _.result(
                    _.find(data.data.legends, { ix: station.ix }),
                    'description',
                  ),
                })
              })
              return data.data
            }
            return data
          },
        },
      },
    )
  }
})()
