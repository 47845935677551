;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('AdminFutureCameraController', AdminFutureCameraController)

  AdminFutureCameraController.$inject = [
    '$http',
    '$uibModal',
    '$window',
    'ApiCameraModel',
    'ApiAdminCameraModel',
    'QueryFilterService',
    'BOOL_VALUES',
  ]

  function AdminFutureCameraController(
    $http,
    $uibModal,
    $window,
    ApiCameraModel,
    ApiAdminCameraModel,
    QueryFilterService,
    BOOL_VALUES,
  ) {
    const vm = this
    vm.boolValues = BOOL_VALUES

    vm.filterData = filterData
    vm.openFutureCameraForm = openFutureCameraForm
    vm.openDeleteFutureCamera = openDeleteFutureCamera
    vm.exportFutureCameras = exportFutureCameras

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      initFutureCameraEnums()
      getFutureCameras()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = 'id'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/camera/meta/future-camera-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function initFutureCameraEnums() {
      vm.futureCameraTypes = ApiCameraModel.getTypes()
      vm.futureCameraStates = ApiCameraModel.getCameraStates()
      vm.futureCameraConstructionReasons = ApiCameraModel.getCameraConstructionReasons()
    }

    function getFutureCameras() {
      vm.loadingData = true
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      ApiAdminCameraModel.getFutureCameraEntries(
        {},
        search,
        function (entries) {
          vm.futureCameras = entries.data
          vm.totalCount = entries.totalCount
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function openFutureCameraForm(camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/future/futureCameraFormModal.html',
        controller: 'FutureCameraFormModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return angular.copy(camera)
          },
          cameraTypes: function () {
            return vm.futureCameraTypes
          },
          cameraStates: function () {
            return vm.futureCameraStates
          },
          cameraConstructionReasons: function () {
            return vm.futureCameraConstructionReasons
          },
        },
      })
      modalInstance.result.then(
        function (request) {
          request.$promise.then(function () {
            getFutureCameras()
          })
        },
        function () {},
      )
    }

    function openDeleteFutureCamera(camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/future/futureCameraRequestDeleteModal.html',
        controller: 'FutureCameraRequestDeleteModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            return camera
          },
        },
      })
      modalInstance.result.then(
        function (request) {
          request.$promise.then(function () {
            getFutureCameras()
          })
        },
        function () {},
      )
    }

    function filterData() {
      vm.currentPage = 1
      getFutureCameras()
    }

    function exportFutureCameras() {
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      return ApiAdminCameraModel.getFutureCamerasExport(search, function (data) {
        $window.saveAs(data.blob, data.fileName)
      })
    }
  }
})()
