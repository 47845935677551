;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.layout')
    .controller('ChangePasswordModalController', ChangePasswordModalController)

  ChangePasswordModalController.$inject = [
    '$state',
    '$uibModalInstance',
    'ApiUserModel',
    'FlashMessage',
  ]

  function ChangePasswordModalController($state, $uibModalInstance, ApiUserModel, FlashMessage) {
    const vm = this
    vm.changePassword = changePassword
    vm.cancel = cancel

    function changePassword(oldPassword, password) {
      ApiUserModel.resetPassword(
        { oldPassword: oldPassword, newPassword: password },
        () => {
          FlashMessage.add(
            {
              id: 'FRONT.CHANGE_PASSWORD.FLASH_MESSAGE.CHANGE_PASSWORD.CHANGE_SUCCESS',
            },
            'success',
            1,
          )
          $uibModalInstance.dismiss('cancel')
        },
        (response) => {
          if (response.data.errorCode) {
            FlashMessage.add('_COMMON.ERROR.' + response.data.errorCode)
          } else {
            FlashMessage.add('FRONT.CHANGE_PASSWORD.FLASH_MESSAGE.CHANGE_PASSWORD.CHANGE_FAILED')
          }
        },
      )
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
