;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('capImageFilter', CapImageFilter)

  CapImageFilter.$inject = []

  function CapImageFilter() {
    return function (input) {
      if (angular.isDefined(input)) {
        switch (input) {
          case '':
            return 'success.png'
          case 'Level1':
            return 'notice.png'
          case 'Level2':
            return 'warning.png'
          case 'Level3':
            return 'danger.png'
          default:
            return 'Neplatný'
        }
      }
      return ''
    }
  }
})()
