;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('AdminDocumentController', AdminDocumentController)

  AdminDocumentController.$inject = [
    '$uibModal',
    'ApiAdminDocumentModel',
    'PersistenceModel',
    'APP_CONFIG',
  ]

  function AdminDocumentController($uibModal, ApiAdminDocumentModel, PersistenceModel, APP_CONFIG) {
    const vm = this

    vm.apiKey = PersistenceModel.getAccessToken()
    vm.baseUrl = APP_CONFIG.cdnUrl

    vm.allowStructureEdit = true
    vm.placeholder = []

    vm.openCreateDocument = openCreateDocument
    vm.openCreateDocuments = openCreateDocuments
    vm.openEditDocument = openEditDocument
    vm.openDeleteDocument = openDeleteDocument

    vm.editTree = editTree
    vm.removeTree = removeTree
    vm.toggleTree = toggleTree
    vm.createSubtree = createSubtree

    var translationTabsHandlers = {}

    vm.setHandlersFn = function (handlersObj) {
      translationTabsHandlers = handlersObj
    }
    

    function getStructure(cultureCode) {
      vm.loadingData = true
      ApiAdminDocumentModel.getStructure(
        { cultureCode: cultureCode },
        function (data) {
          vm.documents = data
          if (vm.allowStructureEdit && vm.documents[0].parentId === undefined) {
            updatePlaceholders(vm.documents[0])
          }
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function removeTree(scope) {
      var nodeData = scope.$modelValue
      if (!nodeData.hasArticles) {
        var modalInstance = $uibModal.open({
          templateUrl: 'app/modules/admin/document/modal/file/deleteFolderModal.html',
          controller: 'DeleteFolderModalController',
          controllerAs: 'modalCtrl',
          resolve: {
            folder: function () {
              return nodeData
            },
          },
        })
        modalInstance.result.then(
          function (promise) {
            promise.$promise.then(function () {
              scope.remove()
            })
          },
          function () {},
        )
      } else {
        ApiAdminDocumentModel.deleteFolder({ id: nodeData.folderId }, function () {
          scope.remove()
        })
      }
    }

    function toggleTree(scope) {
      scope.toggle()
      _.each(scope.childNodes(), function (c) {
        c.collapse()
      })
    }

    function createSubtree(scope) {
      var nodeData = scope.$modelValue
      var subFolder = {
        parentId: nodeData.folderId,
        folderCultures: [
          {
            cultureCode: vm.cultureCode,
            name: nodeData.folderCultures[0].name + '.' + (nodeData.subFolders.length + 1),
            files: [],
          },
        ],
        hasArticles: false,
        subFolders: [],
      }
      vm.loadingData = true
      ApiAdminDocumentModel.createFolder(
        { folder: subFolder },
        function (folder) {
          subFolder.folderId = folder.id
          nodeData.subFolders.push(subFolder)
          updatePlaceholders(subFolder)
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function editTree(scope) {
      var nodeData = scope.$modelValue
      nodeData.folderCultures[0].cultureCode = vm.cultureCode
      if (vm.cultureCode === vm.defualtLanguage) {
        updatePlaceholders(nodeData)
      }

      ApiAdminDocumentModel.editFolder(
        { id: nodeData.folderId },
        { folder: nodeData },
        function () {
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function openCreateDocument(folder) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/document/modal/file/createFileModal.html',
        controller: 'CreateFileModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          folder: function () {
            return folder
          },
        },
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }

    function openCreateDocuments(folder) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/document/modal/file/createMultipleFileModal.html',
        controller: 'CreateMultipleFileModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          folder: function () {
            return folder
          },
        },
      })
      modalInstance.result.then(
        function (files) {
          angular.forEach(files, function (file) {
            folder.folderCultures[0].files.push({
              public: file.fileInfo.public,
              fileId: file.fileInfo.id,
              fileAlias: file.fileInfo.alias,
              fileName: file.fileInfo.name,
              keywords: file.fileInfo.keywords,
            })
          })
        },
        function () {},
      )
    }

    function openEditDocument(file) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/document/modal/file/editFileModal.html',
        controller: 'EditFileModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          file: function () {
            return file
          },
        },
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }

    function openDeleteDocument(file, folder) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/document/modal/file/deleteFileModal.html',
        controller: 'DeleteFileModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          file: function () {
            return file
          },
          folder: function () {
            return folder
          },
          cultureCode: function () {
            return vm.cultureCode
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            folder.folderCultures[0].files = folder.folderCultures[0].files.filter(function (el) {
              return el.fileId !== file.fileId
            })
          })
        },
        function () {},
      )
    }

    function updatePlaceholders(node) {
      vm.placeholder[node.folderId] = node.folderCultures[0].name

      _.forEach(node.subFolders, function (subFolder) {
        updatePlaceholders(subFolder)
      })
    }

    function updateAllowStructureEdit() {
      vm.allowStructureEdit = vm.cultureCode === APP_CONFIG.translation.fallbackLanguage
    }

    vm.translationInit = function (cultureCode) {
      vm.cultureCode = cultureCode
      vm.defualtLanguage = cultureCode

      ApiAdminDocumentModel.getRootCultures(function (existingTranslations) {
        translationTabsHandlers.addExistingTranslation(
          existingTranslations.map(function (culture) {
            return culture.cultureCode
          }),
        )
      })

      updateAllowStructureEdit()
      getStructure(cultureCode)
    }

    vm.translationAdd = function (cultureCode) {
      vm.cultureCode = cultureCode

      updateAllowStructureEdit()
      getStructure(cultureCode)
    }

    vm.translationChange = function (cultureCode) {
      vm.cultureCode = cultureCode

      updateAllowStructureEdit()
      getStructure(cultureCode)
    }
  }
})()
