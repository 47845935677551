;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('MapSearchController', MapSearchController)

  MapSearchController.$inject = [
    '$rootScope',
    '$q',
    '$state',
    'MapHelperService',
    'MapSearchService',
    'HelperService',
    'leafletData',
  ]

  function MapSearchController(
    $rootScope,
    $q,
    $state,
    MapHelperService,
    MapSearchService,
    HelperService,
    leafletData,
  ) {
    const vm = this

    vm.search = search
    vm.getTypeAheadContent = getTypeAheadContent
    vm.onSearchModelChanged = onSearchModelChanged

    activate()

    function activate() {
      vm.data = {
        searchModel: null,
        searchModelOptions: {
          allowInvalid: false,
          debounce: 600,
          getterSetter: false,
        },
      }
      vm.searchGroupLayer = new L.layerGroup([])
    }

    vm.$onInit = function () {
      MapHelperService.initViewModelMapData(vm.data)

      leafletData.getMap('map').then(function (map) {
        if ($state.current.name === 'maps.map-search') {
          var layerCollection = MapHelperService.initMap(map)
          map.removeControl(layerCollection.getLayerControl())

          var townDistrictLayer = MapHelperService.getTownDistrictLayer()
          layerCollection.addLayer(townDistrictLayer)
        }
        map.addLayer(vm.searchGroupLayer)
      })
    }

    function search(value) {
      var promises = []
      promises.push(MapSearchService.findStreets(value))
      promises.push(MapSearchService.findAddresses(value))

      return $q.all(promises).then(function (results) {
        var data = []
        data = data.concat(results[0])
        data = data.concat(results[1])
        data = data.slice(0, 20)
        return data
      })
    }

    function getTypeAheadContent(item) {
      if (HelperService.isNullOrUndefined(item)) {
        return null
      }
      if (item.isStreetModel) {
        return item.mkn
      }
      return item.adresa
    }

    function onSearchModelChanged($item, model) {
      leafletData.getMap('map').then(function (map) {
        vm.searchGroupLayer.clearLayers()

        if (model.isStreetModel) {
          var boundList = []
          var polylineList = []

          for (var key in model.geometries) {
            var coordinates = _.map(model.geometries[key].coordinates, function (coord) {
              return new L.LatLng(coord[1], coord[0])
            })
            polylineList.push(coordinates)
            boundList = boundList.concat(coordinates)
          }
          var multiPolylineLayer = L.polyline(polylineList, {
            color: 'red',
            weight: 2,
            opacity: 0.5,
            smoothFactor: 1,
          })

          vm.searchGroupLayer.addLayer(multiPolylineLayer)
          map.fitBounds(new L.LatLngBounds(boundList))
        } else {
          var marker = new L.Marker(
            new L.LatLng(model.geometry.coordinates[1], model.geometry.coordinates[0]),
            {
              draggable: true,
              icon: new L.Icon({
                iconUrl: 'styles/img/location-pin.png',
                shadowUrl: 'styles/img/marker-shadow.png',
                iconAnchor: new L.Point(16, 32),
                shadowAnchor: new L.Point(14, 40),
              }),
            },
          )

          vm.searchGroupLayer.addLayer(marker)
          map.panTo(new L.LatLng(model.geometry.coordinates[1], model.geometry.coordinates[0]))

          /* Zoom */
          var group = new L.FeatureGroup([marker])
          map.fitBounds(group.getBounds())
        }

        $rootScope.$broadcast('mapAddressSearched', model, vm.searchGroupLayer)
      })
    }
  }
})()
