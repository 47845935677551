;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('eventPriority', EventPriorityFilter)

  EventPriorityFilter.$inject = []

  function EventPriorityFilter() {
    return function (type, types) {
      for (var key in types) {
        if (types.hasOwnProperty(key)) {
          if (types[key].id === type) {
            return types[key].name
          }
        }
      }
      return type
    }
  }
})()
