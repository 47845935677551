;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.event').controller('EventController', EventController)

  EventController.$inject = ['$translate', 'ApiEventModel', 'APP_CONFIG']

  function EventController($translate, ApiEventModel, APP_CONFIG) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.pageSize = 5
    vm.currentPage = 1

    
    vm.getEvents = getEvents

    activate()

    function activate() {
      getEvents()
    }

    function getEvents() {
      vm.loadingData = true
      ApiEventModel.getEvents(
        {
          cultureCode: $translate.use(),
          limit: vm.pageSize,
          offset: (vm.currentPage - 1) * vm.pageSize,
        },
        (data) => {
          vm.events = data.events
          vm.totalCount = data.totalCount
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }
  }
})()
