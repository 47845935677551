;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('NoiseDynamicsMapModalController', NoiseDynamicsMapModalController)

  NoiseDynamicsMapModalController.$inject = [
    '$translate',
    '$window',
    'ApiSourceModel',
    'MapDialogService',
  ]

  function NoiseDynamicsMapModalController($translate, $window, ApiSourceModel, MapDialogService) {
    var vm = this
    vm.downloadData = downloadData

    activate()

    function activate() {
      getEnums()
      vm.data = MapDialogService.getPopupData()
    }

    function getEnums() {
      vm.noiseStates = ApiSourceModel.getNoiseStates({ cultureCode: $translate.use() })
    }

    function downloadData(id) {
      return ApiSourceModel.downloadNoiseData({ path1: id }, function (data) {
        $window.saveAs(data.blob, data.fileName)
      })
    }
  }
})()
