;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('url', UrlFilter)

  UrlFilter.$inject = []

  function UrlFilter() {
    return function (input) {
      if (input && input.search(/^http[s]?\:\/\//) == -1) {
        return `https://${input}`
      }
      return input
    }
  }
})()
