;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('IsActiveCategoryModalController', IsActiveCategoryModalController)

  IsActiveCategoryModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminInitiativeModel',
    'FlashMessage',
    'APP_CONFIG',
    'category',
  ]

  function IsActiveCategoryModalController(
    $uibModalInstance,
    ApiAdminInitiativeModel,
    FlashMessage,
    APP_CONFIG,
    category,
  ) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.category = category

    activate()

    function activate() {
      vm.categoryName = getCategoryName()
    }

    function getCategoryName() {
      return vm.category.cultures.filter(function (x) {
        return x.cultureCode === APP_CONFIG.translation.fallbackLanguage
      })[0].name
    }

    function ok() {
      var promise
      if (vm.category.deleted) {
        promise = ApiAdminInitiativeModel.undeleteInitiativeCategory(
          { id: vm.category.id },
          function () {
            FlashMessage.add('Kategorie oznámení byla úspěšně obnovena.', 'success')
          },
        )
      } else {
        promise = ApiAdminInitiativeModel.deleteInitiativeCategory(
          { id: vm.category.id },
          function () {
            FlashMessage.add('Kategorie oznámení byla úspěšně smazána.', 'success')
          },
        )
      }
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
