;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('HttpInterceptor', HttpInterceptorService)

  HttpInterceptorService.$inject = [
    '$rootScope',
    '$q',
    '$injector',
    'FlashMessage',
    'PersistenceModel',
    'APP_CONFIG',
  ]

  function HttpInterceptorService(
    $rootScope,
    $q,
    $injector,
    FlashMessage,
    PersistenceModel,
    APP_CONFIG,
  ) {
    var deferred = null

    function refreshToken(callback) {
      $.ajax({
        url: APP_CONFIG.apiRootUrl + 'authentication/' + PersistenceModel.getRefreshToken(),
        type: 'PUT',
        contentType: 'application/json',
        dataType: 'json',
      })
        .done(function (data) {
          var validity = PersistenceModel.getAccessTokenValidity()
          if (moment(validity).isBefore(moment(data.accessToken.expiry))) {
            PersistenceModel.setAccessToken(data.accessToken.token)
            PersistenceModel.setAccessTokenValidity(data.accessToken.expiry)
            callback()
          }
        })
        .fail(function () {
          $rootScope.$broadcast('autoLogout')
        })
    }

    function missingTranslation(rejection) {
      $injector.get('$state').go('front.pageNotFound', {}, { location: false })
    }

    function notifyError(rejection) {
      if (rejection.status === 401) {
        refreshToken(function () {
          // TODO potencialni zacykleni, pokud by na default page usera byla 401 nebo 403
          location.reload()
        })
      } else if (rejection.status === 403) {
        $rootScope.$broadcast('noRights')
      }

      /* message from middleware has greater priority */
      var errorFromApi = false
      if (rejection.data) {
        if (rejection.data.errorCode) {
          errorFromApi = true
          if (rejection.data.errorCode === 19000) {
            // TODO errorCode check
            missingTranslation(rejection)
          } else {
            FlashMessage.add(rejection.data.httpStatusCode + ' ' + rejection.data.errorMessage)
          }
        }
      }

      if (errorFromApi === false) {
        switch (rejection.status) {
          case 400:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.400')
            break
          case 401:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.401')
            break
          case 403:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.403')
            break
          case 404:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.404')
            break
          case 405:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.405')
            break
          case 406:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.406')
            break
          case 415:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.415')
            break
          case 500:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.500')
            break
          case 503:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.503')
            break
          default:
            FlashMessage.add('_COMMON.SERVICE.HTTP_INTERCEPTOR.DEFAULT')
        }
      }
    }

    function _setAccessToken(request) {
      var apiKey = PersistenceModel.getAccessToken()
      if (apiKey) {
        request.headers['X-Api-Key'] = apiKey
      }
      return request
    }

    function _isArcGisUrl(url) {
      return _.includes(url, 'mpp.praha.eu/arcgis')
    }

    return {
      request: function (request) {
        if (!_isArcGisUrl(request.url)) {
          var validity = PersistenceModel.getAccessTokenValidity()
          if (validity && moment(validity).isBefore(moment())) {
            deferred = $q.defer()
            refreshToken(function () {
              deferred.resolve(request)
              return deferred.promise.then(function () {
                return _setAccessToken(request)
              })
            })
          }
          if (deferred !== null) {
            return deferred.promise.then(function () {
              return _setAccessToken(request)
            })
          } else {
            return _setAccessToken(request)
          }
        }
        return request
      },

      response: function (response) {
        return response
      },

      requestError: function (rejection) {
        notifyError(rejection)

        return $q.reject(rejection)
      },

      responseError: function (rejection) {
        notifyError(rejection)

        return $q.reject(rejection)
      },
    }
  }
})()
