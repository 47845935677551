;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('AdminInitiativeDetailController', AdminInitiativeDetailController)

  AdminInitiativeDetailController.$inject = [
    '$stateParams',
    'PersistenceModel',
    'InitiativeMapService',
    'ApiAdminInitiativeModel',
    'ApiInitiativeModel',
    'ApiLanguageModel',
    'FlashMessage',
    'APP_CONFIG',
    'INITIATIVE_STATE_STYLES',
    'INITIATIVE_ATTACHMENT_TYPES',
  ]

  function AdminInitiativeDetailController(
    $stateParams,
    PersistenceModel,
    InitiativeMapService,
    ApiAdminInitiativeModel,
    ApiInitiativeModel,
    ApiLanguageModel,
    FlashMessage,
    APP_CONFIG,
    INITIATIVE_STATE_STYLES,
    INITIATIVE_ATTACHMENT_TYPES,
  ) {
    var lang = APP_CONFIG.translation.fallbackLanguage
    var firstLoad = true

    const vm = this

    vm.data = {
      mapLayers: [],
      legend: [],
    }
    vm.emailContent = {}
    vm.stateStyles = INITIATIVE_STATE_STYLES

    vm.assignToMe = assignToMe
    vm.sendMail = sendMail
    vm.contactSelected = contactSelected

    activate()

    function activate() {
      getInitiative()
      getInitiativeStateTypes()
      getLanguages()
    }

    function initMap(initiative) {
      InitiativeMapService.initInitiativeMapForSingleInitiative(vm.data, initiative)
    }

    function getInitiative() {
      vm.loadingData = true
      vm.loadingNotesData = true
      ApiAdminInitiativeModel.getInitiative(
        { id: $stateParams.id, cultureCode: lang },
        function (data) {
          vm.initiative = data
          getInitiativeCategories(data, data.categoryId)

          vm.loadingData = false

          vm.initiativeNotes = data.notes
          vm.loadingNotesData = false

          if (firstLoad) {
            firstLoad = false
            initMap(vm.initiative)
          }
        },
        function (data) {
          vm.loadingData = false
          vm.loadingNotesData = false
        },
      )
    }

    function getInitiativeCategories(initiative) {
      ApiAdminInitiativeModel.getInitiativeCategories(
        { cultureCode: lang },
        function (categories) {
          vm.initiativeCategories = categories
          setMetadataForView(initiative, categories)
        },
        function () {
          FlashMessage.add('Kategorie oznámení neexistuje, pravděpodobně byla smazána.', 'danger')
        },
      )
    }

    function getInitiativeStateTypes() {
      vm.initiativeStateTypes = ApiInitiativeModel.getInitiativeStateTypes({
        cultureCode: lang,
      })
    }

    function getLanguages() {
      ApiLanguageModel.getAllLanguages(function (data) {
        vm.languages = data
      })
    }

    function initAttachment(attachment) {
      var type = 'none'
      var url = null

      if (attachment) {
        type = 'download'
        url = APP_CONFIG.cdnUrl + attachment.id

        for (var i = 0, knownType; (knownType = INITIATIVE_ATTACHMENT_TYPES[i]); i++) {
          if (attachment.contentType.startsWith(knownType + '/')) {
            type = knownType
            break
          }
        }
      }

      return {
        id: attachment.id,
        type: type,
        url: url,
      }
    }

    function setMetadataForView(initiative, categories) {
      vm.contactSelect = vm.initiative.author.email
      contactSelected()

      var currentUserId = PersistenceModel.getUserId()
      vm.isAssignedToMe =
        initiative.responsiblePerson && initiative.responsiblePerson.id === currentUserId
      vm.isNew = initiative.state === 'NEW'
      vm.isInProgress = initiative.state === 'IN_PROGRESS'
      vm.isSolved = initiative.state === 'SOLVED'
      vm.isUnsolved = initiative.state === 'UNSOLVED'
      var initiativeCategoryAdmins = categories.filter(function (category) {
        return category.id === initiative.categoryId
      })
      if (initiativeCategoryAdmins.length > 0) {
        vm.isInMyCategory =
          initiativeCategoryAdmins[0].initiativesAdmins.indexOf(currentUserId) !== -1
      }

      vm.initiativeAttachments = initiative.attachments.map(initAttachment)
    }

    function assignToMe() {
      ApiAdminInitiativeModel.assignToMe(
        { id: vm.initiative.id },
        function (data) {
          FlashMessage.add('Oznámení bylo úspěšně přiřazeno.', 'success')
          getInitiative()
        },
        function () {
          FlashMessage.add('Oznámení má již jiného řešitele.', 'warning')
          getInitiative()
        },
      )
    }

    function sendMail(stateKey) {
      var states = {
        0: 'IN_PROGRESS',
        1: 'SOLVED',
        2: 'UNSOLVED',
      }

      vm.emailContent.state = states[stateKey]

      ApiAdminInitiativeModel.sendMail({ id: $stateParams.id }, vm.emailContent, function () {
        FlashMessage.add('Poznámka k oznámení s emailem byla úspěšně přidána.', 'success', 1)
        clearInitiativeEmailForm()
      })
    }

    function clearInitiativeEmailForm() {
      getInitiative()
      vm.emailContent = {}
      vm.initiativeEmailForm.$setPristine()
    }

    function contactSelected(removeSelection) {
      if (removeSelection) {
        delete vm.contactSelect
      } else {
        vm.emailContent.recipient = vm.contactSelect
      }
      vm.initiativeEmailForm.recipient.$dirty = true
    }
  }
})()
