;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminTrafficBollardModel', ApiAdminTrafficBollardModel)

  ApiAdminTrafficBollardModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminTrafficBollardModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/trafficBollards/:id',
      { id: '@id' },
      {
        getTrafficBollards: {
          method: 'GET',
        },
        create: {
          method: 'POST',
        },
        getSingle: {
          method: 'GET',
        },
        edit: {
          method: 'PUT',
        },
        delete: {
          method: 'DELETE',
        },
      },
    )
  }
})()
