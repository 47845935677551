;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppWeather', {
    templateUrl: 'app/modules/front/homepage/components/weather/index.html',
    controller: WeatherController,
  })

  WeatherController.$inject = ['WETHER_TABS']

  function WeatherController(WETHER_TABS) {
    const vm = this

    vm.activeTab = WETHER_TABS[0].key
    vm.weatherTabs = WETHER_TABS

    activate()

    function activate() {}
  }
})()
