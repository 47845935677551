;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.layout')
    .controller('ForgottenPasswordModalController', ForgottenPasswordModalController)

  ForgottenPasswordModalController.$inject = [
    '$state',
    '$uibModalInstance',
    'ApiUserModel',
    'FlashMessage',
  ]

  function ForgottenPasswordModalController($state, $uibModalInstance, ApiUserModel, FlashMessage) {
    const vm = this
    vm.recoverPassword = recoverPassword
    vm.cancel = cancel

    function recoverPassword(email) {
      ApiUserModel.sendPassword(
        { username: email },
        () => {
          FlashMessage.add(
            {
              id: 'FRONT.FORGOTTEN_PASSWORD.FLASH_MESSAGE.FORGOTTEN_PASSWORD.PASSWORD_NEW',
              values: { email: email },
            },
            'success',
            1,
          )
          $state.go('front.homepage', {}, { reload: true })
        },
        () => {
          FlashMessage.add(
            'FRONT.FORGOTTEN_PASSWORD.FLASH_MESSAGE.FORGOTTEN_PASSWORD.RECOVERY_FAILED',
          )
        },
      )
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
