;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('maxDate', MaxDateFilter)

  MaxDateFilter.$inject = ['$filter']

  function MaxDateFilter($filter) {
    return function (input, format) {
      if (input) {
        if (moment(input).isValid() && moment(input).year() > 2050) {
          return 'do odvolání'
        }
        return $filter('date')(input, format)
      }
      return 'do odvolání'
    }
  }
})()
