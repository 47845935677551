;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.layout')
    .controller('InsertTableModalController', InsertTableModalController)

  InsertTableModalController.$inject = ['$uibModalInstance']

  function InsertTableModalController($uibModalInstance) {
    const vm = this
    vm.table = {
      row: 1,
      col: 1,
    }

    vm.ok = ok
    vm.cancel = cancel

    function createTable(tableParams) {
      if (
        angular.isNumber(tableParams.row) &&
        angular.isNumber(tableParams.col) &&
        tableParams.row > 0 &&
        tableParams.col > 0
      ) {
        var table =
          "<p><br/></p><p><br/></p><div class='TableBorder'><table class='table table-hover table-bordered freeTextTable'>"
        var colWidth = 100 / tableParams.col
        for (var idxRow = 0; idxRow < tableParams.row; idxRow++) {
          var row = '<tr>'
          for (var idxCol = 0; idxCol < tableParams.col; idxCol++) {
            row +=
              (idxRow === 0 ? '<th' : '<td') +
              (idxRow === 0 ? ' style="width: ' + colWidth + '%;"' : '') +
              '>&nbsp;' +
              (idxRow === 0 ? '</th>' : '</td>')
          }
          table += row + '</tr>'
        }
        return table + '</table></div><p><br/></p><p><br/></p>'
      }
    }

    function cancel() {
      $uibModalInstance.dismiss()
    }

    function ok() {
      $uibModalInstance.close(createTable(vm.table))
    }
  }
})()
