;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('FutureCamerasMapModalController', FutureCamerasMapModalController)

  FutureCamerasMapModalController.$inject = ['ApiCameraModel', 'MapDialogService']

  function FutureCamerasMapModalController(ApiCameraModel, MapDialogService) {
    const vm = this

    activate()

    function activate() {
      getEnums()
      vm.data = MapDialogService.getPopupData()
    }

    function getEnums() {
      vm.states = ApiCameraModel.getCameraStates()
      vm.mobilityTypes = ApiCameraModel.getCameraMobilityTypes()
      vm.constructionReasons = ApiCameraModel.getCameraConstructionReasons()
    }
  }
})()
