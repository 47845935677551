;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common', [
    'app.common.models',
    'app.common.services',
    'app.common.directives',
    'app.common.filters',
    'app.common.constants',
    'app.common.modals',
    'app.common.components',
  ])
})()
