;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('CreateMultipleFileModalController', CreateMultipleFileModalController)

  CreateMultipleFileModalController.$inject = [
    '$uibModalInstance',
    '$timeout',
    'ApiAdminDocumentModel',
    'FlashMessage',
    'PersistenceModel',
    'Upload',
    'APP_CONFIG',
    'folder',
  ]

  function CreateMultipleFileModalController(
    $uibModalInstance,
    $timeout,
    ApiAdminDocumentModel,
    FlashMessage,
    PersistenceModel,
    Upload,
    APP_CONFIG,
    folder,
  ) {
    const vm = this
    vm.apiKey = PersistenceModel.getAccessToken()

    vm.ok = ok
    vm.cancel = cancel
    vm.uploadFiles = uploadFiles

    activate()

    function activate() {
      vm.file = {
        public: false,
      }
      vm.numberOfUploadingFiles = 0
    }

    function uploadFiles(files, errFiles) {
      vm.files = files
      vm.errFiles = errFiles
      vm.numberOfUploadingFiles = files.length

      angular.forEach(files, function (file) {
        file.uploading = true
        file.upload = Upload.upload({
          url:
            APP_CONFIG.apiRootUrl +
            'admin/cdn/files/upload?cultureCode=' +
            folder.folderCultures[0].cultureCode,
          headers: {
            'Content-Type': file.type,
            'X-Api-Key': vm.apiKey,
          },
          data: { file: file },
        })

        file.upload.then(
          function (response) {
            $timeout(function () {
              if (response.data.hasOwnProperty('fileInfo')) {
                var fileInfo = response.data.fileInfo
                ApiAdminDocumentModel.addFile(
                  {
                    id: folder.folderId,
                    id1: fileInfo.id,
                    cultureCode: folder.folderCultures[0].cultureCode,
                  },
                  function () {
                    fileInfo.url = APP_CONFIG.cdnUrl + fileInfo.id
                    file.fileInfo = fileInfo
                    file.uploading = false
                    vm.numberOfUploadingFiles--
                    FlashMessage.add(
                      'Soubor ' + fileInfo.alias + ' byl úspěšně vytvořen.',
                      'success',
                    )
                  },
                )
              } else {
                console.log('Returned data: ' + response.data)
              }
            })
          },
          function (response) {
            if (response.status > 0) {
              vm.errorMsg = response.status + ': ' + response.data.errorMessage
              file.uploading = false
              vm.numberOfUploadingFiles--
            }
          },
          function (evt) {
            file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
          },
        )
      })
    }

    function ok() {
      $uibModalInstance.close(vm.files)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
