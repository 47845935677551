;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('ApiFilterService', ApiFilterService)

  ApiFilterService.$inject = []

  function ApiFilterService() {
    var keySettings = {
      event: {
        enumValueKeys: [
          'typeLevel',
          'spa',
          'categories',
          'groupType',
          'type',
          'publicationType',
          'character',
          'state',
        ],
        numericValueKeys: ['riverKm', 'stage', 'flow', 'temperature'],
        booleanValueKeys: [],
      },
      camera: {
        enumValueKeys: ['type'],
        numericValueKeys: ['id', 'latitude', 'longitude'],
        booleanValueKeys: ['active', 'public'],
      },
      user: {
        enumValueKeys: ['roles', 'district', 'interestDistricts'],
        numericValueKeys: ['accessMask'],
        booleanValueKeys: ['active', 'verified', 'privateAccess'],
      },
    }

    return {
      KEYS: keySettings,

      transformSearchQueryAttribute: function (query, model) {
        var search = []

        angular.forEach(query, function (obj, key) {
          if (_.isBoolean(obj) || !_.isEmpty(obj)) {
            var column = { column: key }
            if (obj.hasOwnProperty('valueFrom') || obj.hasOwnProperty('valueTo')) {
              if (obj['valueFrom'] || obj['valueTo']) {
                search.push(angular.extend(obj, column))
              }
            } else if (_.includes(model.enumValueKeys, key)) {
              if (key === 'publicationType') {
                column = { column: 'publication.publicationType' }
              }
              obj = { enumValue: obj }
              search.push(angular.extend(obj, column))
            } else if (_.includes(model.numericValueKeys, key)) {
              obj = { numericValue: Number(obj) }
              search.push(angular.extend(obj, column))
            } else if (_.includes(model.booleanValueKeys, key)) {
              obj = { boolValue: Boolean(obj) }
              search.push(angular.extend(obj, column))
            } else {
              search.push(angular.extend({ value: obj }, column))
            }
          }
        })

        return search
      },
    }
  }
})()
