;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('fixedLength', FixedLengthFilter)

  FixedLengthFilter.$inject = []

  function FixedLengthFilter() {
    return function (input, size) {
      var result = typeof input !== 'string' ? input.toString() : input
      while (result.length < size) {
        result = '0' + result
      }
      return result
    }
  }
})()
