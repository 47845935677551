;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('PersistenceModel', PersistenceModel)

  PersistenceModel.$inject = ['$filter', 'localStorageService']

  function PersistenceModel($filter, localStorageService) {
    return {
      isAuthenticated: function () {
        return this.getAccessToken() !== null
      },
      setUserId: function (userId) {
        localStorageService.set('userId', userId)
      },
      getUserId: function () {
        return localStorageService.get('userId')
      },
      setFirstName: function (firstName) {
        localStorageService.set('firstName', firstName)
      },
      getFirstName: function () {
        return localStorageService.get('firstName')
      },
      setLastName: function (lastName) {
        localStorageService.set('lastName', lastName)
      },
      getLastName: function () {
        return localStorageService.get('lastName')
      },
      getName: function () {
        var user = {
          firstName: this.getFirstName(),
          lastName: this.getLastName(),
        }
        return $filter('userName')(user)
      },
      setUserRoles: function (roles) {
        localStorageService.set('userRoles', roles)
      },
      getUserRoles: function () {
        return localStorageService.get('userRoles')
      },
      hasOnlyUserRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'REGISTERED_USER') && roles.length === 1
      },
      clearAccessToken: function () {
        localStorageService.remove('accessToken')
      },
      setRefreshToken: function (refreshToken) {
        localStorageService.set('refreshToken', refreshToken)
      },
      getRefreshToken: function () {
        return localStorageService.get('refreshToken')
      },
      setRefreshTokenValidity: function (refreshTokenValidity) {
        localStorageService.set('refreshTokenValidity', refreshTokenValidity)
      },
      getRefreshTokenValidity: function () {
        return localStorageService.get('refreshTokenValidity')
      },
      setAccessToken: function (accessToken) {
        localStorageService.set('accessToken', accessToken)
      },
      getAccessToken: function () {
        return localStorageService.get('accessToken')
      },
      setAccessTokenValidity: function (accessTokenValidity) {
        localStorageService.set('accessTokenValidity', accessTokenValidity)
      },
      getAccessTokenValidity: function () {
        return localStorageService.get('accessTokenValidity')
      },
      hasAdminRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'ADMIN')
      },
      hasCrisisRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'CRISIS_MANAGER') || _.includes(roles, 'HEAD_MANAGER')
      },
      hasCrisisManagerRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'CRISIS_MANAGER')
      },
      hasHeadManagerRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'HEAD_MANAGER')
      },
      hasUserRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'REGISTERED_USER')
      },
      hasEventPublisherRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'EVENT_PUBLISHER')
      },
      hasContentPublisherRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'CONTENT_PUBLISHER')
      },
      hasTranslatorRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'TRANSLATOR')
      },
      hasInitiativesAdminRole: function () {
        var roles = this.getUserRoles()
        return _.includes(roles, 'INITIATIVES_ADMIN')
      },
      getDefaultPage: function () {
        if (this.hasInitiativesAdminRole()) {
          return 'admin.initiative'
        } else if (this.hasCrisisRole()) {
          return 'admin.event'
        } else if (this.hasEventPublisherRole()) {
          return 'admin.event'
        } else if (this.hasContentPublisherRole()) {
          return 'admin.cms'
        } else if (this.hasTranslatorRole()) {
          return 'admin.event'
        } else if (this.hasAdminRole()) {
          return 'admin.user'
        } else if (this.hasUserRole()) {
          return 'admin.user-profile'
        }
        return 'front.homepage'
      },
      setSourceDataForEvent: function (sourceData) {
        localStorageService.set('eventSourceData', angular.toJson(sourceData))
      },
      getSourceDataForEvent: function () {
        var data = localStorageService.get('eventSourceData')
        if (data) {
          data = angular.fromJson(localStorageService.get('eventSourceData'))
        }
        return data
      },
      clearSourceDataForEvent: function () {
        localStorageService.remove('eventSourceData')
      },
      setSelectedMaps: function (selectedMaps) {
        localStorageService.set('selectedMaps', angular.toJson(selectedMaps))
      },
      getSelectedMaps: function () {
        return angular.fromJson(localStorageService.get('selectedMaps'))
      },
      setMapLayerVisibility: function (layerKey, value) {
        var mapLayersSettings = localStorageService.get('mapLayerSettings')
        if (mapLayersSettings) {
          mapLayersSettings = angular.fromJson(localStorageService.get('mapLayerSettings'))
        } else {
          mapLayersSettings = {}
        }
        mapLayersSettings[layerKey] = value
        localStorageService.set('mapLayerSettings', angular.toJson(mapLayersSettings))
      },
      getMapLayerVisibility: function (layerKey) {
        var mapLayersSettings = localStorageService.get('mapLayerSettings')
        if (mapLayersSettings) {
          mapLayersSettings = angular.fromJson(localStorageService.get('mapLayerSettings'))
          if (mapLayersSettings.hasOwnProperty(layerKey)) {
            return mapLayersSettings[layerKey]
          }
        }
        return null
      },
      setHiddenTableColumns: function (cacheKey, columns) {
        localStorageService.set(cacheKey, angular.toJson(columns))
      },
      getHiddenTableColumns: function (cacheKey) {
        return angular.fromJson(localStorageService.get(cacheKey))
      },
      setupUser: function (data) {
        this.clear()
        this.setUserId(data.user.id)
        this.setFirstName(data.user.firstName)
        this.setLastName(data.user.lastName)
        this.setRefreshToken(data.refreshToken.token)
        this.setRefreshTokenValidity(data.refreshToken.expiry)
        this.setAccessToken(data.accessToken.token)
        this.setAccessTokenValidity(data.accessToken.expiry)
        this.setUserRoles(data.user.roles)
      },
      clear: function () {
        localStorageService.clearAll()
      },
    }
  }
})()
