;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.source')
    .controller('UploadFiremanCsvModalController', UploadFiremanCsvModalController)

  UploadFiremanCsvModalController.$inject = [
    '$uibModalInstance',
    'PersistenceModel',
    'Upload',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function UploadFiremanCsvModalController(
    $uibModalInstance,
    PersistenceModel,
    Upload,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.fileSelect = fileSelect

    vm.ok = ok
    vm.cancel = cancel

    vm.apiKey = PersistenceModel.getAccessToken()
    

    function fileSelect(files, errFiles) {
      if (files.length === 1) {
        vm.file = files[0]
      }
      if (errFiles.length === 1) {
        vm.errFile = errFiles[0]
      }
    }

    function ok(file) {
      if (!vm.file) {
        return
      }

      file = vm.file

      file.uploading = true
      file.upload = Upload.upload({
        url: APP_CONFIG.apiRootUrl + 'admin/fireDept/import',
        headers: {
          'Content-Type': file.type,
          'X-Api-Key': vm.apiKey,
        },
        data: { file: file },
      })

      file.upload.then(
        function (response) {
          FlashMessage.add('Data hasičského záchranného sboru byla úspěšně nahrána.', 'success')
          file.uploading = false
          $uibModalInstance.close()
        },
        function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data.errorMessage
            file.uploading = false
            vm.numberOfUploadingFiles--
          }
        },
        function (evt) {
          file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
        },
      )
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
