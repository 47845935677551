;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiUserModel', ApiUserModel)

  ApiUserModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiUserModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'users/:id/:path/:id1/:path1',
      { id: '@id', path: '@path', id1: '@id1', path1: '@path1' },
      {
        getCurrentUser: {
          method: 'GET',
          params: {
            path: 'access',
          },
        },
        getUser: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('user')) {
              return data.user
            }
            return data
          },
        },
        editUser: {
          method: 'PUT',
          params: {
            path: 'edit',
          },
        },
        register: {
          method: 'POST',
          params: {
            path: 'register',
          },
        },
        verifyAccount: {
          method: 'POST',
          params: {
            path: 'verify',
          },
        },
        sendPassword: {
          method: 'POST',
          params: {
            path: 'password',
          },
        },
        resetPassword: {
          method: 'PUT',
          params: {
            path: 'password',
          },
        },
        getAllDistricts: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'districts',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('districts')) {
              return data.districts
            }
            return data
          },
        },
      },
    )
  }
})()
