;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  /**
   * @ngdoc service
   * @name services.services:FlashMessage
   *
   * @description
   * Handles flash messages.
   */
  angular.module('app.common.services').factory('FlashMessage', FlashMessageService)

  FlashMessageService.$inject = ['$rootScope', '$filter', '$sce']

  function FlashMessageService($rootScope, $filter, $sce) {
    var flashMessages = []

    /**
     * @ngdoc method
     * @name services.services:FlashMessage#$routeChangeSuccess
     * @methodOf services.services:FlashMessage
     *
     * @description Removes flash messages after success route change.
     */
    $rootScope.$on('$stateChangeSuccess', function () {
      if (flashMessages.length > 0) {
        for (var i = 0; i < flashMessages.length; i++) {
          if (flashMessages[i].ttl === 0) {
            flashMessages.splice(i, 1)
          } else {
            flashMessages[i].ttl--
          }
        }
      }
    })

    return {
      /**
       * @ngdoc method
       * @name services.services:FlashMessage#all
       * @methodOf services.services:FlashMessage
       *
       * @description Returns all flash messages.
       *
       * @returns {Array} flash messages
       */
      all: function () {
        return flashMessages
      },

      /**
       * @ngdoc method
       * @name services.services:FlashMessage#add
       * @methodOf services.services:FlashMessage
       *
       * @param {Object} translationObj Flash message translationObj.
       * @param {String} [type=error] Flash message type.
       * @param {String} [ttl=0] Number of route changes to live.
       * @param {String} [attribute=null] Attribute class name where to show the flash message.
       *
       * @description Adds new flash message.
       */
      add: function (translationObj, type, ttl, attribute) {
        var found = false
        var code = ''

        if (translationObj !== null && angular.isObject(translationObj)) {
          // Object
          code = $filter('translate')(
            translationObj.id || 'Undefined translation ID.',
            translationObj.values,
          )
        } else {
          // String
          code = $filter('translate')(translationObj)
        }

        switch (type) {
          case 'danger':
          case 'warning':
          case 'success':
          case 'info':
            break
          default:
            type = 'danger'
        }

        for (var i = 0; i < flashMessages.length; i++) {
          if (flashMessages[i].code === code) {
            found = true
          } else if (flashMessages[i].ttl === 0) {
            this.remove(i)
          }
        }

        if (!found) {
          flashMessages.push({
            type: type || 'danger',
            message: $sce.trustAsHtml(code),
            code: code,
            ttl: ttl || 0,
            attribute: attribute,
          })
          $rootScope.$broadcast('flashMessageUpdated')
        }
      },

      /**
       * @ngdoc method
       * @name services.services:FlashMessage#remove
       * @methodOf services.services:FlashMessage
       *
       * @param {Number} index Array index.
       *
       * @description Removes the flash message at the index.
       */
      remove: function (index) {
        flashMessages.splice(index, 1)
      },

      /**
       * @ngdoc method
       * @name services.services:FlashMessage#removeAll
       * @methodOf services.services:FlashMessage
       *
       * @description Removes all flash messages.
       */
      removeAll: function () {
        flashMessages.length = 0
      },

      /**
       * @ngdoc method
       * @name services.services:FlashMessage#size
       * @methodOf services.services:FlashMessage
       *
       * @description Returns number of flash messages
       *
       * @returns {Number} number of flash messages
       */
      size: function () {
        return flashMessages.length
      },
    }
  }
})()
