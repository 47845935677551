;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('LayerProviderFactory', LayerProviderFactory)

  LayerProviderFactory.$inject = [
    '$rootScope',
    'MapDialogService',
    'AirQualityLayerProvider',
    'BollardLayerProvider',
    'RestaurantLayerProvider',
    'PassabilityLayerProvider',
    'StaticBollardsLayerProvider',
    'TelescopicBollardsLayerProvider',
    'SyposLayerProvider',
    'CameraLayerProvider',
    'ChemistryLayerProvider',
    'DdrMessageLayerProvider',
    'DynamicDataSourceLayerProvider',
    'EventLayerProvider',
    'InitiativeLayerProvider',
    'ItemCollectionLayerProviderBase',
    'RiverStationLayerProvider',
  ]

  function LayerProviderFactory(
    $rootScope,
    MapDialogService,
    AirQualityLayerProvider,
    BollardLayerProvider,
    RestaurantLayerProvider,
    PassabilityLayerProvider,
    StaticBollardsLayerProvider,
    TelescopicBollardsLayerProvider,
    SyposLayerProvider,
    CameraLayerProvider,
    ChemistryLayerProvider,
    DdrMessageLayerProvider,
    DynamicDataSourceLayerProvider,
    EventLayerProvider,
    InitiativeLayerProvider,
    ItemCollectionLayerProviderBase,
    RiverStationLayerProvider,
  ) {
    function provide(map, providerName) {
      const providers = {
        AirQualityLayerProvider: AirQualityLayerProvider,
        BollardLayerProvider: BollardLayerProvider,
        RestaurantLayerProvider: RestaurantLayerProvider,
        PassabilityLayerProvider: PassabilityLayerProvider,
        StaticBollardsLayerProvider: StaticBollardsLayerProvider,
        TelescopicBollardsLayerProvider: TelescopicBollardsLayerProvider,
        SyposLayerProvider: SyposLayerProvider,
        CameraLayerProvider: CameraLayerProvider,
        ChemistryLayerProvider: ChemistryLayerProvider,
        DdrMessageLayerProvider: DdrMessageLayerProvider,
        DynamicDataSourceLayerProvider: DynamicDataSourceLayerProvider,
        EventLayerProvider: EventLayerProvider,
        InitiativeLayerProvider: InitiativeLayerProvider,
        ItemCollectionLayerProviderBase: ItemCollectionLayerProviderBase,
        RiverStationLayerProvider: RiverStationLayerProvider,
      }
      const providerFactories = {
        _createBollardLayerProvider: _createBollardLayerProvider,
        _createChemistryLayerProvider: _createChemistryLayerProvider,
        _createSyposLayerProvider: _createSyposLayerProvider,
        _createRestaurantLayerProvider: _createRestaurantLayerProvider,
        _createPassabilityLayerProvider: _createPassabilityLayerProvider,
        _createStaticBollardsLayerProvider: _createStaticBollardsLayerProvider,
        _createTelescopicBollardsLayerProvider: _createTelescopicBollardsLayerProvider,
      }
      if (providerName in providers) {
        const provider = providers[providerName]
        const providerCreateFunction = '_create' + providerName
        if (providerCreateFunction in providerFactories) {
          return providerFactories[providerCreateFunction](map)
        } else {
          return new provider(map)
        }
      } else {
        throw new Error(providerName + ' is not defined in LayerProviderService')
      }
    }

    function _createChemistryLayerProvider(map) {
      var chemistryLayerProvider = new ChemistryLayerProvider(map)
      chemistryLayerProvider.setItemClickedFunc(function (hazardousArea) {
        MapDialogService.openDialogHazardousAreas(
          hazardousArea,
          map,
          hazardousArea.coordinates,
          $rootScope.$new(),
        )
      })
      chemistryLayerProvider.update()
      return chemistryLayerProvider
    }

    function _createBollardLayerProvider(map) {
      var bollardLayerProvider = new BollardLayerProvider(map)
      bollardLayerProvider.setItemClickedFunc(function (bollard) {
        MapDialogService.openDialogBollard(bollard, map, bollard.coordinates, $rootScope.$new())
      })
      bollardLayerProvider.update()
      return bollardLayerProvider
    }

    function _createRestaurantLayerProvider(map) {
      var restaurantLayerProvider = new RestaurantLayerProvider(map)
      restaurantLayerProvider.setItemClickedFunc(function (restaurant) {
        MapDialogService.openRestaurant(restaurant, map, restaurant.coordinates, $rootScope.$new())
      })
      restaurantLayerProvider.update()
      return restaurantLayerProvider
    }

    function _createPassabilityLayerProvider(map) {
      var passabilityLayerProvider = new PassabilityLayerProvider(map)
      passabilityLayerProvider.setItemClickedFunc(function (restaurant) {
        MapDialogService.openPassability(restaurant, map, restaurant.coordinates, $rootScope.$new())
      })
      passabilityLayerProvider.update()
      return passabilityLayerProvider
    }

    function _createStaticBollardsLayerProvider(map) {
      var staticBollardsLayerProvider = new StaticBollardsLayerProvider(map)
      staticBollardsLayerProvider.setItemClickedFunc(function (bollard) {
        MapDialogService.openDialogBollard(bollard, map, bollard.coordinates, $rootScope.$new())
      })
      staticBollardsLayerProvider.update()
      return staticBollardsLayerProvider
    }

    function _createTelescopicBollardsLayerProvider(map) {
      var telescopicBollardsLayerProvider = new TelescopicBollardsLayerProvider(map)
      telescopicBollardsLayerProvider.setItemClickedFunc(function (bollard) {
        MapDialogService.openDialogBollard(bollard, map, bollard.coordinates, $rootScope.$new())
      })
      telescopicBollardsLayerProvider.update()
      return telescopicBollardsLayerProvider
    }

    function _createSyposLayerProvider(map) {
      var syposLayerProvider = new SyposLayerProvider(map)
      syposLayerProvider.setItemClickedFunc(function (institution) {
        MapDialogService.openDialogSypos(
          institution,
          map,
          institution.coordinates,
          $rootScope.$new(),
        )
      })
      syposLayerProvider.update()
      return syposLayerProvider
    }

    return {
      provide: provide,
    }
  }
})()
