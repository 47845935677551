;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapHelperService', MapHelperService)

  MapHelperService.$inject = [
    '$translate',
    '$rootScope',
    '$state',
    'MapSettings',
    'LayerCollection',
    'ServerLayer',
    'LineLayer',
    'MapSearchService',
    'HelperService',
    'leafletData',
    'PersistenceModel',
    'APP_CONFIG',
  ]

  function MapHelperService(
    $translate,
    $rootScope,
    $state,
    MapSettings,
    LayerCollection,
    ServerLayer,
    LineLayer,
    MapSearchService,
    HelperService,
    leafletData,
    PersistenceModel,
    APP_CONFIG,
  ) {
    var controllerData = null

    function _makeLeafletOverrides() {
      L.esri.Util.responseToFeatureCollection = function (response, idAttribute) {
        var objectIdField

        if (idAttribute) {
          objectIdField = idAttribute
        } else if (response.objectIdFieldName) {
          objectIdField = response.objectIdFieldName
        } else if (response.fields) {
          var alternativeIdField = null
          for (var j = 0; j <= response.fields.length - 1; j++) {
            if (response.fields[j].type === 'esriFieldTypeOID') {
              objectIdField = response.fields[j].name
            }
            if (response.fields[j].alias === 'GLOBALID') {
              alternativeIdField = response.fields[j].name
            }
          }
          objectIdField = objectIdField || alternativeIdField
        } else {
          objectIdField = 'OBJECTID'
        }

        var featureCollection = {
          type: 'FeatureCollection',
          features: [],
        }
        var features = response.features || response.results
        if (features && features.length) {
          for (var i = features.length - 1; i >= 0; i--) {
            featureCollection.features.push(L.esri.Util.arcgisToGeoJSON(features[i], objectIdField))
          }
        }
        return featureCollection
      }
    }

    function _setProjection(map) {
      var resolutions = [
        198.43789687579377, 132.2919312505292, 92.60435187537043, 52.91677250021167,
        39.687579375158755, 26.458386250105836, 19.843789687579378, 13.229193125052918,
        6.614596562526459, 5.291677250021167, 3.9687579375158752, 2.6458386250105836,
        1.9843789687579376, 1.3229193125052918, 0.9260435187537043, 0.5291677250021167,
        0.39687579375158755, 0.26458386250105836, 0.19843789687579377, 0.13229193125052918,
      ]
      map.options.crs = new L.Proj.CRS(
        'EPSG:5514',
        '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs',
        {
          origin: [-33699800, 33699800],
          resolutions: resolutions,
        },
      )
      map.zoomControl.setPosition('topright')
    }

    function _setDefaultPosition(map) {
      var settings = new MapSettings()
      var lat = settings.getDefaultLatitude()
      var lng = settings.getDefaultLongitude()
      map.setView(new L.LatLng(lat, lng), settings.getDefaultZoom())
    }

    function _createLayerCollection(controllerData) {
      var layerCollection = new LayerCollection(controllerData)
      var layers = _createServerLayers(layerCollection)
      _createLayerSwitchers(layers, controllerData.map)
      return layerCollection
    }

    function _createDefaultLayers(layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.defaultLayers.lineLayers,
        function (lineLayerConfiguration) {
          var lineLayer = new LineLayer(
            angular.extend(lineLayerConfiguration, {
              fillOpacity: 0,
              weight: 3,
              opacity: 0.8,
              simplifyFactor: 0,
            }),
          )
          addLayer(lineLayer, layerCollection)
        },
      )
    }

    function addLayer(layer, layerCollection) {
      layerCollection.addLayer(layer)

      controllerData.mapLayers = layerCollection.getLayers()
      controllerData.legend = generateLegend(controllerData.mapLayers)

      controllerData.map.on('popupopen', (e) => {
        controllerData.map.setView(e.target._popup._latlng, e.target._zoom)
      })
    }

    function removeLayer(layer, layerCollection) {
      layerCollection.removeLayer(layer)
      controllerData.mapLayers = layerCollection.getLayers()
      controllerData.legend = generateLegend(controllerData.mapLayers)
    }

    function _createServerLayers(layerCollection) {
      var serverLayers = []

      angular.forEach(APP_CONFIG.mapSettings.serverLayers, function (serverLayerData) {
        var serverLayer = new ServerLayer(
          angular.extend(
            {
              tileSize: 512,
              isBase: true,
            },
            serverLayerData.options,
          ),
        )

        layerCollection.addLayer(serverLayer)

        var layer = {}
        layer[serverLayerData.switcher.key] = {
          layer: serverLayer.getLayer(),
          switcher: serverLayerData.switcher,
        }
        serverLayers.push(layer)
      })
      return serverLayers
    }

    function _createLayerSwitchers(layers, map) {
      layers = _.transform(layers, _.ary(_.extend, 2), {})
      angular.forEach(layers, function (layer, key) {
        var dependencies = []
        angular.forEach(layer.switcher.dependencies, function (dependency) {
          dependencies.push(layers[dependency].layer)
        })

        var ctrl = new L.Intens.BaseLayerSwitcher(
          [layer.layer],
          dependencies,
          key,
          $translate.instant(layer.switcher.title),
        )
        ctrl.addTo(map)
        if (layer.activate) {
          ctrl.activate()
        }
      })
    }

    function _translator(relativeId) {
      return $translate.instant('_COMMON.SERVICE.MAPS.SERVICES.MAP_HELPER_SERVICE.' + relativeId)
    }

    function prepareLealetDrawTranslations() {
      L.drawLocal.draw.toolbar.actions.text = _translator('draw.toolbar.actions.text')
      L.drawLocal.draw.toolbar.actions.title = _translator('draw.toolbar.actions.title')

      L.drawLocal.draw.toolbar.finish.text = _translator('draw.toolbar.finish.text')
      L.drawLocal.draw.toolbar.finish.title = _translator('draw.toolbar.finish.title')

      L.drawLocal.draw.toolbar.undo.text = _translator('draw.toolbar.undo.text')
      L.drawLocal.draw.toolbar.undo.title = _translator('draw.toolbar.undo.title')

      L.drawLocal.draw.toolbar.buttons.polyline = _translator('draw.toolbar.buttons.polyline')
      L.drawLocal.draw.toolbar.buttons.polygon = _translator('draw.toolbar.buttons.polygon')
      L.drawLocal.draw.toolbar.buttons.rectangle = _translator('draw.toolbar.buttons.rectangle')
      L.drawLocal.draw.toolbar.buttons.circle = _translator('draw.toolbar.buttons.circle')
      L.drawLocal.draw.toolbar.buttons.marker = _translator('draw.toolbar.buttons.marker')
      L.drawLocal.draw.toolbar.buttons.circlemarker = _translator(
        'draw.toolbar.buttons.circlemarker',
      )

      L.drawLocal.draw.handlers.circle.tooltip.start = _translator(
        'draw.handlers.circle.tooltip.start',
      )
      L.drawLocal.draw.handlers.circlemarker.tooltip.start = _translator(
        'draw.handlers.circlemarker.tooltip.start',
      )
      L.drawLocal.draw.handlers.marker.tooltip.start = _translator(
        'draw.handlers.marker.tooltip.start',
      )
      L.drawLocal.draw.handlers.polygon.tooltip.start = _translator(
        'draw.handlers.polygon.tooltip.start',
      )
      L.drawLocal.draw.handlers.polygon.tooltip.cont = _translator(
        'draw.handlers.polygon.tooltip.cont',
      )
      L.drawLocal.draw.handlers.polygon.tooltip.end = _translator(
        'draw.handlers.polygon.tooltip.end',
      )
      L.drawLocal.draw.handlers.polyline.tooltip.start = _translator(
        'draw.handlers.polyline.tooltip.start',
      )
      L.drawLocal.draw.handlers.polyline.tooltip.cont = _translator(
        'draw.handlers.polyline.tooltip.cont',
      )
      L.drawLocal.draw.handlers.polyline.tooltip.end = _translator(
        'draw.handlers.polyline.tooltip.end',
      )
      L.drawLocal.draw.handlers.rectangle.tooltip.start = _translator(
        'draw.handlers.rectangle.tooltip.start',
      )
      L.drawLocal.draw.handlers.simpleshape.tooltip.end = _translator(
        'draw.handlers.simpleshape.tooltip.end',
      )

      L.drawLocal.edit.toolbar.actions.cancel.text = _translator('edit.toolbar.actions.cancel.text')
      L.drawLocal.edit.toolbar.actions.cancel.title = _translator(
        'edit.toolbar.actions.cancel.title',
      )

      L.drawLocal.edit.toolbar.actions.clearAll.text = _translator(
        'edit.toolbar.actions.clearAll.text',
      )
      L.drawLocal.edit.toolbar.actions.clearAll.title = _translator(
        'edit.toolbar.actions.clearAll.title',
      )

      L.drawLocal.edit.toolbar.actions.save.text = _translator('edit.toolbar.actions.save.text')
      L.drawLocal.edit.toolbar.actions.save.title = _translator('edit.toolbar.actions.save.title')

      L.drawLocal.edit.toolbar.buttons.edit = _translator('edit.toolbar.buttons.edit')
      L.drawLocal.edit.toolbar.buttons.editDisabled = _translator(
        'edit.toolbar.buttons.editDisabled',
      )
      L.drawLocal.edit.toolbar.buttons.remove = _translator('edit.toolbar.buttons.remove')
      L.drawLocal.edit.toolbar.buttons.removeDisabled = _translator(
        'edit.toolbar.buttons.removeDisabled',
      )

      L.drawLocal.edit.handlers.edit.tooltip.text = _translator('edit.handlers.edit.tooltip.text')
      L.drawLocal.edit.handlers.edit.tooltip.subtext = _translator(
        'edit.handlers.edit.tooltip.subtext',
      )

      L.drawLocal.edit.handlers.remove.tooltip.text = _translator(
        'edit.handlers.remove.tooltip.text',
      )
    }

    function _createSearchControl(map) {
      L.control
        .search({
          sourceData: _findStreetsAddresses,
          position: 'topright',
          buildTip: _getSearchTip,
          autoType: false,
          initial: false,
          textCancel: _translator('search.cancel'),
          textErr: _translator('search.error'),
          textPlaceholder: _translator('search.placeholder'),
          autoCollapse: false,
          autoResize: true,
          casesensitive: false,
          minLength: 2,
          marker: false,
          propertyLoc: 'geometry',
          propertyName: 'text',
          formatData: function (data) {
            var result = {}
            var key = null

            for (var item in data) {
              if (data.hasOwnProperty(item)) {
                item = data[item]
                if (item.isStreetModel) {
                  key = item.mkn
                } else {
                  key = item.adresa
                }
                result[key] = item
              }
            }
            return result
          },
          moveToLocation: function (item, title, map) {
            moveToLocation(item, title, map)
          },
        })
        .addTo(map)
    }

    function moveToLocation(item, title, map, layer, autoSearch) {
      var geometry = null
      item.autoSearch = autoSearch

      var streetAddressSearchMarker = this ? this.streetAddressSearchMarker : layer
      if (streetAddressSearchMarker) {
        map.removeLayer(streetAddressSearchMarker)
      }

      if (!item.isStreetModel) {
        geometry = L.latLng(item.geometry.coordinates[1], item.geometry.coordinates[0])

        streetAddressSearchMarker = new L.Marker(geometry, {
          draggable: false,
          icon: new L.Icon({
            iconUrl: 'styles/img/location-pin.png',
            shadowUrl: 'styles/img/marker-shadow.png',
            iconAnchor: new L.Point(16, 32),
            shadowAnchor: new L.Point(14, 40),
          }),
        })

        map.addLayer(streetAddressSearchMarker)
        map.panTo(geometry)

        var group = new L.FeatureGroup([streetAddressSearchMarker])
        map.fitBounds(group.getBounds())
        $rootScope.$broadcast('mapAddressSearched', item, group)
      } else {
        var boundList = []
        var polylineList = []
        geometry = item.geometries

        for (var line in geometry) {
          if (geometry.hasOwnProperty(line)) {
            var coordinates = _.map(geometry[line].coordinates, function (coord) {
              return new L.LatLng(coord[1], coord[0])
            })
            polylineList.push(coordinates)
            boundList = boundList.concat(coordinates)
          }
        }

        streetAddressSearchMarker = L.polyline(polylineList, {
          color: 'red',
          weight: 2,
          opacity: 0.5,
          smoothFactor: 1,
        })
        map.addLayer(streetAddressSearchMarker)
        map.fitBounds(new L.LatLngBounds(boundList))
      }
    }

    function _findStreetsAddresses(text, callResponse) {
      return MapSearchService.findStreetsAddresses(text).then(function (results) {
        var data = []
        data = data.concat(results[1])
        data = data.concat(results[0])
        callResponse(data)
      })
    }

    function _getSearchTip(text) {
      return '<a href="#">' + text + '</a>'
    }

    function initViewModelMapData(viewModel, key) {
      // hack - when bounds are set and specified out of range, only basic OpenStreet Map tile layer is loaded - solving 404 not found error
      var southWest = L.latLng(13.732623, 49.505265),
        northEast = L.latLng(15.053312, 50.622762),
        bounds = L.latLngBounds(southWest, northEast)

      angular.extend(viewModel, {
        key: key,
        activeCameraGrid: 0,
        bounds: null,
        cameraGrids: null,
        cameras: null,
        gridOptions: null,
        isAddCameraGridInProgress: false,
        newCameraGridName: null,
        isCameraTabsetCollapsed: false,
        layers: {},
        events: {
          map: {
            enable: ['layeradd', 'layerremove', 'dragend', 'zoomend'],
            logic: 'emit',
          },
        },
        controls: {
          fullscreen: {
            position: 'topright',
          },
        },
        defaults: {
          scrollWheelZoom: true,
          tileLayerOptions: {
            bounds: bounds,
            //continuousWorld: true,
            attribution:
              'Datový podklad © IPR Praha dle licence <a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>',
          },
          minZoom: 3,
          maxZoom: 18,
        },
      })
    }

    function initMap(map, data) {
      controllerData = data
      controllerData.map = map

      _setProjection(map)
      _setDefaultPosition(map)

      var layerCollection = _createLayerCollection(controllerData)
      _createDefaultLayers(layerCollection)
      _createSearchControl(map)

      getTownDistrictLayer(layerCollection)
      registerMapLayerEvents(map, data)
      return layerCollection
    }

    function extendLeafletPopupDefaultOptions(options) {
      return angular.extend(options, {
        autoPanPadding: new L.Point(50, 20),
        keepInView: false,
      })
    }

    function initLayerCollection(map) {
      _setDefaultPosition(map)
      return new LayerCollection(map)
    }

    function createIcon(path) {
      if (this.icons[path] == null) {
        var icon = new L.Icon({
          iconUrl: path,
          iconSize: new L.Point(24, 24),
          iconAnchor: new L.Point(12, 12),
        })
        this.icons[path] = icon
        return icon
      } else {
        return this.icons[path]
      }
    }

    function generateLegend(mapLayers, isMulti) {
      var legend = []
      _.forEach(mapLayers, function (layer) {
        var options = layer.getOptions()
        if (!options.isBase && layer.getKey() !== 'towndistrict') {
          legend.push({
            key: layer.getKey(),
            group: layer.getOptions().group,
            layerNumber: mapLayers.indexOf(layer),
            layer: layer,
            visible: isMulti
              ? false
              : !HelperService.isNullOrUndefined(options.visible)
              ? options.visible
              : false,
            legendOptions: options.legendOptions,
            switchLayer: _switchLayer,
          })
        }
      })
      return legend
    }

    function getCreateIconFunction() {
      return function (cluster) {
        var count = cluster.getChildCount()
        var digits = (count + '').length
        return L.divIcon({
          html: count,
          className: 'cluster digits-' + digits,
          iconSize: null,
        })
      }
    }

    function getTownDistrictLayer(layerCollection) {
      var lineLayer = new LineLayer({
        url: APP_CONFIG.mapSettings.lineLayer.url,
        key: APP_CONFIG.mapSettings.lineLayer.key,
        fillOpacity: 0,
        color: '#000000',
        weight: '2px',
        visible: true,
      })
      addLayer(lineLayer, layerCollection)
    }

    function createClusteredFeatureLayer(url, image, onClickEvent) {
      return new L.esri.ClusteredFeatureLayer({
        url: url,
        pointToLayer: function (geojson, latlng) {
          var selectedIcon = this.createIcon('styles/img/' + image)
          var markerOptions = { icon: selectedIcon }
          var marker = new L.Marker(latlng, markerOptions)
          marker.on('click', onClickEvent)
          return marker
        },
        disableClusteringAtZoom: 11,
        iconCreateFunction: this.getCreateIconFunction(),
      })
    }

    function initMapWithoutButtons(map) {
      _setProjection(map)
      _setDefaultPosition(map)
      return new LayerCollection(map)
    }

    function _switchLayer(legend) {
      leafletData.getMap('map').then(function (map) {
        var layer = legend.layer.getLayer()
        if (legend && legend.visible === false) {
          map.addLayer(layer)
        } else {
          map.removeLayer(layer)
        }
        $rootScope.$emit('mapLayerSwitched', { legend: legend })
      })
    }

    function switchMap(mapKeys, data) {
      angular.forEach(APP_CONFIG.mapSettings, function (mapObj, mapKey) {
        var activeMap = _.includes(mapKeys, mapKey)

        angular.forEach(mapObj, function (entity) {
          if (_.isArray(entity)) {
            angular.forEach(entity, function (obj) {
              if (obj.hasOwnProperty('key')) {
                _getLayerFromMapLayersByKey(obj.key, data, activeMap)
              }
            })
          }
          if (_.isObject(entity) && entity.hasOwnProperty('key')) {
            _getLayerFromMapLayersByKey(entity.key, data, activeMap)
          }
        })
      })
    }

    function _getLayerFromMapLayersByKey(key, data, activeMap) {
      angular.forEach(data.mapLayers, function (layer, layerNumber) {
        if (layer.hasOwnProperty('options') && layer.options.hasOwnProperty('key')) {
          if (layer.options.key === key) {
            _addOrRemoveLayer(layerNumber, data, activeMap)
          }
        }
      })
    }

    function _addOrRemoveLayer(layerNumber, data, activeMap) {
      var legend = _getLegend(data, layerNumber)
      leafletData.getMap('map').then(function (map) {
        var mapLayer = data.mapLayers[layerNumber]
        var layer = mapLayer.getLayer()
        if (activeMap) {
          if (legend) {
            legend.display = true
          }
          mapLayer.setLegendVisible(true)
          // if (legend && legend.visible === false) {
          map.addLayer(layer)
          // }
        } else {
          if (legend) {
            legend.display = false
          }
          if (legend && !legend.disable) {
            mapLayer.setLegendVisible(false)
            map.removeLayer(layer)
          }
        }
      })
    }

    function _getLegend(data, layerNumber) {
      if (data.hasOwnProperty('legend')) {
        return data.legend[data.legend.indexOf(_.find(data.legend, ['layerNumber', layerNumber]))]
      }
    }

    function _getLayerNumberAndSwitch(data, layer, checked) {
      var tempCollection = _.map(data.mapLayers, function (laylay) {
        return laylay.getLayer()
      })
      var layerNumber = tempCollection.indexOf(layer)
      var legend = _getLegend(data, layerNumber)
      if (legend) {
        if ($state.$current.name === 'front.map.all') {
          PersistenceModel.setMapLayerVisibility(legend.key, checked)
        }
        legend.visible = checked
      }
    }

    function registerMapLayerEvents(map, data) {
      map.on('overlayadd', function (overlay) {
        _getLayerNumberAndSwitch(data, overlay.layer, true)
      })
      map.on('overlayremove', function (overlay) {
        _getLayerNumberAndSwitch(data, overlay.layer, false)
      })

      map.on('layeradd', function (overlay) {
        _getLayerNumberAndSwitch(data, overlay.layer, true)
      })
      map.on('layerremove', function (overlay) {
        _getLayerNumberAndSwitch(data, overlay.layer, false)
      })
    }

    _makeLeafletOverrides()

    return {
      icons: {},
      initViewModelMapData: initViewModelMapData,
      initMap: initMap,
      extendLeafletPopupDefaultOptions: extendLeafletPopupDefaultOptions,
      initLayerCollection: initLayerCollection,
      createIcon: createIcon,
      generateLegend: generateLegend,
      getCreateIconFunction: getCreateIconFunction,
      getTownDistrictLayer: getTownDistrictLayer,
      createClusteredFeatureLayer: createClusteredFeatureLayer,
      prepareLealetDrawTranslations: prepareLealetDrawTranslations,
      initMapWithoutButtons: initMapWithoutButtons,
      switchMap: switchMap,
      registerMapLayerEvents: registerMapLayerEvents,
      addLayer: addLayer,
      removeLayer: removeLayer,
      moveToLocation: moveToLocation,
    }
  }
})()
