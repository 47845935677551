;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('LineLayer', LineLayer)

  LineLayer.$inject = ['Layer']

  function LineLayer(Layer) {
    function _createEsriLayer(options) {
      var innerOptions = {
        url: options.url,
        simplifyFactor: options.simplifyFactor,
        clickable: options.clickContext && options.clickFn,
        style: function (feature) {
          var styleOptions = {
            color: options.color,
            fillColor: options.fillColor,
            opacity: options.opacity,
            fillOpacity: options.fillOpacity,
            weight: options.weight,
            stroke: options.stroke,
          }
          if (options.propertyStyling) {
            var featureValue = feature.properties[options.propertyStyling.featureProperty]
            var propertyValue = options.propertyStyling.valueMap[featureValue]
            var styleProperties = options.propertyStyling.styleProperties
            for (var i = 0; i < styleProperties.length; i++) {
              var styleProperty = styleProperties[i]
              styleOptions[styleProperty] = propertyValue
            }
          }

          for (var key in styleOptions) {
            if (styleOptions[key] == null) {
              delete styleOptions[key]
            }
          }
          return styleOptions
        },
      }
      if (options.where) {
        innerOptions.where = options.where
      }
      return new L.esri.FeatureLayer(innerOptions)
    }

    return function (options) {
      var child = Object.create(Layer)
      child.super(options)

      child.init = function () {
        this.options = options

        var esriLayer = _createEsriLayer(options)
        this.layer = esriLayer

        var me = this
        if (options.clickFn && options.clickContext) {
          esriLayer.on('click', function (event) {
            me.options.clickFn.apply(options.clickContext, [
              event.layer.feature.properties,
              me.options.map,
              L.latLng(event.latlng),
              me.options.popupScope,
            ])
          })
        }
        return child
      }

      return child.init()
    }
  }
})()
