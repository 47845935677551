/* eslint-disable angular/window-service */
'use strict'

var appConfig = window.appConfig || {}

appConfig.apiRootUrl = '##->CRISIS_PORTAL_PRAGUE_ONE_API_ROOT_URL<-##'
appConfig.pdfApiRootUrl = '##->CRISIS_PORTAL_PRAGUE_ONE_PDF_API_ROOT_URL<-##'
appConfig.version = '##->CRISIS_PORTAL_PRAGUE_ONE_VERSION<-##'
appConfig.facebookAppId = '190469767638040'
appConfig.autoLogoutTime = 1000 * 60 * 60 * 24
appConfig.galleryGridColumns = 6
appConfig.defaultCameraRefreshRate = 30000
appConfig.cdnUrl = appConfig.apiRootUrl + 'cdn/files/'
appConfig.uploadLimits = {
  images: {
    maxWidth: 2100,
    maxHeight: 2100,
    maxSize: '2.5MB',
  },
  documents: {
    maxSize: '50MB',
  },
}
appConfig.translation = {
  supportedLanguages: ['cs-CZ', 'en-US'],
  availableLanguageKeys: {
    'cs_*': 'cs-CZ',
    en_US: 'en-US',
    en_UK: 'en-US',
    '*': 'cs-CZ', // if other languages werent matched
  },
  fallbackLanguage: 'cs-CZ', // if active language doesnt have a translation
  preferredLanguage: 'cs-CZ',
}
appConfig.mapSettings = {
  defaultMapCenter: {
    lat: 50.087429,
    lng: 14.421164,
    zoom: 9,
  },
  defaultLayers: {
    lineLayers: [
      {
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/2',
        key: 'districts',
        color: '#000000',
        fillColor: '#000000',
        stroke: '#000000',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.DISTRICTS',
          colors: ['#000000'],
          visible: true,
          disable: true,
          display: true,
          order: 999,
        },
      },
    ],
  },
  serverLayers: [
    {
      switcher: {
        key: 'roads',
        title: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.ORIENTATION',
        dependencies: ['nonveg', 'veg', 'relief'],
        activate: true,
      },
      options: {
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/dtmp/mtvu/MapServer',
        key: 'orientationmap',
        legendOptions: {
          label: 'Orientační mapa',
          visible: true,
        },
        visible: true,
      },
    },
    {
      switcher: {
        key: 'relief',
        title: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.RELIEF',
        dependencies: ['roads', 'nonveg', 'veg'],
      },
      options: {
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/dtmp/mtvu_3d/MapServer',
        key: 'reliefmap',
        enableTiling: true,
        legendOptions: {
          label: 'Orientační reliéf',
          visible: true,
        },
      },
    },
    {
      switcher: {
        key: 'veg',
        title: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR',
        dependencies: ['roads', 'nonveg', 'relief'],
      },
      options: {
        url: 'https://gs-pub.praha.eu/imgs/rest/services/ort/letecke_snimkovani/ImageServer',
        key: 'airmap',
        enableTiling: true,
        legendOptions: {
          label: 'Ortofoto mapa',
          visible: true,
        },
      },
    },
    {
      switcher: {
        key: 'nonveg',
        title: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.NONVEG',
        dependencies: ['roads', 'veg', 'relief'],
      },
      options: {
        url: 'https://gs-pub.praha.eu/imgs/rest/services/ort/mimovegetacni_letecke_snimkovani/ImageServer',
        key: 'airnonvegetationmap',
        enableTiling: true,
        legendOptions: {
          label: 'Ortofoto nevegetační',
          visible: true,
        },
      },
    },
  ],
  newsLayers: {
    name: 'Aktuální dění',
    // trafficLayer: {
    //   key: 'newstraffic',
    //   group: 'traffic',
    //   url: '',
    //   visible: true,
    //   legendOptions: {
    //     label: 'Mapa dopravy',
    //     subtitleMapWithImages: true,
    //     subtitleMap: {
    //       Informace: 'dopravni_znacka_genericInfo.png',
    //       Námraza: 'dopravni_znacka_namraza.png',
    //       Nebezpečí: 'dopravni_znacka_nebezpeci.png',
    //       'Dopravní nehoda': 'dopravni_znacka_nehoda.png',
    //       'Práce na silnici': 'dopravni_znacka_prace.png',
    //       Vítr: 'dopravni_znacka_vitr.png',
    //       Uzavírka: 'dopravni_znacka_uzavirka.png',
    //     },
    //     visible: true,
    //     display: true,
    //     order: 1,
    //   },
    // },
    // eventLayers: [
    //   {
    //     url: '',
    //     key: 'publicnews',
    //     visible: true,
    //     legendOptions: {
    //       label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PUBLIC_NEWS',
    //       images: ['udalost.png'],
    //       visible: true,
    //       display: true,
    //       order: 2,
    //     },
    //   },
    //   {
    //     url: '',
    //     key: 'privatenews',
    //     visible: true,
    //     legendOptions: {
    //       label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PRIVATE_NEWS',
    //       images: ['privatni_udalost.png'],
    //       visible: true,
    //       display: true,
    //       order: 3,
    //     },
    //   },
    // ],
    // riverStationLayer: {
    //   url: '',
    //   visible: true,
    //   key: 'riverStations',
    //   legendOptions: {
    //     label: 'Vodočty',
    //     subtitleMapWithImages: true,
    //     subtitleMap: {
    //       'Normální stav': 'success.png',
    //       '1. stupeň povodňové aktivity': 'notice.png',
    //       '2. stupeň povodňové aktivity': 'warning.png',
    //       '3. stupeň povodňové aktivity': 'danger.png',
    //       'Údaje nejsou k dispozici': 'dry.png',
    //     },
    //     visible: true,
    //     display: true,
    //     order: 4,
    //   },
    // },
    airQualityLayer: {
      visible: true,
      url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer',
      key: 'airQuality',
      legendOptions: {
        label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR_QUALITY.TITLE',
        subtitleMapWithImages: true,
        subtitleMap: {
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR_QUALITY.VERY_GOOD': 'air-green.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR_QUALITY.ACCEPTABLE': 'air-yellow.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR_QUALITY.BAD': 'air-red.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.AIR_QUALITY.NO_DATA': 'air-black.png',
        },
        visible: true,
        display: true,
        order: 5,
      },
    },
  },
  trafficLayers: {
    name: 'Doprava',
    trafficLayer: {
      key: 'traffic',
      group: 'traffic',
      url: '',
      visible: true,
      legendOptions: {
        label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.TITLE',
        subtitleMapWithImages: true,
        subtitleMap: {
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.INFORMATION':
            'dopravni_znacka_genericInfo.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.FROST': 'dopravni_znacka_namraza.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.DANGER': 'dopravni_znacka_nebezpeci.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.ACCIDENT': 'dopravni_znacka_nehoda.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.ROADWORK': 'dopravni_znacka_prace.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.WIND': 'dopravni_znacka_vitr.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TRAFFIC.SHUTTER': 'dopravni_znacka_uzavirka.png',
        },
        visible: true,
        display: true,
        order: 6,
      },
    },
    customLayers: [
      {
        url: '',
        key: 'static-bollard',
        layerProvider: 'StaticBollardsLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.STATIC_BOLLARD',
          images: ['cone.png'],
          visible: true,
          display: true,
          order: 12,
        },
      },
      {
        url: '',
        key: 'telescopic-bollard',
        layerProvider: 'TelescopicBollardsLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.TELESCOPIC_BOLLARD',
          images: ['bollards.png'],
          visible: true,
          display: true,
          order: 13,
        },
      },
    ],
    pointLayers: [
      {
        key: 'parkingmachines',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/dop/zony_placeneho_stani/MapServer/1',
        dialogFn: 'openDialogParkingMachine',
        iconUrl: 'parkovaci-automaty.png',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_MACHINES',
          images: ['parkovaci-automaty.png'],
          visible: true,
          display: true,
          order: 7,
        },
      },
    ],
    serverLayers: [
      // {
      //   key: 'borderszps',
      //   url: 'https://gs-pub.praha.eu/arcgis/services/dop/psp_zony_parkovani/MapServer/WMSServer',
      //   wmsLayers: '0',
      //   isWms: true,
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_ZONES',
      //     colors: ['#A900E6'],
      //     images: ['zony-placeneho-stani.png'],
      //     visible: true,
      //     display: true,
      //     order: 8,
      //   },
      // },
      {
        key: 'winter',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/dop/zimni_udrzba/MapServer',
        isDynamic: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WINTER_ROAD_MAINTENANCE.TITLE',
          subtitleMap: {
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WINTER_ROAD_MAINTENANCE.FIRST_ORDER': '#00C900',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WINTER_ROAD_MAINTENANCE.SECOND_ORDER': '#FA3411',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WINTER_ROAD_MAINTENANCE.THIRD_ORDER': '#00A9E6',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WINTER_ROAD_MAINTENANCE.UNMAINTAINED': '#B2B2B2',
          },
          images: ['zimni-udrzba.png'],
          visible: true,
          display: true,
          order: 9,
        },
      },
      {
        key: 'zoneszps',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/dop/zony_placeneho_stani/MapServer',
        isDynamic: true,
        layers: 'show:3',
        queryLayers: '3',
        opacity: 0.5,
        dialogFn: 'openDialogZoneZps',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_SPACES.TITLE',
          subtitleMap: {
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_SPACES.RESIDENTAL': '#73B2FF',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_SPACES.VISITOR': '#FFAA00',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_SPACES.MIXED': '#CA7AF5',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARKING_SPACES.ZTP': '#CCCCCC',
          },
          visible: true,
          display: true,
          order: 11,
        },
      },
    ],
  },
  floodsLayers: {
    name: 'Povodně',
    lineLayers: [
      {
        key: 'antifloodsandline',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/1',
        color: '#ffff00',
        fillColor: '#ffff00',
        visible: true,
        dialogFn: 'openDialogFloodBarrier',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOODS_SAND_BAGS',
          colors: ['#ffff00'],
          images: ['pytle-s-piskem.png'],
          visible: true,
          display: true,
          order: 13,
        },
      },
      {
        key: 'antifloodline',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/2',
        color: '#ff0000',
        fillColor: '#ff0000',
        visible: true,
        dialogFn: 'openDialogFloodBarrier',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOODS.TITLE',
          subtitleMap: {
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOODS.STATIC': '#ff0051',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOODS.MOBILE': '#4CE600',
          },
          images: ['protipovodnova-ochrana.png'],
          visible: true,
          display: true,
          order: 14,
        },
        propertyStyling: {
          valueMap: {
            1: '#ff0051',
            2: '#4CE600',
          },
          featureProperty: 'TYP',
          styleProperties: ['color', 'fillColor'],
        },
      },
      {
        key: 'floodcategory',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/16',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_CATEGORIES.TITLE',
          subtitleMap: {
            AZZU: '#B2B2B2',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_CATEGORIES.FLOWING': '#FFFFBE',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_CATEGORIES.NO_FLOWING': '#E6A82C',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_CATEGORIES.PROTECTION': '#FFD37F',
          },
          images: ['kategorie-zaplavovych-uzemi.png'],
          visible: true,
          display: true,
          order: 15,
        },
        propertyStyling: {
          valueMap: {
            1: '#B2B2B2',
            '2 VLT': '#FFFFBE',
            3: '#E6A82C',
            4: '#FFD37F',
            5: '#FFD37F',
            6: '#FFD37F',
            7: '#FFD37F',
          },
          featureProperty: 'KAT_VLT',
          styleProperties: ['color', 'fillColor'],
        },
      },
      {
        key: 'activezonevltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/15',
        //visible: true,
        color: '#B2B2B2',
        fillColor: '#B2B2B2',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_ACTIVE_ZONES',
          colors: ['#B2B2B2'],
          images: ['aktivni-zona.png'],
          visible: true,
          display: true,
          order: 16,
        },
      },
      {
        key: 'floodareaq5vltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/4',
        color: '#A900E6',
        fillColor: '#A900E6',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_5_YEARS',
          colors: ['#A900E6'],
          images: ['zaplavove-uzemi-5-let.png'],
          visible: true,
          display: true,
          order: 17,
        },
      },
      {
        key: 'floodareaq20vltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/5',
        color: '#00E6A9',
        fillColor: '#00E6A9',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_20_YEARS',
          colors: ['#00E6A9'],
          images: ['zaplavove-uzemi-20-let.png'],
          visible: true,
          display: true,
          order: 18,
        },
      },
      {
        key: 'floodareaq50vltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/6',
        color: '#FF00C5',
        fillColor: '#FF00C5',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_50_YEARS',
          colors: ['#FF00C5'],
          images: ['zaplavove-uzemi-50-let.png'],
          visible: true,
          display: true,
          order: 19,
        },
      },
      {
        key: 'floodareaq100vltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/7',
        color: '#0070C5',
        fillColor: '#0070C5',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_100_YEARS',
          colors: ['#0070C5'],
          images: ['zaplavove-uzemi-100-let.png'],
          visible: true,
          display: true,
          order: 20,
        },
      },
      {
        key: 'floodareaq2002vltava',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/8',
        color: '#FF0000',
        fillColor: '#FF0000',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FLOOD_2002',
          colors: ['#FF0000'],
          images: ['zaplavove-uzemi-2002.png'],
          visible: true,
          display: true,
          order: 21,
        },
      },
    ],
    riverStationLayer: {
      url: '',
      visible: true,
      key: 'riverStations',
      legendOptions: {
        label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.TITLE',
        subtitleMapWithImages: true,
        subtitleMap: {
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.DRY': 'dry.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.NORMAL': 'normal.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.SPA1': 'spa_1.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.SPA2': 'spa_2.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.SPA3': 'spa_3.png',
          '_COMMON.SERVICE.MAPS.LEGEND_LABELS.WATERS.SPA3_EXTREME': 'spa_3_extreme_danger.png',
        },
        visible: true,
        display: true,
        order: 22,
      },
    },
  },
  // shelterLayers: {
  //   name: 'Ukrytí',
  //   lineLayers: [
  //     {
  //       key: 'metrotunnels',
  //       url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/11',
  //       visible: true,
  //       dialogFn: 'openDialogMetroTunnels',
  //       color: '#009ACD',
  //       weight: 12,
  //       opacity: 0.5,
  //       fillOpacity: 0.5,
  //       propertyStyling: {
  //         featureProperty: 'TRASA',
  //         valueMap: {
  //           A: '#458902',
  //           B: '#FFF400',
  //           C: '#FF073A',
  //         },
  //         styleProperties: ['color'],
  //       },
  //       legendOptions: {
  //         label: 'Ochranný systém metra - tunely',
  //         colors: ['#458902', '#FFF400', '#FF073A'],
  //         images: ['ochrany-system-tunely.png'],
  //         visible: true,
  //         display: true,
  //         order: 23,
  //       },
  //     },
  //     {
  //       key: 'strahovtunnel',
  //       url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/7',
  //       dialogFn: 'openDialogTrafficTunnel',
  //       visible: true,
  //       opacity: 0.5,
  //       fillOpacity: 0.5,
  //       legendOptions: {
  //         label: 'Dopravní tunel pod Strahovem',
  //         colors: ['#6E87F5'],
  //         images: ['dopravni-tunel-pod-strahovem.png'],
  //         visible: true,
  //         display: true,
  //         order: 24,
  //       },
  //     },
  //   ],
  //   pointLayers: [
  //     {
  //       key: 'metrostations',
  //       url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/10',
  //       where: 'TRASA IS NOT NULL',
  //       visible: true,
  //       dialogFn: 'openDialogMetroStation',
  //       disableClusterAtZoom: 9,
  //       propertyStyling: {
  //         featureProperty: 'TRASA',
  //         valueMap: {
  //           A: 'greenMetro.png',
  //           B: 'yellowMetro.png',
  //           C: 'redMetro.png',
  //         },
  //         styleProperties: ['icon'],
  //       },
  //       legendOptions: {
  //         label: 'Ochranný systém metra - stanice',
  //         images: ['greenMetro.png', 'yellowMetro.png', 'redMetro.png'],
  //         visible: true,
  //         display: true,
  //         order: 27,
  //       },
  //     },
  //     {
  //       key: 'metroentrances',
  //       url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/8',
  //       visible: true,
  //       dialogFn: 'openDialogMetroEntrance',
  //       propertyStyling: {
  //         featureProperty: 'TRASA',
  //         valueMap: {
  //           A: 'greenEnter.png',
  //           B: 'yellowEnter.png',
  //           C: 'redEnter.png',
  //         },
  //         styleProperties: ['icon'],
  //       },
  //       legendOptions: {
  //         label: 'Ochranný systém metra - vstupy',
  //         images: ['greenEnter.png', 'yellowEnter.png', 'redEnter.png'],
  //         visible: true,
  //         display: true,
  //         order: 28,
  //       },
  //     },
  //     {
  //       key: 'metrofeatures',
  //       url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/9',
  //       icon: new L.Icon({
  //         iconUrl: 'styles/img/stick.png',
  //         iconSize: new L.Point(2, 24),
  //         iconAnchor: new L.Point(1, 12),
  //       }),
  //       legendOptions: {
  //         label: 'Hranice úseků ochran. syst. metra',
  //         colors: ['#CCCCCC'],
  //         images: ['hranice-useku.png'],
  //         visible: true,
  //         display: true,
  //         order: 29,
  //       },
  //       visible: true,
  //       disableClusterAtZoom: 9,
  //     },
  //   ],
  // },
  izsLayers: {
    name: 'Bezpečnost',
    customLayers: [
      {
        url: '',
        key: 'chemistry',
        layerProvider: 'ChemistryLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.CHEMICAL_SUBSTANCE',
          images: ['waste.png'],
          visible: true,
          display: true,
          order: 32,
        },
      },
    ],
    pointLayers: [
      {
        key: 'rotatingsirens',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0',
        where: "DRUH_SIRENY = 'rotační'",
        iconUrl: 'sirenGreen.png',
        visible: true,
        disableClusterAtZoom: 8,
        dialogFn: 'openDialogSiren',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.ROTATION_SIRENS',
          images: ['sirenGreen.png'],
          visible: true,
          display: true,
          order: 25,
        },
      },
      {
        key: 'electronic',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0',
        where: "DRUH_SIRENY = 'elektronická'",
        iconUrl: 'sirenRed.png',
        visible: true,
        disableClusterAtZoom: 8,
        dialogFn: 'openDialogSiren',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.ELECTRONIC_SIRENS',
          images: ['sirenRed.png'],
          visible: true,
          display: true,
          order: 26,
        },
      },
      {
        key: 'shelterssmall',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/3',
        visible: true,
        dialogFn: 'openDialogShelters',
        iconUrl: 'shelterSmall.png',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SMALL_SHELTERS',
          images: ['shelterSmall.png'],
          visible: true,
          display: true,
          order: 30,
        },
      },
      {
        key: 'sheltersbig',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/4',
        visible: true,
        dialogFn: 'openDialogShelters',
        iconUrl: 'shelterBig.png',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.BIG_SHELTERS',
          images: ['shelterBig.png'],
          visible: true,
          display: true,
          order: 31,
        },
      },
      {
        key: 'policecr',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/0',
        iconUrl: 'police.png',
        visible: true,
        dialogFn: 'openDialogPolice',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.POLICE',
          images: ['police.png'],
          visible: true,
          display: true,
          order: 33,
        },
      },
      {
        key: 'emergency',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/1',
        iconUrl: 'emergency.png',
        visible: true,
        dialogFn: 'openDialogEmergency',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.EMERGENCY',
          images: ['emergency.png'],
          visible: true,
          display: true,
          order: 34,
        },
      },
      // {
      //   key: 'firebrigade',
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/2',
      //   iconUrl: 'fireman.png',
      //   visible: true,
      //   dialogFn: 'openDialogFireBrigade',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FIREMAN',
      //     images: ['fireman.png'],
      //     visible: true,
      //     display: true,
      //     order: 35,
      //   },
      // },
      {
        key: 'policemuni',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/3',
        iconUrl: 'municipalPolice.png',
        visible: true,
        dialogFn: 'openDialogMunicipalPolice',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.MUNICIPAL',
          images: ['municipalPolice.png'],
          visible: true,
          display: true,
          order: 36,
        },
      },
      // {
      //   key: 'volunteerfirebrigade',
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/10',
      //   iconUrl: 'volunteerfireman.png',
      //   visible: true,
      //   dialogFn: 'openDialogVolunteerFireBrigade',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.VOLUNTEER_FIREMAN',
      //     images: ['volunteerfireman.png'],
      //     visible: true,
      //     display: true,
      //     order: 37,
      //   },
      // },
    ],
    lineLayers: [
      // {
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/4',
      //   key: 'munipoliceheadquarters',
      //   color: '#343434',
      //   fillColor: '#343434',
      //   stroke: '#343434',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.MUNICIPAL_HEADQUARTERS',
      //     colors: ['#343434'],
      //     images: ['obvodni-reditelstvi-mestske-policie.png'],
      //     visible: true,
      //     display: true,
      //     order: 38,
      //   },
      // },
      // {
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/5',
      //   key: 'crpoliceheadquarters',
      //   color: '#285891',
      //   fillColor: '#285891',
      //   stroke: '#285891',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.POLICE_HEADQUARTERS',
      //     colors: ['#285891'],
      //     images: ['obvodni-reditelstvi-policie.png'],
      //     visible: true,
      //     display: true,
      //     order: 39,
      //   },
      // },
      // {
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/6',
      //   key: 'munipolicedistricts',
      //   color: '#000000',
      //   fillColor: '#000000',
      //   stroke: '#000000',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.MUNICIPAL_DISTRICTS',
      //     colors: ['#000000'],
      //     images: ['okrsky-mestske-policie.png'],
      //     visible: true,
      //     display: true,
      //     order: 40,
      //   },
      // },
      // {
      //   url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/7',
      //   key: 'crpolicedistricts',
      //   color: '#285891',
      //   fillColor: '#285891',
      //   stroke: '#285891',
      //   legendOptions: {
      //     label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.POLICE_DISTRICTS',
      //     colors: ['#285891'],
      //     images: ['okrsky-policie.png'],
      //     visible: true,
      //     display: true,
      //     order: 41,
      //   },
      // },
      {
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/7',
        key: 'firedistricts',
        color: '#99312e',
        fillColor: '#99312e',
        stroke: '#99312e',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FIRE_DISTRICTS',
          colors: ['#99312e'],
          images: ['hasebni-obvody.png'],
          visible: true,
          display: true,
          order: 42,
        },
      },
    ],
  },
  cadastreLayers: {
    name: 'Územní členění',
    serverLayers: [
      {
        key: 'cadastrez',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer',
        isDynamic: true,
        layers: 'show:12',
        queryLayers: '12',
        visible: true,
        dialogFn: 'openDialogCadastre',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PARCELS',
          colors: ['#000000'],
          images: ['parcely.png'],
          visible: true,
          display: true,
          order: 43,
        },
      },
      //   {
      //     key: 'cadastrei',
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer',
      //     isDynamic: true,
      //     layers: 'show:15',
      //     queryLayers: '15',
      //     dialogFn: 'openDialogCadastre',
      //     legendOptions: {
      //       label: 'Parcely i',
      //       colors: ['#FAA700'],
      //       visible: true,
      //       display: true,
      //       order: 44,
      //     },
      //   },
      //   {
      //     key: 'addresses',
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer',
      //     visible: true,
      //     isDynamic: true,
      //     layers: 'show:7',
      //     queryLayers: '8',
      //     dialogFn: 'openDialogAddresses',
      //     legendOptions: {
      //       label: 'Adresní body',
      //       colors: ['#00A9E6'],
      //       images: ['adresni-body.png'],
      //       visible: true,
      //       display: true,
      //       order: 45,
      //     },
      //   },
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer',
      //     key: 'streets',
      //     isDynamic: true,
      //     layers: 'show:4',
      //     legendOptions: {
      //       label: 'Uliční úseky',
      //       colors: ['#FFFFFF'],
      //       images: ['ulicni-useky.png'],
      //       visible: true,
      //       display: true,
      //       order: 46,
      //     },
      //   },
      // ],
      // lineLayers: [
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/0',
      //     key: 'prague',
      //     color: '#E60000',
      //     fillColor: '#E60000',
      //     stroke: '#E60000',
      //     legendOptions: {
      //       label: 'Hranice Prahy',
      //       colors: ['#E60000'],
      //       images: ['hranice-prahy.png'],
      //       visible: true,
      //       display: true,
      //       order: 47,
      //     },
      //   },
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/1',
      //     key: 'citydistricts',
      //     color: '#8C005E',
      //     fillColor: '#8C005E',
      //     stroke: '#8C005E',
      //     legendOptions: {
      //       label: 'Hranice městských částí',
      //       images: ['hranice-mestskych-casti.png'],
      //       colors: ['#8C005E'],
      //       visible: true,
      //       display: true,
      //       order: 48,
      //     },
      //   },
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/2',
      //     key: 'districts',
      //     color: '#F2C2DA',
      //     fillColor: '#F2C2DA',
      //     stroke: '#F2C2DA',
      //     legendOptions: {
      //       label: 'Správní obvody',
      //       colors: ['#F2C2DA'],
      //       visible: true,
      //       display: true,
      //       order: 49,
      //     },
      //   },
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/10',
      //     key: 'buildings',
      //     color: '#663838',
      //     fillColor: '#663838',
      //     stroke: '#663838',
      //     legendOptions: {
      //       label: 'Stavební objekty',
      //       colors: ['#663838'],
      //       images: ['stavebni-objekty.png'],
      //       visible: true,
      //       display: true,
      //       order: 50,
      //     },
      //   },
      //   {
      //     url: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/3',
      //     key: 'cadastredistricts',
      //     color: '#C08FDB',
      //     fillColor: '#C08FDB',
      //     stroke: '#C08FDB',
      //     legendOptions: {
      //       label: 'Hranice katastrální území',
      //       colors: ['#C08FDB'],
      //       images: ['hranice-katastralni-uzemi.png'],
      //       visible: true,
      //       display: true,
      //       order: 51,
      //     },
      //   },
    ],
  },
  schoolLayers: {
    customLayers: [
      {
        url: '',
        key: 'sypos',
        layerProvider: 'SyposLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SYPOS',
          images: ['edu-icon32.png'],
          visible: true,
          display: true,
          order: 10,
        },
      },
    ],
    serverLayers: [
      {
        key: 'schools',
        url: 'https://gs-pub.praha.eu/arcgis/services/fs/skoly/MapServer/WmsServer',
        wmsLayers: '7,8,9,11,12,13,15,16,17',
        visible: false,
        isWms: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.TITLE',
          subtitleMap: {
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.KINDERGARTEN': '#FFD37F',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.ELEMENTARY_SCHOOL': '#FF5500',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.HIGH_SCHOOL': '#B062C8',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.HIGHER_VOCATIONAL_SCHOOL': '#006FFF',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.LANGUAGE_SCHOOL': '#A83700',
            '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.HOBBY': '#01FF00',
          },
          display: true,
          order: 1,
        },
      },
    ],
    pointLayers: [
      {
        key: 'rectorates',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/skoly/MapServer/18',
        iconUrl: 'vs_rektoraty.png',
        dialogFn: 'openDialogRectorate',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.RECTORATE',
          images: ['vs_rektoraty.png'],
          order: 2,
        },
      },
      {
        key: 'faculties',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/skoly/MapServer/17',
        iconUrl: 'vs_fakulty.png',
        dialogFn: 'openDialogFaculty',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.FACULTY',
          images: ['vs_fakulty.png'],
          order: 3,
        },
      },
      {
        key: 'othereducation',
        url: 'https://gs-pub.praha.eu/arcgis/rest/services/fs/skoly/MapServer/19',
        iconUrl: 'vs_ostatni.png',
        dialogFn: 'openDialogOtherEducation',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.SCHOOL.OTHER_EDUCATION',
          images: ['vs_ostatni.png'],
          order: 4,
        },
      },
    ],
  },
  restaurantLayers: {
    customLayers: [
      {
        url: '',
        key: 'restaurant',
        layerProvider: 'RestaurantLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.RESTAURANTS',
          images: ['restaurant.png'],
          visible: true,
          display: true,
          order: 1,
        },
      },
    ],
  },
  hzsLayers: {
    customLayers: [
      {
        url: '',
        key: 'passability',
        layerProvider: 'PassabilityLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.HZS.PASSABILITY',
          images: ['passability.png'],
          visible: true,
          display: true,
          order: 1,
        },
      },
      {
        url: '',
        key: 'staticBollards',
        layerProvider: 'StaticBollardsLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.HZS.STATIC_BOLLARDS',
          images: ['cone.png'],
          visible: true,
          display: true,
          order: 2,
        },
      },
      {
        url: '',
        key: 'telescopicBollards',
        layerProvider: 'TelescopicBollardsLayerProvider',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.HZS.TELESCOPIC_BOLLARDS',
          images: ['bollards.png'],
          visible: true,
          display: true,
          order: 3,
        },
      },
    ],
  },
  cameraLayers: {
    name: 'Kamery',
    customLayers: [
      {
        url: '',
        key: 'publiccameras',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PUBLIC_CAMERAS',
          images: ['camera_public.png'],
          visible: true,
          display: true,
          order: 52,
        },
      },
      {
        url: '',
        key: 'privatecameras',
        visible: true,
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.PRIVATE_CAMERAS',
          images: ['camera_private.png'],
          visible: true,
          display: true,
          order: 53,
        },
      },
      {
        url: '',
        key: 'futurecameras',
        legendOptions: {
          label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.FUTURE_CAMERAS',
          subtitleMapWithImages: true,
          subtitleMap: {
            Navrhovaná: 'camera_suggested_draft.png',
            Projektovaná: 'camera_suggested_designed.png',
            Záložní: 'camera_suggested_backup.png',
            'Ve výstavbě': 'camera_suggested_under_construction.png',
            Dokončená: 'camera_suggested_finished.png',
            'V provozu': 'camera_suggested_working.png',
          },
          visible: true,
          display: true,
          order: 54,
        },
      },
    ],
    polygonsOfVisibility: {
      url: '',
      key: 'polygonsofvisibility',
      legendOptions: {
        label: '_COMMON.SERVICE.MAPS.LEGEND_LABELS.CAMERA_VISIBILITY_POLYGONS',
        colors: ['rgba(206,65,150,0.55)'],
        visible: true,
        display: true,
        order: 55,
      },
    },
  },
  safetyLayers: {
    name: 'Pocit bezpečí',
    pointLayers: [
      {
        url: '',
        key: 'safety',
        visible: true,
      },
    ],
  },
  lineLayer: {
    url: 'https://gs-pub.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/3',
    key: 'towndistrict',
  },
  search: {
    MAP_SEARCH_ADDRESSES_URL:
      'https://gs-pub.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/0',
    MAP_SEARCH_STREETS_URL:
      'https://gs-pub.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/7',
    MAP_PARCELS_URL: 'https://gs-pub.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/14',
  },
}
window.appConfig = appConfig
