;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').constant('WETHER_TABS', [
    {
      key: 'weather',
      title: 'FRONT.HOMEPAGE.WIDGET.WEATHER.TAB.WEATHER.TITLE',
    },
    {
      key: 'air',
      title: 'FRONT.HOMEPAGE.WIDGET.WEATHER.TAB.AIR_POLLUTION.TITLE',
    },
    {
      key: 'hydrology',
      title: 'FRONT.HOMEPAGE.WIDGET.WEATHER.TAB.HYDROLOGY.TITLE',
    },
  ])
})()
