;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('CameraMapService', CameraMapService)

  CameraMapService.$inject = [
    '$q',
    '$rootScope',
    '$filter',
    '$stateParams',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'GeometryHelper',
    'CameraLayerProvider',
    'CustomLayer',
    'APP_CONFIG',
  ]

  function CameraMapService(
    $q,
    $rootScope,
    $filter,
    $stateParams,
    leafletData,
    MapHelperService,
    MapDialogService,
    GeometryHelper,
    CameraLayerProvider,
    CustomLayer,
    APP_CONFIG,
  ) {
    var marker = {
      instance: null,
      isFromSearch: false,
    }
    var map = null,
      publicCamerasLayer = null,
      privateCamerasLayer = null,
      futureCamerasLayer = null
    var removedMarkers = []

    $rootScope.$on('mapLayerSwitched', function (event, data) {
      if (futureCamerasLayer && map.hasLayer(futureCamerasLayer.getLayer())) {
        var layer = futureCamerasLayer.getLayer()
        var markers = layer.getLayers()

        if (
          map.hasLayer(publicCamerasLayer.getLayer()) ||
          (privateCamerasLayer && map.hasLayer(privateCamerasLayer.getLayer()))
        ) {
          angular.forEach(markers, function (marker) {
            if (marker.options.cameraState === 'Working') {
              layer.removeLayer(marker)
              removedMarkers.push(marker)
            }
          })
        } else {
          angular.forEach(removedMarkers, function (marker) {
            layer.addLayer(marker)
          })
          removedMarkers = []
        }
      }
    })

    function initCameraLayers(grid, map, layerCollection, onClickFunc, hasPrivateCameraAccess) {
      createPublicCamerasLayer(grid, map, layerCollection, onClickFunc)
      if (hasPrivateCameraAccess) {
        createPrivateCamerasLayer(grid, map, layerCollection, onClickFunc)
        createFutureCamerasLayer(grid, map, layerCollection, false)
        _createPolygonsOfVisibility(layerCollection)
      }
    }

    function initFutureCameraLayers(grid, map, layerCollection) {
      createFutureCamerasLayer(grid, map, layerCollection, true)
    }

    function _createCameraCustomLayer(
      camerasLayerProvider,
      key,
      layerCollection,
      overrideDefaultLayerVisibility,
    ) {
      var customLayer = null
      angular.forEach(
        APP_CONFIG.mapSettings.cameraLayers.customLayers,
        function (customLayerConfig) {
          if (customLayerConfig.key === key) {
            if (angular.isDefined(overrideDefaultLayerVisibility)) {
              customLayerConfig.visible = overrideDefaultLayerVisibility
            }
            customLayer = new CustomLayer(
              angular.extend(customLayerConfig, {
                layer: camerasLayerProvider.getLayer(),
              }),
            )
            MapHelperService.addLayer(customLayer, layerCollection)
            _handlePopupOpen(camerasLayerProvider.getLayer())
          }
        },
      )
      return customLayer
    }

    function _handlePopupOpen(camerasLayerProvider) {
      const cameraId = $stateParams.cameraId
      camerasLayerProvider.eachLayer((marker) => {
        if (cameraId && marker.cameraId.toString() === cameraId.toString()) {
          marker.fire('click')
        }
      })
    }

    function _createPolygonsOfVisibility(layerCollection) {
      var imageUrl = 'styles/img/camera_polygons.png'
      var imageBounds = new L.Bounds(new L.Point(-753000, -1055010), new L.Point(-724000, -1034010))
      var polygonsOfVisibilityMapLayer = new L.Proj.ImageOverlay(imageUrl, imageBounds)
      polygonsOfVisibilityMapLayer.setOpacity(0.55)

      var customLayer = new CustomLayer(
        angular.extend(APP_CONFIG.mapSettings.cameraLayers.polygonsOfVisibility, {
          layer: polygonsOfVisibilityMapLayer,
        }),
      )
      MapHelperService.addLayer(customLayer, layerCollection)
    }

    function _createCameraLayerProvider(grid, map, onClickFunc, camerasFilter) {
      var camerasLayerProvider = new CameraLayerProvider(map, camerasFilter)
      if (onClickFunc === null) {
        onClickFunc = function (grid, camera) {
          MapDialogService.openDialogFutureCameras(
            camera,
            map,
            { lat: camera.latitude, lon: camera.longitude },
            $rootScope.$new(),
          )
        }
      }
      camerasLayerProvider.setItemClickedFunc(function (camera) {
        onClickFunc(grid, camera)
      })

      var deferred = $q.defer()
      const promise = camerasLayerProvider.update()
      promise.$promise.then(() => {
        deferred.resolve(camerasLayerProvider)
      })
      return deferred.promise
    }

    function _setCameraPosition(marker, cameraRef) {
      var position = marker.getLatLng()
      cameraRef.location.longitude = position.lng
      cameraRef.location.latitude = position.lat

      cameraRef.street = null
      cameraRef.houseNumber = null
      cameraRef.orientationNumber = null
    }

    function _setMarker(latlng) {
      if (marker.isFromSearch) {
        marker.instance.clearLayers()
        marker.instance = null
        marker.isFromSearch = false
      }

      if (marker.instance === null) {
        marker.instance = new L.Marker(latlng, {
          draggable: true,
          icon: new L.Icon({
            iconUrl: 'styles/img/location-pin.png',
            shadowUrl: 'styles/img/marker-shadow.png',
            iconAnchor: new L.Point(16, 32),
            shadowAnchor: new L.Point(14, 40),
          }),
        })
        marker.instance.addTo(map)
      } else {
        marker.instance.setLatLng(latlng)
      }
      map.panTo(latlng)
    }

    function createPublicCamerasLayer(grid, map, layerCollection, onClickFunc) {
      var camerasFilter = {
        includePublic: true,
        // includeInactive: true
      }
      var publicCamerasLayerProviderPromise = _createCameraLayerProvider(
        grid,
        map,
        onClickFunc,
        camerasFilter,
      )

      publicCamerasLayerProviderPromise.then((publicCamerasLayerProvider) => {
        publicCamerasLayer = _createCameraCustomLayer(
          publicCamerasLayerProvider,
          'publiccameras',
          layerCollection,
        )
      })
    }

    function createPrivateCamerasLayer(grid, map, layerCollection, onClickFunc) {
      var camerasFilter = {
        includePrivate: true,
        // includeInactive: true
      }
      var privateCamerasLayerProviderPromise = _createCameraLayerProvider(
        grid,
        map,
        onClickFunc,
        camerasFilter,
      )
      privateCamerasLayerProviderPromise.then((privateCamerasLayerProvider) => {
        privateCamerasLayer = _createCameraCustomLayer(
          privateCamerasLayerProvider,
          'privatecameras',
          layerCollection,
        )
      })
    }

    function createFutureCamerasLayer(grid, map, layerCollection, overrideDefaultLayerVisibility) {
      var camerasFilter = {
        futureCameras: true,
      }
      var futureCamerasLayerProviderPromise = _createCameraLayerProvider(
        grid,
        map,
        null,
        camerasFilter,
      )
      futureCamerasLayerProviderPromise.then((futureCamerasLayerProvider) => {
        futureCamerasLayer = _createCameraCustomLayer(
          futureCamerasLayerProvider,
          'futurecameras',
          layerCollection,
          overrideDefaultLayerVisibility,
        )
      })
    }

    function initCameraMap(index, grid, data, onClickFunc, hasPrivateCameraAccess) {
      MapHelperService.initViewModelMapData(data)
      leafletData.getMap('map' + (_.isNumber(index) ? '-' + index : '')).then(function (m) {
        map = m
        removedMarkers = []
        var layerCollection = MapHelperService.initMap(map, data)
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        initCameraLayers(grid, map, layerCollection, onClickFunc, hasPrivateCameraAccess)
      })
    }

    function initCameraMapForFutureCameraSelect(data, futureCameraRef) {
      marker.instance = null
      map = null
      if (!futureCameraRef.location) {
        futureCameraRef.location = {}
      }
      MapHelperService.initViewModelMapData(data, 'futureCamerasmap')

      leafletData.getMap('map').then(function (m) {
        map = m
        removedMarkers = []
        var layerCollection = MapHelperService.initMap(map, data)
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        initFutureCameraLayers(null, map, layerCollection)

        if (
          futureCameraRef.location &&
          futureCameraRef.location.longitude &&
          futureCameraRef.location.latitude
        ) {
          var latlng = new L.LatLng(
            futureCameraRef.location.latitude,
            futureCameraRef.location.longitude,
          )
          _setMarker(latlng)
        }

        map.on('click', function (e) {
          _setMarker(e.latlng)
          _setCameraPosition(marker.instance, futureCameraRef)
        })

        if (marker.instance) {
          marker.instance.on('dragend', function (evt) {
            var position = evt.target.getLatLng()
            _setMarker(new L.LatLng(position.lat, position.lng))
            _setCameraPosition(marker.instance, futureCameraRef)
          })
        }

        $rootScope.$on('mapAddressSearched', function (event, address, searchGroupLayer) {
          if (marker.instance) {
            marker.instance.remove()
          }
          marker.instance = searchGroupLayer
          marker.isFromSearch = true
          if (!address.isStreetModel) {
            futureCameraRef.street = address.nazevUlice
            futureCameraRef.houseNumber = address.cisloDomovni
            futureCameraRef.orientationNumber = address.cisloOrientacni
            futureCameraRef.location.latitude = address.geometry.coordinates[1]
            futureCameraRef.location.longitude = address.geometry.coordinates[0]
          } else {
            futureCameraRef.street = address.mkn
          }
          futureCameraRef.locationAddress = $filter('address')(futureCameraRef)
        })
      })
    }

    return {
      initCameraMap: initCameraMap,
      initCameraLayers: initCameraLayers,
      initCameraMapForFutureCameraSelect: initCameraMapForFutureCameraSelect,
    }
  }
})()
