;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('PcrDynamicsMapModalController', PcrDynamicsMapModalController)

  PcrDynamicsMapModalController.$inject = ['ApiSourceModel', 'MapDialogService']

  function PcrDynamicsMapModalController(ApiSourceModel, MapDialogService) {
    const vm = this

    activate()

    function activate() {
      getEnums()
      vm.data = MapDialogService.getPopupData()
    }

    function getEnums() {}
  }
})()
