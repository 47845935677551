;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminInitiativeModel', ApiAdminInitiativeModel)

  ApiAdminInitiativeModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminInitiativeModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/initiatives/:path1/:id/:path2',
      { path1: '@path1', id: '@id', path2: '@path2' },
      {
        getSearchedInitiatives: {
          method: 'POST',
          params: {
            path1: 'search',
          },
        },
        getInitiatives: {
          method: 'GET',
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('initiatives'),
        },
        getInitiative: {
          method: 'GET',
          transformResponse: UtilService.simpleResponseTransform('initiative'),
        },
        enableInitiative: {
          method: 'PUT',
          params: {
            path2: 'publish',
          },
        },
        disableInitiative: {
          method: 'PUT',
          params: {
            path2: 'unpublish',
          },
        },
        updateInitiative: {
          method: 'PUT',
        },
        deleteInitiative: {
          method: 'DELETE',
        },
        // initiative states
        assignToMe: {
          method: 'POST',
          params: {
            path2: 'assign',
          },
        },
        // initiative categories
        getInitiativeCategories: {
          method: 'GET',
          params: {
            path1: 'categories',
          },
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('initiativeCategories'),
        },
        createInitiativeCategory: {
          method: 'POST',
          params: {
            path1: 'categories',
          },
        },
        updateInitiativeCategory: {
          method: 'PUT',
          params: {
            path1: 'categories',
          },
        },
        deleteInitiativeCategory: {
          method: 'DELETE',
          params: {
            path1: 'categories',
          },
        },
        undeleteInitiativeCategory: {
          method: 'POST',
          params: {
            path1: 'categories',
            path2: 'undelete',
          },
        },
        // initiative notes
        getInitiativeNotes: {
          method: 'GET',
          params: {
            path2: 'notes',
          },
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('notes'),
        },
        addInitiativeNote: {
          method: 'POST',
          params: {
            path2: 'notes',
          },
        },
        // email
        sendMail: {
          method: 'POST',
          params: {
            path2: 'mail',
          },
        },
      },
    )
  }
})()
