;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('TrafficMapService', TrafficMapService)

  TrafficMapService.$inject = [
    '$rootScope',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'DdrMessageLayerProvider',
    'LayerProviderFactory',
    'CustomLayer',
    'PointLayer',
    'ServerLayer',
    'APP_CONFIG',
  ]

  function TrafficMapService(
    $rootScope,
    leafletData,
    MapHelperService,
    MapDialogService,
    DdrMessageLayerProvider,
    LayerProviderFactory,
    CustomLayer,
    PointLayer,
    ServerLayer,
    APP_CONFIG,
  ) {
    function _initZpsLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.trafficLayers.pointLayers,
        function (pointLayerConfiguration) {
          var pointLayer = new PointLayer(
            angular.extend(pointLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: MapDialogService[pointLayerConfiguration.dialogFn],
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(pointLayer, layerCollection)
        },
      )

      angular.forEach(
        APP_CONFIG.mapSettings.trafficLayers.serverLayers,
        function (serverLayerConfiguration) {
          var serverLayer = new ServerLayer(
            angular.extend(serverLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: serverLayerConfiguration.dialogFn
                ? MapDialogService[serverLayerConfiguration.dialogFn]
                : null,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(serverLayer, layerCollection)
        },
      )

      angular.forEach(
        APP_CONFIG.mapSettings.trafficLayers.customLayers,
        function (customLayerConfig) {
          var customLayer = new CustomLayer(
            angular.extend(customLayerConfig, {
              layer: LayerProviderFactory.provide(map, customLayerConfig.layerProvider).getLayer(),
            }),
          )
          MapHelperService.addLayer(customLayer, layerCollection)
        },
      )
    }

    function initTrafficLayers(map, layerCollection) {
      DdrMessageLayerProvider.initDdrMessagesLayer(
        map,
        APP_CONFIG.mapSettings.trafficLayers.trafficLayer,
        layerCollection,
      )
      _initZpsLayers(map, layerCollection)
    }

    function initTrafficMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initTrafficLayers(map, layerCollection)
      })
    }

    return {
      initTrafficMap: initTrafficMap,
      initTrafficLayers: initTrafficLayers,
    }
  }
})()
