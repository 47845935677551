;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.article')
    .controller('DetailArticlePreviewController', DetailArticlePreviewController)

  DetailArticlePreviewController.$inject = [
    '$stateParams',
    'PersistenceModel',
    'ApiAdminArticleModel',
    'ApiDocumentModel',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function DetailArticlePreviewController(
    $stateParams,
    PersistenceModel,
    ApiAdminArticleModel,
    ApiDocumentModel,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.apiKey = PersistenceModel.getAccessToken()
    vm.baseUrl = APP_CONFIG.cdnUrl
    var requestedCultureCode = $stateParams.cultureCode
      ? $stateParams.cultureCode
      : APP_CONFIG.translation.fallbackLanguage

    
    vm.isTreeSectionActive = isTreeSectionActive

    activate()

    function activate() {
      getArticle()
      getArticlesStructure()
    }

    function getArticle() {
      vm.loadingData = true
      ApiAdminArticleModel.getArticle(
        { id1: $stateParams.id },
        function (article) {
          vm.article = article
          var defaultTranslationIndex = _.findIndex(vm.article.articleCulture, [
            'cultureCode',
            requestedCultureCode,
          ])
          if (defaultTranslationIndex >= 0) {
            _.assign(vm.article, vm.article.articleCulture[defaultTranslationIndex])
            if (vm.article.breadcrumbs.length === 1) {
              vm.article.breadcrumbs.unshift({})
            }
          } else {
            FlashMessage.add('Překlad neexistuje (' + requestedCultureCode + ')', 'danger')
          }
          getDocuments(article.fileFolderId)
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function getArticlesStructure() {
      ApiAdminArticleModel.getArticleTree(
        { cultureCode: requestedCultureCode },
        function (articles) {
          vm.articleStructure = articles[0]
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function getDocuments(folderId) {
      ApiDocumentModel.getFolder(
        { id: folderId, cultureCode: requestedCultureCode },
        function (documents) {
          vm.documents = [documents]
        },
        function () {
          FlashMessage.removeAll()
        },
      )
    }

    function isTreeSectionActive(relativeUrl) {
      var isActive = false
      if (!vm.article) {
        return isActive
      }
      angular.forEach(vm.article.breadcrumbs, function (breadcrumb) {
        if (breadcrumb.relativeUrl === relativeUrl) {
          isActive = true
        }
      })
      return isActive
    }
  }
})()
