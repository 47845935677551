;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.source')
    .controller('AdminTrafficBollardFormController', AdminTrafficBollardFormController)

  AdminTrafficBollardFormController.$inject = [
    '$state',
    '$stateParams',
    'MapSelectionService',
    'ApiAdminTrafficBollardModel',
    'FlashMessage',
    'BOLLARD_TYPES',
  ]

  function AdminTrafficBollardFormController(
    $state,
    $stateParams,
    MapSelectionService,
    ApiAdminTrafficBollardModel,
    FlashMessage,
    BOLLARD_TYPES,
  ) {
    const vm = this
    vm.isEdit = !!$stateParams.id
    vm.ok = ok

    vm.data = {
      mapLayers: [],
      legend: [],
    }

    vm.bollardTypes = Object.keys(BOLLARD_TYPES).map((key) => ({
      id: key,
      name: BOLLARD_TYPES[key],
    }))

    activate()

    function activate() {
      if (vm.isEdit) {
        getTrafficBollard()
      } else {
        vm.bollard = { position: {} }
        initMap(vm.bollard)
      }
    }

    function getTrafficBollard() {
      ApiAdminTrafficBollardModel.getSingle({ id: $stateParams.id }, (data) => {
        vm.bollard = data.trafficBollard
        initMap(vm.bollard)
      })
    }

    function initMap(bollard) {
      MapSelectionService.initMapForSelect(vm.data, bollard.position)
    }

    function ok() {
      if (vm.isEdit) {
        ApiAdminTrafficBollardModel.edit({}, { trafficBollard: vm.bollard }, () => {
          FlashMessage.add(`Sloupek ${vm.bollard.placeName} byla úspěšně editován.`, 'warning', 1)
          $state.go('admin.bollards')
        })
      } else {
        ApiAdminTrafficBollardModel.create({}, { trafficBollard: vm.bollard }, () => {
          FlashMessage.add(`Sloupek ${vm.bollard.placeName} byla úspěšně vytvořen.`, 'success', 1)
          $state.go('admin.bollards')
        })
      }
    }
  }
})()
