;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('EditCameraModalController', EditCameraModalController)

  EditCameraModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminCameraModel',
    'FlashMessage',
    'camera',
  ]

  function EditCameraModalController($uibModalInstance, ApiAdminCameraModel, FlashMessage, camera) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.camera = camera
    

    function ok() {
      var promise = ApiAdminCameraModel.editCamera(
        { id1: vm.camera.id },
        { camera: vm.camera },
        function () {
          FlashMessage.add(
            'Kamera s ID ' + vm.camera.identifier + ' byla úspěšně editována.',
            'success',
          )
        },
      )
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
