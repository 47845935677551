;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('PopupMapModalController', PopupMapModalController)

  PopupMapModalController.$inject = ['MapDialogService', 'PersistenceModel']

  function PopupMapModalController(MapDialogService, PersistenceModel) {
    const vm = this
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()

    activate()

    function activate() {
      vm.data = MapDialogService.getPopupData()
    }
  }
})()
