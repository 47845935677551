;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.document').controller('DocumentController', DocumentController)

  DocumentController.$inject = [
    '$translate',
    'ApiDocumentModel',
    'ArticleTreeService',
    'PersistenceModel',
    'APP_CONFIG',
  ]

  function DocumentController(
    $translate,
    ApiDocumentModel,
    ArticleTreeService,
    PersistenceModel,
    APP_CONFIG,
  ) {
    const vm = this
    vm.apiKey = PersistenceModel.getAccessToken()
    vm.baseUrl = APP_CONFIG.cdnUrl

    
    vm.toggleTree = toggleTree

    activate()

    function activate() {
      getStructure()
      vm.articleStructure = ArticleTreeService.getArticleTree()
    }

    function getStructure() {
      vm.loadingData = true
      ApiDocumentModel.getStructure(
        { cultureCode: $translate.use() },
        (documents) => {
          vm.documents = documents
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }

    function toggleTree(scope) {
      scope.toggle()
      _.each(scope.childNodes(), (c) => {
        c.collapse()
      })
    }
  }
})()
