;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('CustomLayer', CustomLayer)

  CustomLayer.$inject = ['Layer']

  function CustomLayer(Layer) {
    return function (options) {
      var child = Object.create(Layer)
      child.super(options)

      child.init = function () {
        this.options = options
        this.layer = this.options.layer

        return child
      }

      return child.init()
    }
  }
})()
