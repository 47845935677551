;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.layout').controller('AdminLayoutController', AdminLayoutController)

  AdminLayoutController.$inject = [
    '$rootScope',
    '$state',
    '$translate',
    'ApiLanguageModel',
    'PersistenceModel',
    'ApiAuthenticationModel',
    'APP_CONFIG',
  ]

  function AdminLayoutController(
    $rootScope,
    $state,
    $translate,
    ApiLanguageModel,
    PersistenceModel,
    ApiAuthenticationModel,
    APP_CONFIG,
  ) {
    const vm = this
    vm.changeLanguage = changeLanguage
    vm.logout = logout
    vm.fulltextSearch = fulltextSearch
    vm.openSearchBox = openSearchBox
    vm.createEvent = createEvent
    vm.scrollTo = scrollTo
    vm.modifyHtml = modifyHtml

    vm.hasAdminRole = PersistenceModel.hasAdminRole()
    vm.hasCrisisRole = PersistenceModel.hasCrisisRole()
    vm.hasHeadManagerRole = PersistenceModel.hasHeadManagerRole()
    vm.hasUserRole = PersistenceModel.hasUserRole()
    vm.hasEventPublisherRole = PersistenceModel.hasEventPublisherRole()
    vm.hasContentPublisherRole = PersistenceModel.hasContentPublisherRole()
    vm.hasTranslatorRole = PersistenceModel.hasTranslatorRole()
    vm.hasInitiativesAdminRole = PersistenceModel.hasInitiativesAdminRole()

    vm.actualCultureCode = $translate.use()

    activate()

    function activate() {
      vm.showSearchBox = false
      vm.fulltext = ''
      vm.sideMenuOpened = window.innerWidth > 767
      vm.version = APP_CONFIG.version
      vm.currentDate = new Date()
      vm.languageSelect = ApiLanguageModel.getAllLanguages()
      vm.defaultLanguage = APP_CONFIG.translation.fallbackLanguage
    }

    function changeLanguage(langKey) {
      $translate.use(langKey)
      vm.actualCultureCode = langKey
    }

    function logout() {
      $rootScope.$broadcast('logout')
      ApiAuthenticationModel.logout(
        function () {
          doLogout()
        },
        function () {
          doLogout()
        },
      )
    }

    function doLogout() {
      PersistenceModel.clear()
      $state.go(PersistenceModel.getDefaultPage(), {}, { reload: true })
    }

    function fulltextSearch() {
      $state.go('front.search', { fulltext: vm.fulltext }, {})
    }

    function openSearchBox() {
      if (vm.fulltext.trim() !== '') {
        fulltextSearch()
      } else {
        vm.showSearchBox = !vm.showSearchBox
      }
      angular.element('#fulltext-search').focus()
    }

    function createEvent() {
      PersistenceModel.clearSourceDataForEvent()
    }

    function scrollTo(verticalScroll) {
      angular.element('html, body').animate({ scrollTop: verticalScroll }, 400)
    }

    function modifyHtml(html) {
      var textFragment = html.match(/<!--StartFragment-->([\s\S]*?)<!--EndFragment-->/i)
      if (textFragment && textFragment.length > 1) {
        return textFragment[1]
      }
      return html
    }
  }
})()
