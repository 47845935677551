;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.initiative')
    .controller('InitiativeFormController', InitiativeFormController)

  InitiativeFormController.$inject = [
    '$state',
    '$timeout',
    '$translate',
    'ApiInitiativeModel',
    'InitiativeMapService',
    'PersistenceModel',
    'Upload',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function InitiativeFormController(
    $state,
    $timeout,
    $translate,
    ApiInitiativeModel,
    InitiativeMapService,
    PersistenceModel,
    Upload,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this

    vm.sendInitiative = sendInitiative
    vm.addressChanged = addressChanged
    vm.uploadFile = uploadFile

    vm.files = []
    vm.apiKey = PersistenceModel.getAccessToken()
    vm.maxUploadedFilesError = false
    vm.numberOfUploadingFiles = 0
    vm.initiative = {
      reported: false,
      addressModified: true,
    }

    activate()

    function activate() {
      vm.data = {
        mapLayers: [],
        legend: [],
      }
      getInitiativeStateTypes()
      getInitiativeCategories()
      initMap()
    }

    function initMap() {
      InitiativeMapService.initInitiativeMapForSelect(vm.data, vm.initiative, true)
    }

    function getInitiativeStateTypes() {
      vm.stateTypes = ApiInitiativeModel.getInitiativeStateTypes({
        cultureCode: $translate.use(),
      })
    }

    function getInitiativeCategories() {
      vm.categories = ApiInitiativeModel.getInitiativeCategories({
        cultureCode: $translate.use(),
      })
    }

    function addressChanged() {
      vm.initiative.addressModified = true
    }

    function uploadFile(files, errFiles) {
      if (files.length > 3) {
        vm.maxUploadedFilesError = true
        return
      }
      vm.maxUploadedFilesError = false

      vm.files = files
      vm.errFiles = errFiles
      vm.numberOfUploadingFiles = files.length

      angular.forEach(vm.files, (file) => {
        file.uploading = true
        file.upload = Upload.upload({
          url: APP_CONFIG.apiRootUrl + 'admin/cdn/files/upload?public=true',
          headers: {
            'Content-Type': file.type,
            'X-Api-Key': vm.apiKey,
          },
          data: { file: file },
        })

        file.upload.then(
          (response) => {
            $timeout(() => {
              if (response.data.hasOwnProperty('fileInfo')) {
                var fileInfo = response.data.fileInfo
                fileInfo.url = APP_CONFIG.cdnUrl + fileInfo.id
                file.fileInfo = fileInfo
                file.uploading = false
                vm.numberOfUploadingFiles--
              } else {
                console.log('Returned data: ' + response.data)
              }
            })
          },
          (response) => {
            if (response.status > 0) {
              vm.errorMsg = response.status + ': ' + response.data
              file.uploading = false
              vm.numberOfUploadingFiles--
            }
          },
          (evt) => {
            file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
          },
        )
      })
    }

    function sendInitiative(initiative) {
      if (!vm.initiative.latitude || !vm.initiative.longitude) {
        FlashMessage.add('FRONT.INITIATIVE.FLASH_MESSAGE.CREATE_INITIATIVE_GPS_ERROR', 'danger')
        return
      }

      if (vm.files.length > 0) {
        initiative.attachments = vm.files.map((file) => {
          return file.fileInfo
        })
      }

      initiative.cultureCode = $translate.use()
      ApiInitiativeModel.createInitiative(
        { initiative: initiative },
        () => {
          FlashMessage.add('FRONT.INITIATIVE.FLASH_MESSAGE.CREATE_INITIATIVE', 'success', 1)
          $state.go('front.map.initiative', { reload: true })
        },
        () => {
          FlashMessage.add('FRONT.INITIATIVE.FLASH_MESSAGE.CREATE_INITIATIVE_ERROR', 'danger')
        },
      )
    }
  }
})()
