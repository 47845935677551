;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('policeSubType', PoliceSubTypeFilter)

  PoliceSubTypeFilter.$inject = []

  function PoliceSubTypeFilter() {
    return function (subType, groups) {
      angular.forEach(groups, function (group) {
        if (group.hasOwnProperty('subtypes')) {
          angular.forEach(group.subtypes, function (obj) {
            if (obj.id === subType) {
              subType = obj.name
            }
          })
        }
      })
      return subType
    }
  }
})()
