;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('eventType', EventTypeFilter)

  EventTypeFilter.$inject = []

  function EventTypeFilter() {
    var mapping = {
      // UNSPECIFIED: "help",
      // ACCIDENT: "crash",
      // LAND_FALL: "landslide",
      // FIRE: "fire",
      // TRAFFIC: "traffic",
      // FLOOD: "water",
      // ENVIRONMENTAL_ACCIDENT: "ecology",
      // BIOLOGICAL_ACCIDENT: "biology",
      // PYROTECHNIST_EVENT: "pyrotechnic",
      // WARNING: "exclamation",
      // PUBLIC_ORDER: "publicorder",
      // DANGEROUS_LEAKAGE: "leak",
      // EPIDEMIC_WARNING: "epidemy",
      // TRAINING: "exercise",
      // OTHERS: "other",
      CHMI_WARNING: 'ecology',
      NARCOTIC: 'narcotic',
      HOMELESS: 'homeless',
      PLANNED_EVENT: 'exercise',
      EXTRAORDINARY_EVENT: 'exclamation',
      OTHERS: 'other',
    }

    return function (type, funcName, types) {
      switch (funcName) {
        case 'iconClass':
          return mapIconClass(type)
        case 'typeValue':
          return mapType(type, types)
        default:
          return type
      }
    }

    function mapType(type, types) {
      for (var key in types) {
        if (types.hasOwnProperty(key)) {
          if (types[key].id === type) {
            return types[key].name
          }
        }
      }
      return type
    }

    function mapIconClass(type) {
      if (mapping.hasOwnProperty(type)) {
        type = mapping[_.toUpper(type)]
      }
      return type
    }
  }
})()
