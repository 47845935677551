;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('numberToText', NumberToTextFilter)

  NumberToTextFilter.$inject = []

  function NumberToTextFilter() {
    var me = this

    me.getValue = function (typeOfMessage, code) {
      var objs = NumberToText[typeOfMessage]
      var returnedValue = 'Neznámé'
      var i

      for (i = 0; i < objs.length; i++) {
        var obj = objs[i]
        if (obj.code === code) {
          returnedValue = obj.name
          break
        }
      }
      return returnedValue
    }

    me.barriertypeNumberToText = function (data) {
      var mapping = {
        0: 'Neznámé',
        1: 'Pevné PPO',
        2: 'Mobilní PPO',
        3: 'Pytle s pískem',
        4: 'Ostatní',
      }
      if (data in mapping) {
        return mapping[data]
      } else {
        return 'Neznámé'
      }
    }

    me.barrierkindNumberToText = function (data) {
      var mapping = {
        1: 'Zajišťovaná městem',
        2: 'Zajišťovaná individuálně',
      }
      if (data in mapping) {
        return mapping[data]
      } else {
        return 'Neznámé'
      }
    }

    me.barrierprotectionNumberToText = function (data) {
      var mapping = {
        2002: 'Ochrana navržená na Q2002',
        5: 'Ochrana navržená na Q5',
        10: 'Ochrana navržená na Q10',
        20: 'Ochrana navržená na Q20',
        50: 'Ochrana navržená na Q50',
        100: 'Ochrana navržená na Q100',
      }
      if (data in mapping) {
        return mapping[data]
      } else {
        return 'Neznámé'
      }
    }

    return function (data, typeOfMessage) {
      var methodName = typeOfMessage.toLowerCase() + 'NumberToText'
      if (me[methodName] !== undefined && me[methodName] instanceof Function) {
        return me[methodName](data)
      } else if (me[typeOfMessage] !== undefined) {
        return me.getValue(typeOfMessage, data)
      }
    }
  }
})()
