;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').component('cppSchools', {
    templateUrl: 'app/modules/front/map/components/schools/index.html',
    controller: SchoolsController,
  })

  SchoolsController.$inject = ['PersistenceModel', 'ApiMapModel']

  function SchoolsController(PersistenceModel, ApiMapModel) {
    const vm = this
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()

    activate()

    function activate() {
      if (vm.isUserLoggedIn) {
        getSchools()
      }
    }

    function getSchools() {
      vm.loadingData = true
      ApiMapModel.getSchools(
        (data) => {
          vm.syposInstitutions = data
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }
  }
})()
