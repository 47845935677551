;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('EventService', EventService)

  EventService.$inject = ['$filter', 'PersistenceModel']

  function EventService($filter, PersistenceModel) {
    var iskrAttributesMapping = {
      id: {
        mapTo: 'reportId',
        mapToSourceData: false,
      },
      severityLevel: {
        label: 'Závažnost',
      },
      eventType: {
        label: 'Typ',
      },
      eventTypeId: {
        mapTo: 'type',
        mapToSourceData: false,
      },
      eventName: {
        label: 'Název',
        mapTo: 'title', // mapTo rika, ze chci pridat atribut nejen do sourceData, ale take do event objektu pod danym nazvem - kvuli predvyplneni formulare
      },
      eventState: {
        label: 'Stav',
      },
      created: {
        label: 'Vytvořeno',
        format: 'dd.MM.yyyy HH:mm',
      },
      eventDescription: {
        label: 'Popis',
        mapTo: ['perex', 'description'],
      },
      locationDescription: {
        label: 'Lokalizace',
      },
      address: {
        label: 'Adresa',
      },
      cityPart: {
        mapTo: 'cityPart',
        mapToSourceData: false, // mapToSourceData rika, ze nechci pridat atribut do sourceData - typicky chci pridat jen do event objektu kvuli predvyplneni formulare
      },
      landRegistryNumber: {
        mapTo: 'landRegistryNumber',
        mapToSourceData: false,
      },
      houseNumber: {
        mapTo: 'houseNumber',
        mapToSourceData: false,
      },
      region: {
        mapTo: 'region',
        mapToSourceData: false,
      },
      cityDistrict: {
        mapTo: 'cityDistrict',
        mapToSourceData: false,
      },
      city: {
        mapTo: 'city',
        mapToSourceData: false,
      },
      administrativeDistrict: {
        mapTo: 'administrativeDistrict',
        mapToSourceData: false,
      },
      street: {
        mapTo: 'street',
        mapToSourceData: false,
      },
    }

    var eventsTypeMapping = {
      206: 'ACCIDENT',
      208: 'ACCIDENT',
      211: 'ACCIDENT',
      189: 'FIRE',
      191: 'FIRE',
      216: 'TRAFFIC',
      392: 'TRAFFIC',
      271: 'TRAFFIC',
      200: 'ENVIRONMENTAL_ACCIDENT',
      309: 'PYROTECHNIST_EVENT',
      164: 'WARNING',
      222: 'PUBLIC_ORDER',
      379: 'DANGEROUS_LEAKAGE',
      172: 'EPIDEMIC_WARNING',
      425: 'OTHERS',
    }

    function _getDistrictKeyByValue(districts, districtValue) {
      var district = _.find(districts, function (obj) {
        return obj.name === districtValue
      })
      if (district) {
        return [district.id]
      }
      return []
    }

    return {
      savePoliceSourceData: function (sourceData, districts) {
        var iskrData = {
          reportType: 'ISKR',
          event: {},
        }
        angular.forEach(iskrAttributesMapping, function (obj, attrName) {
          if (attrName === 'address') {
            obj.value = $filter('arrayToString')([
              sourceData.region,
              sourceData.city,
              sourceData.cityPart,
              sourceData.cityDistrict,
              sourceData.street,
              sourceData.houseNumber,
              sourceData.landRegistryNumber,
            ])
            iskrData[attrName] = obj
          } else {
            if (sourceData.hasOwnProperty(attrName)) {
              if (obj.hasOwnProperty('mapTo')) {
                if (attrName === 'eventTypeId') {
                  if (eventsTypeMapping.hasOwnProperty(sourceData[attrName])) {
                    iskrData.event['type'] = eventsTypeMapping[sourceData[attrName]]
                  } else {
                    iskrData.event['type'] = 'OTHERS'
                  }
                } else {
                  if (Array.isArray(obj.mapTo)) {
                    angular.forEach(obj.mapTo, function (value) {
                      iskrData.event[value] = sourceData[attrName]
                    })
                  } else {
                    if (obj.mapTo === 'administrativeDistrict') {
                      iskrData.event.publication = {
                        private: true,
                        emailToDistricts: _getDistrictKeyByValue(districts, sourceData[attrName]),
                      }
                    }
                    iskrData.event[obj.mapTo] = sourceData[attrName]
                  }
                }
              }
              if (!obj.hasOwnProperty('mapToSourceData')) {
                obj.value = sourceData[attrName]
                iskrData[attrName] = obj
              }
            }
          }
        })
        PersistenceModel.setSourceDataForEvent(iskrData)
      },
      saveFiremanSourceData: function (sourceData, districts) {},
      loadSourceDataForEvent: function () {
        var sourceData = PersistenceModel.getSourceDataForEvent()
        if (sourceData) {
          var reportType = sourceData.reportType
          var event = sourceData.event
          delete sourceData['reportType']
          delete sourceData['event']

          return angular.extend(event, {
            reportType: reportType,
            sourceData: sourceData,
          })
        }
        return {
          reportType: 'UNASSIGNED',
          sourceData: sourceData,
        }
      },
      clearSourceDataForEvent: function () {
        PersistenceModel.clearSourceDataForEvent()
      },
    }
  }
})()
