;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.services')
    .factory('ItemCollectionLayerProviderBase', ItemCollectionLayerProviderBase)

  ItemCollectionLayerProviderBase.$inject = []

  function ItemCollectionLayerProviderBase() {
    return {
      super: function (map) {
        this.layer = new L.markerClusterGroup({})
        this.map = map
        this.addedItems = []
        this.addedLayers = []
        this.currentItemsDict = []
      },

      setItemClickedFunc: function (itemClickedFunc) {
        this.itemClickedFunc = itemClickedFunc
      },

      update: function () {
        return null
      },

      getLayer: function () {
        return this.layer
      },

      getItemKey: function () {},

      createLayer: function () {
        return null
      },

      itemsEqual: function () {
        return false
      },

      processItems: function (items) {
        var me = this

        this.currentItemsDict = {}

        // remove items that were removed
        _.forEach(items, function (item) {
          var itemKey = me.getItemKey(item)
          me.currentItemsDict[itemKey] = item
        })

        var itemsToRemove = []
        this.addedItems.forEach(function (previousKey) {
          var currentItem = me.currentItemsDict[previousKey]
          if (angular.isUndefined(currentItem)) {
            itemsToRemove.push(previousKey)
          }
        })
        itemsToRemove.forEach(function (key) {
          me.removeLayer(key)
        })

        angular.forEach(this.currentItemsDict, function (currentItem, currentKey) {
          var previousItem = me.addedItems[currentKey]
          var layer = {}
          if (angular.isUndefined(previousItem)) {
            layer = me.createAndHookLayer(currentItem)
            me.addedItems[currentKey] = currentItem
            me.layer.addLayer(layer)
            me.addedLayers[currentKey] = layer
          } else if (!me.itemsEqual(currentItem, previousItem)) {
            me.removeLayer(currentKey)
            layer = me.createAndHookLayer(currentItem)
            me.addedItems[currentKey] = currentItem
            me.layer.addLayer(layer)
            me.addedLayers[currentKey] = layer
          }
        })
      },

      createAndHookLayer: function (currentItem) {
        var me = this
        var layer = this.createLayer(currentItem)
        if (layer instanceof L.FeatureGroup) {
          layer.on('contextmenu', function (event) {
            me.map.contextmenu.showAt(event.latlng, null)
          })
        }
        var layerObj = layer
        if (layerObj.on != null) {
          layerObj.on('click', function () {
            if (me.itemClickedFunc != null) {
              me.itemClickedFunc(currentItem, layer)
            }
          })
        }
        return layer
      },

      removeLayer: function (itemKey) {
        var layer = this.addedLayers[itemKey]
        if (layer) {
          this.layer.removeLayer(layer)
          delete this.addedItems[itemKey]
          delete this.addedLayers[itemKey]
        }
      },
    }
  }
})()
