;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('SafetyMapService', SafetyMapService)

  SafetyMapService.$inject = ['$rootScope', '$translate', 'leafletData', 'MapHelperService']

  function SafetyMapService($rootScope, $translate, leafletData, MapHelperService) {
    function _addIsInShapeFunctionForLeafletShapeTypes() {
      L.Polygon.include({
        isInShape: (latLng) => {
          return turf.inside(new L.Marker(latLng).toGeoJSON(), this.toGeoJSON())
        },
      })

      L.Circle.include({
        isInShape: (latLng) => {
          return this.getLatLng().distanceTo(latLng) < this.getRadius()
        },
      })
    }

    function _createCustomColorPickerButton() {
      L.Control.PickColor = L.Control.extend({
        options: {
          position: 'topright',
        },
        onAdd: (map) => {
          const controlDiv = L.DomUtil.create('div', 'leaflet-toolbar leaflet-bar')
          controlDiv.innerHTML =
            '<input type="color" class="sr-only" id="color" name="colorchoice" />'
          L.DomEvent
            //.addListener(controlDiv, 'click', L.DomEvent.stopPropagation)
            //.addListener(controlDiv, 'click', L.DomEvent.preventDefault)
            .addListener(controlDiv, 'click', () => {
              $('#color')[0].click()
            })

          const controlUI = L.DomUtil.create('a', 'leaflet-palette-icon', controlDiv)
          controlUI.title = $translate.instant(
            '_COMMON.SERVICE.MAPS.SERVICES.MAP_HELPER_SERVICE.edit.toolbar.buttons.color',
          )
          controlUI.href = '#'
          return controlDiv
        },
      })
      return new L.Control.PickColor()
    }

    function _createCustomOpacityButton() {
      L.Control.Opacity = L.Control.extend({
        options: {
          position: 'topright',
        },
        onAdd: (map) => {
          const controlDiv = L.DomUtil.create('div', 'leaflet-toolbar leaflet-bar')
          controlDiv.innerHTML =
            '<input id="opacity" type="range" min="0" max="1" value="0.2" step="0.1" style="display: none; height: 23px" />'

          const controlUI = L.DomUtil.create('a', 'leaflet-opacity-icon', controlDiv)
          controlUI.title = $translate.instant(
            '_COMMON.SERVICE.MAPS.SERVICES.MAP_HELPER_SERVICE.edit.toolbar.buttons.opacity',
          )
          controlUI.href = '#'

          L.DomEvent.addListener(controlDiv, 'click', () => {
            $('#opacity').toggle()
            $(controlUI).toggle()
          })

          // Disable dragging when user's cursor enters the element
          L.DomEvent.addListener(controlDiv, 'mouseover', () => {
            map.dragging.disable()
          })

          // Re-enable dragging when user's cursor leaves the element
          L.DomEvent.addListener(controlDiv, 'mouseout', () => {
            map.dragging.enable()
          })

          return controlDiv
        },
      })
      return new L.Control.Opacity()
    }

    function _getPopupContent(title, description) {
      return `<strong>${title}</strong><p>${description}</p>`
    }

    function _initPolygons(drawnItems, data) {
      angular.forEach(data, (polygonData) => {
        if (polygonData.latlngs && polygonData.latlngs.length > 0) {
          const polygon = new L.Polygon(L.GeoJSON.coordsToLatLngs(polygonData.latlngs), {
            color: polygonData.color,
            opacity: polygonData.opacity,
            fillOpacity: polygonData.opacity,
          })

          const content = _getPopupContent(polygonData.title, polygonData.description)
          polygon.bindPopup(content)

          drawnItems.addLayer(polygon)

          const polygonLeafletData = {
            id: polygon._leaflet_id,
            color: polygon.options.color,
            opacity: polygon.options.opacity,
            title: polygonData.title,
            description: polygonData.description,
            latlngs: polygon.toGeoJSON().geometry.coordinates[0],
          }
          $rootScope.$emit('polygon:initiated', polygonLeafletData)
        }
      })
    }

    function _createDrawnItemsLayer(map) {
      const drawnItems = new L.FeatureGroup()
      map.addLayer(drawnItems)
      return drawnItems
    }

    function _createDrawControl(drawnItems, map) {
      MapHelperService.prepareLealetDrawTranslations()
      _addIsInShapeFunctionForLeafletShapeTypes()

      const colorPickerControl = _createCustomColorPickerButton()
      const opacityControl = _createCustomOpacityButton()
      map.addControl(colorPickerControl)
      map.addControl(opacityControl)

      const optionColorSelected = $('#color').val()
      const opacity = +$('#opacity').val()

      var drawControl = new L.Control.Draw({
        position: 'topright',
        draw: {
          polygon: {
            showArea: true,
            showLength: true,
            shapeOptions: {
              color: optionColorSelected,
              opacity: opacity,
              fillOpacity: opacity,
            },
          },
          polyline: false,
          rectangle: false,
          marker: false,
          circle: false,
          /*circle: {
            showRadius: true,
            metric: true,
            feet: false,
            nautic: false,
            shapeOptions: {
              color: optionColorSelected,
              opacity: opacity,
              fillOpacity: opacity,
            },
          },*/
          circlemarker: false,
        },
        edit: {
          featureGroup: drawnItems,
        },
      }).addTo(map)

      $('#color, #opacity').change(() => {
        const optionColorSelected = $('#color').val()
        const opacity = +$('#opacity').val()

        drawControl.setDrawingOptions({
          polygon: {
            shapeOptions: {
              color: optionColorSelected,
              opacity: opacity,
              fillOpacity: opacity,
            },
          },
          /*
          circle: {
            shapeOptions: {
              color: optionColorSelected,
              opacity: opacity,
              fillOpacity: opacity,
            },
          },
          */
        })
      })

      $rootScope.$on('polygon:formEdited', (event, data) => {
        const layer = drawnItems.getLayers().find(({ _leaflet_id }) => _leaflet_id === data.id)
        if (layer) {
          const content = _getPopupContent(data.title, data.description)
          layer.bindPopup(content)

          layer.setStyle({
            color: data.color,
            opacity: data.opacity,
            fillOpacity: data.opacity,
          })
        }
      })

      map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer
        const title = 'Nadpis'
        const description = 'Popis.'

        const content = _getPopupContent(title, description)
        layer.bindPopup(content)

        drawnItems.addLayer(layer)

        const polygonData = {
          id: layer._leaflet_id,
          color: layer.options.color,
          opacity: layer.options.opacity,
          title,
          description,
          latlngs: layer.toGeoJSON().geometry.coordinates[0],
        }
        $rootScope.$emit('polygon:created', polygonData)
      })

      map.on(L.Draw.Event.EDITED, (e) => {
        e.layers.eachLayer((layer) => {
          const polygonData = {
            id: layer._leaflet_id,
            latlngs: layer.toGeoJSON().geometry.coordinates[0],
          }
          $rootScope.$emit('polygon:edited', polygonData)
        })
      })

      map.on(L.Draw.Event.DELETED, (e) => {
        e.layers.eachLayer((layer) => {
          $rootScope.$emit('polygon:deleted', { id: layer._leaflet_id })
        })
      })

      /*
      map.on(L.Draw.Event.EDITRESIZE, (e) => {
        e.layer._map._editTooltip.updateContent({
          subtext:
            L.drawLocal.draw.handlers.circle.radius +
            ': ' +
            L.GeometryUtil.readableDistance(
              e.layer._mRadius,
              drawControl.options.draw.circle.metric,
              drawControl.options.draw.circle.feet,
              drawControl.options.draw.circle.nautic,
            ),
          text: L.drawLocal.draw.handlers.simpleshape.tooltip.end,
        })
      })
      */

      return drawnItems
    }

    function initSafetyLayers(map, data) {
      const drawnItems = _createDrawnItemsLayer(map)

      if (data.length > 0) {
        _initPolygons(drawnItems, data)
      }
      return drawnItems
    }

    function initSafetyMap(data, request, enableDrawControl) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then((map) => {
        MapHelperService.initMap(map, data)

        request.$promise.then((polygons) => {
          // workaround: pokud se defaultni vrstva "Hranice spravnich obvodu" nacte pozdeji, nez polygony, tak zachytava click event a nad polygony se nezobrazuji popupy
          setTimeout(() => {
            const drawnItems = initSafetyLayers(map, polygons)

            if (enableDrawControl) {
              _createDrawControl(drawnItems, map)
            }
          }, 1000)
        })
      })
    }

    return {
      initSafetyMap: initSafetyMap,
      initSafetyLayers: initSafetyLayers,
    }
  }
})()
