;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.cms')
    .controller('DeleteArticleModalController', DeleteArticleModalController)

  DeleteArticleModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminArticleModel',
    'FlashMessage',
    'article',
    'cultureData',
  ]

  function DeleteArticleModalController(
    $uibModalInstance,
    ApiAdminArticleModel,
    FlashMessage,
    article,
    cultureData,
  ) {
    const vm = this
    vm.article = article
    vm.cultureData = cultureData

    vm.ok = ok
    vm.cancel = cancel    

    function ok() {
      var promise = ApiAdminArticleModel.deleteArticle(
        { cultureCode: cultureData.cultureCode, id: vm.article.id },
        function () {
          if (cultureData.isDefault) {
            FlashMessage.add('Článek ' + vm.article.title + ' byl úspěšně smazán.', 'success')
          } else {
            FlashMessage.add(
              'Překlad článku ' + vm.article.title + ' byl úspěšně smazán.',
              'success',
            )
          }
        },
      )
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
