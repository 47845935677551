;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.cms').controller('AdminCmsController', AdminCmsController)

  AdminCmsController.$inject = [
    '$http',
    '$state',
    '$stateParams',
    '$uibModal',
    'ApiAdminArticleModel',
    'APP_CONFIG',
    'BOOL_VALUES',
  ]

  function AdminCmsController(
    $http,
    $state,
    $stateParams,
    $uibModal,
    ApiAdminArticleModel,
    APP_CONFIG,
    BOOL_VALUES,
  ) {
    const vm = this
    vm.getArticles = getArticles
    vm.query = {
      parentId: null,
    }
    vm.boolValues = BOOL_VALUES
    vm.openDeactivateArticle = openDeactivateArticle

    activate()

    function activate() {
      getArticlesStructure()
      prepareTableHeaders()
    }

    function getArticlesStructure() {
      vm.loadingData = true
      vm.articlesStructure = ApiAdminArticleModel.getArticleTree(
        { cultureCode: APP_CONFIG.translation.fallbackLanguage },
        function (tree) {
          if (!$stateParams.id) {
            var childArticle = tree[0].childArticles[0]
            if (childArticle) {
              $state.go(
                'admin.cms',
                {
                  id: childArticle.draft ? childArticle.draft.id : childArticle.id,
                },
                { reload: true },
              )
            } else {
              vm.loadingData = false
            }
          } else {
            getArticles()
          }
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function getArticles() {
      ApiAdminArticleModel.getAllArticles(
        {
          cultureCode: APP_CONFIG.translation.fallbackLanguage,
          includeInactive: true,
        },
        function (articles) {
          vm.mainArticle =
            _.find(articles, ['id', $stateParams.id]) ||
            _.find(articles, ['draft.id', $stateParams.id])
          if (vm.mainArticle) {
            vm.childArticles = _.filter(articles, ['parentId', vm.mainArticle.id])
            if (vm.childArticles && vm.childArticles.length > 0 && !$stateParams.childId) {
              var firstChild = vm.childArticles[0]
              $state.go(
                'admin.cms',
                {
                  id: vm.mainArticle.draft ? vm.mainArticle.draft.id : vm.mainArticle.id,
                  childId: firstChild.draft ? firstChild.draft.id : firstChild.id,
                },
                { reload: true },
              )
            } else {
              vm.mainChildArticle =
                _.find(articles, ['id', $stateParams.childId]) ||
                _.find(articles, ['draft.id', $stateParams.childId])
              if (vm.mainChildArticle) {
                vm.subChildArticles = _.filter(articles, ['parentId', vm.mainChildArticle.id])
              }
              vm.loadingData = false
            }
          } else {
            vm.loadingData = false
          }
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/cms/meta/article-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function openDeactivateArticle(article) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/cms/modal/article/deactivateArticleModal.html',
        controller: 'DeactivateArticleModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          article: function () {
            return article
          },
          cultureData: function () {
            return {
              cultureCode: article.cultureCode,
              isDefault: true,
            }
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getArticles()
          })
        },
        function () {},
      )
    }
  }
})()
