;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cppChart', ChartDirective)

  ChartDirective.$inject = ['$translate', 'ApiSourceModel']

  function ChartDirective($translate, ApiSourceModel) {
    return {
      restrict: 'A',
      scope: {
        noiseStationId: '=',
      },
      link: function (scope, elem) {
        ApiSourceModel.getNoiseGraphData({ path1: scope.noiseStationId }, function (data) {
          new Chart(elem[0].getContext('2d'), {
            type: 'bar',
            data: {
              labels: prepareLabels(),
              datasets: [
                {
                  label: $translate.instant('FRONT.MAP.MODAL.DYNAMICS.STATE'),
                  borderWidth: 1,
                  type: 'bar',
                  backgroundColor: prepareColors(
                    data.allGraphData,
                    'rgba(140, 0, 0, 0.8)',
                    'rgba(40, 113, 40, 0.8)',
                  ),
                  borderColor: prepareColors(
                    data.allGraphData,
                    'rgb(153, 0, 0)',
                    'rgb(0, 102, 51)',
                  ),
                  data: data.allGraphData,
                },
                // {
                //     label: $translate.instant('FRONT.MAP.MODAL.DYNAMICS.MIN_GRAPH_LABEL'),
                //     borderWidth: 1,
                //     type: 'bar',
                //     data: data.minDayGraphData
                // },
                // {
                //     label: $translate.instant('FRONT.MAP.MODAL.DYNAMICS.MAX_GRAPH_LABEL'),
                //     borderWidth: 1,
                //     type: 'bar',
                //     data: data.maxDayGraphData
                // },
                // {
                //     label: $translate.instant('FRONT.MAP.MODAL.DYNAMICS.MEAN_GRAPH_LABEL'),
                //     borderWidth: 3,
                //     hoverBackgroundColor: "rgba(255,99,132,0.4)",
                //     hoverBorderColor: "rgba(255,99,132,1)",
                //     type: 'line',
                //     data: data.averageDayGraphData
                // }
              ],
            },
            options: {
              legend: {
                display: false,
              },
              scales: {
                y: {
                  max: 1,
                  min: -1,
                },
              },
            },
          })
        })
      },
    }
  }

  function prepareLabels() {
    var labels = []
    // for (var i = 6; i >= 0; i--) {
    //     labels.push(_.capitalize(moment().subtract(i, "d").format('dddd')));
    // }
    for (var i = 12; i > 0; i--) {
      labels.push(moment().subtract(i, 'h').format('HH') + 'h')
    }
    return labels
  }

  function prepareColors(data, lowColor, highColor) {
    var colors = []
    angular.forEach(data, function (value) {
      if (value >= 0) {
        colors.push(highColor)
      } else {
        colors.push(lowColor)
      }
    })
    return colors
  }
})()
