;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.services')
    .factory('DynamicDataSourceMapService', DynamicDataSourceMapService)

  DynamicDataSourceMapService.$inject = [
    '$rootScope',
    '$translate',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'ApiMapModel',
    'DynamicDataSourceLayerProvider',
    'CustomLayer',
  ]

  function DynamicDataSourceMapService(
    $rootScope,
    $translate,
    leafletData,
    MapHelperService,
    MapDialogService,
    ApiMapModel,
    DynamicDataSourceLayerProvider,
    CustomLayer,
  ) {
    var mapLayerConfigs = []
    var layerProviders = []

    $rootScope.$on('mapLayerSwitched', function (event, data) {
      if (layerProviders.length > 0) {
        angular.forEach(mapLayerConfigs, function (mapLayerConfig) {
          if (mapLayerConfig.key === data.legend.key) {
            mapLayerConfig.visible = data.legend.visible
          }
        })
      }
    })

    function _getBaseLayerConfig(layerOptions, order) {
      layerOptions.icon = layerOptions.icon.toLowerCase()

      if (layerOptions.source === 'NOISE') {
        return {
          url: '',
          key: layerOptions.id,
          visible: true,
          color: layerOptions.icon,
          legendOptions: {
            label: layerOptions.name,
            images: ['noise_' + layerOptions.icon + '.png'],
            visible: true,
            display: true,
            order: order,
          },
        }
      }
      return {
        url: '',
        key: layerOptions.id,
        visible: true,
        color: layerOptions.icon,
        fillColor: layerOptions.icon,
        legendOptions: {
          label: layerOptions.name,
          colors: [layerOptions.icon],
          visible: true,
          display: true,
          order: order,
        },
      }
    }

    function _createDynamicDataSourceLayerProvider(
      map,
      endpointKey,
      layerConfig,
      dateFrom,
      dateTo,
    ) {
      var dynamicDataSourceLayerProvider = new DynamicDataSourceLayerProvider(
        map,
        endpointKey,
        layerConfig,
        dateFrom,
        dateTo,
      )
      dynamicDataSourceLayerProvider.setItemClickedFunc(function (item) {
        MapDialogService.openDialogDynamics(
          angular.extend(item, { iconColor: layerConfig.color }),
          map,
          {
            lat: item.latitude,
            lon: item.longitude,
          },
          $rootScope.$new(),
          item.source,
        )
      })

      dynamicDataSourceLayerProvider.update()
      return dynamicDataSourceLayerProvider
    }

    function _createDynamicDataSourceCustomLayer(
      DynamicDataSourceLayerProvider,
      layerCollection,
      layerConfig,
    ) {
      var customLayer = new CustomLayer(
        angular.extend(layerConfig, {
          layer: DynamicDataSourceLayerProvider.getLayer(),
        }),
      )
      MapHelperService.addLayer(customLayer, layerCollection)
    }

    function _initDynamicDataSourceLayer(map, layerCollection, endpointKey) {
      ApiMapModel.getLayersForMap(
        { id: endpointKey, cultureCode: $translate.use() },
        function (data) {
          angular.forEach(data, function (layerOptions, key) {
            var baseLayerConfig = _getBaseLayerConfig(layerOptions, key)
            var dynamicDataSourceLayerProvider = _createDynamicDataSourceLayerProvider(
              map,
              endpointKey,
              baseLayerConfig,
            )
            _createDynamicDataSourceCustomLayer(
              dynamicDataSourceLayerProvider,
              layerCollection,
              baseLayerConfig,
            )
            mapLayerConfigs.push(baseLayerConfig)
            layerProviders.push(dynamicDataSourceLayerProvider)
          })
        },
      )
    }

    function initDynamicDataSourceLayers(map, layerCollection, endpointKey) {
      if (endpointKey) {
        _initDynamicDataSourceLayer(map, layerCollection, endpointKey)
      } else {
        ApiMapModel.getAllDynamicMaps(function (mapOptions) {
          angular.forEach(mapOptions, function (mapOption) {
            _initDynamicDataSourceLayer(map, layerCollection, mapOption.id)
          })
        })
      }
    }

    function initDynamicDataSourceMap(data, endpointKey) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initDynamicDataSourceLayers(map, layerCollection, endpointKey)
      })
    }

    return {
      initDynamicDataSourceMap: initDynamicDataSourceMap,
      initDynamicDataSourceLayers: initDynamicDataSourceLayers,
    }
  }
})()
