;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapProviderService', MapProviderService)

  MapProviderService.$inject = [
    '$translate',
    'NewsMapService',
    'CadastreMapService',
    'DynamicDataSourceMapService',
    'FloodsMapService',
    'HzsMapService',
    'InitiativeMapService',
    'IzsMapService',
    'RestaurantMapService',
    'SafetyMapService',
    'SchoolMapService',
    'TrafficMapService',
  ]

  function MapProviderService(
    $translate,
    NewsMapService,
    CadastreMapService,
    DynamicDataSourceMapService,
    FloodsMapService,
    HzsMapService,
    InitiativeMapService,
    IzsMapService,
    RestaurantMapService,
    SafetyMapService,
    SchoolMapService,
    TrafficMapService,
  ) {
    function getProviders() {
      return {
        NewsMapService: {
          func: NewsMapService.initNewsMap,
          label: $translate.instant('FRONT.MAP.MENU.AIR'),
          showInCms: true,
        },
        CadastreMapService: {
          func: CadastreMapService.initCadastreMap,
          label: $translate.instant('FRONT.MAP.MENU.CADASTRE'),
          showInCms: true,
        },
        CriminalityMapService: {
          func: DynamicDataSourceMapService.initDynamicDataSourceMap,
          param: 'criminality',
          label: $translate.instant('FRONT.MAP.MENU.CRIMINALITY'),
          showInCms: true,
        },
        FloodsMapService: {
          func: FloodsMapService.initFloodsMap,
          label: $translate.instant('FRONT.MAP.MENU.FLOODS'),
          showInCms: true,
        },
        HzsMapService: {
          func: HzsMapService.initHzsMap,
          label: $translate.instant('FRONT.MAP.MENU.HZS'),
          showInCms: true,
        },
        InitiativeMapService: {
          func: InitiativeMapService.initInitiativeMap,
          label: $translate.instant('FRONT.MAP.MENU.INITIATIVES'),
          showInCms: true,
        },
        IzsMapService: {
          func: IzsMapService.initIzsMap,
          label: $translate.instant('FRONT.MAP.MENU.ISZ'),
          showInCms: true,
        },
        NoiseMapService: {
          func: DynamicDataSourceMapService.initDynamicDataSourceMap,
          param: 'noise',
          label: $translate.instant('FRONT.MAP.MENU.NOISE'),
          showInCms: true,
        },
        RestaurantMapService: {
          func: RestaurantMapService.initRestaurantMap,
          label: $translate.instant('FRONT.MAP.MENU.PUB'),
          showInCms: true,
        },
        SafetyMapService: {
          func: SafetyMapService.initSafetyMap,
          label: $translate.instant('FRONT.MAP.MENU.SAFETY'),
          showInCms: true,
        },
        SchoolMapService: {
          func: SchoolMapService.initSchoolMap,
          label: $translate.instant('FRONT.MAP.MENU.SCHOOL'),
          showInCms: true,
        },
        TrafficMapService: {
          func: TrafficMapService.initTrafficMap,
          label: $translate.instant('FRONT.MAP.MENU.TRAFFIC'),
          showInCms: true,
        },
      }
    }

    function provide(key, data) {
      if (key) {
        const provider = getProviders()[key]
        provider.func(data, provider.param)
      }
    }

    return {
      getProviders: getProviders,
      provide: provide,
    }
  }
})()
