;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.user').controller('EditUserModalController', EditUserModalController)

  EditUserModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminUserModel',
    'FlashMessage',
    'user',
    'roles',
    'districts',
  ]

  function EditUserModalController(
    $uibModalInstance,
    ApiAdminUserModel,
    FlashMessage,
    user,
    roles,
    districts,
  ) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.user = user
    vm.roles = roles
    vm.districts = districts

    function ok() {
      var promise = ApiAdminUserModel.editUser({ id: vm.user.id }, { user: vm.user }, function () {
        FlashMessage.add('Uživatelův účet ' + vm.user.email + ' byl úspěšně editován.', 'success')
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
