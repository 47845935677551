;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('CadastreMapModalController', CadastreMapModalController)

  CadastreMapModalController.$inject = [
    '$window',
    'MapDialogService',
    'ApiMapModel',
    'PersistenceModel',
  ]

  function CadastreMapModalController($window, MapDialogService, ApiMapModel, PersistenceModel) {
    const vm = this

    vm.cadastreClickThrough = cadastreClickThrough
    vm.getParcelPdf = getParcelPdf
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()

    activate()

    function activate() {
      vm.data = {
        isPeasant: true,
        parcelData: MapDialogService.getPopupData(),
      }
      if (vm.isUserLoggedIn) {
        getParcelInfo()
      }
    }

    function cadastreClickThrough() {
      $window.open(
        `https://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?typ=parcela&id=${vm.data.parcelData.PAR_ID}`,
        '_blank',
      )
    }

    function getParcelPdf() {
      vm.loadingPdf = true
      ApiMapModel.getParcelPdf(
        { id1: vm.data.parcelData.PAR_ID },
        () => {
          vm.loadingPdf = false
        },
        () => {
          vm.loadingPdf = false
        },
      )
    }

    function getParcelInfo() {
      vm.loadingParcelInfo = true
      vm.data.parcelDetailInfo = ApiMapModel.getParcelInfo(
        { id1: vm.data.parcelData.PAR_ID },
        () => {
          vm.loadingParcelInfo = false
        },
        () => {
          vm.loadingParcelInfo = false
        },
      )
    }
  }
})()
