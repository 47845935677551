;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpDatepicker', DatePicker)

  DatePicker.$inject = []

  function DatePicker() {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        format: '@',
        options: '=?',
        enableDate: '=?',
      },
      replace: true,
      templateUrl: 'app/modules/_common/directives/datepicker.html',
    }
  }
})()
