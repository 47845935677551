;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('ChangeStateModalController', ChangeStateModalController)

  ChangeStateModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminInitiativeModel',
    'FlashMessage',
    'initiative',
  ]

  function ChangeStateModalController(
    $uibModalInstance,
    ApiAdminInitiativeModel,
    FlashMessage,
    initiative,
  ) {
    const vm = this

    vm.ok = ok
    vm.cancel = cancel
    vm.initiative = initiative
    

    function ok() {
      var promise
      if (vm.initiative.active) {
        promise = ApiAdminInitiativeModel.disableInitiative({ id: vm.initiative.id }, function () {
          FlashMessage.add('Oznámení bylo úspěšně skryto.', 'success')
        })
      } else {
        promise = ApiAdminInitiativeModel.enableInitiative({ id: vm.initiative.id }, function () {
          FlashMessage.add('Oznámení bylo úspěšně zveřejněno.', 'success')
        })
      }
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
