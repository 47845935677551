;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('SyposLayerProvider', SyposLayerProvider)

  SyposLayerProvider.$inject = [
    'ItemCollectionLayerProviderBase',
    'ApiMapModel',
    'MapHelperService',
  ]

  function SyposLayerProvider(ItemCollectionLayerProviderBase, ApiMapModel, MapHelperService) {
    return function (map) {
      var child = Object.create(ItemCollectionLayerProviderBase)
      child.super(map)

      child.init = function () {}

      child.update = function update() {
        const request = ApiMapModel.getSchools(function (institutes) {
          child.processItems(institutes)
        })
        return request.$promise
      }

      child.createLayer = function createLayer(item) {
        const markerOptions = {
          icon: MapHelperService.createIcon('/styles/img/edu-icon32.png'),
        }
        const latlng = {
          lat: item.position.latitude,
          lon: item.position.longitude,
        }
        angular.extend(item, { coordinates: latlng })

        const marker = new L.marker(latlng, markerOptions)
        marker.syposId = item.id
        return marker
      }

      child.getItemKey = function getItemKey(item) {
        return item.id.toString()
      }

      child.itemsEqual = function itemsEqual(item1, item2) {
        return item1.id === item2.id
      }

      child.init()
      return child
    }
  }
})()
