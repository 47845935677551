;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cdnImage', ImageCdnDirective)

  ImageCdnDirective.$inject = ['$sce', 'APP_CONFIG']

  function ImageCdnDirective($sce, APP_CONFIG) {
    return {
      restrict: 'A',
      scope: {
        cdnId: '@',
      },
      replace: true,
      link: function (scope, element, attr) {
        const url = APP_CONFIG.apiRootUrl + '/cdn/files/' + scope.cdnId
        const src = $sce.getTrustedMediaUrl(url)
        attr.$set('src', src)
      },
    }
  }
})()
