;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.source').controller('AdminPoliceController', AdminPoliceController)

  AdminPoliceController.$inject = [
    '$http',
    '$state',
    '$uibModal',
    'QueryFilterService',
    'ApiAdminSourceModel',
    'ApiSourceModel',
    'ApiUserModel',
    'EventService',
  ]

  function AdminPoliceController(
    $http,
    $state,
    $uibModal,
    QueryFilterService,
    ApiAdminSourceModel,
    ApiSourceModel,
    ApiUserModel,
    EventService,
  ) {
    var vm = this
    var districts = []

    vm.filterData = filterData
    vm.getPoliceEntries = getPoliceEntries
    vm.publish = publish
    vm.openUploadPoliceCsv = openUploadPoliceCsv

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getPoliceEntries()
      getEnums()
      getDistricts()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = 'incomeDate'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/sources/meta/police-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getPoliceEntries() {
      vm.loadingData = true
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      ApiAdminSourceModel.getSearchedPoliceEntries(
        {},
        search,
        function (entries) {
          vm.policeEntries = entries.data
          vm.totalCount = entries.totalCount
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function filterData() {
      vm.currentPage = 1
      getPoliceEntries()
    }

    function getEnums() {
      vm.policeGroups = ApiSourceModel.getPoliceGroups()
      vm.policeTypes = ApiSourceModel.getPoliceTypes()
      vm.policePriorityTypes = ApiSourceModel.getPolicePriorityTypes()
      vm.policeStateTypes = ApiSourceModel.getPoliceStateTypes()
    }

    function getDistricts() {
      districts = ApiUserModel.getAllDistricts()
    }

    function openUploadPoliceCsv() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/uploadPoliceCsvModal.html',
        controller: 'UploadPoliceCsvModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function () {
          getPoliceEntries()
        },
        function () {},
      )
    }

    function publish(policeEntry) {
      EventService.savePoliceSourceData(policeEntry, districts)
      $state.go('admin.event-create', {}, { reload: true })
    }
  }
})()
