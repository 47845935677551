;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.layout')
    .controller('InsertMapModalController', InsertMapModalController)

  InsertMapModalController.$inject = ['$uibModalInstance', 'MapProviderService']

  function InsertMapModalController($uibModalInstance, MapProviderService) {
    const vm = this

    vm.ok = ok
    vm.cancel = cancel

    activate()

    function activate() {
      vm.mapProviders = Object.entries(MapProviderService.getProviders())
        .filter(([, settings]) => settings.showInCms)
        .map(([mapProviderService, settings]) => ({
          key: mapProviderService,
          label: settings.label,
        }))
      vm.selectedMap = vm.mapProviders[0].key
    }

    function ok() {
      $uibModalInstance.close(vm.selectedMap)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
