;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.source').controller('DeleteModalController', DeleteModalController)

  DeleteModalController.$inject = ['$uibModalInstance', 'FlashMessage', 'data']

  function DeleteModalController($uibModalInstance, FlashMessage, data) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.data = data

    function ok() {
      $uibModalInstance.close(true)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
