;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('eventImage', EventImageFilter)

  EventImageFilter.$inject = []

  function EventImageFilter() {
    return function (event, privateAccess) {
      var imageName = ''

      if (event.hasOwnProperty('groupType')) {
        switch (_.toUpper(event.groupType)) {
          case 'SAFETY_ACTUALITY':
            imageName = privateAccess ? 'privatni_planovana_udalost.png' : 'planovana_udalost.png'
            break
          case 'OSKS_ACTUALITY':
          case 'WARNING':
            imageName = privateAccess ? 'privatni_udalost.png' : 'udalost.png'
            break
        }
      }

      return 'styles/img/' + imageName
    }
  }
})()
