;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.filters')
    .filter('riverStationNameToPositionFilter', RiverStationNameToPositionFilter)

  RiverStationNameToPositionFilter.$inject = []

  function RiverStationNameToPositionFilter() {
    return function (input) {
      switch (input) {
        case 'Zbečno':
          return [50.04055, 13.9199, 307229]
        case 'Beroun':
          return [49.95917, 14.08917, 307284]
        case 'Srbsko':
          return [49.93807, 14.13111, 10045026]
        case 'Praha - Chuchle':
          return [50.02722, 14.39861, 307225]
        case 'Praha - Karlův most':
          return [50.08639, 14.41361, 29183269]
        case 'Praha - Na Františku':
          return [50.09336, 14.42194, 307287]
        default:
          return [0, 0]
      }
    }
  }
})()
