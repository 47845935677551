;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiDocumentModel', ApiDocumentModel)

  ApiDocumentModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiDocumentModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'documents/:id/:path/:id1',
      { id: '@id', path: '@path', id1: '@id1' },
      {
        getFolder: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('folder')) {
              return data.folder
            }
            return data
          },
        },
        getStructure: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('documents')) {
              return [data.documents]
            }
            return data
          },
        },
      },
    )
  }
})()
