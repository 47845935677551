;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('InitiativeMapService', InitiativeMapService)

  InitiativeMapService.$inject = [
    '$q',
    '$rootScope',
    '$filter',
    '$stateParams',
    '$translate',
    'leafletData',
    'ApiInitiativeModel',
    'MapHelperService',
    'MapDialogService',
    'InitiativeLayerProvider',
    'CustomLayer',
    'GeometryHelper',
  ]

  function InitiativeMapService(
    $q,
    $rootScope,
    $filter,
    $stateParams,
    $translate,
    leafletData,
    ApiInitiativeModel,
    MapHelperService,
    MapDialogService,
    InitiativeLayerProvider,
    CustomLayer,
    GeometryHelper,
  ) {
    var tmpOrder = 100 // starting order

    var map = null
    var marker = {
      instance: null,
      isFromSearch: false,
    }

    function _layerFromCategory(category) {
      return {
        url: '',
        key: 'initiatives-' + category.id,
        categoryId: category.id,
        mapImage: category.logo,
        visible: true,
        legendOptions: {
          absoluteSrc: true,
          label: category.name,
          images: [category.logo],
          visible: true,
          display: true,
          order: tmpOrder++,
        },
      }
    }

    function _createInitiativeLayerProvider(map, categoryId, image) {
      var initiativeLayerProvider = new InitiativeLayerProvider.fromCategory(map, categoryId, image)
      initiativeLayerProvider.setItemClickedFunc(function (initiative) {
        MapDialogService.openDialogInitiatives(
          angular.extend(initiative, { image: image }),
          map,
          { lat: initiative.latitude, lon: initiative.longitude },
          $rootScope.$new(),
        )
      })

      var deferred = $q.defer()
      const promise = initiativeLayerProvider.update()
      promise.$promise.then(() => {
        deferred.resolve(initiativeLayerProvider)
      })
      return deferred.promise
    }

    function _createInitiativeCustomLayer(initiativeLayerProvider, layerCollection, layer) {
      var customLayer = new CustomLayer(
        angular.extend(layer, {
          layer: initiativeLayerProvider.getLayer(),
        }),
      )
      MapHelperService.addLayer(customLayer, layerCollection)
      _handlePopupOpen(initiativeLayerProvider.getLayer())
    }

    function _handlePopupOpen(initiativeLayerProvider) {
      const initiativeId = $stateParams.initiativeId
      initiativeLayerProvider.eachLayer((marker) => {
        if (initiativeId && marker.initiativeId.toString() === initiativeId.toString()) {
          marker.fire('click')
        }
      })
    }

    function _setInitiativePosition(marker, initiative) {
      var position = marker.getLatLng()
      initiative.longitude = position.lng
      initiative.latitude = position.lat

      initiative.street = null
      initiative.houseNumber = null
      initiative.orientationNumber = null
    }

    function _setMarker(latlng) {
      if (marker.isFromSearch) {
        marker.instance.clearLayers()
        marker.instance = null
        marker.isFromSearch = false
      }

      if (marker.instance === null) {
        marker.instance = new L.Marker(latlng, {
          draggable: true,
          icon: new L.Icon({
            iconUrl: 'styles/img/location-pin.png',
            shadowUrl: 'styles/img/marker-shadow.png',
            iconAnchor: new L.Point(16, 32),
            shadowAnchor: new L.Point(14, 40),
          }),
        })
        marker.instance.addTo(map)
      } else {
        marker.instance.setLatLng(latlng)
      }
      map.panTo(latlng)
    }

    function _checkMapBoundaries(latLng) {
      return (
        latLng.lat < 50.26064617465902 &&
        latLng.lat > 49.87128496751144 &&
        latLng.lng < 14.793261778143691 &&
        latLng.lng > 14.066956151847052
      )
    }

    function _addStatus(message) {
      console.log('GPS location: ', message)
    }

    function _onAccuratePositionError(e) {
      _addStatus(e.message)
    }

    function _onAccuratePositionProgress(e) {
      _addStatus('Progressing … (Accuracy: ' + e.accuracy + ')')
    }

    function _onAccuratePositionFound(e) {
      if (!_checkMapBoundaries(e.latlng)) {
        _addStatus('Position out of boundaries')
        return false
      }
      _addStatus('Most accurate position found (Accuracy: ' + e.accuracy + ')')
      _setMarker(e.latlng)
      return true
    }

    function initInitiativeLayers(map, layerCollection) {
      var layersConfig = []

      ApiInitiativeModel.getInitiativeCategories(
        { cultureCode: $translate.use() },
        function (categories) {
          layersConfig = categories.map(function (category) {
            return _layerFromCategory(category)
          })

          angular.forEach(layersConfig, function (layer) {
            var initiativeLayerProviderPromise = _createInitiativeLayerProvider(
              map,
              layer.categoryId,
              layer.mapImage,
            )
            initiativeLayerProviderPromise.then((initiativeLayerProvider) =>
              _createInitiativeCustomLayer(initiativeLayerProvider, layerCollection, layer),
            )
          })
        },
      )
    }

    function initInitiativeMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (frontMap) {
        var layerCollection = MapHelperService.initMap(frontMap, data)
        data.bounds = GeometryHelper.convertToBoundingBox(frontMap.getBounds())

        initInitiativeLayers(frontMap, layerCollection)
      })
    }

    function initInitiativeMapForSelect(data, initiative, autoLocate) {
      marker.instance = null
      map = null
      MapHelperService.initViewModelMapData(data, 'initiativesmap')

      leafletData.getMap('map').then(function (m) {
        map = m
        var layerCollection = MapHelperService.initMap(map, data)
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        initInitiativeLayers(map, layerCollection)

        if (initiative.longitude && initiative.latitude) {
          var latlng = new L.LatLng(initiative.latitude, initiative.longitude)
          _setMarker(latlng)
        }

        if (autoLocate === true) {
          map.on('accuratepositionerror', _onAccuratePositionError)
          map.on('accuratepositionprogress', _onAccuratePositionProgress)
          map.on('accuratepositionfound', function (e) {
            if (_onAccuratePositionFound(e)) {
              initiative.addressModified = true
              _setInitiativePosition(marker.instance, initiative)
            }
          })

          map.findAccuratePosition({
            maxWait: 1000,
            desiredAccuracy: 20,
          })
        }

        map.on('click', function (e) {
          initiative.addressModified = true
          _setMarker(e.latlng)
          _setInitiativePosition(marker.instance, initiative)

          marker.instance.on('dragend', function (evt) {
            initiative.addressModified = true
            var position = evt.target.getLatLng()
            _setMarker(new L.LatLng(position.lat, position.lng))
            _setInitiativePosition(marker.instance, initiative)
          })
        })

        $rootScope.$on('mapAddressSearched', function (event, address, searchGroupLayer) {
          if (marker.instance) {
            marker.instance.remove()
          }
          marker.instance = searchGroupLayer
          marker.isFromSearch = true
          if (!address.isStreetModel) {
            initiative.street = address.nazevUlice
            initiative.houseNumber = address.cisloDomovni
            initiative.orientationNumber = address.cisloOrientacni
            initiative.latitude = address.geometry.coordinates[1]
            initiative.longitude = address.geometry.coordinates[0]
          } else {
            initiative.street = address.mkn
          }
          initiative.address = $filter('address')(initiative)
          initiative.addressModified = false
        })
      })
    }

    function initInitiativeMapForSingleInitiative(data, initiative) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (frontMap) {
        var layerCollection = MapHelperService.initMap(frontMap, data)
        data.bounds = GeometryHelper.convertToBoundingBox(frontMap.getBounds())

        var layerConfig = {
          url: '',
          key: 'initiatives-detail',
          mapImage: 'styles/img/location-pin.png',
          visible: true,
        }

        var initiativeLayerProvider = new InitiativeLayerProvider.fromSingleInitiative(
          frontMap,
          initiative,
          layerConfig.mapImage,
        )
        initiativeLayerProvider.update()
        frontMap.panTo(new L.LatLng(initiative.latitude, initiative.longitude))
        _createInitiativeCustomLayer(initiativeLayerProvider, layerCollection, layerConfig)
      })
    }

    return {
      initInitiativeMap: initInitiativeMap,
      initInitiativeMapForSelect: initInitiativeMapForSelect,
      initInitiativeMapForSingleInitiative: initInitiativeMapForSingleInitiative,
      initInitiativeLayers: initInitiativeLayers,
    }
  }
})()
