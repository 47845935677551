;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.user')
    .controller('DeactivateUserModalController', DeactivateUserModalController)

  DeactivateUserModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminUserModel',
    'FlashMessage',
    'user',
  ]

  function DeactivateUserModalController($uibModalInstance, ApiAdminUserModel, FlashMessage, user) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.user = user
    

    function ok() {
      vm.user.active = !vm.user.active
      var promise = ApiAdminUserModel.editUser({ id: vm.user.id }, { user: vm.user }, function () {
        FlashMessage.add(
          'Uživatelův účet ' +
            vm.user.email +
            ' byl úspěšně ' +
            (!vm.user.active ? 'de' : '') +
            'aktivován.',
          'success',
        )
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
