;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('QueryFilterService', QueryFilterService)

  QueryFilterService.$inject = []

  function QueryFilterService() {
    function _getAllValidPathsRecursion(paths, obj, currentPath) {
      if (!angular.isObject(obj) || _.isArray(obj)) {
        paths.push(currentPath)
        return
      }

      var keys = Object.keys(obj)
      if (keys.length === 0 || keys[0].startsWith('value')) {
        paths.push(currentPath)
        return
      }

      for (var key in keys) {
        _getAllValidPathsRecursion(paths, obj[keys[key]], currentPath + '.' + keys[key])
      }
    }

    function getAllValidPaths(obj) {
      var paths = []

      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          _getAllValidPathsRecursion(paths, obj[key], key)
        }
      }

      return paths
    }

    var processTypes = {
      string: function (valueObj) {
        return valueObj !== '' ? { value: valueObj } : null
      },
      number: function (valueObj) {
        return valueObj || valueObj === 0 ? { numericValue: Number(valueObj) } : null
      },
      bool: function (valueObj) {
        return { boolValue: Boolean(valueObj) }
      },
      range: function (valueObj) {
        // expects valueFrom and/or valueTo
        var empty = true
        angular.forEach(valueObj, function (obj) {
          if (obj) {
            empty = false
          }
        })
        return !empty ? valueObj : null
      },
      enum: function (valueObj) {
        return valueObj.length > 0 ? { enumValue: valueObj } : null
      },
      counter: function (valueObj) {
        return valueObj.length > 0 ? { counterValues: valueObj } : null
      },
      boolMultiple: function (valueObj) {
        return valueObj.length === 1 ? processTypes.bool(valueObj[0]) : null
      },
    }

    return {
      transformSearchQueryAttribute: function (query) {
        var search = []

        angular.forEach(query, function (typeObj, typeKey) {
          var processType = processTypes[typeKey]
          if (!processType) {
            console.warn('[QueryFilterService] cannot process type:', typeKey)
            return
          }
          var typeObjPaths = getAllValidPaths(typeObj)

          typeObjPaths.forEach(function (path) {
            var searchObj = { column: path }
            var value = processType(_.get(typeObj, path))
            if (value) {
              search.push(angular.extend(searchObj, value))
            }
          })
        })

        return search
      },

      getSearchObject: function (pageSize, currentPage, orderByField, reverseSort, query) {
        return {
          limit: pageSize,
          offset: (currentPage - 1) * pageSize,
          orderBy: orderByField,
          desc: reverseSort,
          search: this.transformSearchQueryAttribute(query),
        }
      },
    }
  }
})()
