;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapSettings', MapSettings)

  MapSettings.$inject = ['APP_CONFIG']

  function MapSettings(APP_CONFIG) {
    return function mapSettings(defaultZoom, defaultLatitude, defaultLongitude) {
      this.defaultZoom = defaultZoom
      this.defaultLatitude = defaultLatitude
      this.defaultLongitude = defaultLongitude
      this.center = APP_CONFIG.mapSettings.defaultMapCenter

      this.getDefaultZoom = function getDefaultZoom() {
        return this.defaultZoom ? this.defaultZoom : this.center.zoom
      }

      this.getDefaultLatitude = function () {
        return this.defaultLatitude ? this.defaultLatitude : this.center.lat
      }

      this.getDefaultLongitude = function () {
        return this.defaultLongitude ? this.defaultLongitude : this.center.lng
      }
    }
  }
})()
