;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.front.map')
    .controller('CameraImageDetailController', CameraImageDetailController)

  CameraImageDetailController.$inject = [
    '$stateParams',
    '$scope',
    '$translate',
    '$interval',
    '$timeout',
    'ApiCameraModel',
  ]

  function CameraImageDetailController(
    $stateParams,
    $scope,
    $translate,
    $interval,
    $timeout,
    ApiCameraModel,
  ) {
    const vm = this
    vm.data = {
      autoRefreshEnabled: false,
      refreshRate: 30000,
    }
    vm.camera = {}

    vm.changeAutoRefresh = changeAutoRefresh

    var reloadTimer = null
    var countdownTimer = null
    var reloadEnabled = true

    activate()

    function activate() {
      getCamera()
      reloadImage()
    }

    function reloadImage() {
      vm.data.imageLoadedTime = new Date().getTime()
      ApiCameraModel.getImage({ id1: $stateParams.id }, function (data) {
        vm.camera.dataUrl = data.contentBase64
        vm.camera.imageContentType = data.contentType
      })
      reloadEnabled = false
      setupReloadTimer()
    }

    function getCamera() {
      ApiCameraModel.getCamera({ id1: $stateParams.id }, function (data) {
        vm.camera = Object.assign(vm.camera, data)
      })
    }

    function setupCountdownTimer() {
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
      }

      vm.data.timeToReload = vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      countdownTimer = $interval(function () {
        vm.data.timeToReload =
          vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      }, 500)
    }

    function setupReloadTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      reloadTimer = $timeout(function () {
        reloadEnabled = true
        if (vm.data.autoRefreshEnabled) {
          reloadImage()
        }
      }, vm.data.refreshRate)
    }

    function changeAutoRefresh() {
      if (reloadEnabled && vm.data.autoRefreshEnabled) {
        reloadImage()
      }
      if (vm.data.autoRefreshEnabled) {
        setupCountdownTimer()
      } else if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    function destroyTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    $scope.$on('$destroy', destroyTimer)
    $scope.$on('logout', destroyTimer)
  }
})()
