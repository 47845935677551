;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('spaFilter', SpaFilter)

  SpaFilter.$inject = []

  function SpaFilter() {
    return function (input) {
      switch (input) {
        case -1:
          return 'Sucho'
        case 0:
          return 'Normální stav'
        case 1:
          return '1. SPA'
        case 2:
          return '2. SPA'
        case 3:
          return '3. SPA'
        case 4:
          return '3. SPA - extrémní ohrožení'
        default:
          return 'Normální stav'
      }
    }
  }
})()
