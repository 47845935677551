;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.traffic').controller('TrafficDetailController', TrafficDetailController)

  TrafficDetailController.$inject = ['$state', '$stateParams', 'ApiTrafficModel']

  function TrafficDetailController($state, $stateParams, ApiTrafficModel) {
    const vm = this

    activate()

    function activate() {
      getDdrMessages()
      getDdrMessage()
    }

    function getDdrMessages() {
      vm.loadingDdrMessagesData = true
      ApiTrafficModel.getDdrMessages(
        {
          limit: 10,
        },
        (data) => {
          vm.ddrTree = data.messages
          vm.loadingDdrMessagesData = false
        },
        () => {
          vm.loadingDdrMessagesData = false
        },
      )
    }

    function getDdrMessage() {
      vm.loadingData = true
      vm.ddr = ApiTrafficModel.getDdrMessage(
        { id: $stateParams.id },
        () => {
          vm.id = $stateParams.id
          vm.loadingData = false
        },
        (errData) => {
          if (errData.status === 404) {
            $state.go('front.pageNotFound')
          }
          vm.loadingData = false
        },
      )
    }
  }
})()
