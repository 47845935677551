;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppSearch', {
    templateUrl: 'app/modules/front/layout/components/search/index.html',
    controller: SearchController,
  })

  SearchController.$inject = ['$state']

  function SearchController($state) {
    const vm = this

    vm.opened = false
    vm.fulltext = ''

    vm.fulltextSearch = fulltextSearch

    function fulltextSearch() {
      $state.go('front.search', { fulltext: vm.fulltext }, {})
    }
  }
})()
