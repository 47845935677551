;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.constants')
    .constant('BOOL_VALUES', [
      {
        key: true,
        value: 'Ano',
      },
      {
        key: false,
        value: 'Ne',
      },
    ])
    .constant('INITIATIVE_STATE_STYLES', {
      NEW: 'primary',
      IN_PROGRESS: 'warning',
      SOLVED: 'success',
      UNSOLVED: 'danger',
    })
    .constant('BOLLARD_TYPES', {
      STATIC: 'Dopravní',
      TELESCOPIC: 'Výsuvný',
    })
    .constant('INITIATIVE_ATTACHMENT_TYPES', [
      'image',
      // "video"
    ])
})()
