;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('AdminCameraAvailabilityController', AdminCameraAvailabilityController)

  AdminCameraAvailabilityController.$inject = [
    '$http',
    '$uibModal',
    'ApiAdminUserModel',
    'BOOL_VALUES',
  ]

  function AdminCameraAvailabilityController($http, $uibModal, ApiAdminUserModel, BOOL_VALUES) {
    const vm = this
    vm.boolValues = BOOL_VALUES
    vm.openEditRole = openEditRole

    activate()

    function activate() {
      getRoles()
      prepareTableHeaders()
    }

    function getRoles() {
      vm.loadingData = true
      vm.roles = ApiAdminUserModel.getAllRoles(
        function () {
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http
        .get('app/modules/admin/camera/meta/camera-availability-list.json')
        .then(function (response) {
          vm.headers = response.data
        })
    }

    function openEditRole(role) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/availability/editCameraAvailabilityModal.html',
        controller: 'EditCameraAvailabilityModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          role: function () {
            return role
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getRoles()
          })
        },
        function () {},
      )
    }
  }
})()
