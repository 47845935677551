;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppLogin', {
    templateUrl: 'app/modules/front/layout/components/login/index.html',
    controller: LoginController,
  })

  LoginController.$inject = [
    '$rootScope',
    '$state',
    '$uibModal',
    'PersistenceModel',
    'ApiAuthenticationModel',
  ]

  function LoginController(
    $rootScope,
    $state,
    $uibModal,
    PersistenceModel,
    ApiAuthenticationModel,
  ) {
    const vm = this
    vm.userName = PersistenceModel.getName()
    vm.defaultAdminPage = PersistenceModel.getDefaultPage()

    vm.logout = logout
    vm.isAuthenticated = isAuthenticated
    vm.openLoginModal = openLoginModal
    vm.openRegistrationModal = openRegistrationModal
    vm.openChangePasswordModal = openChangePasswordModal

    function openRegistrationModal() {
      const modalInstance = $uibModal.open({
        templateUrl: 'app/modules/front/layout/modal/registration/registrationModal.html',
        controller: 'RegistrationModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        () => {},
        () => {},
      )
    }

    function openChangePasswordModal() {
      const modalInstance = $uibModal.open({
        templateUrl: 'app/modules/front/layout/modal/change-password/changePasswordModal.html',
        controller: 'ChangePasswordModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        () => {},
        () => {},
      )
    }

    function openLoginModal() {
      const modalInstance = $uibModal.open({
        templateUrl: 'app/modules/front/layout/modal/login/loginModal.html',
        controller: 'LoginModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        () => {},
        () => {},
      )
    }

    function logout() {
      $rootScope.$broadcast('logout')
      ApiAuthenticationModel.logout(
        () => {
          doLogout()
        },
        () => {
          doLogout()
        },
      )
    }

    function doLogout() {
      PersistenceModel.clear()
      $state.go(PersistenceModel.getDefaultPage(), {}, { reload: true })
    }

    function isAuthenticated() {
      return PersistenceModel.isAuthenticated()
    }
  }
})()
