;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('UtilService', UtilService)

  UtilService.$inject = ['$window', 'APP_CONFIG']

  function UtilService($window, APP_CONFIG) {
    return {
      remapObjectAttributes: function (obj, map) {
        var newObj = {}
        _.each(obj, function (value, key) {
          key = map[key] || key
          newObj[key] = value
        })
        return newObj
      },
      getIdFromLocation: function (location) {
        return location.substr(location.lastIndexOf('/') + 1)
      },
      updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
        var separator = uri.indexOf('?') !== -1 ? '&' : '?'
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + '=' + value + '$2')
        } else {
          return uri + separator + key + '=' + value
        }
      },
      findObjectKeyRecursively: function (obj, childSection, key, value, result) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] === value) {
            result.push(obj)
          } else {
            if (obj.hasOwnProperty(childSection)) {
              return this.findObjectKeyRecursively(
                obj[childSection],
                childSection,
                key,
                value,
                result,
              )
            }
          }
        } else if (obj instanceof Array) {
          var me = this
          angular.forEach(obj, function (object) {
            if (object.hasOwnProperty(childSection)) {
              return me.findObjectKeyRecursively(object, childSection, key, value, result)
            }
          })
        }
      },
      encodeToBase64: function (obj, attribute) {
        if (obj.hasOwnProperty(attribute) && obj[attribute] !== null) {
          obj[attribute] = Base64.encode(obj[attribute])
        }
        return obj
      },
      decodeToBase64: function (obj, attribute) {
        if (obj.hasOwnProperty(attribute) && obj[attribute] !== null) {
          obj[attribute] = Base64.decode(obj[attribute])
        }
        return obj
      },
      replaceContentVariableToValue: function (content) {
        return _.replace(content, new RegExp('{{baseUrl}}', 'g'), APP_CONFIG.apiRootUrl)
      },
      replaceContentValueToVariable: function (content) {
        return _.replace(content, new RegExp(APP_CONFIG.apiRootUrl, 'g'), '{{baseUrl}}')
      },
      downloadFile: function (data, headers) {
        if (headers['content-type'] && headers['content-disposition']) {
          var blob = new Blob([data], {
            type: headers['content-type'],
          })

          var match = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/g.exec(
            headers['content-disposition'],
          )
          var filename = match.length > 0 ? match[1] : 'export.pdf'
          $window.saveAs(blob, filename)
        }
      },
      simpleResponseTransform: function (property) {
        return function (data) {
          data = angular.fromJson(data)
          if (data && data.hasOwnProperty(property)) {
            return data[property]
          }
          return data
        }
      },
      getFileNameFromHeader: function (header) {
        if (!header) {
          return null
        }
        var result = header.split(';')[1].trim().split('=')[1]
        return result.replace(/"/g, '')
      },
    }
  }
})()
