;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('InjectCss', InjectCssService)

  InjectCssService.$inject = ['$q']

  function InjectCssService($q) {
    var injectCss = {}

    var createLink = function (id, url) {
      var link = document.createElement('link')
      link.id = id
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.media = 'screen, print'
      link.href = url
      return link
    }

    injectCss.set = function (id, url) {
      var deferred = $q.defer(),
        link

      link = createLink(id, url)
      link.onload = deferred.resolve
      angular.element('head').append(link)

      return deferred.promise
    }

    return injectCss
  }
})()
