;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('SafetyMapController', SafetyMapController)

  SafetyMapController.$inject = [
    '$rootScope',
    'ApiMapModel',
    'PersistenceModel',
    'SafetyMapService',
  ]

  function SafetyMapController($rootScope, ApiMapModel, PersistenceModel, SafetyMapService) {
    const vm = this

    vm.data = {
      mapLayers: [],
      legend: [],
    }
    vm.polygons = []
    vm.canBeEdited = PersistenceModel.isAuthenticated() && PersistenceModel.hasAdminRole()

    activate()

    function activate() {
      SafetyMapService.initSafetyMap(vm.data, ApiMapModel.getPolygons(), vm.canBeEdited)
    }

    $rootScope.$on('polygon:initiated', (event, polygonData) => {
      vm.polygons.push(polygonData)
    })

    $rootScope.$on('polygon:created', (event, polygonData) => {
      vm.polygons.push(polygonData)
    })

    $rootScope.$on('polygon:edited', (event, polygonData) => {
      for (let i in vm.polygons) {
        if (vm.polygons[i].id === polygonData.id) {
          vm.polygons[i] = angular.extend(vm.polygons[i], polygonData)
          break
        }
      }
    })

    $rootScope.$on('polygon:deleted', (event, polygonData) => {
      vm.polygons = vm.polygons.filter(({ id }) => id !== polygonData.id)
    })
  }
})()
