;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminEventModel', ApiAdminEventModel)

  ApiAdminEventModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminEventModel($resource, UtilService, APP_CONFIG) {
    var cultureFields = [
      'cultureCode',
      'location',
      'characteristic',
      'title',
      'perex',
      'keywords',
      'description',
      'cooperation',
      'actionSteps',
      'actionOSKS',
      'relativeUrl',
      'publication',
      'attachment',
    ]

    function tansformEventRequest(eventObj) {
      var eventCultures = {}

      _.forEach(cultureFields, function (path) {
        var tmp = _.get(eventObj, path)
        if (tmp) {
          _.unset(eventObj, path)
          _.set(eventCultures, path, tmp)
        }
      })

      eventObj.eventCultures = [eventCultures]
      delete eventObj.translations

      return eventObj
    }

    function transformEventResponse(eventObj) {
      var defaultTranslationIndex = _.findIndex(eventObj.eventCultures, [
        'cultureCode',
        APP_CONFIG.translation.fallbackLanguage,
      ])
      return _.assign(eventObj, eventObj.eventCultures[defaultTranslationIndex])
    }

    function transformMultipleLanguagesEventResponse(eventObj) {
      var defaultTranslationIndex = _.findIndex(eventObj.eventCultures, [
        'cultureCode',
        APP_CONFIG.translation.fallbackLanguage,
      ])
      eventObj.publication = eventObj.eventCultures[defaultTranslationIndex].publication
      eventObj.translations = eventObj.eventCultures
      return eventObj
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/events/:id/:path',
      { id: '@id', path: '@path' },
      {
        getSearchedEvents: {
          method: 'POST',
          params: {
            path: 'search',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('events')) {
              data.events = _.map(data.events, function (obj) {
                if (obj && obj.hasOwnProperty('sourceData')) {
                  obj.sourceData = angular.fromJson(event.sourceData)
                }
                return transformEventResponse(obj)
              })
            }
            return data
          },
        },
        getAll: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('events')) {
              var events = _.map(data.events, function (obj) {
                if (obj && obj.hasOwnProperty('sourceData')) {
                  obj.sourceData = angular.fromJson(event.sourceData)
                }
                return transformEventResponse(obj)
              })
              return events
            }
            return data
          },
        },
        downloadAll: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
        getSingle: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('event')) {
              var event = data.event
              if (event && event.hasOwnProperty('sourceData')) {
                event.sourceData = angular.fromJson(event.sourceData)
              }
              return transformMultipleLanguagesEventResponse(event)
            }
            return data
          },
        },
        createEvent: {
          method: 'POST',
          transformRequest: function (data) {
            data = angular.copy(data)
            if (data && data.hasOwnProperty('sourceData')) {
              data.sourceData = angular.toJson(data.sourceData)
            }
            tansformEventRequest(data)
            return angular.toJson({ event: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        editEvent: {
          method: 'PUT',
          transformRequest: function (data) {
            data = angular.copy(data)
            if (data && data.hasOwnProperty('sourceData')) {
              data.sourceData = angular.toJson(data.sourceData)
            }
            tansformEventRequest(data)
            return angular.toJson({ event: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        createEventEmail: {
          method: 'POST',
          params: {
            path: 'email',
          },
          transformRequest: function (data) {
            data = angular.copy(data)
            if (data && data.hasOwnProperty('sourceData')) {
              data.sourceData = angular.toJson(data.sourceData)
            }
            return angular.toJson({ event: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        publishEvent: {
          method: 'POST',
          params: {
            path: 'publish',
          },
        },
        unpublishEvent: {
          method: 'DELETE',
        },
      },
    )
  }
})()
