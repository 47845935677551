;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpBindHtmlCompile', BindHtmlCompileDirective)

  BindHtmlCompileDirective.$inject = ['$compile']

  function BindHtmlCompileDirective($compile) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.$watch(
          function () {
            return scope.$eval(attrs.cpBindHtmlCompile)
          },
          function (value) {
            element.html(value)
            $compile(element.contents())(scope)
          },
        )
      },
    }
  }
})()
