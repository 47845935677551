;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminArticleModel', ApiAdminArticleModel)

  ApiAdminArticleModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminArticleModel($resource, UtilService, APP_CONFIG) {
    var cultureFields = [
      'cultureCode',
      'title',
      'description',
      'keywords',
      'perex',
      'relativeUrl',
      'publicContent',
      'privateContent',
      'breadcrumbs',
      'publication',
      'fileFolderId',
    ]

    function transformArticleRequest(articleObj) {
      var articleCulture = {}

      _.forEach(cultureFields, function (path) {
        var tmp = _.get(articleObj, path)
        if (tmp) {
          _.unset(articleObj, path)
          _.set(articleCulture, path, tmp)
        }
      })

      articleObj.articleCulture = [articleCulture]
      delete articleObj.translations

      return articleObj
    }

    function transformArticleResponse(articleObj) {
      var defaultTranslationIndex = _.findIndex(articleObj.articleCulture, [
        'cultureCode',
        APP_CONFIG.translation.fallbackLanguage,
      ])
      return _.assign(articleObj, articleObj.articleCulture[defaultTranslationIndex])
    }

    function transformMultipleLanguagesArticleResponse(articleObj) {
      _.forEach(articleObj.articleCulture, function (cultureData) {
        // cultureData = UtilService.decodeToBase64(cultureData, "publicContent");
        cultureData.publicContent = UtilService.replaceContentVariableToValue(
          cultureData.publicContent,
        )

        // cultureData = UtilService.decodeToBase64(cultureData, "privateContent");
        cultureData.privateContent = UtilService.replaceContentVariableToValue(
          cultureData.privateContent,
        )
      })

      articleObj.translations = articleObj.articleCulture

      return articleObj
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/articles/:id/:path/:id1',
      {
        id: '@id',
        path: '@path',
        id1: '@id1',
      },
      {
        getAllArticles: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('articles')) {
              return data.articles.map(transformArticleResponse)
            }
            return data
          },
        },
        createArticle: {
          method: 'POST',
          transformRequest: function (data) {
            data = angular.copy(data)
            if (data.publicContent && _.trim(data.publicContent).length > 0) {
              data.publicContent = UtilService.replaceContentValueToVariable(data.publicContent)
              data = UtilService.encodeToBase64(data, 'publicContent')
            } else {
              data.publicContent = null
            }
            if (data.privateContent && _.trim(data.privateContent).length > 0) {
              data.privateContent = UtilService.replaceContentValueToVariable(data.privateContent)
              data = UtilService.encodeToBase64(data, 'privateContent')
            } else {
              data.privateContent = null
            }
            data.parentId = data.parent.id

            transformArticleRequest(data)

            return angular.toJson({ article: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        getArticle: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('article')) {
              var article = data.article
              return transformMultipleLanguagesArticleResponse(article)
            }
            return data
          },
        },
        editArticle: {
          method: 'PUT',
          transformRequest: function (data) {
            data = angular.copy(data)
            if (data.publicContent && _.trim(data.publicContent).length > 0) {
              data.publicContent = UtilService.replaceContentValueToVariable(data.publicContent)
              data = UtilService.encodeToBase64(data, 'publicContent')
            } else {
              data.publicContent = null
            }
            if (data.privateContent && _.trim(data.privateContent).length > 0) {
              data.privateContent = UtilService.replaceContentValueToVariable(data.privateContent)
              data = UtilService.encodeToBase64(data, 'privateContent')
            } else {
              data.privateContent = null
            }
            data.parentId = data.parent.id

            transformArticleRequest(data)

            return angular.toJson({ article: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        publishArticle: {
          method: 'PUT',
          params: {
            path: 'publish',
          },
        },
        deactivateArticle: {
          method: 'PUT',
          params: {
            path: 'unpublish',
          },
        },
        deleteArticle: {
          method: 'DELETE',
        },
        getArticleTree: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'tree',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('articles')) {
              return [data.articles]
            }
            return data
          },
        },
        linkArticleAttachments: {
          method: 'POST',
          params: {
            path: 'attachments',
          },
        },
        deleteArticleAttachment: {
          method: 'DELETE',
          params: {
            path: 'attachments',
          },
        },
      },
    )
  }
})()
