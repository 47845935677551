;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  const injectCssFunction = function ($q, $http, InjectCss) {
      var deferred = $q.defer()
      $http.get('rev-manifest.json').then(function (response) {
        var id = 'style',
          link = angular.element('link#' + id)
        InjectCss.set(id, 'build/' + response.data['front-style.css']).then(
          function () {
            if (link.length) {
              link.remove()
            }
            deferred.resolve()
          },
        )
      })
      return deferred.promise
    }

  angular
    .module('app.front', [
      'ncy-angular-breadcrumb',
      'app.front.layout',
      'app.front.homepage',
      'app.front.registration',
      'app.front.forgottenPassword',
      'app.front.article',
      'app.front.map',
      'app.front.notFound',
      'app.front.event',
      'app.front.school',
      'app.front.document',
      'app.front.traffic',
      'app.front.initiative',
    ])
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/stranka-nenalezena')

        $stateProvider
          .state('camera-image-detail', {
            url: '/kamery/{id}',
            resolve: {
              load: [
                '$q',
                '$http',
                'InjectCss',
                injectCssFunction,
              ],
            },
            views: {
              root: {
                templateUrl: 'app/modules/front/map/views/cameraImageDetail.html',
                controller: 'CameraImageDetailController',
                controllerAs: 'cameraImageDetailCtrl',
              },
            },
          })
          .state('front', {
            abstract: true,
            resolve: {
              load: [
                '$q',
                '$http',
                'InjectCss',
                injectCssFunction,
              ],
            },
            views: {
              root: {
                templateUrl: 'app/modules/front/layout/layout.tpl.html',
                controller: 'FrontLayoutController',
                controllerAs: 'layoutCtrl',
              },
            },
          })
          .state('front.homepage', {
            url: '/',
            views: {
              content: {
                templateUrl: 'app/modules/front/homepage/views/main.html',
                controller: 'HomepageController',
                controllerAs: 'homepageCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.homepage'|translate}}",
            },
          })
          .state('front.registration-confirmation', {
            url: '/potvrzeni-registrace?token',
            views: {
              content: {
                templateUrl: 'app/modules/front/registration/views/confirm.html',
                controller: 'RegistrationConfirmationController',
                controllerAs: 'registrationConfirmationCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.registration-confirmation'|translate}}",
            },
          })
          .state('front.forgotten-password-confirmation', {
            url: '/obnova-hesla?token',
            views: {
              content: {
                templateUrl: 'app/modules/front/forgotten-password/views/confirm.html',
                controller: 'ForgottenPasswordConfirmationController',
                controllerAs: 'forgottenPasswordConfirmationCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.forgotten-password-confirmation'|translate}}",
            },
          })
          .state('front.search', {
            url: '/clanky/vyhledavani/:fulltext',
            views: {
              content: {
                templateUrl: 'app/modules/front/article/views/search.html',
                controller: 'ArticleSearchController',
                controllerAs: 'articleSearchCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.search'|translate}}",
            },
          })
          .state('front.article-detail', {
            url: '/clanky/:relativeUrl',
            views: {
              content: {
                templateUrl: 'app/modules/front/article/views/detail.html',
                controller: 'DetailArticleController',
                controllerAs: 'detailArticleCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.article-detail'|translate}}",
            },
          })
          .state('front.article-preview', {
            url: '/clanky/nahled/:id/:cultureCode',
            views: {
              content: {
                templateUrl: 'app/modules/front/article/views/preview.html',
                controller: 'DetailArticlePreviewController',
                controllerAs: 'detailArticlePreviewCtrl',
              },
            },
            ncyBreadcrumb: {
              label:
                "{{'FRONT.MODULE_JS.front.article-detail'|translate}} {{detailArticlePreviewCtrl.article.title}}",
            },
          })

          .state('front.map', {
            abstract: true,
            data: {
              scrollTo: {
                anchor: 'map-nav-scrollTo',
              },
            },
            views: {
              content: {
                templateUrl: 'app/modules/front/map/views/main.html',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map'|translate}}",
            },
          })
          .state('front.map.initiative', {
            url: '/mapy/oznameni/:initiativeId',
            params: {
              initiativeId: { squash: true, value: null },
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/initiatives.html',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.initiative'|translate}}",
            },
          })
          .state('front.map.criminality', {
            url: '/mapy/kriminalita',
            data: {
              providerService: 'CriminalityMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.criminality'|translate}}",
            },
          })

          .state('front.map.camera', {
            url: '/mapy/kamery/:cameraId',
            params: {
              cameraId: { squash: true, value: null },
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/camera.html',
                controller: 'CameraMapController',
                controllerAs: 'cameraMapCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.camera'|translate}}",
            },
          })
          .state('front.map.camera-detail', {
            url: '/mapy/kamery/{id}',
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/cameraDetail.html',
                controller: 'CameraDetailMapController',
                controllerAs: 'cameraDetailMapCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.camera-detail'|translate}}",
            },
          })
          .state('front.map.cadastre', {
            url: '/mapy/zakladni-mapa-hranic',
            data: {
              providerService: 'CadastreMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.cadastre'|translate}}",
            },
          })
          .state('front.map.floods', {
            url: '/mapy/povodne-velkeho-rozsahu/:stationName',
            params: {
              stationName: { squash: true, value: null },
            },
            data: {
              providerService: 'FloodsMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.floods'|translate}}",
            },
          })
          .state('front.map.noise', {
            url: '/mapy/hluk',
            data: {
              providerService: 'NoiseMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.noise'|translate}}",
            },
          })
          .state('front.map.traffic', {
            url: '/mapy/doprava',
            data: {
              providerService: 'TrafficMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.traffic'|translate}}",
            },
          })
          .state('front.map.izs', {
            url: '/mapy/ochrana-obyvatelstva',
            data: {
              providerService: 'IzsMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.izs'|translate}}",
            },
          })
          .state('front.map.safety', {
            url: '/mapy/pocit-bezpeci',
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/safety.html',
                controller: 'SafetyMapController',
                controllerAs: 'safetyMapCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.safety'|translate}}",
            },
          })
          .state('front.map.school', {
            url: '/mapy/skolska-zarizeni/:syposId',
            params: {
              syposId: { squash: true, value: null },
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/school.html',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.school'|translate}}",
            },
          })
          .state('front.map.pub', {
            url: '/mapy/hospody',
            data: {
              providerService: 'RestaurantMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.pub'|translate}}",
            },
          })
          .state('front.map.hzs', {
            url: '/mapy/hasicsky-zachranny-sbor',
            data: {
              providerService: 'HzsMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.hzs'|translate}}",
            },
          })
          .state('front.map.air', {
            url: '/mapy/aktualni-deni/:stationName',
            params: {
              stationName: { squash: true, value: null },
            },
            data: {
              providerService: 'NewsMapService',
            },
            views: {
              mapContent: {
                templateUrl: 'app/modules/front/map/views/map.html',
                controller: 'MapController',
                controllerAs: 'mapContentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.map.air'|translate}}",
            },
          })
          .state('front.initiative-form', {
            url: '/nove-oznameni/',
            data: {},
            views: {
              content: {
                templateUrl: 'app/modules/front/initiative/views/form.html',
                controller: 'InitiativeFormController',
                controllerAs: 'initiativeFormCtrl',
              },
            },
            ncyBreadcrumb: {
              parent: 'front.initiative',
              label: "{{'FRONT.MODULE_JS.front.initiative-form'|translate}}",
            },
          })
          .state('front.event', {
            url: '/aktuality',
            data: {
              type: 'news',
            },
            views: {
              content: {
                templateUrl: 'app/modules/front/event/views/main.html',
                controller: 'EventController',
                controllerAs: 'eventCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.event'|translate}}",
            },
          })
          .state('front.planned-event', {
            url: '/planovane-akce',
            data: {
              type: 'safety',
            },
            views: {
              content: {
                templateUrl: 'app/modules/front/event/views/main.html',
                controller: 'EventController',
                controllerAs: 'eventCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.planned-event'|translate}}",
            },
          })
          .state('front.event-detail', {
            url: '/aktuality/:id',
            views: {
              content: {
                templateUrl: 'app/modules/front/event/views/detail.html',
                controller: 'EventDetailController',
                controllerAs: 'eventDetailCtrl',
              },
            },
            ncyBreadcrumb: {
              label: '{{eventDetailCtrl.event.eventCultures[0].title}}',
            },
          })
          .state('front.planned-event-detail', {
            url: '/planovane-akce/:id',
            views: {
              content: {
                templateUrl: 'app/modules/front/event/views/detail.html',
                controller: 'EventDetailController',
                controllerAs: 'eventDetailCtrl',
              },
            },
            ncyBreadcrumb: {
              label: '{{eventDetailCtrl.event.eventCultures[0].title}}',
            },
          })
          .state('front.sypos-detail', {
            url: '/skoly/mapa-skol/:id',
            views: {
              content: {
                templateUrl: 'app/modules/front/school/views/detail.html',
                controller: 'SyposDetailController',
                controllerAs: 'syposDetailCtrl',
              },
            },
            ncyBreadcrumb: {
              label: '{{syposDetailCtrl.institution.title}}',
            },
          })
          .state('front.document', {
            url: '/dokumenty',
            views: {
              content: {
                templateUrl: 'app/modules/front/document/views/main.html',
                controller: 'DocumentController',
                controllerAs: 'documentCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.document' | translate}}",
            },
          })
          .state('front.traffic', {
            url: '/doprava',
            views: {
              content: {
                templateUrl: 'app/modules/front/traffic/views/main.html',
                controller: 'TrafficController',
                controllerAs: 'trafficCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.traffic'|translate}}",
            },
          })
          .state('front.traffic-detail', {
            url: '/doprava/:id',
            views: {
              content: {
                templateUrl: 'app/modules/front/traffic/views/detail.html',
                controller: 'TrafficDetailController',
                controllerAs: 'trafficDetailCtrl',
              },
            },
            ncyBreadcrumb: {
              label: '{{trafficDetailCtrl.traffic.eventCultures[0].title}}',
            },
          })
          .state('front.pageNotFound', {
            url: '/stranka-nenalezena',
            views: {
              content: {
                templateUrl: 'app/modules/front/page-not-found/views/pageNotFound.html',
                controller: 'PageNotFoundController',
                controllerAs: 'pageNotFoundCtrl',
              },
            },
            ncyBreadcrumb: {
              label: "{{'FRONT.MODULE_JS.front.pageNotFound'|translate}}",
            },
          })
      },
    ])
})()
