;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.source')
    .controller('AdminTrafficBollardsController', AdminTrafficBollardsController)

  AdminTrafficBollardsController.$inject = [
    '$http',
    '$state',
    '$uibModal',
    'QueryFilterService',
    'ApiAdminBollardsModel',
    'ApiSourceModel',
    'ApiUserModel',
    'EventService',
    'BOLLARD_TYPES',
  ]

  function AdminTrafficBollardsController(
    $http,
    $state,
    $uibModal,
    QueryFilterService,
    ApiAdminBollardsModel,
    ApiSourceModel,
    ApiUserModel,
    EventService,
    BOLLARD_TYPES,
  ) {
    const vm = this
    var districts = []

    vm.getBollardsEntries = getBollardsEntries
    vm.publish = publish
    vm.openUploadCsv = openUploadCsv
    vm.bollardTypes = Object.keys(BOLLARD_TYPES).map((key) => ({
      id: key,
      name: BOLLARD_TYPES[key],
    }))

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getBollardsEntries()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = '-placeName'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/sources/meta/bollards-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getBollardsEntries() {
      vm.loadingData = true
      ApiAdminBollardsModel.getTrafficBollardsPoints(
        {},
        function (entries) {
          vm.entries = entries.trafficBollards
          vm.totalCount = entries.trafficBollards.length
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function openUploadCsv() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/sources/modal/importCsvModal.html',
        controller: 'ImportCsvModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          title: () => 'Nahrát data sloupků',
          importType: () => 'trafficBollards',
          successMessage: () => 'Data sloupků byla úspěšně nahrána.',
        },
      })
      modalInstance.result.then(
        function () {
          getBollardsEntries()
        },
        function () {},
      )
    }

    function publish(firemanEntry) {
      EventService.saveFiremanSourceData(firemanEntry, districts)
      $state.go('admin.event-create', {}, { reload: true })
    }
  }
})()
