;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpTranslationTabs', TranslationTabsDirective)

  TranslationTabsDirective.$inject = ['ApiLanguageModel', 'APP_CONFIG']

  function TranslationTabsDirective(ApiLanguageModel, APP_CONFIG) {
    return {
      restrict: 'A',
      scope: {
        form: '=',
        entity: '=',
        modelCommonData: '=?',
        modelCultureData: '=?',
        showDefaultTranslationOnly: '=?',
        pathToCultures: '=?',
        setCultureCode: '=?',
        overrideEventFn: '=?',
        onTranslationInit: '&?',
        onTranslationAdd: '&?',
        onTranslationChange: '&?',
        disableLoadedFn: '=?',
        setEmitEntityLoaded: '&?',
        setHandlers: '&?',
      },
      replace: true,
      templateUrl: 'app/modules/_common/directives/translationTabs.html',
      link: function (scope) {
        var config = {
          defaultLanguage: APP_CONFIG.translation.fallbackLanguage,
          showDefaultTranslationOnly: scope.showDefaultTranslationOnly || false,
          overrideEventFn: scope.overrideEventFn || false,
          disableLoadedFn: scope.disableLoadedFn || false,
          entity: {
            pathToCultures: scope.pathToCultures || 'translations',
            setCultureCode: scope.setCultureCode || true,
          },
        }

        var translationData = {}
        scope.activeTranslation = config.defaultLanguage

        scope.emitEntityLoadedFn = function () {
          getAllLanguages()
        }

        if (scope.setHandlers) {
          scope.handlers = {
            addExistingTranslation: function (cultureCodeArray) {
              if (!_.isArray(cultureCodeArray)) {
                cultureCodeArray = [cultureCodeArray]
              }
              cultureCodeArray.forEach(function (cultureCode) {
                var languageIndex = _.findIndex(scope.availableTranslations, [
                  'cultureCode',
                  cultureCode,
                ])
                if (languageIndex !== -1) {
                  scope.existingTranslations.push(
                    scope.availableTranslations.splice(languageIndex, 1)[0],
                  )
                }
              })
            },
          }
          scope.setHandlers({ handlersObj: scope.handlers })
        }

        // init directive
        if (!config.disableLoadedFn && scope.setEmitEntityLoaded) {
          scope.setEmitEntityLoaded({
            emitEntityLoadedFn: scope.emitEntityLoadedFn,
          })
        } else {
          scope.emitEntityLoadedFn()
        }

        function getAllLanguages() {
          ApiLanguageModel.getAllLanguages(function (data) {
            var entityTranslations = [{ cultureCode: scope.activeTranslation }]
            if (scope.entity) {
              var tmp = _.get(scope.entity, config.entity.pathToCultures)
              if (tmp) {
                entityTranslations = tmp
              }
            }
            initTranslations(data, angular.copy(entityTranslations))
          })
        }

        function initTranslations(allLanguages, availableTranslationsData) {
          scope.availableTranslations = []
          scope.existingTranslations = []

          _.forEach(allLanguages, function (language) {
            var languageIndex = _.findIndex(availableTranslationsData, [
              'cultureCode',
              language.cultureCode,
            ])
            if (languageIndex !== -1) {
              var cultureData = availableTranslationsData.splice(languageIndex, 1)[0]
              if (
                config.showDefaultTranslationOnly === false ||
                cultureData.cultureCode === config.defaultLanguage
              ) {
                translationData[language.cultureCode] = _.assign(
                  translationData[language.cultureCode],
                  cultureData,
                )
              }
              scope.existingTranslations.push(language)
            } else if (config.showDefaultTranslationOnly === false) {
              scope.availableTranslations.push(language)
            }
          })

          _.assign(scope.entity, translationData[scope.activeTranslation])

          if (config.entity.setCultureCode) {
            if (scope.entity === undefined) {
              scope.entity = {}
            }
            scope.entity.cultureCode = scope.activeTranslation
          }

          if (scope.onTranslationInit) {
            scope.onTranslationInit({ cultureCode: scope.activeTranslation })
          }
        }

        function translationTransition(destObj, srcObj) {
          if (srcObj) {
            var toCopy = []
            if (_.isArray(scope.modelCommonData)) {
              toCopy = scope.modelCommonData
            } else if (_.isArray(scope.modelCultureData)) {
              toCopy = _.difference(Object.keys(srcObj), scope.modelCultureData)
            }
            _.forEach(toCopy, function (path) {
              var tmp = _.get(srcObj, path)
              if (tmp) {
                _.set(destObj, path, tmp)
              }
            })
          }
          return destObj
        }

        scope.translationAdd = function (index) {
          var addedLang = scope.availableTranslations.splice(index, 1)[0]
          var fromCultureCode = scope.activeTranslation

          scope.activeTranslation = addedLang.cultureCode
          scope.existingTranslations.push(addedLang)
          scope.form.$setPristine()

          if (!config.overrideEventFn) {
            translationData[fromCultureCode] = angular.copy(scope.entity)
            // scope.entity = translationTransition({}, scope.entity);
            if (config.entity.setCultureCode) {
              scope.entity.cultureCode = addedLang.cultureCode
            }
          }

          if (scope.onTranslationAdd) {
            scope.onTranslationAdd({
              cultureCode: scope.activeTranslation,
              template: translationData[config.defaultLanguage],
            })
          }
        }

        scope.translationChange = function (lang) {
          var fromCultureCode = scope.activeTranslation
          var toCultureCode = lang.cultureCode

          scope.activeTranslation = toCultureCode
          scope.form.$setPristine()

          if (!config.overrideEventFn) {
            translationData[fromCultureCode] = angular.copy(scope.entity)
            translationTransition(translationData[toCultureCode], translationData[fromCultureCode])
            scope.entity = translationData[toCultureCode]
          }

          if (scope.onTranslationChange) {
            scope.onTranslationChange({
              cultureCode: scope.activeTranslation,
              template: translationData[config.defaultLanguage],
            })
          }
        }
      },
    }
  }
})()
