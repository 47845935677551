;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('zoneZpsTariff', ZoneZpsTariffFilter)

  ZoneZpsTariffFilter.$inject = []

  function ZoneZpsTariffFilter() {
    return function (zpsTariff) {
      var days = []

      zpsTariff.split('; ').forEach(function (dayRow) {
        var inDay = dayRow.split(': ')
        if (inDay.length === 2) {
          days.push({
            day: inDay[0],
            hours: _.uniq(inDay[1].split(', ')),
          })
        }
      })

      return days
    }
  }
})()
