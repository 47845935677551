;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('unique', UniqueFilter)

  UniqueFilter.$inject = []

  function UniqueFilter() {
    return function (input, field) {
      return _.uniqBy(input, field)
    }
  }
})()
