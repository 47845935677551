;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiMapModel', ApiMapModel)

  ApiMapModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiMapModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + ':path/:id/:path1/:id1/:path2',
      {
        path: '@path',
        id: '@id',
        path1: '@path1',
        id1: '@id1',
        path2: '@path2',
      },
      {
        getAllDynamicMaps: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'maps',
            path1: 'types',
          },
          transformResponse: UtilService.simpleResponseTransform('types'),
        },
        getLayersForMap: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'maps',
            path2: 'layers',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
        getDataForMap: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'maps',
            path1: 'layers',
            path2: 'data',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
        getInitiatives: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'initiatives',
          },
          transformResponse: UtilService.simpleResponseTransform('initiatives'),
        },
        getChemistryHazardousAreas: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'hazardousAreas',
          },
          transformResponse: UtilService.simpleResponseTransform('hazardousAreas'),
        },
        getEvents: {
          method: 'GET',
          params: {
            path: 'events',
            path1: 'map',
          },
        },
        getSchools: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'sypos',
            path1: 'institutes',
          },
          transformResponse: UtilService.simpleResponseTransform('institutes'),
        },
        getSchool: {
          method: 'GET',
          params: {
            path: 'sypos',
            path1: 'institutes',
          },
        },
        getRestaurants: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'restaurants',
          },
          transformResponse: UtilService.simpleResponseTransform('restaurants'),
        },
        getRiverStations: {
          method: 'GET',
          params: {
            path: 'rivers',
          },
        },
        getPassabilityPoints: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'passabilityPoints',
          },
          transformResponse: UtilService.simpleResponseTransform('passabilityPoints'),
        },
        getBollards: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'trafficBollards',
          },
          transformResponse: UtilService.simpleResponseTransform('trafficBollards'),
        },
        getDdrMessages: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'ddr',
            path1: 'map',
          },
          transformResponse: UtilService.simpleResponseTransform('messages'),
        },
        getAirQuality: {
          method: 'GET',
          params: {
            path: 'isko',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
        getParcelPdf: {
          method: 'GET',
          params: {
            path: 'cadasters',
            path1: 'parcels',
            path2: 'owner',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
        getParcelInfo: {
          method: 'GET',
          params: {
            path: 'cadasters',
            path1: 'parcels',
          },
          transformResponse: UtilService.simpleResponseTransform('parcelInfo'),
        },
        getPolygons: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'maps',
            path1: 'safety',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
      },
    )
  }
})()
