;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('bollardType', TrafficBollardTypeFilter)

  TrafficBollardTypeFilter.$inject = ['BOLLARD_TYPES']

  function TrafficBollardTypeFilter(BOLLARD_TYPES) {
    return function (type) {
      if (BOLLARD_TYPES.hasOwnProperty(type)) {
        return BOLLARD_TYPES[type]
      }
      return 'UNKNOWN'
    }
  }
})()
