;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminDocumentModel', ApiAdminDocumentModel)

  ApiAdminDocumentModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminDocumentModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/documents/:id/:path/:id1',
      { id: '@id', path: '@path', id1: '@id1' },
      {
        getStructure: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('documents')) {
              return [data.documents]
            }
            return data
          },
        },
        getRootCultures: {
          method: 'GET',
          params: {
            id: 'rootCultures',
          },
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cultures')) {
              return data.cultures
            }
            return data
          },
        },
        getFolder: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('folder')) {
              return data.folder
            }
            return data
          },
        },
        createFolder: {
          method: 'POST',
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        editFolder: {
          method: 'PUT',
        },
        deleteFolder: {
          method: 'DELETE',
        },
        addFile: {
          method: 'POST',
          params: {
            path: 'files',
          },
        },
        deleteFile: {
          method: 'DELETE',
          params: {
            path: 'files',
          },
        },
        lockStructure: {
          method: 'GET',
        },
        unlockStructure: {
          method: 'DELETE',
        },
      },
    )
  }
})()
