;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.event').controller('EventDetailController', EventDetailController)

  EventDetailController.$inject = [
    '$state',
    '$stateParams',
    '$translate',
    '$location',
    'ApiEventModel',
    'ApiPdfModel',
    'APP_CONFIG',
  ]

  function EventDetailController(
    $state,
    $stateParams,
    $translate,
    $location,
    ApiEventModel,
    ApiPdfModel,
    APP_CONFIG,
  ) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.facebookAppId = APP_CONFIG.facebookAppId
    vm.printContent = printContent
    vm.downloadPdf = downloadPdf

    activate()

    function activate() {
      getEvents()
      getEventSeverities()
      getEventPriorities()
      getEventTypes()
      getEventDetail()
    }

    function getEvents() {
      vm.loadingEventsData = true
      vm.eventsTree = ApiEventModel.getEvents(
        { cultureCode: $translate.use(), limit: 10 },
        () => {
          vm.loadingEventsData = false
        },
        () => {
          vm.loadingEventsData = false
        },
      )
    }

    function getEventDetail() {
      vm.loadingData = true
      ApiEventModel.getSingle(
        { id: $stateParams.id, cultureCode: $translate.use() },
        (event) => {
          vm.id = $stateParams.id
          vm.event = event
          vm.event.url = _.join([$location.absUrl(), $translate.use()], '/')
          vm.loadingData = false
        },
        (errData) => {
          if (errData.status === 404) {
            $state.go('front.pageNotFound')
          }
          vm.loadingData = false
        },
      )
    }

    function getEventSeverities() {
      vm.eventSeverities = ApiEventModel.getSeverities()
    }

    function getEventPriorities() {
      vm.eventPriorities = ApiEventModel.getPriorities()
    }

    function getEventTypes() {
      vm.eventTypes = ApiEventModel.getEventTypes({
        cultureCode: $translate.use(),
      })
    }

    function printContent() {
      window.print()
    }

    function downloadPdf() {
      vm.loadingPdf = true
      ApiPdfModel.downloadPdf(
        { filename: $stateParams.id, relativeUrl: window.location.pathname },
        () => {
          vm.loadingPdf = false
        },
        () => {
          vm.loadingPdf = false
        },
      )
    }
  }
})()
