;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppMenu', {
    templateUrl: 'app/modules/front/layout/components/menu/index.html',
    controller: MenuController,
  })

  MenuController.$inject = ['ArticleTreeService']

  function MenuController(ArticleTreeService) {
    const vm = this
    

    activate()

    function activate() {
      vm.articleStructure = ArticleTreeService.getArticleTree()
    }
  }
})()
