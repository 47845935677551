;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('NewsMapService', NewsMapService)

  NewsMapService.$inject = [
    'leafletData',
    'MapHelperService',
    'RiverStationLayerProvider',
    'EventLayerProvider',
    'DdrMessageLayerProvider',
    'AirQualityLayerProvider',
    'GeometryHelper',
    'APP_CONFIG',
  ]

  function NewsMapService(
    leafletData,
    MapHelperService,
    RiverStationLayerProvider,
    EventLayerProvider,
    DdrMessageLayerProvider,
    AirQualityLayerProvider,
    GeometryHelper,
    APP_CONFIG,
  ) {
    function initNewsLayers(map, layerCollection) {
      _setupDdrMessageLayer(map, layerCollection)
      _setupRiverStationLayer(layerCollection)
      _setupAirQualityLayer(layerCollection)
      _setupEventLayer(map, layerCollection)
    }

    function initNewsMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        initNewsLayers(map, layerCollection)
      })
    }

    function _setupDdrMessageLayer(map, layerCollection) {
      var ddrMessageConfiguration = _getDdrMessageConfiguration()
      if (ddrMessageConfiguration) {
        DdrMessageLayerProvider.initDdrMessagesLayer(map, ddrMessageConfiguration, layerCollection)
      }
    }

    function _setupRiverStationLayer(layerCollection) {
      var riverStationConfiguration = _getRiverStationConfiguration()
      if (riverStationConfiguration) {
        RiverStationLayerProvider.initRiverStationLayer(riverStationConfiguration, layerCollection)
      }
    }

    function _setupAirQualityLayer(layerCollection) {
      var airQualityConfiguration = _setupAirQualityConfiguration()
      if (airQualityConfiguration) {
        AirQualityLayerProvider.initAirQualityLayer(airQualityConfiguration, layerCollection)
      }
    }

    function _setupEventLayer(map, layerCollection) {
      var eventConfiguration = _getEventConfiguration()
      if (eventConfiguration) {
        EventLayerProvider.initEventLayer(map, eventConfiguration, layerCollection)
      }
    }

    function _getDdrMessageConfiguration() {
      return APP_CONFIG.mapSettings.newsLayers.trafficLayer
    }

    function _getEventConfiguration() {
      return APP_CONFIG.mapSettings.newsLayers.eventLayers
    }

    function _setupAirQualityConfiguration() {
      return APP_CONFIG.mapSettings.newsLayers.airQualityLayer
    }

    function _getRiverStationConfiguration() {
      return APP_CONFIG.mapSettings.newsLayers.riverStationLayer
    }

    return {
      initNewsMap: initNewsMap,
      initNewsLayers: initNewsLayers,
    }
  }
})()
