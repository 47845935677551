;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('uniqueLegend', UniqueLegendFilter)

  UniqueLegendFilter.$inject = []

  function UniqueLegendFilter() {
    return function (input, field) {
      return _.uniqBy(input, function (obj) {
        if (obj.visible && obj.display && obj.hasOwnProperty(field) && obj[field]) {
          return obj[field]
        }
        return obj.key
      })
    }
  }
})()
