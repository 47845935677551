;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppWeatherInPrague', {
    templateUrl: 'app/modules/front/homepage/components/weather/widgets/weather.html',
    controller: WeatherInPragueController,
  })

  WeatherInPragueController.$inject = ['ApiWeatherModel']

  function WeatherInPragueController(ApiWeatherModel) {
    const vm = this

    activate()

    function activate() {
      getForecast()
    }

    function getForecast() {
      vm.loadingForecastData = true
      vm.forecast = ApiWeatherModel.getForecast(
        () => {
          vm.loadingForecastData = false
        },
        () => {
          vm.loadingForecastData = false
        },
      )
    }
  }
})()
