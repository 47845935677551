;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.source').controller('AdminNoiseController', AdminNoiseController)

  AdminNoiseController.$inject = [
    '$translate',
    '$http',
    '$window',
    'QueryFilterService',
    'ApiAdminSourceModel',
    'ApiSourceModel',
  ]

  function AdminNoiseController(
    $translate,
    $http,
    $window,
    QueryFilterService,
    ApiAdminSourceModel,
    ApiSourceModel,
  ) {
    var vm = this
    vm.filterData = filterData
    vm.exportNoiseData = exportNoiseData
    vm.getNoiseEntries = getNoiseEntries

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getNoiseEntries()
      getEnums()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = 'incomeDate'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/sources/meta/noise-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getNoiseEntries() {
      vm.loadingData = true
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      ApiAdminSourceModel.getSearchedNoiseEntries(
        {},
        search,
        function (entries) {
          vm.noiseEntries = entries.data
          vm.totalCount = entries.totalCount
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function filterData() {
      vm.currentPage = 1
      getNoiseEntries()
    }

    function getEnums() {
      vm.noiseStates = ApiSourceModel.getNoiseStates({ cultureCode: $translate.use() })
    }

    function exportNoiseData() {
      var search = QueryFilterService.getSearchObject(
        vm.totalCount,
        1,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      return ApiAdminSourceModel.getNoiseDataExport(search, function (data) {
        $window.saveAs(data.blob, data.fileName)
      })
    }
  }
})()
