;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppHydrology', {
    templateUrl: 'app/modules/front/homepage/components/weather/widgets/hydrology.html',
    controller: HydrologyController,
  })

  HydrologyController.$inject = ['ApiWeatherModel', 'ApiSourceModel']

  function HydrologyController(ApiWeatherModel, ApiSourceModel) {
    const vm = this

    activate()

    function activate() {
      getRiverStations()
      getRiversSpaTypes()
    }

    function getRiverStations() {
      vm.loadingRiverStationsData = true
      vm.riverStations = ApiWeatherModel.getRiverStations(
        () => {
          vm.loadingRiverStationsData = false
        },
        () => {
          vm.loadingRiverStationsData = false
        },
      )
    }

    function getRiversSpaTypes() {
      vm.riversSpaTypes = ApiSourceModel.getRiversSpaTypes()
    }
  }
})()
