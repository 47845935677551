;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminUserModel', ApiAdminUserModel)

  ApiAdminUserModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminUserModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/users/:id/:path/:id1/:path1',
      { id: '@id', path: '@path', id1: '@id1', path1: '@path1' },
      {
        getAllUsers: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('users')) {
              return data.users
            }
            return data
          },
        },
        downloadUsers: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
        createUser: {
          method: 'POST',
        },
        getUser: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('user')) {
              return data.user
            }
            return data
          },
        },
        editUser: {
          method: 'PUT',
        },
        getAllRoles: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'roles',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('roles')) {
              return data.roles
            }
            return data
          },
        },
        getUserRole: {
          method: 'GET',
          params: {
            path: 'roles',
          },
        },
        editRole: {
          method: 'PUT',
          params: {
            path: 'roles',
          },
        },
        getAllDistricts: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'districts',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('districts')) {
              return data.districts
            }
            return data
          },
        },
        getInitiativeAdminUsers: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'initiativesManagers',
          },
          transformResponse: UtilService.simpleResponseTransform('users'),
        },
      },
    )
  }
})()
