;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('ArticleTreeService', ArticleTreeService)

  ArticleTreeService.$inject = ['$filter', '$translate', 'ApiArticleModel']

  function ArticleTreeService($filter, $translate, ApiArticleModel) {
    return {
      getArticleTree: () => {
        return ApiArticleModel.getArticleTree(
          { cultureCode: $translate.use() },
          (tree) => {
            tree.childArticles = fillStaticArticlesToTree(tree)
            return tree
          },
          () => {
            return getStaticSections()
          },
        )
      },
    }

    function fillStaticArticlesToTree(tree) {
      var childArticles = $filter('orderBy')(tree.childArticles, 'order') || []

      var staticSections = getStaticSections()
      angular.forEach(staticSections, (staticSection) => {
        childArticles.push(staticSection)
      })

      return childArticles
    }

    function getStaticSections() {
      return [
        {
          childArticles: [],
          routerState: 'front.document',
          title: $translate.instant('FRONT.LAYOUT.CONTROLLER.FRONT_LAYOUT.SECTIONS.DOCUMENTS'),
        },
      ]
    }
  }
})()
