;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiEventModel', ApiEventModel)

  ApiEventModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiEventModel($resource, UtilService, APP_CONFIG) {
    var cultureFields = [
      'cultureCode',
      'location',
      'characteristic',
      'title',
      'perex',
      'keywords',
      'description',
      'cooperation',
      'actionSteps',
      'actionOSKS',
      'relativeUrl',
      'publication',
      'image',
      'attachment',
    ]

    function transformEventResponse(eventObj) {
      return _.assign(eventObj, eventObj.eventCultures[0])
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'events/:id/:path/:path1',
      { id: '@id', path: '@path', path1: '@path1' },
      {
        getAll: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('events')) {
              return data.events
            }
            return data
          },
        },
        getSingle: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('event')) {
              var event = data.event
              if (event && event.hasOwnProperty('sourceData')) {
                event.sourceData = angular.fromJson(event.sourceData)
              }
              return event
            }
            return data
          },
        },
        getEvents: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('events')) {
              var events = _.map(data.events, function (obj) {
                if (obj && obj.hasOwnProperty('sourceData')) {
                  obj.sourceData = angular.fromJson(event.sourceData)
                }
                return obj
              })
              return { events: events, totalCount: data.totalCount }
            }
            return data
          },
        },
        getWarning: {
          method: 'GET',
          params: {
            path: 'warning',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('event')) {
              var event = data.event
              if (event && event.hasOwnProperty('sourceData')) {
                event.sourceData = angular.fromJson(event.sourceData)
              }
              return event
            }
            return data
          },
        },
        getWarningDetail: {
          method: 'GET',
          params: {
            path: 'warningDetail',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('event')) {
              var event = data.event
              if (event && event.hasOwnProperty('sourceData')) {
                event.sourceData = angular.fromJson(event.sourceData)
              }
              return event
            }
            return data
          },
        },
        getSeverities: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'severities',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('severity')) {
              return data.severity
            }
            return data
          },
        },
        getPriorities: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'priorities',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('priority')) {
              return data.priority
            }
            return data
          },
        },
        getReportTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'reportTypes',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('sourceTypes')) {
              return data.sourceTypes
            }
            return data
          },
        },
        getGroupTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'groupTypes',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('eventGroupTypes')) {
              return data.eventGroupTypes
            }
            return data
          },
        },
        getEventTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'types',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('eventTypes')) {
              return data.eventTypes
            }
            return data
          },
        },
        getEventPublications: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'publicationType',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('publicationTypes')) {
              return data.publicationTypes
            }
            return data
          },
        },
        getEventNotifications: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'notifications',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('eventNotifications')) {
              return data.eventNotifications.map(transformEventResponse)
            }
            return data
          },
        },
        updateEventNotifications: {
          method: 'PUT',
          params: {
            path: 'notifications',
          },
        },
        getPublicationTargetTypes: {
          method: 'GET',
          params: {
            path: 'targetTypes',
          },
        },
        downloadEvent: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
      },
    )
  }
})()
