;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('EditFileModalController', EditFileModalController)

  EditFileModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminCdnModel',
    'FlashMessage',
    'UtilService',
    'file',
  ]

  function EditFileModalController(
    $uibModalInstance,
    ApiAdminCdnModel,
    FlashMessage,
    UtilService,
    file,
  ) {
    const vm = this
    var map = {
      fileId: 'id',
      fileName: 'name',
      fileAlias: 'alias',
    }
    vm.file = UtilService.remapObjectAttributes(file, map)
    vm.ok = ok
    vm.cancel = cancel
    

    function ok() {
      if (vm.file.content) {
        editFile()
      } else {
        editFileInfo()
      }
    }

    function editFileInfo() {
      var promise = ApiAdminCdnModel.editFileInfo(
        { id: vm.file.id },
        { fileInfo: vm.file },
        function () {
          angular.extend(file, {
            public: vm.file.public,
            fileId: vm.file.id,
            fileAlias: vm.file.alias,
            fileName: vm.file.name,
            keywords: vm.file.keywords,
          })
          FlashMessage.add('Soubor ' + vm.file.alias + ' byl úspěšně editován.', 'success')
        },
      )
      $uibModalInstance.close(promise)
    }

    function editFile() {
      var promise = ApiAdminCdnModel.editFile({ id: vm.file.id }, { file: vm.file }, function () {
        angular.extend(file, {
          public: vm.file.public,
          fileId: vm.file.id,
          fileAlias: vm.file.alias,
          fileName: vm.file.name,
          keywords: vm.file.keywords,
        })
        FlashMessage.add('Soubor ' + vm.file.alias + ' byl úspěšně přenahrán.', 'success')
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
