;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminSafetyModel', ApiAdminSafetyModel)

  ApiAdminSafetyModel.$inject = ['$resource', 'APP_CONFIG']

  function ApiAdminSafetyModel($resource, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/:path/:path1',
      { path: '@path', id: '@id' },
      {
        savePolygons: {
          method: 'POST',
          params: {
            path: 'maps',
            path1: 'safety',
          },
        },
      },
    )
  }
})()
