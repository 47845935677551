;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('join', JoinFilter)

  JoinFilter.$inject = []

  function JoinFilter() {
    return function (input, separator = ', ') {
      if (angular.isDefined(input) && angular.isArray(input)) {
        return input.reduce((sum, next) => {
          if (sum === '') {
            return next
          } else {
            return sum + separator + next
          }
        }, '')
      }
      return input
    }
  }
})()
