;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('arrayToString', ArrayToStringFilter)

  ArrayToStringFilter.$inject = []

  function ArrayToStringFilter() {
    return function (items, attribute, collection, delimiter) {
      if (items && Array.isArray(items)) {
        if (attribute) {
          items = _.map(items, attribute)
        }

        var mappedItems = []
        angular.forEach(items, function (item) {
          angular.forEach(collection || [], function (obj) {
            if (obj.id === item && obj.name) {
              mappedItems.push(obj.name)
            }
          })
        })

        if (mappedItems.length > 0) {
          items = mappedItems
        }
        return _.filter(items).join(delimiter || ', ')
      }
      return items
    }
  }
})()
