;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('pagination', PaginationFilter)

  PaginationFilter.$inject = []

  function PaginationFilter() {
    return function (input, start) {
      if (angular.isDefined(input)) {
        return input.slice(start)
      }
      return input
    }
  }
})()
