;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').component('cppCameras', {
    templateUrl: 'app/modules/front/map/components/cameras/index.html',
    controller: CamerasController,
  })

  CamerasController.$inject = ['ApiUserModel', 'ApiCameraModel']

  function CamerasController(ApiUserModel, ApiCameraModel) {
    const vm = this

    activate()

    function activate() {
      getCameras()
    }

    function getCameras() {
      vm.loadingData = true
      ApiUserModel.getCurrentUser((user) => {
        ApiCameraModel.getAll(
          {
            includePublic: true,
            includePrivate: user.usersAccessRights.privateAccess,
          },
          (cameras) => {
            vm.cameras = cameras
            vm.loadingData = false
          },
          () => {
            vm.loadingData = false
          },
        )
      })
    }
  }
})()
