;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.layout').component('cppHeader', {
    templateUrl: 'app/modules/front/layout/components/header/index.html',
    controller: HeaderController,
    controllerAs: 'headerCtrl',
  })

  HeaderController.$inject = ['$transitions']

  function HeaderController($transitions) {
    const vm = this
    vm.open = false
    $transitions.onFinish({}, function (transition) {
      vm.open = false
    })
  }
})()
