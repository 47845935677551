;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.event').controller('AdminEventController', AdminEventController)

  AdminEventController.$inject = [
    '$http',
    '$state',
    'QueryFilterService',
    'ApiAdminEventModel',
    'ApiEventModel',
    'PersistenceModel',
    'ApiFilterService',
    'APP_CONFIG',
  ]

  function AdminEventController(
    $http,
    $state,
    QueryFilterService,
    ApiAdminEventModel,
    ApiEventModel,
    PersistenceModel,
    ApiFilterService,
    APP_CONFIG,
  ) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.createEvent = createEvent

    vm.filterData = filterData
    vm.getEvents = getEvents
    vm.downloadEvents = downloadEvents

    activate()

    function activate() {
      initSearchQuery()
      getEvents()
      prepareTableHeaders()
      getEventSeverities()
      getEventPriorities()
      getEventTypes()
      getEventPublications()
    }

    function initSearchQuery() {
      vm.query = {
        enum: {
          eventCultures: {
            publication: { publicationType: ['PUBLISHED'] },
          },
        },
      }
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = 'created'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/event/meta/event-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getEvents() {
      vm.loadingData = true
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      ApiAdminEventModel.getSearchedEvents(
        { cultureCode: APP_CONFIG.translation.fallbackLanguage },
        search,
        function (data) {
          vm.events = data.events
          vm.totalCount = data.totalCount
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function downloadEvents(format) {
      var search = ApiFilterService.transformSearchQueryAttribute(
        vm.query,
        ApiFilterService.KEYS.event,
      )
      ApiAdminEventModel.downloadAll(
        {},
        {
          orderBy: vm.orderByField,
          desc: vm.reverseSort,
          search: search,
          format: format,
        },
      )
    }

    function filterData() {
      vm.currentPage = 1
      getEvents()
    }

    function getEventSeverities() {
      vm.eventSeverities = ApiEventModel.getSeverities()
    }

    function getEventPriorities() {
      vm.eventPriorities = ApiEventModel.getPriorities()
    }

    function getEventTypes() {
      vm.eventTypes = ApiEventModel.getEventTypes({
        cultureCode: APP_CONFIG.translation.fallbackLanguage,
      })
    }

    function getEventPublications() {
      vm.eventPublications = ApiEventModel.getEventPublications()
    }

    function createEvent() {
      PersistenceModel.clearSourceDataForEvent()
      $state.go('admin.event-create', { id: null }, { reload: true })
    }
  }
})()
