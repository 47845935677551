;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('IzsMapService', IzsMapService)

  IzsMapService.$inject = [
    '$rootScope',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'PointLayer',
    'LineLayer',
    'CustomLayer',
    'LayerProviderFactory',
    'APP_CONFIG',
  ]

  function IzsMapService(
    $rootScope,
    leafletData,
    MapHelperService,
    MapDialogService,
    PointLayer,
    LineLayer,
    CustomLayer,
    LayerProviderFactory,
    APP_CONFIG,
  ) {
    var defaultOpacity = 0.8
    var defaultWeight = 3

    function initIzsLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.izsLayers.pointLayers,
        function (pointLayerConfiguration) {
          var pointLayer = new PointLayer(
            angular.extend(pointLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: MapDialogService[pointLayerConfiguration.dialogFn],
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(pointLayer, layerCollection)
        },
      )
      angular.forEach(
        APP_CONFIG.mapSettings.izsLayers.lineLayers,
        function (lineLayerConfiguration) {
          var lineLayer = new LineLayer(
            angular.extend(lineLayerConfiguration, {
              fillOpacity: 0,
              weight: defaultWeight,
              opacity: defaultOpacity,
              simplifyFactor: 0,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(lineLayer, layerCollection)
        },
      )
      angular.forEach(APP_CONFIG.mapSettings.izsLayers.customLayers, function (customLayerConfig) {
        var customLayer = new CustomLayer(
          angular.extend(customLayerConfig, {
            layer: LayerProviderFactory.provide(map, customLayerConfig.layerProvider).getLayer(),
          }),
        )
        MapHelperService.addLayer(customLayer, layerCollection)
      })
    }

    function initIzsMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initIzsLayers(map, layerCollection)
      })
    }

    return {
      initIzsMap: initIzsMap,
      initIzsLayers: initIzsLayers,
    }
  }
})()
