;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('EditCameraAvailabilityModalController', EditCameraAvailabilityModalController)

  EditCameraAvailabilityModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminUserModel',
    'FlashMessage',
    'role',
  ]

  function EditCameraAvailabilityModalController(
    $uibModalInstance,
    ApiAdminUserModel,
    FlashMessage,
    role,
  ) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel
    vm.role = role
    

    function ok() {
      var promise = ApiAdminUserModel.editRole({ id1: vm.role.id }, { role: vm.role }, function () {
        FlashMessage.add('Četnost zobrazení byla úspěšně editována.', 'success')
      })
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
