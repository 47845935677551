;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapDialogService', MapDialogService)

  MapDialogService.$inject = ['$compile', '$templateRequest', 'MapHelperService']

  function MapDialogService($compile, $templateRequest, MapHelperService) {
    var popupData = {}

    function _openDialog(data, map, latlng, popupScope, template, options) {
      _setPopupData(data)
      map.setView(latlng)
      $templateRequest(template).then(function (data) {
        var linkFunction = $compile(data)
        L.popup(
          MapHelperService.extendLeafletPopupDefaultOptions(
            angular.extend({ minWidth: 500 }, options),
          ),
        )
          .setLatLng(latlng)
          .setContent(linkFunction(popupScope)[0])
          .openOn(map)
      })
    }

    function openDialogCadastre(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/cadastreMapModal.html', options)
    }

    function openDialogSiren(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/sirenMapModal.html', options)
    }

    function openDialogAddresses(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/addressesMapModal.html', options)
    }

    function openDialogPolice(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/policeMapModal.html', options)
    }

    function openDialogEmergency(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/emergencyMapModal.html', options)
    }

    function openDialogFireBrigade(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/fireBrigadeMapModal.html',
        options,
      )
    }

    function openDialogFireBrigade(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/fireBrigadeMapModal.html',
        options,
      )
    }

    function openDialogVolunteerFireBrigade(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/volunteerFireBrigadeMapModal.html',
        options,
      )
    }

    function openDialogMunicipalPolice(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/municipalPoliceMapModal.html',
        options,
      )
    }

    function openDialogFloodBarrier(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/floodBarrierMapModal.html',
        options,
      )
    }

    function openDialogParkingMachine(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/parkingMachineMapModal.html',
        options,
      )
    }

    function openDialogBollard(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/bollardMapModal.html', options)
    }

    function openDialogSypos(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/syposMapModal.html', options)
    }

    function openDialogRectorate(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/rectorateMapModal.html', options)
    }

    function openDialogFaculty(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/facultyMapModal.html', options)
    }

    function openDialogOtherEducation(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/otherEducationMapModal.html',
        options,
      )
    }

    function openRestaurant(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/restaurantMapModal.html',
        options,
      )
    }

    function openPassability(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/passabilityMapModal.html',
        options,
      )
    }

    function openDialogZoneZps(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/zoneZpsMapModal.html', options)
    }

    function openDialogHazardousAreas(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/hazardousAreasMapModal.html',
        options,
      )
    }

    function openDialogDdrMessages(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/ddrMessageMapModal.html',
        options,
      )
    }

    function openDialogShelters(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/sheltersMapModal.html', options)
    }

    function openDialogEvents(data, map, latlng, popupScope, options) {
      _openDialog(data, map, latlng, popupScope, 'app/modules/front/map/modal/eventMapModal.html', options)
    }

    function openDialogFutureCameras(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/futureCamerasMapModal.html',
        options,
      )
    }

    function openDialogInitiatives(data, map, latlng, popupScope, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/initiativesMapModal.html',
        options,
      )
    }

    function openDialogDynamics(data, map, latlng, popupScope, source, options) {
      _openDialog(
        data,
        map,
        latlng,
        popupScope,
        'app/modules/front/map/modal/' + source.toLowerCase() + 'DynamicsMapModal.html',
        options,
      )
    }

    function _setPopupData(data) {
      popupData = data
    }

    function getPopupData() {
      return popupData
    }

    return {
      getPopupData: getPopupData,
      openDialogCadastre: openDialogCadastre,
      openDialogSiren: openDialogSiren,
      openDialogAddresses: openDialogAddresses,
      openDialogPolice: openDialogPolice,
      openDialogEmergency: openDialogEmergency,
      openDialogFireBrigade: openDialogFireBrigade,
      openDialogVolunteerFireBrigade: openDialogVolunteerFireBrigade,
      openDialogMunicipalPolice: openDialogMunicipalPolice,
      openDialogFloodBarrier: openDialogFloodBarrier,
      openDialogParkingMachine: openDialogParkingMachine,
      openDialogBollard: openDialogBollard,
      openDialogRectorate: openDialogRectorate,
      openDialogFaculty: openDialogFaculty,
      openRestaurant: openRestaurant,
      openDialogOtherEducation: openDialogOtherEducation,
      openDialogSypos: openDialogSypos,
      openDialogZoneZps: openDialogZoneZps,
      openDialogHazardousAreas: openDialogHazardousAreas,
      openDialogDdrMessages: openDialogDdrMessages,
      openDialogShelters: openDialogShelters,
      openDialogEvents: openDialogEvents,
      openDialogFutureCameras: openDialogFutureCameras,
      openDialogInitiatives: openDialogInitiatives,
      openDialogDynamics: openDialogDynamics,
      openPassability: openPassability,
    }
  }
})()
