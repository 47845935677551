;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.event')
    .controller('AdminCreateEventController', AdminCreateEventController)

  AdminCreateEventController.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    '$uibModal',
    'ApiEventModel',
    'ApiAdminEventModel',
    'ApiUserModel',
    'ApiAdminUserModel',
    'PersistenceModel',
    'EventService',
    'EventMapService',
    'MapSearchService',
    'MapHelperService',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function AdminCreateEventController(
    $rootScope,
    $state,
    $stateParams,
    $uibModal,
    ApiEventModel,
    ApiAdminEventModel,
    ApiUserModel,
    ApiAdminUserModel,
    PersistenceModel,
    EventService,
    EventMapService,
    MapSearchService,
    MapHelperService,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.modelCultureData = [
      'cultureCode',
      'location',
      'characteristic',
      'title',
      'perex',
      'keywords',
      'description',
      'cooperation',
      'actionSteps',
      'actionOSKS',
      'relativeUrl',
      'attachment',
    ]

    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.hasHeadManagerRole = PersistenceModel.hasHeadManagerRole()
    vm.hasEventPublisherRole = PersistenceModel.hasEventPublisherRole()
    vm.hasTranslatorRole = PersistenceModel.hasTranslatorRole()
    vm.isEventEdit = $stateParams.isEventEdit
    // vm.asyncLoad = !!($stateParams.isEventEdit || $stateParams.id);
    vm.data = {
      mapLayers: [],
      legend: [],
    }

    vm.createEvent = createEvent
    vm.publishEvent = publishEvent
    vm.editAndUnpublishEvent = editAndUnpublishEvent
    vm.coordinateChanged = coordinateChanged
    vm.openUploadImageModal = openUploadImageModal
    vm.openUploadDocumentModal = openUploadDocumentModal
    vm.isWebPublicationType = isWebPublicationType
    vm.isEmailPublicationType = isEmailPublicationType
    vm.eventPublicationTypeChanged = eventPublicationTypeChanged

    activate()

    function activate() {
      getUserRefreshRate().then(function (user) {
        vm.userCameraRefreshRate = user.usersAccessRights.cameraRefreshRate * 1000
        vm.userPrivateCameraAccess = user.usersAccessRights.privateAccess

        initEventSourceData()
      })
      getEventTypes()
      getEventDistricts()
      getUserRoles()
      getPublicationTargetTypes()
    }

    function initMap() {
      EventMapService.initEventMap(
        vm.data,
        vm.event,
        null,
        openCameraDetail,
        vm.userPrivateCameraAccess,
      )
    }

    function getUserRefreshRate() {
      return ApiUserModel.getCurrentUser().$promise
    }

    function openCameraDetail(grid, camera) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/cameraDetailModal.html',
        controller: 'CameraDetailModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            camera.disabled = true
            return camera
          },
          refreshRate: function () {
            return vm.userCameraRefreshRate
          },
        },
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }

    function getEventTypes() {
      vm.eventTypes = ApiEventModel.getEventTypes({
        cultureCode: APP_CONFIG.translation.fallbackLanguage,
      })
    }

    function getEventDistricts() {
      vm.districts = ApiUserModel.getAllDistricts()
    }

    function getPublicationTargetTypes() {
      vm.eventPublications = ApiEventModel.getPublicationTargetTypes()
    }

    function initEventSourceData() {
      if ($stateParams.id) {
        ApiAdminEventModel.getSingle({ id: $stateParams.id }, function (event) {
          vm.event = angular.extend(event, {
            validSince: event.validSince || moment().format(),
            publication: angular.extend(event.publication, setDefaultPublication()),
          })
          if (!vm.isEventEdit) {
            vm.event.id = null
            vm.event.publication.publicationType = null
          }
          initMap()
          searchAddress(vm.event)
        })
      } else {
        var event = EventService.loadSourceDataForEvent()
        vm.event = angular.extend(event, {
          source: 'Praha1.online',
          validSince: event.validSince || moment().format(),
          publication: setDefaultPublication(),
        })
        initMap()
        searchAddress(vm.event)
      }
    }

    function setDefaultPublication() {
      return {
        publishTarget: vm.hasEventPublisherRole || vm.hasTranslatorRole ? 'WEB_AND_EMAIL' : 'EMAIL',
        private: false,
        publishForRolesByEmail:
          vm.hasEventPublisherRole || vm.hasTranslatorRole
            ? ['REGISTERED_USER', 'CRISIS_MANAGER', 'HEAD_MANAGER']
            : ['CRISIS_MANAGER', 'HEAD_MANAGER'],
        emailToDistricts: ['PRAHA_1'],
      }
    }

    function searchAddress(event) {
      var address = ''
      if (event.street) {
        address = event.street
        if (event.houseNumber) {
          doAddressSearch(address + ' ' + event.houseNumber)
        }
        if (event.landRegistryNumber) {
          doAddressSearch(address + ' ' + event.landRegistryNumber)
        }
      }
    }

    function doAddressSearch(address) {
      MapSearchService.findAddresses(address).then(function (results) {
        var data = []
        data = data.concat(results)
        data = data.slice(0, 1)

        if (data.length > 0) {
          leafletData.getMap('map').then(function (map) {
            MapHelperService.moveToLocation(data[0], '', map, null, true)
          })
        }
      })
    }

    function createEvent() {
      vm.createEventForm.$setSubmitted()
      vm.event.publication = null
      if (vm.hasEventPublisherRole || vm.hasTranslatorRole) {
        if (vm.isEventEdit) {
          ApiAdminEventModel.editEvent({ id: vm.event.id }, vm.event, function () {
            FlashMessage.add(
              'Aktualita ' + vm.event.title + ' byla úspěšně editována.',
              'success',
              1,
            )
            $state.go('admin.event', {}, { reload: true })
          })
        } else {
          vm.event.id = null
          ApiAdminEventModel.createEvent(vm.event, function () {
            FlashMessage.add(
              'Aktualita ' + vm.event.title + ' byla úspěšně vytvořena.',
              'success',
              1,
            )
            $state.go('admin.event', {}, { reload: true })
          })
        }
      } else {
        FlashMessage.add('Nemáte oprávnění vytvořit aktualitu.')
      }
    }

    function publishEvent() {
      vm.createEventForm.$setSubmitted()
      vm.event.publication.publicationType = 'PUBLISHED'
      if (vm.hasEventPublisherRole || vm.hasTranslatorRole) {
        if (vm.isEventEdit) {
          ApiAdminEventModel.editEvent({ id: vm.event.id }, vm.event, function () {
            FlashMessage.add(
              'Aktualita ' + vm.event.title + ' byla úspěšně editována a publikována.',
              'success',
              1,
            )
            $state.go('admin.event', {}, { reload: true })
          })
        } else {
          ApiAdminEventModel.createEvent(vm.event, function (event) {
            FlashMessage.add(
              'Aktualita ' + vm.event.title + ' byla úspěšně vytvořena a publikována.',
              'success',
              1,
            )
            $state.go('admin.event', {}, { reload: true })
          })
        }
      } else if (vm.hasHeadManagerRole) {
        ApiAdminEventModel.createEventEmail(vm.event, function (event) {
          FlashMessage.add(
            'Aktualita ' + vm.event.title + ' byla úspěšně vytvořena a publikována na email.',
            'success',
            1,
          )
          $state.go('admin.event', {}, { reload: true })
        })
      } else {
        FlashMessage.add('Nemáte oprávnění publikovat aktualita.')
      }
    }

    function editAndUnpublishEvent() {
      if (vm.hasEventPublisherRole || vm.hasTranslatorRole || vm.hasHeadManagerRole) {
        vm.event.publication = null
        if (vm.isEventEdit) {
          ApiAdminEventModel.editEvent({ id: vm.event.id }, vm.event, function () {
            ApiAdminEventModel.unpublishEvent(
              { id: vm.event.id, cultureCode: vm.event.cultureCode },
              function () {
                FlashMessage.add(
                  'Aktualita ' + vm.event.title + ' byla úspěšně editována a odpublikována.',
                  'success',
                  1,
                )
                $state.go('admin.event', {}, { reload: true })
              },
            )
          })
        }
      } else {
        FlashMessage.add('Nemáte oprávnění odpublikovat aktualitu.')
      }
    }

    function openUploadImageModal() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadImageModal.html',
        controller: 'UploadImageModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function (images) {
          angular.forEach(images, function (image) {
            vm.event.image = image.fileInfo
          })
        },
        function () {},
      )
    }

    function openUploadDocumentModal() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadDocumentModal.html',
        controller: 'UploadDocumentModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function (files) {
          angular.forEach(files, function (file) {
            vm.event.attachment = file.fileInfo
          })
        },
        function () {},
      )
    }

    function coordinateChanged() {
      if (vm.event.coordinateX && vm.event.coordinateY) {
        EventMapService.updateMarkerPosition(vm.event.coordinateY, vm.event.coordinateX)
      }
    }

    function getUserRoles() {
      ApiAdminUserModel.getAllRoles(function (roles) {
        vm.roles = _.filter(roles, function (obj) {
          if (vm.hasEventPublisherRole || vm.hasTranslatorRole) {
            return (
              obj.id === 'REGISTERED_USER' ||
              obj.id === 'CRISIS_MANAGER' ||
              obj.id === 'HEAD_MANAGER'
            )
          }
          return obj.id === 'CRISIS_MANAGER' || obj.id === 'HEAD_MANAGER'
        })
      })
    }

    function isWebPublicationType() {
      return vm.event && vm.event.publication ? vm.event.publication.publishTarget === 'WEB' : false
    }

    function isEmailPublicationType() {
      return vm.event && vm.event.publication
        ? vm.event.publication.publishTarget === 'EMAIL'
        : false
    }

    function eventPublicationTypeChanged() {
      if (vm.event.publication) {
        vm.event.publication.private = true
      }
    }

    // map events
    $rootScope.$on('mapAddressSearched', function (event, address) {
      if (!address.isStreetModel) {
        var administrativeDistrict = null
        var cityDistrict = address.nazevMestCast

        if (address.autoSearch === true) {
          vm.event.coordinateX = address.geometry.coordinates[0]
          vm.event.coordinateY = address.geometry.coordinates[1]
        } else {
          vm.event.street = address.nazevUlice
          vm.event.cityDistrict = cityDistrict
          vm.event.cityPart = address.nazevCastObce
          if (administrativeDistrict) {
            vm.event.administrativeDistrict = administrativeDistrict
          }
          vm.event.houseNumber = address.cisloOrientacni
          vm.event.landRegistryNumber = address.cisloDomovni
          vm.event.coordinateX = address.geometry.coordinates[0]
          vm.event.coordinateY = address.geometry.coordinates[1]
        }
      } else {
        vm.event.street = address.mkn
      }
    })
  }
})()
