;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.user').controller('AdminUserInboxController', AdminUserInboxController)

  AdminUserInboxController.$inject = [
    '$http',
    '$translate',
    'ApiEventModel',
    'FlashMessage',
    'APP_CONFIG',
  ]

  function AdminUserInboxController(
    $http,
    $translate,
    ApiEventModel,
    FlashMessage,
    APP_CONFIG,
  ) {
    const vm = this
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.markEventsAsRead = markEventsAsRead

    activate()

    function activate() {
      prepareTableHeaders()
      getEventTypes()
      getEvents()
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/user/meta/user-inbox-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getEvents() {
      vm.loadingData = true
      vm.events = ApiEventModel.getEventNotifications(
        { cultureCode: $translate.use() },
        function () {
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function getEventTypes() {
      vm.eventTypes = ApiEventModel.getEventTypes({
        cultureCode: $translate.use(),
      })
    }

    function markEventsAsRead(event) {
      ApiEventModel.updateEventNotifications({ eventIds: [event.id] }, function () {
        FlashMessage.add('Aktualita byla označena jako přečtená.', 'success')
        vm.event = null
        getEvents()
      })
    }
  }
})()
