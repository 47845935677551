;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('EventMapService', EventMapService)

  EventMapService.$inject = [
    'leafletData',
    'MapHelperService',
    'NewsMapService',
    'IzsMapService',
    'CameraMapService',
    'InitiativeMapService',
    'GeometryHelper',
  ]

  function EventMapService(
    leafletData,
    MapHelperService,
    NewsMapService,
    IzsMapService,
    CameraMapService,
    InitiativeMapService,
    GeometryHelper,
  ) {
    var marker = null,
      map = null

    function _initLayers(grid, map, layerCollection, onClickFunc, hasPrivateCameraAccess) {
      NewsMapService.initNewsLayers(map, layerCollection)
      // TrafficMapService.initTrafficLayers(map, layerCollection);
      // FloodsMapService.initFloodsLayers(map, layerCollection);
      // SheltersMapService.initSheltersLayers(map, layerCollection);
      // ChemistryMapService.initChemistryLayers(map, layerCollection);
      IzsMapService.initIzsLayers(map, layerCollection)
      InitiativeMapService.initInitiativeLayers(map, layerCollection)
      // CadastreMapService.initCadastreLayers(map, layerCollection);
      CameraMapService.initCameraLayers(
        grid,
        map,
        layerCollection,
        onClickFunc,
        hasPrivateCameraAccess,
      )
    }

    function _setEventPosition(marker, event) {
      var position = marker.getLatLng()
      event.coordinateX = position.lng
      event.coordinateY = position.lat
    }

    function _addMarker(latlng) {
      marker = new L.Marker(latlng, {
        draggable: true,
        icon: new L.Icon({
          iconUrl: 'styles/img/location-pin.png',
          shadowUrl: 'styles/img/marker-shadow.png',
          iconAnchor: new L.Point(16, 32),
          shadowAnchor: new L.Point(14, 40),
        }),
      })
      map.panTo(latlng)
    }

    function updateMarkerPosition(lat, lng) {
      var position = new L.LatLng(lat, lng)
      if (marker !== null) {
        marker.setLatLng(position)
        map.panTo(position)
      } else {
        _addMarker(position)
        map.addLayer(marker)
      }
    }

    function initEventMap(data, event, grid, onClickFunc, hasPrivateCameraAccess) {
      marker = null
      map = null
      MapHelperService.initViewModelMapData(data, 'eventsmap')

      leafletData.getMap('map').then(function (m) {
        map = m
        var layerCollection = MapHelperService.initMap(map, data)
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        _initLayers(grid, map, layerCollection, onClickFunc, hasPrivateCameraAccess)

        if (event.coordinateX && event.coordinateY) {
          var latlng = new L.LatLng(event.coordinateY, event.coordinateX)
          _addMarker(latlng)
          map.addLayer(marker)
        }

        map.on('click', function (e) {
          if (marker !== null) {
            map.removeLayer(marker)
          }
          _addMarker(e.latlng)
          _setEventPosition(marker, event)

          marker.on('dragend', function (evt) {
            var position = evt.target.getLatLng()
            updateMarkerPosition(position.lat, position.lng)
            _setEventPosition(marker, event)
          })
          map.addLayer(marker)
        })
      })
    }

    return {
      initEventMap: initEventMap,
      updateMarkerPosition: updateMarkerPosition,
    }
  }
})()
