;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppCap', {
    templateUrl: 'app/modules/front/homepage/components/weather/widgets/cap.html',
    controller: CapController,
  })

  CapController.$inject = ['ApiWeatherModel']

  function CapController(ApiWeatherModel) {
    const vm = this

    activate()

    function activate() {
      getCapData()
    }

    function getCapData() {
      vm.loadingCapData = true
      vm.capData = ApiWeatherModel.getCap(
        () => {
          vm.loadingCapData = false
        },
        () => {
          vm.loadingCapData = false
        },
      )
    }
  }
})()
