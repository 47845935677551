;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('HelperService', HelperService)

  HelperService.$inject = []

  function HelperService() {
    return {
      isNullOrUndefined: function (value) {
        return _.isUndefined(value) || _.isNull(value)
      },
      getAgGridLocalizedObject: function () {
        return {
          // for filter panel
          page: 'strana',
          more: 'více',
          to: 'do',
          of: 'z',
          next: 'další',
          last: 'poslední',
          first: 'první',
          previous: 'předchozí',
          loadingOoo: 'načítání...',

          // for set filter
          selectAll: 'vybrat vše',
          searchOoo: 'vyhledávání...',
          blanks: 'prázdné',

          // for number filter and text filter
          filterOoo: 'filtr...',
          applyFilter: 'potvrdit filtr...',

          // for number filter
          equals: 'rovná se',
          notEquals: 'nerovná se',
          lessThan: 'menší než',
          greaterThan: 'větší než',

          // for text filter
          contains: 'obsahuje',
          startsWith: 'začíná',
          endsWith: 'končí',

          // the header of the default group column
          group: 'skupina',

          // other
          noRowsToShow: 'žádná data',

          // standard menu
          copy: 'Kopírovat',
          ctrlC: 'Ctrl + C',
          paste: 'Vložit',
          ctrlV: 'Ctrl + C',
        }
      },
    }
  }
})()
