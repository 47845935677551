;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('schoolFilter', SchoolFilter)

  SchoolFilter.$inject = []

  function SchoolFilter() {
    const types = {
      universityBuildingType: {
        F: 'fakulta',
        K: 'kolej',
        M: 'menza',
        KAT: 'katedry',
        S: 'sport',
        SUZ: 'Správa účelových zařízení',
        U: 'ústav',
        CEL: 'celoškolské',
        A: 'administrativa',
      },
      universityName: {
        1: 'Bankovní institut Praha',
        2: 'Vysoká škola hotelová',
        3: 'Vysoká škola finanční a správní',
        4: 'Literární akademie J.Š.',
        5: 'Vysoká škola cestovního ruchu, hotelnictví a lázeňství',
        6: 'Soukromá vysoká škola ekonomických studií',
        7: 'Soukromá vysoká škola obchodní v Praze',
        8: 'Pražský technologický institut',
        9: 'Vysoká škola veřejné správy a mezinárodních vztahů v Praze',
        10: 'Vysoká škola mezinárodních a veřejných vztahů',
        11: 'Vysoká škola J. A. Komenského',
        12: 'Anglo-americký institut',
        13: 'Vysoká škola psychosociálních studií Praha',
        14: 'Vysoká škola aplikovaného práva',
        15: 'Vysoká škola ekonomie a managementu',
        16: 'Univerzity of NewYork of Prague',
        17: 'Vysoká škola manažerské informatiky a ekonomie',
        18: 'Mezinárodníbaptistický teologický seminář',
        19: 'Vysoká škola tělesné výchovy a sportu PALESTRA',
        20: 'Vysoká škola umělecko průmyslová',
        21: 'Vysoká škola chemicko technologická',
        22: 'Vysoká škola ekonomická',
        23: 'Policejní akademie',
        24: 'Akademie výtvarných umění',
        25: 'Akademie múzických umění',
        26: 'Česká zemědělská univerzita',
        27: 'České vysoké učení technické',
        28: 'Univerzita Karlova',
        29: 'AKCENT College s.r.o.',
        30: 'Metropolitní univerzita Praha',
        31: 'Vysoká škola zdravotnická',
        32: 'Archip s.r.o.',
        33: 'ART & DESIGN INSTITUT, s.r.o.',
        34: 'Unicorn College s.r.o.',
        35: 'CEVRO Institut, o. p. s.',
        36: 'Vysoká škola regionálního rozvoje, s. r. o.',
      },
      universityTypes: {
        SVŠ: 'soukromá',
        VVŠ: 'veřejná',
        STŠ: 'státní',
      },
    }

    return function (input, type) {
      if (angular.isDefined(input) && angular.isDefined(type)) {
        if (type in types) {
          if (input in types[type]) {
            return types[type][input]
          } else {
            console.warn('schoolFilter dost not resolve code ' + input + 'in type ' + type)
          }
        } else {
          console.warn('Type ' + type + 'is not defined in school filter')
        }
      }
      return ''
    }
  }
})()
