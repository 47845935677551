;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('MapSelectionService', MapSelectionService)

  MapSelectionService.$inject = ['leafletData', 'MapHelperService', 'GeometryHelper', '$rootScope']

  function MapSelectionService(leafletData, MapHelperService, GeometryHelper, $rootScope) {
    const marker = {
      instance: null,
      isFromSearch: false,
    }
    let map = null

    function initMapForSelect(data, position) {
      marker.instance = null
      map = null
      position = position || {}

      leafletData.getMap('map').then(function (m) {
        map = m
        data.bounds = GeometryHelper.convertToBoundingBox(map.getBounds())

        MapHelperService.initMap(map, data)
        if (position && position.longitude && position.latitude) {
          var latlng = new L.LatLng(position.latitude, position.longitude)
          _setMarker(latlng, position)
        }

        map.on('click', function (e) {
          _setMarker(e.latlng, position)
        })

        $rootScope.$on('mapAddressSearched', function (event, address, searchGroupLayer) {
          if (marker.instance) {
            marker.instance.remove()
          }
          marker.instance = searchGroupLayer
          marker.isFromSearch = true
          _setMarker(
            new L.LatLng(address.geometry.coordinates[1], address.geometry.coordinates[0]),
            position,
          )
        })
      })
    }

    function _setMarker(latlng, position) {
      if (marker.isFromSearch) {
        marker.instance.clearLayers()
        marker.instance = null
        marker.isFromSearch = false
      }

      if (marker.instance === null) {
        marker.instance = new L.Marker(latlng, {
          draggable: true,
          icon: new L.Icon({
            iconUrl: 'styles/img/location-pin.png',
            shadowUrl: 'styles/img/marker-shadow.png',
            iconAnchor: new L.Point(16, 32),
            shadowAnchor: new L.Point(14, 40),
          }),
        })
        marker.instance.addTo(map)
      } else {
        marker.instance.setLatLng(latlng)
      }
      _setPosition(marker.instance, position)

      marker.instance.on('dragend', function (evt) {
        _setPosition(marker.instance, position)
      })

      map.panTo(latlng)
    }

    function _setPosition(marker, position) {
      var markerPosition = marker.getLatLng()
      position.longitude = markerPosition.lng
      position.latitude = markerPosition.lat
    }

    return {
      initMapForSelect: initMapForSelect,
    }
  }
})()
