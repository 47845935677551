;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('AdminInitiativeController', AdminInitiativeController)

  AdminInitiativeController.$inject = [
    '$http',
    '$uibModal',
    'QueryFilterService',
    'PersistenceModel',
    'ApiAdminInitiativeModel',
    'ApiInitiativeModel',
    'ApiLanguageModel',
    'APP_CONFIG',
    'BOOL_VALUES',
  ]

  function AdminInitiativeController(
    $http,
    $uibModal,
    QueryFilterService,
    PersistenceModel,
    ApiAdminInitiativeModel,
    ApiInitiativeModel,
    ApiLanguageModel,
    APP_CONFIG,
    BOOL_VALUES,
  ) {
    var lang = APP_CONFIG.translation.fallbackLanguage

    const vm = this

    vm.reportedSelect = BOOL_VALUES
    vm.activeSelect = BOOL_VALUES

    vm.getInitiatives = getInitiatives
    vm.filterData = filterData
    vm.openChangeStateModal = openChangeStateModal
    vm.hasCurrentUserInitiativeCategoryRights = hasCurrentUserInitiativeCategoryRights

    activate()

    function activate() {
      initSearchQuery()
      prepareTableHeaders()
      getInitiatives()
      getInitiativeCategories()
      getInitiativeStateTypes()
      getLanguages()
    }

    function initSearchQuery() {
      vm.query = {}
      vm.pageSize = 20
      vm.currentPage = 1
      vm.reverseSort = true
      vm.orderByField = 'created'
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/initiative/meta/initiative-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function getInitiatives() {
      vm.loadingData = true
      var search = QueryFilterService.getSearchObject(
        vm.pageSize,
        vm.currentPage,
        vm.orderByField,
        vm.reverseSort,
        vm.query,
      )

      ApiAdminInitiativeModel.getSearchedInitiatives(
        {},
        search,
        function (data) {
          vm.initiatives = data.initiatives
          vm.totalCount = data.totalCount
          vm.loadingData = false
        },
        function (data) {
          vm.loadingData = false
        },
      )
    }

    function getInitiativeCategories() {
      vm.initiativeCategories = ApiAdminInitiativeModel.getInitiativeCategories({
        cultureCode: lang,
      })
    }

    function getInitiativeStateTypes() {
      vm.initiativeStateTypes = ApiInitiativeModel.getInitiativeStateTypes({
        cultureCode: lang,
      })
    }

    function getLanguages() {
      ApiLanguageModel.getAllLanguages(function (cultures) {
        vm.allLanguages = cultures
        cultures.unshift({ id: null, name: 'Nic nevybráno' })
      })
    }

    function hasCurrentUserInitiativeCategoryRights(initiative) {
      var currentUserId = PersistenceModel.getUserId()

      if (!initiative.responsiblePerson || initiative.responsiblePerson.id !== currentUserId) {
        return false
      }

      var initiativeCategoryAdmins = vm.initiativeCategories.filter(function (category) {
        return category.id === initiative.categoryId
      })

      if (initiativeCategoryAdmins.length > 0) {
        return initiativeCategoryAdmins[0].initiativesAdmins.indexOf(currentUserId) !== -1
      }
      return false
    }

    function filterData() {
      vm.currentPage = 1
      getInitiatives()
    }

    function openChangeStateModal(initiative) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/initiative/modal/changeState/changeStateModal.html',
        controller: 'ChangeStateModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          initiative: function () {
            return initiative
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getInitiatives()
          })
        },
        function () {},
      )
    }
  }
})()
