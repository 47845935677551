;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('EventLayerProvider', EventLayerProvider)

  EventLayerProvider.$inject = [
    '$rootScope',
    '$filter',
    '$translate',
    'ApiMapModel',
    'MapHelperService',
    'MapDialogService',
    'PersistenceModel',
    'CustomLayer',
  ]

  function EventLayerProvider(
    $rootScope,
    $filter,
    $translate,
    ApiMapModel,
    MapHelperService,
    MapDialogService,
    PersistenceModel,
    CustomLayer,
  ) {
    function initEventLayer(map, configuration, layerCollection) {
      _createPublicEventsLayer(map, configuration, layerCollection)
      if (PersistenceModel.hasCrisisRole()) {
        _createPrivateEventsLayer(map, configuration, layerCollection)
      }
    }

    function _createMarkerGroupLayer(configuration, key, layerCollection) {
      var markersGroup = {}
      angular.forEach(configuration, function (customLayer) {
        if (customLayer.key === key) {
          markersGroup = new L.MarkerClusterGroup({
            iconCreateFunction: MapHelperService.getCreateIconFunction(),
            disableClusteringAtZoom: 10,
          })

          var eventLayer = new CustomLayer(
            angular.extend(customLayer, {
              layer: markersGroup,
            }),
          )
          MapHelperService.addLayer(eventLayer, layerCollection)
        }
      })
      return markersGroup
    }

    function _createPublicEventsLayer(map, configuration, layerCollection) {
      var filter = {
        includePublic: true,
        cultureCode: $translate.use(),
        // includeInactive: true
      }
      var markersGroup = _createMarkerGroupLayer(configuration, 'publicnews', layerCollection)
      eventsToMarkers(map, filter, markersGroup)
    }

    function _createPrivateEventsLayer(map, configuration, layerCollection) {
      var filter = {
        includePrivate: true,
        cultureCode: $translate.use(),
        // includeInactive: true
      }
      var markersGroup = _createMarkerGroupLayer(configuration, 'privatenews', layerCollection)
      eventsToMarkers(map, filter, markersGroup, true)
    }

    function eventsToMarkers(map, filter, markersGroup, privateAccess) {
      ApiMapModel.getEvents(filter, function (mapEvents) {
        if (mapEvents.hasOwnProperty('events')) {
          angular.forEach(mapEvents.events, function (mapEvent) {
            mapEvent.imagePath = $filter('eventImage')(mapEvent, privateAccess)

            var markerOptions = {
              icon: new L.Icon({
                iconUrl: mapEvent.imagePath,
                iconSize: new L.point(24, 24),
                iconAnchor: new L.point(16, 16),
              }),
            }
            var marker = new L.marker([mapEvent.coordinateY, mapEvent.coordinateX], markerOptions)
            marker.on('click', function (event, args) {
              MapDialogService.openDialogEvents(
                mapEvent,
                map,
                marker.getLatLng(),
                $rootScope.$new(),
              )
            })
            markersGroup.addLayer(marker)
          })
        }
      })
    }

    return {
      initEventLayer: initEventLayer,
    }
  }
})()
