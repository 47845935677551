;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('PointLayer', PointLayer)

  PointLayer.$inject = ['Layer', 'MapHelperService']

  function PointLayer(Layer, MapHelperService) {
    function _createLayer(options, child) {
      var innerOptions = {
        clickable: false,
        opacity: options.opacity,
      }

      var layer = null
      if (options.disableLoadAtOnce) {
        innerOptions = _getInnerOptions(options, child)
        layer = options.disableClustering
          ? new L.esri.FeatureLayer(innerOptions)
          : L.markerClusterGroup(innerOptions)
      } else if (!options.disableClustering) {
        innerOptions.disableClusteringAtZoom = options.disableClusterAtZoom || 11
        innerOptions.iconCreateFunction = MapHelperService.getCreateIconFunction()

        layer = new L.MarkerClusterGroup(innerOptions)
      } else {
        layer = new L.FeatureGroup(innerOptions)
      }
      return layer
    }

    function _createQuery(options, layer, child) {
      return L.esri
        .query({
          url: options.url,
        })
        .where(options.where || '1=1')
        .fields('*')
        .run(function (err, featureCollection) {
          if (!featureCollection.features) {
            return
          }
          featureCollection.features.forEach(function (feature) {
            if (!feature.geometry || !feature.geometry.coordinates) {
              return
            }
            var marker = _getMarker(feature, options, child)
            layer.addLayer(marker)
          })
        })
    }

    function _getInnerOptions(options, child) {
      var innerOptions = {
        url: options.url,
        //clickable: (this.options.clickContext && this.options.clickFn),
        clickable: false,
        opacity: options.opacity,
        pointToLayer: function (geojson, latlng) {
          return _getMarker(geojson, options, child, latlng)
        },
      }
      if (options.where) {
        innerOptions.where = options.where
      }
      if (!options.disableClustering) {
        innerOptions.disableClusteringAtZoom = options.disableClusterAtZoom || 11
        innerOptions.iconCreateFunction = MapHelperService.getCreateIconFunction()
      }
      return innerOptions
    }

    function _getMarker(feature, options, child, latlng) {
      var iconUrlFromProperty = null
      if (options.propertyStyling) {
        var value = feature.properties[options.propertyStyling.featureProperty]
        iconUrlFromProperty = options.propertyStyling.valueMap[value]
      }

      var markerOptions = {
        icon:
          options.icon ||
          MapHelperService.createIcon('styles/img/' + (iconUrlFromProperty || options.iconUrl)),
      }

      if (!latlng) {
        latlng = L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0])
      }
      var marker = new L.marker(latlng, markerOptions)
      if (options.clickFn && options.clickContext) {
        marker.on('click', function (event) {
          if (options.queryUrl) {
            child.handleServerQuery(event)
          } else {
            options.clickFn.apply(options.clickContext, [
              feature.properties,
              options.map,
              latlng,
              options.popupScope,
            ])
          }
        })
      }
      return marker
    }

    return function (options) {
      var child = Object.create(Layer)
      child.super(options)

      child.init = function () {
        this.options = options
        this.layer = _createLayer(options, child)

        if (options.disableLoadAtOnce) {
          return child
        }
        _createQuery(options, this.layer, child)
        return child
      }

      return child.init()
    }
  }
})()
