;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('HzsMapService', HzsMapService)

  HzsMapService.$inject = [
    'leafletData',
    'MapHelperService',
    'LayerProviderFactory',
    'CustomLayer',
    'APP_CONFIG',
  ]

  function HzsMapService(
    leafletData,
    MapHelperService,
    LayerProviderFactory,
    CustomLayer,
    APP_CONFIG,
  ) {
    const marker = {
      instance: null,
      isFromSearch: false,
    }

    function initHzsLayers(map, layerCollection) {
      angular.forEach(APP_CONFIG.mapSettings.hzsLayers.customLayers, function (customLayerConfig) {
        const provider = LayerProviderFactory.provide(map, customLayerConfig.layerProvider)
        provider.update().then(() => {
          var customLayer = new CustomLayer(
            angular.extend(customLayerConfig, {
              layer: provider.getLayer(),
            }),
          )
          MapHelperService.addLayer(customLayer, layerCollection)
        })
      })
    }

    function initHzsMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initHzsLayers(map, layerCollection)
      })
    }

    return {
      initHzsMap: initHzsMap,
      initHzsLayers: initHzsLayers,
    }
  }
})()
