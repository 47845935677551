;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.cms')
    .controller('AdminCreateArticleController', AdminCreateArticleController)

  AdminCreateArticleController.$inject = [
    '$state',
    '$uibModal',
    'ApiAdminArticleModel',
    'ApiAdminDocumentModel',
    'FlashMessage',
  ]

  function AdminCreateArticleController(
    $state,
    $uibModal,
    ApiAdminArticleModel,
    ApiAdminDocumentModel,
    FlashMessage,
  ) {
    const vm = this
    vm.modelCommonData = ['parent', 'order']
    vm.article = {}

    vm.createArticle = createArticle
    vm.openEditStructure = openEditStructure

    activate()

    function activate() {
      vm.loadingData = true
    }

    vm.onTranslationInit = function (cultureCode) {
      ApiAdminDocumentModel.getStructure(
        { cultureCode: cultureCode },
        function (data) {
          vm.documentsStructure = data
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
      ApiAdminArticleModel.getArticleTree(
        { cultureCode: cultureCode },
        function (data) {
          vm.articlesStructure = data
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function createArticle(data, publish) {
      if (data.fileFolderId) {
        data.fileFolderId = data.fileFolderId.folderId ? data.fileFolderId.folderId : null
      }

      ApiAdminArticleModel.createArticle(data, function (article) {
        if (publish) {
          ApiAdminArticleModel.publishArticle(
            { cultureCode: data.cultureCode, id: article.id },
            function (warningResponse) {
              if (warningResponse && warningResponse.hasOwnProperty('errorMessage')) {
                FlashMessage.add(warningResponse.errorMessage, 'warning', 1)
              } else {
                FlashMessage.add(
                  'Článek ' + data.title + ' byl úspěšně vytvořen a publikován.',
                  'success',
                  1,
                )
              }
            },
          )
        } else {
          FlashMessage.add('Článek ' + data.title + ' byl úspěšně vytvořen.', 'success', 1)
        }

        ApiAdminArticleModel.getArticle({ id: article.id }, function (article) {
          var params = getRedirectPathParams(data.parent, article)
          $state.go('admin.cms', params, { reload: true })
        })
      })
    }

    function getRedirectPathParams(parentArticle, article) {
      if (angular.isUndefined(parentArticle.parentId) || parentArticle.parentId === null) {
        return { id: article.draft ? article.draft.id : article.id }
      } else {
        var parent = _.find(vm.articlesStructure[0].childArticles, ['id', parentArticle.parentId])
        if (parent && parent.parentId !== null) {
          return {
            id: parent.draft ? parent.draft.id : parent.id,
            childId: parentArticle.draft ? parentArticle.draft.id : parentArticle.id,
          }
        } else {
          return {
            id: parentArticle.draft ? parentArticle.draft.id : parentArticle.id,
            childId: article.draft ? article.draft.id : article.id,
          }
        }
      }
    }

    function openEditStructure() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/cms/modal/structure/editArticleStructureModal.html',
        controller: 'EditArticleStructureModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }
  }
})()
