;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.directives').directive('cpFileUpload', FileUploadDirective)

  FileUploadDirective.$inject = []

  function FileUploadDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        ngChange: '&?',
      },
      link: function (scope, element, attrs, ngModel) {
        element[0].addEventListener(
          'change',
          function (evt) {
            var file = evt.target.files[0]
            var reader = new FileReader()

            reader.onload = (function (theFile) {
              return function (e) {
                var model = angular.copy(ngModel.$viewValue) || {}
                model.content = e.target.result.replace(/^data:.*;base64,/, '')
                model.name = file.name
                // if (!model.alias) {
                model.alias = file.name
                // }
                model.type = file.type
                model.size = file.size
                scope.$apply(function () {
                  ngModel.$setViewValue(model)
                })
                if (scope.ngChange) {
                  scope.ngChange()
                }
              }
            })(file)
            reader.readAsDataURL(file)
          },
          false,
        )
      },
    }
  }
})()
