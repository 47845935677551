;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminCdnModel', ApiAdminCdnModel)

  ApiAdminCdnModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminCdnModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/cdn/:path/:id/:path1',
      { path: '@path', id: '@id', path1: '@path1' },
      {
        getContentTypes: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'files',
            path1: 'types',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('types')) {
              return data.types
            }
            return data
          },
        },
        createFile: {
          method: 'POST',
          params: {
            path: 'files',
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        getSingle: {
          method: 'GET',
          params: {
            path: 'files',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('file')) {
              return data.file
            }
            return data
          },
        },
        editFile: {
          method: 'PUT',
          params: {
            path: 'files',
          },
        },
        deleteFile: {
          method: 'DELETE',
          params: {
            path: 'files',
          },
        },
        getAllFileInfo: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'files',
            path1: 'info',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('fileInfos')) {
              return data.fileInfos
            }
            return data
          },
        },
        getFileInfo: {
          method: 'GET',
          params: {
            path: 'files',
            path1: 'info',
          },
        },
        editFileInfo: {
          method: 'PUT',
          params: {
            path: 'files',
            path1: 'info',
          },
        },
      },
    )
  }
})()
