;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.source').controller('AdminPubFormController', AdminPubFormController)

  AdminPubFormController.$inject = [
    '$state',
    '$stateParams',
    'MapSelectionService',
    'ApiAdminSourceModel',
    'FlashMessage',
  ]

  function AdminPubFormController(
    $state,
    $stateParams,
    MapSelectionService,
    ApiAdminSourceModel,
    FlashMessage,
  ) {
    const vm = this
    vm.isEdit = !!$stateParams.id
    vm.ok = ok

    vm.data = {
      mapLayers: [],
      legend: [],
    }

    activate()

    function activate() {
      if (vm.isEdit) {
        getPub()
      } else {
        vm.pub = { position: {} }
        initMap(vm.pub)
      }
    }

    function getPub() {
      ApiAdminSourceModel.getPub({ id: $stateParams.id }, (data) => {
        vm.pub = data.restaurant
        initMap(vm.pub)
      })
    }

    function initMap(pub) {
      MapSelectionService.initMapForSelect(vm.data, pub.position)
    }

    function ok() {
      if (vm.isEdit) {
        ApiAdminSourceModel.editPub({ id: vm.pub.id }, vm.pub, () => {
          FlashMessage.add(`Hospoda ${vm.pub.name} byla úspěšně editována.`, 'warning', 1)
          $state.go('admin.pub')
        })
      } else {
        ApiAdminSourceModel.createPub({}, vm.pub, () => {
          FlashMessage.add(`Hospoda ${vm.pub.name} byla úspěšně vytvořena.`, 'success', 1)
          $state.go('admin.pub')
        })
      }
    }
  }
})()
