;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').component('cppInitiatives', {
    templateUrl: 'app/modules/front/map/components/initiatives/index.html',
    controller: InitiativesController,
  })

  InitiativesController.$inject = ['$translate', 'ApiInitiativeModel', 'PersistenceModel', 'APP_CONFIG']

  function InitiativesController($translate, ApiInitiativeModel, PersistenceModel, APP_CONFIG) {
    const vm = this

    vm.pageSize = 20
    vm.currentPage = 1
    vm.baseUrl = APP_CONFIG.cdnUrl
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()
    vm.hasOnlyUserRole = PersistenceModel.hasOnlyUserRole()

    activate()

    function activate() {
      getInitiativeStateTypes()
      getInitiatives()
    }

    function getInitiativeStateTypes() {
      vm.stateTypes = ApiInitiativeModel.getInitiativeStateTypes({
        cultureCode: $translate.use(),
      })
    }

    function getInitiatives() {
      vm.loadingData = true
      ApiInitiativeModel.getInitiatives(
        {
          cultureCode: $translate.use(),
          limit: vm.pageSize,
          offset: (vm.currentPage - 1) * vm.pageSize,
        },
        (initiatives) => {
          vm.initiatives = initiatives
          vm.loadingData = false
        },
        () => {
          vm.loadingData = false
        },
      )
    }
  }
})()
