;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppStaticLinks', {
    templateUrl: 'app/modules/front/homepage/components/static-links/index.html',
    controller: StaticLinksController,
  })

  StaticLinksController.$inject = ['$translate']

  function StaticLinksController($translate) {
    const vm = this

    activate()

    function activate() {
      getStaticLinks()
    }

    function getStaticLinks() {
      vm.staticLinks = [
        {
          path: 'front.map.initiative',
          name: $translate.instant('FRONT.HOMEPAGE.LINKS.LINK1'),
        },
        {
          url: 'https://www.praha1.cz',
          name: $translate.instant('FRONT.HOMEPAGE.LINKS.LINK2'),
        },
        {
          url: 'https://praha1.online/bulletin/list',
          name: $translate.instant('FRONT.HOMEPAGE.LINKS.LINK3'),
        },
        {
          url: 'https://praha1.online/default/streets',
          name: $translate.instant('FRONT.HOMEPAGE.LINKS.LINK4'),
        },
        {
          url: 'https://nebytypraha1.cz',
          name: $translate.instant('FRONT.HOMEPAGE.LINKS.LINK5'),
        },
      ]
    }
  }
})()
