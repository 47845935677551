;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('ChemistryLayerProvider', ChemistryLayerProvider)

  ChemistryLayerProvider.$inject = [
    'ItemCollectionLayerProviderBase',
    'ApiMapModel',
    'MapHelperService',
    'GeometryHelper',
  ]

  function ChemistryLayerProvider(
    ItemCollectionLayerProviderBase,
    ApiMapModel,
    MapHelperService,
    GeometryHelper,
  ) {
    return function (map) {
      var child = Object.create(ItemCollectionLayerProviderBase)
      child.super(map)

      child.init = function () {}

      child.update = function update() {
        ApiMapModel.getChemistryHazardousAreas(function (hazardousAreas) {
          child.processItems(hazardousAreas)
        })
      }

      child.createLayer = function createLayer(item) {
        var markerOptions = {
          icon: MapHelperService.createIcon('styles/img/waste.png'),
        }
        var latlng = map.options.crs.unproject({
          x: item.coordinateX,
          y: item.coordinateY,
        })
        angular.extend(item, { coordinates: latlng })

        return new L.marker(latlng, markerOptions)
      }

      child.getItemKey = function getItemKey(item) {
        return item.id.toString()
      }

      child.itemsEqual = function itemsEqual(item1, item2) {
        return GeometryHelper.coordinatesEqual(item1, item2) && item1.name === item2.name
      }

      child.init()
      return child
    }
  }
})()
