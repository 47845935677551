;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.services')
    .factory('RiverStationLayerProvider', RiverStationLayerProvider)

  RiverStationLayerProvider.$inject = [
    '$q',
    '$rootScope',
    '$stateParams',
    '$templateRequest',
    '$compile',
    '$timeout',
    '$filter',
    'MapHelperService',
    'CustomLayer',
    'ApiMapModel',
    'ApiSourceModel',
  ]

  function RiverStationLayerProvider(
    $q,
    $rootScope,
    $stateParams,
    $templateRequest,
    $compile,
    $timeout,
    $filter,
    MapHelperService,
    CustomLayer,
    ApiMapModel,
    ApiSourceModel,
  ) {
    function initRiverStationLayer(configuration, layerCollection) {
      ApiMapModel.getRiverStations((data) => {
        ApiSourceModel.getRiversSpaTypes((spaTypes) => {
          var promise = _getRiverStationsLayerGroup(data.stations, spaTypes)
          promise.then((stationGroup) => {
            var customLayer = new CustomLayer(
              angular.extend(configuration, {
                layer: stationGroup,
              }),
            )
            MapHelperService.addLayer(customLayer, layerCollection)
            _handlePopupOpen(stationGroup)
          })
        })
      })
    }

    function _getRiverStationsLayerGroup(stations) {
      var deferred = $q.defer(),
        markers = [],
        promises = []

      angular.forEach(stations, (station) => {
        var location = $filter('riverStationNameToPositionFilter')(station.stationName)
        var marker = new L.Marker(new L.LatLng(location[0], location[1]), {
          draggable: false,
          icon: new L.Icon({
            iconUrl: 'styles/img/' + _.toLower(station.spa || 'normal') + '.png',
            iconSize: new L.Point(24, 24),
            iconAnchor: new L.Point(24, 24),
            shadowAnchor: new L.Point(12, 12),
            popupAnchor: new L.Point(5, 5),
          }),
        })

        var def = $q.defer()
        promises.push(def.promise)

        var popupScope = $rootScope.$new(true)
        angular.extend(popupScope, {
          location: location,
          station: station,
          today: moment().format('LL'),
          actualizationDate: moment(station.timestampUtc).format('LL'),
          actualizationTime: moment(station.timestampUtc).format('LTS'),
        })

        $templateRequest('app/modules/_common/service/maps/html/riverStationPopup.html').then((response) => {
          var template = $compile(response)(popupScope)
          $timeout(() => {
            marker.bindPopup(
              template[0].outerHTML,
              MapHelperService.extendLeafletPopupDefaultOptions({}),
            )
            marker.stationName = station.stationName
            markers.push(marker)
            def.resolve(marker)
          })
        })
      })

      $q.all(promises).then((markers) => {
        var stationGroup = new L.LayerGroup(markers)
        deferred.resolve(stationGroup)
      })

      return deferred.promise
    }

    function _handlePopupOpen(stationGroup) {
      const stationName = $stateParams.stationName
      stationGroup.eachLayer((marker) => {
        if (stationName && marker.stationName === stationName.toString()) {
          marker.openPopup()
        }
      })
    }

    return {
      initRiverStationLayer: initRiverStationLayer,
    }
  }
})()
