;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.services').factory('FloodsMapService', FloodsMapService)

  FloodsMapService.$inject = [
    '$rootScope',
    'leafletData',
    'MapHelperService',
    'MapDialogService',
    'LineLayer',
    'RiverStationLayerProvider',
    'APP_CONFIG',
  ]

  function FloodsMapService(
    $rootScope,
    leafletData,
    MapHelperService,
    MapDialogService,
    LineLayer,
    RiverStationLayerProvider,
    APP_CONFIG,
  ) {
    var defaultOpacity = 0.8
    var defaultFillOpacity = 0.4
    var defaultSimplifyFactor = 2.5

    function initFloodsLayers(map, layerCollection) {
      angular.forEach(
        APP_CONFIG.mapSettings.floodsLayers.lineLayers,
        function (lineLayerConfiguration) {
          var lineLayer = new LineLayer(
            angular.extend(lineLayerConfiguration, {
              clickContext: MapDialogService,
              clickFn: MapDialogService[lineLayerConfiguration.dialogFn],
              opacity: defaultOpacity,
              fillOpacity: defaultFillOpacity,
              simplifyFactor: defaultSimplifyFactor,
              map: map,
              popupScope: $rootScope.$new(),
            }),
          )
          MapHelperService.addLayer(lineLayer, layerCollection)
        },
      )
    }

    function initFloodsMap(data) {
      MapHelperService.initViewModelMapData(data)

      leafletData.getMap('map').then(function (map) {
        var layerCollection = MapHelperService.initMap(map, data)
        initFloodsLayers(map, layerCollection)

        var riverStationLayer = APP_CONFIG.mapSettings.floodsLayers.riverStationLayer
        if (riverStationLayer) {
          RiverStationLayerProvider.initRiverStationLayer(riverStationLayer, layerCollection)
        }
      })
    }

    return {
      initFloodsMap: initFloodsMap,
      initFloodsLayers: initFloodsLayers,
    }
  }
})()
