;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('eventSeverity', EventSeverityFilter)

  EventSeverityFilter.$inject = []

  function EventSeverityFilter() {
    return function (type, types) {
      for (var key in types) {
        if (types.hasOwnProperty(key)) {
          if (types[key].id === type) {
            return types[key].name
          }
        }
      }
      return type
    }
  }
})()
