;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('DeleteFileModalController', DeleteFileModalController)

  DeleteFileModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminDocumentModel',
    'FlashMessage',
    'file',
    'folder',
    'cultureCode',
  ]

  function DeleteFileModalController(
    $uibModalInstance,
    ApiAdminDocumentModel,
    FlashMessage,
    file,
    folder,
    cultureCode,
  ) {
    const vm = this
    vm.file = file

    vm.ok = ok
    vm.cancel = cancel
    

    function ok() {
      var promise = ApiAdminDocumentModel.deleteFile(
        { id: folder.folderId, id1: vm.file.fileId, cultureCode: cultureCode },
        function () {
          FlashMessage.add('Soubor ' + vm.file.fileAlias + ' byl úspěšně smazán.', 'success')
        },
      )
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
