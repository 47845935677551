;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.source')
    .controller('AdminPassabilityPointFormController', AdminPassabilityPointFormController)

  AdminPassabilityPointFormController.$inject = [
    '$state',
    '$stateParams',
    '$uibModal',
    'MapSelectionService',
    'ApiAdminPassabilityModel',
    'FlashMessage',
  ]

  function AdminPassabilityPointFormController(
    $state,
    $stateParams,
    $uibModal,
    MapSelectionService,
    ApiAdminPassabilityModel,
    FlashMessage,
  ) {
    const vm = this
    vm.isEdit = !!$stateParams.id
    vm.ok = ok
    vm.openUploadImageModal = openUploadImageModal

    vm.data = {
      mapLayers: [],
      legend: [],
    }

    activate()

    function activate() {
      if (vm.isEdit) {
        getPassabilityPoint()
      } else {
        vm.passabilityPoint = { position: {} }
        initMap(vm.passabilityPoint)
      }
    }

    function getPassabilityPoint() {
      ApiAdminPassabilityModel.getSingle({ id: $stateParams.id }, (data) => {
        vm.passabilityPoint = data.passabilityPoint
        initMap(vm.passabilityPoint)
      })
    }

    function initMap(passabilityPoint) {
      MapSelectionService.initMapForSelect(vm.data, passabilityPoint.position)
    }

    function openUploadImageModal() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/layout/modal/textAngular/uploadImageModal.html',
        controller: 'UploadImageModalController',
        controllerAs: 'modalCtrl',
      })
      modalInstance.result.then(
        function (images) {
          angular.forEach(images, function (image) {
            vm.passabilityPoint.imageCdnDataId = image.fileInfo.id
          })
        },
        function () {},
      )
    }

    function ok() {
      if (vm.isEdit) {
        ApiAdminPassabilityModel.edit({}, { passabilityPoint: vm.passabilityPoint }, () => {
          FlashMessage.add(
            `Průjezdnost ${vm.passabilityPoint.placeName} byla úspěšně editována.`,
            'warning',
            1,
          )
          $state.go('admin.passability')
        })
      } else {
        ApiAdminPassabilityModel.create({}, { passabilityPoint: vm.passabilityPoint }, () => {
          FlashMessage.add(
            `Průjezdnost ${vm.passabilityPoint.placeName} byla úspěšně vytvořena.`,
            'success',
            1,
          )
          $state.go('admin.passability')
        })
      }
    }
  }
})()
