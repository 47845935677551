;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.filters').filter('address', AddressFilter)

  AddressFilter.$inject = []

  function AddressFilter() {
    return function (event) {
      var address = ''
      if (event) {
        address = [event.city, event.cityPart, event.cityDistrict, event.street].join(' ')

        if (event.houseNumber) {
          address += ' ' + event.houseNumber
          if (event.landRegistryNumber) {
            address += '/' + event.landRegistryNumber
          } else if (event.orientationNumber) {
            address += '/' + event.orientationNumber
          }
        } else if (event.landRegistryNumber) {
          address += ' ' + event.landRegistryNumber
          if (event.houseNumber) {
            address += '/' + event.houseNumber
          }
        }
      }
      return _.trim(address)
    }
  }
})()
