;(() => {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.homepage').component('cppTraffic', {
    templateUrl: 'app/modules/front/homepage/components/traffic/index.html',
    controller: TrafficController,
  })

  TrafficController.$inject = ['ApiTrafficModel']

  function TrafficController(ApiTrafficModel) {
    const vm = this

    activate()

    function activate() {
      getDdrMessages()
    }

    function getDdrMessages() {
      vm.loadingDdrData = true
      ApiTrafficModel.getDdrMessages(
        { limit: 3, offset: 0 },
        (data) => {
          vm.loadingDdrData = false
          vm.ddrData = data.messages
        },
        () => {
          vm.loadingDdrData = false
        },
      )
    }
  }
})()
