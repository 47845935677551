;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.cms')
    .controller('DeactivateArticleModalController', DeactivateArticleModalController)

  DeactivateArticleModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminArticleModel',
    'FlashMessage',
    'article',
    'cultureData',
  ]

  function DeactivateArticleModalController(
    $uibModalInstance,
    ApiAdminArticleModel,
    FlashMessage,
    article,
    cultureData,
  ) {
    const vm = this
    vm.article = article
    vm.cultureData = cultureData

    vm.ok = ok
    vm.cancel = cancel
    

    function ok() {
      var promise = ApiAdminArticleModel.deactivateArticle(
        { cultureCode: vm.cultureData.cultureCode },
        { cultureCode: vm.cultureData.cultureCode, id: vm.article.id },
        function () {
          if (cultureData.isDefault) {
            FlashMessage.add('Článek ' + vm.article.title + ' byl úspěšně deaktivován.', 'success')
          } else {
            FlashMessage.add(
              'Překlad článku ' + vm.article.title + ' byl úspěšně deaktivován.',
              'success',
            )
          }
        },
      )
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
