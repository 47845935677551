;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.user')
    .controller('AdminUserProfileController', AdminUserProfileController)

  AdminUserProfileController.$inject = [
    '$translate',
    'ApiUserModel',
    'ApiLanguageModel',
    'PersistenceModel',
    'FlashMessage',
  ]

  function AdminUserProfileController(
    $translate,
    ApiUserModel,
    ApiLanguageModel,
    PersistenceModel,
    FlashMessage,
  ) {
    const vm = this
    var userCopy = {}
    vm.saveUser = saveUser
    vm.saveUserPassword = saveUserPassword

    activate()

    function activate() {
      getUserProfile()
      getDistricts()
      getLanguages()
    }

    function getUserProfile() {
      vm.user = ApiUserModel.getUser({ id: PersistenceModel.getUserId() }, function (data) {
        userCopy = angular.copy(data)
      })
    }

    function getDistricts() {
      vm.districts = ApiUserModel.getAllDistricts()
    }

    function getLanguages() {
      vm.languages = ApiLanguageModel.getAllLanguages()
    }

    function saveUser() {
      $translate.use(vm.user.cultureCode)
      ApiUserModel.editUser({ id1: vm.user.id }, { user: vm.user }, function () {
        userCopy = angular.copy(vm.user)
        FlashMessage.add('Údaje uživatele ' + vm.user.email + ' byly úspěšně editovány.', 'success')
      })
    }

    function saveUserPassword(credentials) {
      var user = angular.extend(userCopy, credentials)
      ApiUserModel.editUser({ id1: vm.user.id }, { user: user }, function () {
        FlashMessage.add('Heslo uživatele ' + vm.user.email + ' bylo úspěšně změněno.', 'success')
      })
    }
  }
})()
