;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.admin.user').controller('AdminUserController', AdminUserController)

  AdminUserController.$inject = [
    '$q',
    '$http',
    '$uibModal',
    'ApiAdminUserModel',
    'ApiUserModel',
    'ApiFilterService',
    'BOOL_VALUES',
  ]

  function AdminUserController(
    $q,
    $http,
    $uibModal,
    ApiAdminUserModel,
    ApiUserModel,
    ApiFilterService,
    BOOL_VALUES,
  ) {
    const vm = this
    vm.pageSize = 100
    vm.boolValues = BOOL_VALUES
    vm.query = { roles: [''], districts: [''], interestDistricts: [''] }
    vm.userRolesQuery = { roles: [''] }

    vm.downloadUsers = downloadUsers

    vm.openEditUser = openEditUser
    vm.openDeactivateUser = openDeactivateUser
    vm.openResetUserPassword = openResetUserPassword

    activate()

    function activate() {
      prepareTableHeaders()
      prepareRoleTableHeaders()

      vm.userRoles = getRoles()
      vm.districts = getDistricts()
      $q.all([vm.userRoles.$promise, vm.districts.$promise]).then(function () {
        getUsers()
      })
    }

    function getRoles() {
      vm.loadingUserRoles = true
      return ApiAdminUserModel.getAllRoles(
        function (data) {
          angular.forEach(data, function (role) {
            vm.query.roles.push(role.id)
          })
          vm.loadingUserRoles = false
        },
        function () {
          vm.loadingUserRoles = false
        },
      )
    }

    function getDistricts() {
      return ApiUserModel.getAllDistricts(function (data) {
        angular.forEach(data, function (district) {
          vm.query.districts.push(district.id)
          vm.query.interestDistricts.push(district.id)
        })
      })
    }

    function getUsers() {
      vm.loadingData = true
      vm.users = ApiAdminUserModel.getAllUsers(
        function () {
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }

    function downloadUsers(format) {
      var search = ApiFilterService.transformSearchQueryAttribute(
        vm.query,
        ApiFilterService.KEYS.user,
      )
      ApiAdminUserModel.downloadUsers({
        orderBy: vm.orderByField,
        desc: vm.reverseSort,
        search: search,
        format: format,
      })
    }

    function prepareTableHeaders() {
      vm.headers = []
      $http.get('app/modules/admin/user/meta/user-list.json').then(function (response) {
        vm.headers = response.data
      })
    }

    function prepareRoleTableHeaders() {
      vm.roleHeaders = []
      $http.get('app/modules/admin/user/meta/user-role-list.json').then(function (response) {
        vm.roleHeaders = response.data
      })
    }

    function openEditUser(user) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/user/modal/user/editUserModal.html',
        controller: 'EditUserModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          user: function () {
            return user
          },
          roles: function () {
            return vm.userRoles
          },
          districts: function () {
            return vm.districts
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getUsers()
          })
        },
        function () {},
      )
    }

    function openDeactivateUser(user) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/user/modal/user/deactivateUserModal.html',
        controller: 'DeactivateUserModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          user: function () {
            return user
          },
        },
      })
      modalInstance.result.then(
        function (promise) {
          promise.$promise.then(function () {
            getUsers()
          })
        },
        function () {},
      )
    }

    function openResetUserPassword(user) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/modules/admin/user/modal/password/resetUserPasswordModal.html',
        controller: 'ResetUserPasswordModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          user: function () {
            return user
          },
        },
      })
      modalInstance.result.then(
        function () {},
        function () {},
      )
    }
  }
})()
