;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.article').controller('ArticleSearchController', ArticleSearchController)

  ArticleSearchController.$inject = ['$stateParams', '$translate', 'ApiSearchModel']

  function ArticleSearchController($stateParams, $translate, ApiSearchModel) {
    const vm = this
    vm.pageSize = 20
    vm.currentPage = 1

    activate()

    function activate() {
      vm.searchedText = $stateParams.fulltext

      vm.loadingData = true
      ApiSearchModel.search(
        {
          pattern: vm.searchedText,
          cultureCode: $translate.use(),
          limit: vm.pageSize,
          offset: (vm.currentPage - 1) * vm.pageSize,
        },
        function (searchData) {
          vm.searchResults = searchData
          vm.loadingData = false
        },
        function () {
          vm.loadingData = false
        },
      )
    }
  }
})()
