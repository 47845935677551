;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiInitiativeModel', ApiInitiativeModel)

  ApiInitiativeModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiInitiativeModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'initiatives/:relativeUrl/:path',
      { relativeUrl: '@relativeUrl', path: '@path' },
      {
        getInitiatives: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('initiatives')) {
              return data.initiatives
            }
            return data
          },
        },
        getInitiative: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('initiative')) {
              return data.initiative
            }
            return data
          },
        },
        createInitiative: {
          method: 'POST',
          transformResponse: function (data, headersGetter) {
            data = data ? angular.fromJson(data) : {}
            if (headersGetter('Location')) {
              data.relativeUrl = UtilService.getIdFromLocation(headersGetter('Location'))
            }
            return data
          },
        },
        getInitiativeCategories: {
          method: 'GET',
          params: {
            path: 'categories',
          },
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('initiativeCategories'),
        },
        getInitiativeCategory: {
          method: 'GET',
          params: {
            path: 'categories',
          },
          transformResponse: UtilService.simpleResponseTransform('category'),
        },
        getInitiativeStateTypes: {
          method: 'GET',
          params: {
            path: 'stateTypes',
          },
          isArray: true,
          transformResponse: UtilService.simpleResponseTransform('initiativeStateTypes'),
        },
      },
    )
  }
})()
