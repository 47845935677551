;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminSourceModel', ApiAdminSourceModel)

  ApiAdminSourceModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminSourceModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/:path/:id/:path1',
      { path: '@path', id: '@id', path1: '@path1' },
      {
        getPoliceEntries: {
          method: 'GET',
          isArray: true,
          params: {
            path: 'svod',
          },
          transformResponse: UtilService.simpleResponseTransform('data'),
        },
        getSearchedPoliceEntries: {
          method: 'POST',
          params: {
            path: 'svod',
            path1: 'search',
          },
        },
        getSearchedFiremanEntries: {
          method: 'POST',
          params: {
            path: 'fireDept',
            path1: 'search',
          },
        },
        getSearchedNoiseEntries: {
          method: 'POST',
          params: {
            path: 'noise',
            path1: 'search',
          },
        },
        getSearchedPubEntries: {
          method: 'POST',
          params: {
            path: 'restaurants',
            path1: 'search',
          },
        },
        createPub: {
          method: 'POST',
          params: {
            path: 'restaurants',
          },
        },
        getPub: {
          method: 'GET',
          params: {
            path: 'restaurants',
          },
        },
        editPub: {
          method: 'PUT',
          params: {
            path: 'restaurants',
          },
        },
        deletePub: {
          method: 'DELETE',
          params: {
            path: 'restaurants',
          },
        },
        getNoiseDataExport: {
          method: 'POST',
          params: {
            path: 'noise',
            path1: 'export',
          },
          transformResponse: function (data, headers) {
            var attachment = null
            if (data) {
              attachment = new Blob([data], {
                type: 'text/csv',
              })
            }
            var fileName =
              UtilService.getFileNameFromHeader(headers('content-disposition')) ||
              'ExportDatHluku.csv'
            return {
              blob: attachment,
              fileName: fileName,
            }
          },
        },
      },
    )
  }
})()
