;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.initiative')
    .controller('EditCategoryModalController', EditCategoryModalController)

  EditCategoryModalController.$inject = [
    '$uibModalInstance',
    'ApiLanguageModel',
    'ApiAdminInitiativeModel',
    'FlashMessage',
    'APP_CONFIG',
    'users',
    'category',
  ]

  function EditCategoryModalController(
    $uibModalInstance,
    ApiLanguageModel,
    ApiAdminInitiativeModel,
    FlashMessage,
    APP_CONFIG,
    users,
    category,
  ) {
    const vm = this
    vm.addTranslation = addTranslation
    vm.isRequired = isRequired
    vm.checkLogo = checkLogo

    vm.ok = ok
    vm.cancel = cancel

    vm.users = users
    vm.category = angular.copy(category)

    vm.categoryLogo = {
      isImage: false,
    }

    activate()

    function activate() {
      getLanguages()
    }

    function filterTranslations(data) {
      vm.languages = _.differenceBy(data, vm.category.cultures, 'cultureCode')
      if (vm.languages.length > 0) {
        vm.addTranslationSelect = vm.languages[0]
      }
    }

    function getLanguages() {
      ApiLanguageModel.getAllLanguages(function (data) {
        filterTranslations(data)
      })
    }

    function addTranslation(culture) {
      culture.name = ''
      vm.category.cultures.push(culture)
      filterTranslations(vm.languages)
    }

    function isRequired(culture) {
      return culture === APP_CONFIG.translation.fallbackLanguage
    }

    function checkLogo() {
      vm.categoryLogo.isImage = vm.categoryLogo.type.startsWith('image/')
      vm.categoryLogo.touched = true
    }

    function ok() {
      if (!vm.category.initiativesAdmins || vm.category.initiativesAdmins.length === 0) {
        FlashMessage.add('Není vyplněn žádný správce kategorie.', 'danger')
        return
      }

      if (vm.categoryLogo.isImage) {
        vm.category.logo = 'data:' + vm.categoryLogo.type + ';base64,' + vm.categoryLogo.content
      }
      var promise = ApiAdminInitiativeModel.updateInitiativeCategory(
        { id: vm.category.id, initiativeCategory: vm.category },
        function () {
          FlashMessage.add('Kategorie oznámení byla úspěšně upravena.', 'success')
        },
      )
      $uibModalInstance.close(promise)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
