;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiPdfModel', ApiPdfModel)

  ApiPdfModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiPdfModel($resource, UtilService, APP_CONFIG) {
    return $resource(
      APP_CONFIG.pdfApiRootUrl + 'api/v1/pdf/:path',
      { path: '@path' },
      {
        downloadPdf: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
      },
    )
  }
})()
