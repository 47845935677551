;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.common.models').factory('ApiAdminCameraModel', ApiAdminCameraModel)

  ApiAdminCameraModel.$inject = ['$resource', 'UtilService', 'APP_CONFIG']

  function ApiAdminCameraModel($resource, UtilService, APP_CONFIG) {
    function extendCameraObject(camera) {
      return angular.extend(camera, {
        imageUrl: APP_CONFIG.apiRootUrl + 'cameras/' + camera.id + '/image',
        sizeX: 1,
        sizeY: 1,
      })
    }

    return $resource(
      APP_CONFIG.apiRootUrl + 'admin/cameras/:path/:id1/:path1/:id2/:path2',
      {
        path: '@path',
        id1: '@id1',
        path1: '@path1',
        id2: '@id2',
        path2: '@path2',
      },
      {
        getAll: {
          method: 'GET',
          isArray: true,
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cameras')) {
              angular.forEach(data.cameras, function (camera, index) {
                data.cameras[index] = extendCameraObject(camera)
              })
              return data.cameras
            }
            return data
          },
        },
        downloadCameras: {
          method: 'POST',
          params: {
            path: 'export',
          },
          responseType: 'blob',
          transformResponse: function (data, headersGetter) {
            UtilService.downloadFile(data, headersGetter())
          },
        },
        getCamera: {
          method: 'GET',
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('camera')) {
              return data.camera
            }
            return data
          },
        },
        editCamera: {
          method: 'PUT',
        },
        getCameraGrids: {
          method: 'GET',
          isArray: true,
          params: {
            path1: 'grids',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cameraGrids')) {
              var map = {
                coordinateX: 'col',
                coordinateY: 'row',
              }
              angular.forEach(data.cameraGrids, function (grid) {
                angular.forEach(grid.cameraPositions, function (camera, index) {
                  grid.cameraPositions[index] = extendCameraObject(
                    UtilService.remapObjectAttributes(camera, map),
                  )
                })
              })
              return data.cameraGrids
            }
            return data
          },
        },
        createCameraGrid: {
          method: 'POST',
          params: {
            path1: 'grids',
          },
          transformRequest: function (data) {
            var map = {
              col: 'coordinateX',
              row: 'coordinateY',
            }
            angular.forEach(data.cameraPositions, function (camera, index) {
              data.cameraPositions[index] = UtilService.remapObjectAttributes(camera, map)
            })
            return angular.toJson({ cameraGrid: data })
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        getCameraGrid: {
          method: 'GET',
          params: {
            path1: 'grids',
          },
          transformResponse: function (data) {
            data = angular.fromJson(data)
            if (data && data.hasOwnProperty('cameraGrid')) {
              var map = {
                coordinateX: 'col',
                coordinateY: 'row',
              }
              angular.forEach(data.cameraGrid.cameraPositions, function (camera, index) {
                data.cameraGrid.cameraPositions[index] = extendCameraObject(
                  UtilService.remapObjectAttributes(camera, map),
                )
              })
              return data.cameraGrid
            }
            return data
          },
        },
        editCameraGrid: {
          method: 'PUT',
          params: {
            path1: 'grids',
          },
          transformRequest: function (data) {
            var map = {
              col: 'coordinateX',
              row: 'coordinateY',
            }
            angular.forEach(data.cameraPositions, function (camera, index) {
              data.cameraPositions[index] = UtilService.remapObjectAttributes(camera, map)
            })
            return angular.toJson({ cameraGrid: data })
          },
        },
        deleteCameraGrid: {
          method: 'DELETE',
          params: {
            path1: 'grids',
          },
        },
        // future cameras
        getFutureCameraEntries: {
          method: 'POST',
          params: {
            path1: 'future',
            path2: 'search',
          },
        },
        getFutureCamerasExport: {
          method: 'POST',
          params: {
            path1: 'future',
            path2: 'export',
          },
          transformResponse: function (data, headers) {
            var attachment = null
            if (data) {
              attachment = new Blob([data], {
                type: 'text/csv',
              })
            }
            var fileName =
              UtilService.getFileNameFromHeader(headers('content-disposition')) ||
              'ExportPlanovanychKamer.csv'
            return {
              blob: attachment,
              fileName: fileName,
            }
          },
        },
        getFutureCameraRequest: {
          method: 'GET',
          params: {
            path1: 'future',
          },
          transformResponse: UtilService.simpleResponseTransform('camera'),
        },
        createFutureCamera: {
          method: 'POST',
          params: {
            path1: 'future',
          },
          transformResponse: function (data, headersGetter) {
            if (headersGetter('Location')) {
              return {
                id: UtilService.getIdFromLocation(headersGetter('Location')),
              }
            }
            return data ? angular.fromJson(data) : null
          },
        },
        updateFutureCamera: {
          method: 'PUT',
          params: {
            path1: 'future',
          },
        },
        deleteFutureCameraRequest: {
          method: 'DELETE',
          params: {
            path1: 'future',
          },
        },
        assignFutureCameraAttachment: {
          method: 'PUT',
          params: {
            path: 'future',
            path1: 'attachments',
          },
        },
        unassignFutureCameraAttachment: {
          method: 'DELETE',
          params: {
            path: 'future',
            path1: 'attachments',
          },
        },
      },
    )
  }
})()
