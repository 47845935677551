;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.layout')
    .controller('UploadImageModalController', UploadImageModalController)

  UploadImageModalController.$inject = [
    '$uibModalInstance',
    'PersistenceModel',
    'Upload',
    '$timeout',
    'APP_CONFIG',
  ]

  function UploadImageModalController(
    $uibModalInstance,
    PersistenceModel,
    Upload,
    $timeout,
    APP_CONFIG,
  ) {
    const vm = this
    vm.files = []
    vm.apiKey = PersistenceModel.getAccessToken()

    vm.uploadImages = uploadImages
    vm.ok = ok
    vm.cancel = cancel
    vm.uploadLimits = APP_CONFIG.uploadLimits.images

    activate()

    function activate() {
      vm.numberOfUploadingFiles = 0
    }

    function uploadImages(files, errFiles) {
      vm.files = files
      vm.errFiles = errFiles
      vm.numberOfUploadingFiles = files.length

      angular.forEach(files, function (file) {
        file.uploading = true
        file.upload = Upload.upload({
          url: APP_CONFIG.apiRootUrl + 'admin/cdn/files/upload?public=true',
          headers: {
            'Content-Type': file.type,
            'X-Api-Key': vm.apiKey,
          },
          data: { file: file },
        })

        file.upload.then(
          function (response) {
            $timeout(function () {
              if (response.data.hasOwnProperty('fileInfo')) {
                var fileInfo = response.data.fileInfo
                fileInfo.url = APP_CONFIG.cdnUrl + fileInfo.id
                file.fileInfo = fileInfo
                file.uploading = false
                vm.numberOfUploadingFiles--
              } else {
                console.log('Returned data: ' + response.data)
              }
            })
          },
          function (response) {
            if (response.status > 0) {
              vm.errorMsg = response.status + ': ' + response.data.errorMessage
              file.uploading = false
              vm.numberOfUploadingFiles--
            }
          },
          function (evt) {
            file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total))
          },
        )
      })
    }

    function ok() {
      $uibModalInstance.close(vm.files)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
