;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular.module('app.front.map').controller('CameraMapController', CameraMapController)

  CameraMapController.$inject = [
    '$scope',
    '$uibModal',
    '$uibModalStack',
    '$interval',
    '$timeout',
    'ApiCameraModel',
    'ApiUserModel',
    'CameraMapService',
    'PersistenceModel',
  ]

  function CameraMapController(
    $scope,
    $uibModal,
    $uibModalStack,
    $interval,
    $timeout,
    ApiCameraModel,
    ApiUserModel,
    CameraMapService,
    PersistenceModel,
  ) {
    const vm = this

    vm.data = {}
    vm.activeIndex = 0
    vm.cameraGrids = []

    vm.changeAutoRefresh = changeAutoRefresh

    activate()

    function activate() {
      getUserRefreshRate().then(function (user) {
        vm.userCameraRefreshRate = user.usersAccessRights.cameraRefreshRate * 1000
        vm.userPrivateCameraAccess = user.usersAccessRights.privateAccess

        if (PersistenceModel.isAuthenticated()) {
          getCameraGrids()
        } else {
          addGrid()
          initMap()
        }
      })
    }

    function getUserRefreshRate() {
      return ApiUserModel.getCurrentUser().$promise
    }

    function getCameraGrids() {
      ApiCameraModel.getCameraGrids(function (grids) {
        if (grids.length > 0) {
          angular.forEach(grids, function (grid) {
            addGrid(grid)
            reloadImage(grid)
          })
        } else {
          addGrid()
        }
        initMap()
      })
    }

    function initMap() {
      CameraMapService.initCameraMap(
        null,
        vm.cameraGrids[0],
        vm.data,
        openCameraDetail,
        vm.userPrivateCameraAccess,
      )
    }

    function addGrid(grid) {
      vm.cameraGrids.push({
        id: grid ? grid.id : null,
        name: grid ? grid.name : 'Grid ' + (vm.cameraGrids.length + 1),
        reloadTimer: null,
        countdownTimer: null,
        reloadEnabled: true,
        autoRefreshEnabled: false,
        refreshRate: vm.userCameraRefreshRate,
        cameraPositions: grid ? grid.cameraPositions : [],
        gridsterOptions: {
          margins: [20, 20],
          columns: grid ? grid.columnNumber : 2,
          draggable: {
            handle: 'div.draggable-' + vm.cameraGrids.length,
          },
        },
      })
      vm.activeIndex = vm.cameraGrids.length - 1
    }

    function openCameraDetail(grid, camera) {
      $uibModalStack.dismissAll()
      $uibModal.open({
        templateUrl: 'app/modules/admin/camera/modal/camera/cameraDetailModal.html',
        controller: 'CameraDetailModalController',
        controllerAs: 'modalCtrl',
        resolve: {
          camera: function () {
            camera.disabled = true
            return camera
          },
          refreshRate: function () {
            return vm.userCameraRefreshRate
          },
        },
      })
    }

    function reloadImage(grid) {
      grid.imageLoadedTime = new Date().getTime()
      angular.forEach(grid.cameraPositions, function (camera) {
        ApiCameraModel.getImage({ id1: camera.id }, function (data) {
          camera.dataUrl = data.contentBase64
          camera.imageContentType = data.contentType
        })
      })
      grid.reloadEnabled = false
      setupReloadTimer(grid)
    }

    function setupCountdownTimer(grid) {
      if (grid.countdownTimer) {
        $interval.cancel(grid.countdownTimer)
      }

      grid.timeToReload = grid.refreshRate - (new Date().getTime() - grid.imageLoadedTime)
      grid.countdownTimer = $interval(function () {
        grid.timeToReload = grid.refreshRate - (new Date().getTime() - grid.imageLoadedTime)
      }, 500)
    }

    function setupReloadTimer(grid) {
      if (grid.reloadTimer) {
        $timeout.cancel(grid.reloadTimer)
      }
      grid.reloadTimer = $timeout(function () {
        grid.reloadEnabled = true
        if (grid.autoRefreshEnabled) {
          reloadImage(grid)
        }
      }, grid.refreshRate)
    }

    function changeAutoRefresh(grid) {
      if (grid.reloadEnabled && grid.autoRefreshEnabled) {
        reloadImage(grid)
      }
      if (grid.autoRefreshEnabled) {
        setupCountdownTimer(grid)
      } else if (grid.countdownTimer) {
        $interval.cancel(grid.countdownTimer)
        grid.countdownTimer = null
      }
    }

    function destroyTimers() {
      angular.forEach(vm.cameraGrids, function (grid) {
        if (grid.countdownTimer) {
          $interval.cancel(grid.countdownTimer)
          grid.countdownTimer = null
        }
        if (grid.reloadTimer) {
          $timeout.cancel(grid.reloadTimer)
        }
      })
    }

    $scope.$on('$destroy', destroyTimers)
    $scope.$on('logout', destroyTimers)
  }
})()
