;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller(
      'FutureCameraRequestDeleteModalController',
      FutureCameraRequestDeleteModalController,
    )

  FutureCameraRequestDeleteModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminCameraModel',
    'FlashMessage',
    'camera',
  ]

  function FutureCameraRequestDeleteModalController(
    $uibModalInstance,
    ApiAdminCameraModel,
    FlashMessage,
    camera,
  ) {
    const vm = this

    vm.ok = ok
    vm.cancel = cancel

    vm.camera = camera
    

    function deleteFutureCameraRequest(camera) {
      var request = ApiAdminCameraModel.deleteFutureCameraRequest(
        { id2: camera.id },
        function (data) {
          FlashMessage.add('Kamera byla úspěšně smazána.', 'success', 1)
        },
      )
      $uibModalInstance.close(request)
    }

    function ok() {
      deleteFutureCameraRequest(vm.camera)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
