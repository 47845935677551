;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.camera')
    .controller('CameraDetailModalController', CameraDetailModalController)

  CameraDetailModalController.$inject = [
    '$scope',
    '$uibModalInstance',
    '$interval',
    '$timeout',
    'ApiCameraModel',
    'PersistenceModel',
    'camera',
    'refreshRate',
  ]

  function CameraDetailModalController(
    $scope,
    $uibModalInstance,
    $interval,
    $timeout,
    ApiCameraModel,
    PersistenceModel,
    camera,
    refreshRate,
  ) {
    const vm = this
    vm.data = {
      autoRefreshEnabled: false,
      refreshRate: refreshRate || 30000,
    }
    vm.camera = camera

    vm.ok = ok
    vm.cancel = cancel
    vm.changeAutoRefresh = changeAutoRefresh
    vm.isUserLoggedIn = PersistenceModel.isAuthenticated()

    var reloadTimer = null
    var countdownTimer = null
    var reloadEnabled = true

    activate()

    function activate() {
      reloadImage()
    }

    function reloadImage() {
      vm.data.imageLoadedTime = new Date().getTime()
      ApiCameraModel.getImage({ id1: camera.id }, function (data) {
        vm.camera.dataUrl = data.contentBase64
        vm.camera.imageContentType = data.contentType
      })
      reloadEnabled = false
      setupReloadTimer()
    }

    function setupCountdownTimer() {
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
      }

      vm.data.timeToReload = vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      countdownTimer = $interval(function () {
        vm.data.timeToReload =
          vm.data.refreshRate - (new Date().getTime() - vm.data.imageLoadedTime)
      }, 500)
    }

    function setupReloadTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      reloadTimer = $timeout(function () {
        reloadEnabled = true
        if (vm.data.autoRefreshEnabled) {
          reloadImage()
        }
      }, vm.data.refreshRate)
    }

    function changeAutoRefresh() {
      if (reloadEnabled && vm.data.autoRefreshEnabled) {
        reloadImage()
      }
      if (vm.data.autoRefreshEnabled) {
        setupCountdownTimer()
      } else if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    function ok() {
      $uibModalInstance.close(vm.camera)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }

    function destroyTimer() {
      if (reloadTimer) {
        $timeout.cancel(reloadTimer)
      }
      if (countdownTimer) {
        $interval.cancel(countdownTimer)
        countdownTimer = null
      }
    }

    $scope.$on('$destroy', destroyTimer)
    $scope.$on('logout', destroyTimer)
  }
})()
