;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.admin.document')
    .controller('CreateFileModalController', CreateFileModalController)

  CreateFileModalController.$inject = [
    '$uibModalInstance',
    'ApiAdminCdnModel',
    'ApiAdminDocumentModel',
    'FlashMessage',
    'folder',
  ]

  function CreateFileModalController(
    $uibModalInstance,
    ApiAdminCdnModel,
    ApiAdminDocumentModel,
    FlashMessage,
    folder,
  ) {
    const vm = this
    vm.ok = ok
    vm.cancel = cancel

    activate()

    function activate() {
      vm.file = {
        public: false,
      }
    }

    function ok(data) {
      var promises = []
      var promise = ApiAdminCdnModel.createFile(
        { cultureCode: folder.folderCultures[0].cultureCode },
        { file: data },
      )
      promises.push(promise.$promise)
      promises.push(
        promise.$promise.then(function (file) {
          return ApiAdminDocumentModel.addFile(
            {
              id: folder.folderId,
              id1: file.id,
              cultureCode: folder.folderCultures[0].cultureCode,
            },
            function () {
              folder.folderCultures[0].files.push({
                public: data.public,
                fileId: file.id,
                fileAlias: data.alias,
                fileName: data.name,
                keywords: data.keywords,
              })
              FlashMessage.add('Soubor byl úspěšně vytvořen.', 'success')
            },
          ).$promise
        }),
      )
      $uibModalInstance.close(promises)
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
