;(function () {
  'use strict'

  //###INSERT-LICENSE-HERE###

  angular
    .module('app.common.modals')
    .controller('LeaveConfirmationModalController', LeaveConfirmationModalController)

  LeaveConfirmationModalController.$inject = ['$uibModalInstance']

  function LeaveConfirmationModalController($uibModalInstance) {
    const vm = this

    vm.ok = ok
    vm.cancel = cancel

    function ok() {
      $uibModalInstance.close()
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel')
    }
  }
})()
